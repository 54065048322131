import React from 'react'
import * as Icons from './../../components/icons/index'

function Occasion(){
	return (
		<div className="occasion">
			<div className="container">
				<div className="occa">
					<Icons.Cargo24 width="2em" height="2em" />
					<span>
						<strong>24 Saatte</strong><br />
						kargoda
					</span>
				</div>
				<div className="occa">
					<Icons.CalendarTick width="2em" height="2em" />
					<span>
						<strong>14 günde</strong><br />
						iade
					</span>
				</div>
				<div className="occa">
					<Icons.ShieldTick width="2em" height="2em" />
					<span>
						<strong>7/24</strong><br />
						güvenli alışveriş
					</span>
				</div>
				<div className="occa">
					<Icons.CargoMan width="2em" height="2em" />
					<span>
						<strong>6 Taksit</strong><br />
						& kapıda ödeme
					</span>
				</div>
			</div>
		</div>
	)
}

export default Occasion
