import React, { useState, useEffect, useRef } from 'react'
import NumberFormat from 'react-number-format';
import * as Icons from './../../icons/index'

function Input(props) {

	let input_props = {...props, title: undefined, label: undefined, transferref: undefined}

	const [type, setType] = useState(props.type)
	const [tel, setTel] = useState(props.value)

	const isFirstRun = useRef(true)

	const phoneFormat = (value) => {
		if(!value?.length){
			return
		}
		let phone = value?.replace(/[^0-9]/gi, '')?.trim()

		if(phone.slice(0, 2) === "90" || phone.slice(0, 2) === "05"){
			let loop = true
			let newValue = phone
			while(loop){
				if(newValue.slice(2, 4) !== "90" && newValue.slice(2, 4) !== "05"){
					loop = false
				}
				newValue = newValue.slice(2, 12)
			}

			setTel(newValue)
			return
		}

		if(phone?.length === 10){
			setTel(phone)
			return
		}
	}

	useEffect(() => {
		if(props.type === "tel" && isFirstRun.current !== props.value){
			isFirstRun.current = props.value
			phoneFormat(props.value)
		}
	}, [props])

	return (
		<>
			{
				props.title &&
				<label htmlFor={props.name} className="form-label">
					{props.title}
				</label>
			}
			<>
				{
					props.type === "tel" ?
						<>
							<NumberFormat {...input_props} className={"form-control" + (props.error ? ' red' : '')} id={props.name} format="+90 (###) ### ####" value={tel}
							onChange={e => {
								phoneFormat(e.target.value)
								if(props.onChange){
									props.onChange(e)
								}
							}} onPaste={e => {
								e.preventDefault()
								phoneFormat(e.clipboardData.getData('Text'))
							}} mask="" getInputRef={props.transferref} allowEmptyFormatting />
						</>
					:
					props.type === "card" ?
						<NumberFormat {...input_props} className={"form-control" + (props.error ? ' red' : '')} id={props.name} format="####  ####  ####  ####" mask="_" type="text" getInputRef={props.transferref} allowEmptyFormatting />
					:
					props.type === "last" ?
						<NumberFormat {...input_props} className={"form-control" + (props.error ? ' red' : '')} id={props.name} format="##/##" placeholder="MM/YY" mask={['A', 'A', 'Y', 'Y']} type="text" getInputRef={props.transferref} allowEmptyFormatting />
					:
					props.type === "iban" ?
						<NumberFormat {...input_props} className={"form-control" + (props.error ? ' red' : '')} id={props.name} format="TR## #### #### #### #### #### ##" mask="_" type="text" getInputRef={props.transferref} allowEmptyFormatting />
					:
						<input {...input_props} className={"form-control" + (props.error ? ' red' : '')} id={props.name} ref={props.transferref} type={type}/>
				}
				{
					props.type === "password" &&
					<div className="show-password">
						{
							props.type === type ?
								<Icons.Eye onClick={() => setType('text')}/>
							:
								<Icons.EyeClose onClick={() => setType('password')}/>
						}
					</div>
				}
			</>
			{
				props.error &&  <span className="error danger mt-2">{props.error}</span>
			}
		</>
	)
}

export default Input
