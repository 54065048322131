import React from 'react'
import TypeLink from './../global/link'
import SwiperCore, { Navigation, Pagination, Scrollbar, Autoplay, Lazy } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

SwiperCore.use([Navigation, Pagination, Autoplay, Lazy]);

function Slider(props) {
	return (
		props.data &&
		<div className="banner">
			<div className="container">
				<Swiper slidesPerView={1} spaceBetween={10} pagination={{ clickable: true }} lazy={true} autoplay={{delay: 4000}} loop>
					{
						props.data?.map((elm, i) =>
							<SwiperSlide key={i}>
								<TypeLink to={elm.url}>
									<img src={elm.img} className="d-block w-100" alt={elm.title} loading="lazy"/>
								</TypeLink>
							</SwiperSlide>
						)
					}
				</Swiper>
			</div>
		</div>
	)
}

export default Slider
