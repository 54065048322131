import React, { useState, useEffect, Suspense } from "react";
import { Link } from "react-router-dom";
import Image from './image'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, Lazy } from 'swiper';

SwiperCore.use([Navigation, Pagination, Lazy])

function LastEnterProductList(props) {

	const [data, setData] = useState()

	useEffect(() => {
		setData(props.data)
	}, [props])

	return (
		<>
		{
			data &&
			<>
				<div className="clearfix"></div>
				<div className="last-enter mt-3 mb-lg-5 mt-lg-0">
					<div className="container">
						<h2 className="hr w-80"><span>SON GEZDİKLERİNİZ</span></h2>
						<div className="lse-product-list m-0">
						{
							window.innerWidth > 540 &&
							<Swiper slidesPerView={8} spaceBetween={10} lazy={true}>
								{
									data?.map((elm, i) =>
										<SwiperSlide key={i}>
										<div key={i} className="lse-item">
											<Link to={`/${elm.url}`} className="img"><Image src={elm.img} alt={elm.title}/></Link>
											<Link to={`/${elm.url}`} className="title">{elm.title}</Link>
										</div>
										</SwiperSlide>
									)
								}
							</Swiper>
						}
						{
							window.innerWidth <= 540 &&
							<Swiper slidesPerView={2} spaceBetween={10} pagination={{ clickable: true }} lazy={true}>
								{
									data?.map((elm, i) =>
										<SwiperSlide key={i}>
											<div key={i} className="lse-item">
												<Link to={`/${elm.url}`} className="img"><Image src={elm.img} alt={elm.title}/></Link>
												<Link to={`/${elm.url}`} className="title">{elm.title}</Link>
											</div>
										</SwiperSlide>
									)
								}
							</Swiper>
						}
						</div>
					</div>
				</div>
			</>
		}
		</>
	);
}

export default LastEnterProductList;
