import React from 'react'
import * as Icons from './../../icons/index'

function Checkbox(props) {
	let input_props = {...props, label: undefined, transferref: undefined}
	return (
		<div className={"mb-3 form-check " + (props.className ?? '')}>
			<label className="form-check-label" htmlFor={props.name}>
				<input type="checkbox" className="form-check-input" id={props.name} {...input_props} ref={props.transferref}/>
				<span><Icons.TickBold /></span>
				{props.label && <span dangerouslySetInnerHTML={{__html: props.label}}></span>}
				{props.label2 && <span>{props.label2}</span>}
				{props.error &&  <span className="error danger mt-2">{props.error}</span>}
			</label>
		</div>
	)
}

export default Checkbox
