import React from 'react'

import ShowingWord from './showing-word'
import Question from './question'

export default function RenderModal(props){
	switch(props?.id){
		case "kvkk":
			return <ShowingWord key={props.id} url="kvkk" size="large" {...props} />
		break;

		case "policy":
			return <ShowingWord key={props.id} url="policy" size="large" {...props}/>
		break;

		case "membership":
			return <ShowingWord key={props.id} url="agreement" size="large" {...props}/>
		break;

		case "question":
			return <Question key={props.id} {...props}/>
		break;

		default:
			console.warn("gerçersiz modal isteği")
		break;
	}
}
