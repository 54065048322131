import React, { createContext, Component } from 'react'
import axios from 'axios'
export const FiltersContext = createContext()

class FiltersContextProvider extends Component {

	constructor( props ) {
		super( props )

		this.state = {
			filterData: null
		}
	}

	componentDidMount(){
		const data = JSON.parse(sessionStorage.getItem("scl_gbl_d"))
		if(data){
			this.setState({filterData: data})
		}else{
			this.updateFilterData()
		}
	}

	updateFilterData = () => {
		axios.get("/GetGlobalData")
		.then(res => {
			this.setState({filterData: {
				brands: this.toSmartBrandData(res.data.data.brands),
				campaigns: this.toSmartCampaignData(res.data.data.campaigns),
				categories: this.toSmartCategoryData(res.data.data.categories),
				colors: this.toSmartColorsData(res.data.data.colors),
				features: this.toSmartFeaturesData(res.data.data.features),
				groups: this.toSmartGroupData(res.data.data.groups)
			}}, () => {
				sessionStorage.setItem("scl_gbl_d", JSON.stringify(this.state.filterData))
			})
		})
	}

	toSmartBrandData = (data) => {
		let brands = {}
		for (let i = 0; i < data?.length ?? 0; i++) {
			brands[data[i].code] = data[i].name
		}
		return brands
	}

	toSmartCampaignData = data => {
		let campaigns = {}
		for (let i = 0; i < data?.length ?? 0; i++) {
			campaigns[data[i].id] = {...data[i]}
		}
		campaigns['data'] = data
		return campaigns
	}

	toSmartCategoryData = data => {
		let categories = {}
		for (let i = 0; i < data?.length ?? 0; i++) {
			categories[data[i].code] = {...data[i]}
		}
		return categories
	}

	toSmartGroupData = data => {
		let groups = {}
		for (let i = 0; i < data?.length ?? 0; i++) {
			try {
				groups[data[i].id] = data[i]
			} catch{}
		}

		return groups
	}

	toSmartColorsData = data => {
		let colorsData = {}
		let whiteData = []
		var i = 0
		for(i = 0; i < data?.length; i++){
			try {
				if(data[i].children){
					for(var c = 0;c < data[i].children.length; c++){
						try{
							colorsData[data[i].children[c].code] = {...data[i].children[c], type: "children", parentCode: data[i].code}

							if(data[i].code === "0032" || data[i].hexcode.toLowerCase() === "#ffffff"){
								whiteData.push(data[i].children[c].code)
							}
						}
						catch{}
					}
				}

				if(data[i].code === "0032"){
					whiteData.push(data[i].code)
				}

				delete data[i]['children']
				colorsData[data[i].code] = {...data[i], type: "parent"}
			}
			catch{}
		}
		colorsData['whiteColors'] = whiteData
		return colorsData
	}

	toSmartFeaturesData = data => {
		let featuresData = {}
		for (var i = 0; i < data?.length; i++) {
			try {
				const elm = data[i]
				delete data[i]['titleDatas']
				delete data[i]['type']

				featuresData[elm.code] = data[i]
			} catch (e) {}
		}

		return featuresData
	}

	getBrandName = (code) => {
		return this.state.filterData?.brands?.[code] ?? code
	}

	getCategoryName = (code) => {
		return this.state.filterData?.categories?.[code]?.title ?? code
	}

	getGroupName = (code) => {
		return this.state.filterData?.groups?.[code].title ?? code
	}

	getWhiteColor = (value) => {
		return this.state.filterData?.colors?.['whiteData']?.indexOf(value)
	}

	getColorName = (code) => {
		return this.state.filterData?.colors?.[code]?.title ?? code
	}

	getColorHex = (code) => {
		return this.state.filterData?.colors?.[code]?.hexCode
	}

	getGroupUrl = (code) => {
		return this.state.filterData?.groups?.[code]?.titleInUrl ?? code
	}

	getFeatureName = (code) => {
		return this.state.filterData?.features?.[code]?.title ?? code
	}

	getFeatureUrl = (code) => {
		return this.state.filterData?.features?.[code]?.titleInUrl
	}

	getFeatureParent = (code) => {
		return this.state.filterData?.features?.[code]?.parentCode
	}

	getFeature = (code) => {
		return this.state.filterData?.features?.[code]
	}

	getCategoryName = (code) => {
		return this.state.filterData?.categories?.[code]?.title ?? code
	}

	getCampaignName = (code) => {
		return this.state.filterData?.campaigns?.[code]?.title ?? code
	}

	createFeaturesList = (data) => {
		let newList = {}
		if(!data){
			return
		}
		for (var i = 0; i < data.length; i++) {
			try{
				const elm = this.getFeature(data[i])
				if((newList["parents"] ?? []).indexOf(elm.parentCode) < 0){
					newList["parents"] = [...(newList['parents'] ?? []), elm.parentCode]
				}
				newList[elm.parentCode] = [...(newList[elm.parentCode] ?? []), data[i]]
			}catch{
			}
		}

		return newList
	}

	writeFeaturesList = (data) => {
		const newData = this.createFeaturesList(data)
		if(!newData?.parents){
			return []
		}

		const features = newData.parents.map(e => {
			return {code: e, child: newData[e]}
		})
		return features
	}

	render() {
		return (
			<FiltersContext.Provider value={{
					...this.state,
					getBrandName: this.getBrandName,
					getCategoryName: this.getCategoryName,
					getGroupName: this.getGroupName,
					getGroupUrl: this.getGroupUrl,
					getColorName: this.getColorName,
					getColorHex: this.getColorHex,
					getWhiteColor: this.getWhiteColor,
					getFeatureName: this.getFeatureName,
					getFeatureUrl: this.getFeatureUrl,
					getFeatureParent: this.getFeatureParent,
					getFeature: this.getFeature,
					getCampaignName: this.getCampaignName,
					createFeaturesList: this.createFeaturesList,
					writeFeaturesList: this.writeFeaturesList,
				}}>
				{this.props.children}
			</FiltersContext.Provider>
		)
	}
}

export default FiltersContextProvider
