import React, { useState, useEffect, useRef } from "react";
import styles from './combineModals.module.css'
import axios from 'axios'
import $ from 'jquery'
import { useTranslation } from 'react-i18next';
import SwiperCore, { Navigation, Pagination as SwipperPagination, Lazy } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import Combine from './../../../rComponents/ui/list/combine'
import CombineModal from './../../../components/modal/combine'

SwiperCore.use([Navigation, SwipperPagination, Lazy])

function CombineModals(props) {

	const {t} = useTranslation()

	const [combineData, setCombineData] = useState(null)
	const [combineModalData, setCombineModalData] = useState([])

	const isFirstRun = useRef("")

	useEffect(() => {
		if(isFirstRun.current != props.id){
			isFirstRun.current = props.id
			setCombineModalData([])
			updateCombine()
		}
	}, [props])

	const updateCombine = () => {
		axios.get("/GetCombines?code=" + props.id)
		.then(res => {
			if(res?.data?.isSuccess){
				setCombineData(res.data.data.map(item => { return {content: item, type: "combine"}}))
			}
		})
	}

	const modalOpen = (id) => {
		$("#combine_" + id).show().addClass("show")
		$("body").addClass('modal-open')
		$("body").append('<div class="modal-backdrop fade show"></div>')
	}

	const addCombineList = async (data) => {
		if(combineModalData?.indexOf(data) <= -1){
			await setCombineModalData([...combineModalData, data])
		}
		modalOpen(data.id)
	}

	return <>
		{
			combineData?.length > 0 &&
			<div className={styles.conatiner}>
				<h2 className={`p-2 ${styles.title}`}>KOMBİN ÖNERİLERİ</h2>
				<Swiper slidesPerView={2} spaceBetween={10} pagination={{ clickable: true }} lazy={true}>
					{
						combineData?.map((elm, i) => (
							<SwiperSlide lazy="true" key={i}>
								<div className={styles.combine}>
									<Combine image={elm.content.imagePath} products={elm.content.products} look={t('ListPage.constants.title')} onClickHandler={() => addCombineList({...elm, id: i + 1})} key={i}/>
								</div>
							</SwiperSlide>
						))
					}
				</Swiper>
			</div>
		}
		{
			combineModalData?.map((elm, i) =>
				<CombineModal data={elm} isDetail={props.isDetail} key={i}/>
			)
		}
	</>
}

export default CombineModals;
