import React, { useEffect, useRef, useContext } from 'react'
import config from './../../package/config'
import { gaView } from './../../package/google-analytic'
import { visitTag } from './../../package/criteo'

import { AuthContext } from './../../context/AuthContext'

import SeoComponent from './../../rComponents/global/seoComponent'

import LoginComponent from './../../widgets/login'

function Login(props) {

	const { isAuth } = useContext(AuthContext)

	const isFirstRun = useRef(0)

	useEffect(() => {
		if(isFirstRun.current <= 1){
			isFirstRun.current += 1
			if(isAuth){
				props.history.replace("/")
			}else{
				gaView()
				visitTag()
			}
		}
	}, [isAuth])

	const seoData = {
		title: "Giriş Yap" + config.prefix + config.name,
		description: "Giriş yapmak için e-posta adresinizi ve şifrenizi girmelisiniz!"
	}

	return (
		<>
			<SeoComponent {...seoData}/>
			<div id="login">
				<div className="container" style={{width: "500px", minHeight: "500px"}}>
					<div className="row pt-5 pb-5 mt-5 mb-5 text-center">
						<div className="col">
							<LoginComponent />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Login
