import React, { useLayoutEffect, useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import queryString from 'query-string'
import config from './../../package/config'
import { gaView } from './../../package/google-analytic'
import { homePageTag } from './../../package/criteo'

import SeoComponent from './../../rComponents/global/seoComponent'
import HomePageRender from './../../rComponents/global/homePageRender'
import { LanguageContext } from './../../context/LanguageContext'

function Index(props) {

	const seoData = {
		title: `Anasayfa ${config.prefix}${config.name}`,
		description: "Her zaman şık olmayı seven kadınların tercihi SecilStore ile sezonun trendlerini takip edin. Online alışveriş ve kapıda ödeme seçenekleri ile şimdi alışverişe başlayın."
	}

	const { userChoices } = useContext(LanguageContext)

	const [indexData, setIndexData] = useState(null)

	const isFirstRun = useRef(true)

	useLayoutEffect(() => {
		const queryStringParse = queryString.parse(props.location.search)
		if(isFirstRun.current){
			isFirstRun.current = false
			gaView()
			homePageTag()

			let url
			if(queryStringParse?.testPreview){
				url = config.url + '/' + queryStringParse?.testPreview + '.json'
			}

			// const data = JSON.parse(sessionStorage.getItem('scl_hp_d'))
			const data = false
			if(!url && !queryString?.textPreview && data){
				setIndexData(data)
			}else{
				updateHomePageData(url)
			}
		}
	}, [props])

	useEffect(() => {
		const query = new URLSearchParams(props.location.search);

		if(query.get("date")) {
			updateHomePageData(query.get("date"))
		} else {
			updateHomePageData()
		}
	}, [userChoices, props.location.search])

	const updateHomePageData = (date = new Date().valueOf()) => {
		//axios.get(config.app_name === "secil" ? '/gethomepagedata?unixtime='+date+'&device=all&' : config.url + '/homepage.json')
		axios.get(config.app_name === "secil" ? window.location.origin + '/shomepage.json' : window.location.origin + '/ihomepage.json')
		.then(res => {
			if(res?.status === 200) {
				// const data = sessionStorage.setItem('scl_hp_d', JSON.stringify(res.data))
				setIndexData(res.data.data)
			}
		})
	}

	return (
		<>
			<SeoComponent {...seoData}/>
			<HomePageRender data={indexData}/>
		</>
	)
}

export default Index
