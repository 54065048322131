import * as React from "react";

function SvgPlusBold(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M21.27 29.57v-8.28h8.24c.71 0 1.29-.58 1.29-1.29 0-.71-.58-1.29-1.29-1.29h-8.24v-8.28c0-.71-.58-1.29-1.29-1.29-.71 0-1.29.58-1.29 1.29v8.28h-8.24c-.71 0-1.29.58-1.29 1.29 0 .71.58 1.29 1.29 1.29h8.24v8.28c0 .71.58 1.29 1.29 1.29.71.01 1.29-.57 1.29-1.29z"
      />
    </svg>
  );
}

export default SvgPlusBold;
