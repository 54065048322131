import React from "react";
import { useTranslation } from 'react-i18next'
import { gaView } from './../../../package/google-analytic'

import * as Icons from './../../icons/index'

function OrderTicket() {

	const { t, i18n } = useTranslation();

	return (
		<a href="tel://03124723434" className="order-ticket base-regular">
			<span><Icons.Ticket />{t('UserPage.ticket')}</span>
			<span>0 (312) 472 3434</span>
		</a>
	);
}

export default OrderTicket;
