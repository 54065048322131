import * as React from "react";

function SvgArrowDown(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M20 25.76c.15 0 .29-.06.39-.16l10.32-10.4c.22-.22.22-.57 0-.79a.543.543 0 00-.78 0l-9.53 9.61c-.22.22-.57.22-.79 0l-9.53-9.61a.543.543 0 00-.78 0c-.22.22-.22.57 0 .79l10.32 10.4c.09.1.23.16.38.16z"
      />
    </svg>
  );
}

export default SvgArrowDown;
