import React from 'react'
import TypeLink from './../global/link'
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './sliderText.module.css'

SwiperCore.use([Navigation, Pagination]);

function SliderText(props) {
	return (
		<Swiper slidesPerView={1} direction={'vertical'} spaceBetween={10} autoplay={{delay: 4000}} effect={"flip"} speed={1000} loop className={styles.slider}>
			{
				props?.data?.map((elm, i) =>
					<SwiperSlide key={i}>
						<TypeLink to={elm.url}>
							<span>{elm.text}</span>
						</TypeLink>
					</SwiperSlide>
				)
			}
		</Swiper>
	)
}

export default SliderText
