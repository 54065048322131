import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { gaView } from './../../../package/google-analytic'
import { visitTag } from './../../../package/criteo'

import Product from './../../widgets/list/product'

function Oneriler(props) {

	const [data, setData] = useState(null)
	useEffect(() => {


			const requestHeader = {headers: {titleinurl: "yeni-gelenler"}}
			const pageSize = window.innerWidth < 540 ? 6 : 5
			axios.get("/GetProducts?page=1&pagesize=" + pageSize, requestHeader)
			.then(res => {
				if(res?.data?.isSuccess){
					setData(res?.data?.data?.data)
				}
			})
	}, [props])

	return (
		<>
			<div id="content">
				<div className="container">
					<h1 className="bg">Senin için önerilerimiz var</h1>
					<div className="scl-product-list py-3">
						{
							data?.map((elm, i) =>
								<Product data={elm} index={i} key={i}/>
							)
						}
					</div>
				</div>
			</div>
		</>
	)
}

export default Oneriler
