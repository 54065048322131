import React from 'react'
import styles from './register.module.css'
import config from './../../package/config'

import SocialLoginButtons from './../ui/modal/socialLoginButtons'

import Input from './../../components/widgets/forms/input'
import Button from './../../components/widgets/forms/button'
import Checkbox from './../../components/widgets/forms/checkbox'

import * as Icons from './../../components/icons'

import { useTranslation, Trans } from 'react-i18next';

function Register(props) {

	const {t} = useTranslation()

	return (
		<div className={styles.register}>
			<>
				<h2>{t('LoginModal.register.message', {name: config.name})}</h2>
				{
					props.error?.length > 0 &&
					<div className="errorMessage mb-4">
						<Icons.CloseCircle />
						{props.error}
					</div>
				}
				{ config.app_name === "secil" &&
					<SocialLoginButtons />
				}
				<form onSubmit={props.onSubmit}>
					<div className="row g-1" style={{display: (props.step === 1 ? 'flex' : 'none')}}>
						<div className="col-7 mb-3"><Input type="text" placeholder={t('LoginModal.register.name-placeholder')} name="register_name" error={props.errors?.register_name?.message} transferref={props.register}/></div>
						<div className="col-5 mb-3"><Input type="text" placeholder={t('LoginModal.register.surname-placeholder')} name="register_surname" error={props.errors?.register_surname?.message} transferref={props.register}/></div>
					</div>
					<div className="row g-0" style={{display: (props.step === 1 ? 'flex' : 'none')}}>

						<div className="mb-3">
							<Input type="mail" placeholder={t('LoginModal.register.mail-placeholder')} name="register_mail" autoComplete="email" error={props.errors?.register_mail?.message} transferref={props.register}/>
						</div>
						<div className="mb-3">
							<Input type="password" placeholder={t('LoginModal.register.pass-placeholder')} name="register_password" autoComplete="current-password" error={props.errors?.register_password?.message} transferref={props.register}/>
						</div>
						<div className="mb-3">
							<Input type="tel" placeholder={t('LoginModal.register.phone-placeholder')} name="register_phone" autoComplete="tel-local" error={props.errors?.register_phone?.message} transferref={props.register}/>
						</div>

						<Checkbox label2={<Trans i18nKey="LoginModal.register.kvkk"><strong onClick={() => props.openModal('kvkk')}>link</strong><strong onClick={() => props.openModal('membership')}>link</strong></Trans>} name="is_contractcheck" error={props.errors?.is_contractcheck?.message} transferref={props.register}/>
						<Checkbox label={t('LoginModal.register.subscribe')} name="is_subscribe" error={props.errors?.is_subscribe?.message} transferref={props.register}/>
					</div>
					{
						props.step === 2 &&
						<div className="mb-3">
							<Input type="text" title={t('LoginModal.register.code-label')} placeholder={t('LoginModal.register.code-placeholder')} name="register_code" onChange={e => props.setRegisterCode(e.target.value) }/>
						</div>
					}
					<div className="mb-3 mt-3 form-btn">
						<Button type="submit" className="btn" title={props.step === 1 ? t('LoginModal.register.button') : "TAMAMLA"}/>
					</div>
				{/*
				<h3 className="hr"><span>{t('LoginModal.login.or')}</span></h3>
				<div className="mb-3 fb-btn">
						<button type="button" className="btn">{t('LoginModal.login.facebook')}</button>
				</div>
				*/}
				</form>
			</>
		</div>
	)
}

export default Register
