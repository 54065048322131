import ReactGA from 'react-ga';
import config from './config';

let isActive = false
export const gaStart = () => {
	isActive = true
	if(process.env.REACT_APP_STAGE === 'beta' || process.env.REACT_APP_STAGE === 'dev'){
		ReactGA.initialize(config.analytics, {
				debug: false,
		})
	} else {
		ReactGA.initialize(config.analytics)
	}
	ReactGA.plugin.require('ec')
}

export const gaView = (url) => {
	setTimeout(() => {
		if(!url){
			url = window.location.pathname + window.location.search
		}

		if(!isActive){
			return false
		}
		ReactGA.pageview(url)
	}, 500)
}

export const gaAddBasket = (data) => {
	if(!isActive){
		return false
	}

	ReactGA.plugin.execute('ec', 'addProduct', {
		id: data.id,
		name: data.title,
		category: data.category,
		brand: data.brand,
		variant: data.variant,
		price: data.price,
		quantity: data.quantity,
		currency: "TRY",
	})

	ReactGA.plugin.execute('ec', 'setAction', 'add')
	ReactGA.plugin.execute('ec', 'event', 'UX', 'click', 'add to cart')

	// ReactGA.plugin.execute('ec', 'clear')
}

export const gaRemoveBasket = (data) => {
	if(!isActive){
		return false
	}

	ReactGA.plugin.execute('ec', 'addProduct', {
		id: data.id,
		name: data.title,
		brand: data.brand,
		variant: data.variant,
		price: data.price,
		quantity: data.quantity,
		currency: "TRY",
	})

	ReactGA.plugin.execute('ec', 'setAction', 'remove')
	ReactGA.plugin.execute('ec', 'event', 'UX', 'click', 'remove to cart')

	// ReactGA.plugin.execute('ec', 'clear')
}

export const gaCheckout = (data, step) => {
	if(!isActive){
		return false
	}

	if(data?.items){
		for(let i=0; i < data.items.length; i++){
			ReactGA.plugin.execute('ec', 'addProduct', {
				id: data.items[i].id,
				name: data.items[i].title,
				category: data.items[i].category,
				brand: data.items[i].brand,
				variant: data.items[i].variant,
				price: data.items[i].price,
				quantity: data.items[i].quantity,
				currency: "TRY",
			})
		}
	}

	ReactGA.plugin.execute('ec', 'setAction', step.step === 4 ? 'checkout' : 'checkout_option', {...step});

	ReactGA.ga('send', 'pageview')
	// ReactGA.plugin.execute('ec', 'clear')
}

export const gaAddImpression = data => {
	if(!isActive){
		return false
	}

	if(data?.items){
		for(let i=0; i < data.items.length; i++){
			ReactGA.plugin.execute('ec', 'addImpression', {
				id: data.items[i].id,
				name: data.items[i].name,
				category: data.items[i].category,
				brand: data.items[i].brand,
				variant: data.items[i].variant,
				list: data.items[i].list,
				position: data.items[i].position
			})
		}
	}

	// ReactGA.plugin.execute('send', 'setAction', 'detail')
	// ReactGA.ga('send', 'pageview')
}

export const gaListClickProduct = (data) => {
	if(!isActive){
		return false
	}

	const sendData = {
		id: data.id,
		name: data.name,
		category: data.category,
		brand: data.brand,
		variant: data.variant,
		position: data.position
	}

	ReactGA.plugin.execute('ec', 'addProduct', sendData)

	ReactGA.plugin.execute('send', 'setAction', 'list-performance', {'list': data.group})

	ReactGA.event({
		category: data.group,
		action: "urun-tiklama",
		label: data.id
	})

	ReactGA.ga('send', 'pageview')
}

export const gaPurchase = (data) => {
	if(!isActive){
		return false
	}

	if(data?.items){
		for(let i=0; i < data.items.length; i++){
			ReactGA.plugin.execute('ec', 'addProduct', {
				id: data.items[i].id,
				name: data.items[i].title,
				category: data.items[i].category,
				brand: data.items[i].brand,
				variant: data.items[i].variant,
				price: data.items[i].price,
				quantity: data.items[i].quantity,
				currency: "TRY",
			})
		}
	}

	ReactGA.plugin.execute('ec', 'setAction', 'purchase', data.purchase)

	ReactGA.ga('send', 'pageview')
	ReactGA.plugin.execute('ec', 'clear')

	dataLayerPush({
		'event': 'purchase',
		'value': data.purchase.revenue,
		'id': data.purchase.id
	})
}

export const dataLayerPush = (data) => {
	window.dataLayer = window.dataLayer || []
	window.dataLayer.push({
		'event': data.event,
		'value': data.value,
		'currency': data.currency ?? 'TRY',
		'transaction_id': data.id ?? ''
	})
}

export const gaBannerClick = (data) => {
	if(!isActive){
		return false
	}

	ReactGA.event({
	  category: 'Click',
	  action: 'banner-tiklama',
		label: data.url
	});
}
