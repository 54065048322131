import React, { useContext} from "react";
import { AuthContext } from './AuthContext'


function Giris() {


	const {toggleAuth} = useContext(AuthContext)

	function onChangeAnswer(e){
		if(e.target.value === "kerberos"){
			toggleAuth()
		}
	}


	return (
		<>
			<input name="selam" onChange={onChangeAnswer} autoComplete="secilgizlisifre"/>
		</>
	);

}

export default Giris;
