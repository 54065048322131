import React, { useEffect, useContext, useState } from "react";
import TypeLink from "./../../../rComponents/global/link";

import $ from "jquery";

import * as Icons from './../../icons/index'
import { LanguageContext } from './../../../context/LanguageContext'
import { convertStylesStringToObject } from './../../../package/global-function'
import axios from 'axios'
import config from './../../../package/config'
import FirsatUrunu from './../../widgets/list/firsaturunu'

function MainMenu(props) {

	const { userChoices } = useContext(LanguageContext)

	const [windowWidth, setWindowWidth] = useState(0)
	const [menu, setMenu] = useState([])

	useEffect(() => {
			setWindowWidth($(window).width())
			setTimeout(function(){
				if( windowWidth > 768 ){

					$(".close-page-hover").off().mouseenter(function(){
						$(".close-page-hover").hide();
						$(".menu-children").hide();
						$(".menu-children").removeAttr('style');
						$(".mobile-menu-button .a.active").click()
						$('.scl-mobile-filter.active').click()
					});

				}else{
					$(".close-page-hover, .mobile-menu-close").off().click(function(){
						$(".close-page-hover").hide();
						$(".menu-children").hide();
						$(".menu-children").removeAttr('style');
						$("#main-menu > .container > ul > li").removeClass('active');
						$(".mobile-menu-button .a.active").click()
						$(".login-user .a.active").click()
						$('.scl-mobile-filter.active').click()
					});

					$(".mobile-menu-button .a").off().click(function(){
						var th = $(this)
						if(!th.hasClass("active")){
							$(".close-page-hover").click();
						}
						if(th.hasClass("active")){
							th.removeClass("active");
							$("#main-menu").animate({"left":(windowWidth <= 500 ? "-100%" : "-70%" )}, 600);
							$(".close-page-hover").hide();
							$("body,#root, .App").removeClass("modal-open");
						}else{
							th.addClass("active");
							$("#main-menu").animate({"left": "0%"}, 600);
							$(".close-page-hover").show();
							$("body,#root, .App").addClass("modal-open");
						}
					});

					$("[for^=altmenu_] > span").off().on('click', function(){
						if($(this).parent().hasClass("active")){
							$(this).parent().removeClass("active");
							$(this).parent().children(".menu-children").animate({"height":"0"}, 300);
						}else{
							$(this).parent().addClass("active");

							var childH3 = $(this).parent().children(".menu-children").find("h3").length * 42;
							var childLi = $(this).parent().children(".menu-children").find("li").length * 40;

							if(windowWidth < 540){
								childH3 = $(this).parent().children(".menu-children").find("h3").length * 52;
								childLi = $(this).parent().children(".menu-children").find("li").length * 33.2;
							}
							$(this).parent().children(".menu-children").animate({"height": (childH3 + childLi + 20) + "px"}, 300);
						}
					});
				}
				$(".close-page-click").off().click(function(){
					$("body").removeClass("modal-open")
					$(".close-page-click").hide();
					$(".scl-content-sort").removeClass("active");
					$(".menu-children").hide();
					$(".search input").val("");
					$(".search-complete").hide();
					$(".menu-children").removeAttr('style');
				});
			}, 200)
	}, [props])

	useEffect(() => {
		updateMenu()
	}, [userChoices])

	const updateMenu = () => {
		axios.get('/GetMenu').then(res => {
			setMenu(res?.data?.data || [])
		});
	}

	const menuClose = e => {
		$("body").removeClass("modal-open")
		$(".close-page-hover").click()
		$(".close-page-hover").mouseenter()

		if(e === props.history.location.pathname){
			$("html, body, .App").animate({ scrollTop: 0 }, 500)
		}
	}

	const menuMouseEnter = e => {
		if( windowWidth > 768 ){
			$(".close-page-hover").show();
			$(".menu-children").hide();
			$(".menu-children").removeAttr('style');
			let tfor = $(e.currentTarget).attr('for');
			if(tfor != null){
				tfor = '#' + tfor;
				$(tfor).show();
				$(tfor).animate({opacity: '1'}, 300);
			}
		}
	}

	const menuMouseLeave = () => {
		if( windowWidth > 768 ){
			$(".menu-children, .close-page-hover").hide();
		}
	}

	return (
		<React.Fragment>
			<div id="main-menu">
				<div className="container">
					<ul className="list-inline">
						<li className="list-inline-item mt-2 pb-2 mobile-menu-close d-lg-none" style={{height: "40px", color: "black"}}><span className="float-right a" style={{fontSize: "13px"}}><Icons.CloseRegular /></span></li>
						{
							menu && menu.map((elm, i) =>
								<li
								key={"menu_" + i}
								onMouseEnter={menuMouseEnter}
								onMouseLeave={menuMouseLeave}
								htmlFor={"altmenu_" + i}
								className={"list-inline-item" + (elm.children !== null ? ((elm.children.length > 2 && elm.children?.[0]?.children ? '' : ' little') + (elm.children.length ===  2 ? ' two' : '')) : ' little') + (!elm.children?.[0]?.children && elm.children ? ' notTitle' : '')}>
									{
										elm.titleinurl === "" || (windowWidth <= 768 && elm.children !== null) ?
										<span className="main-title">
											<span>
												{elm.title}
												{
													elm.children !== null &&
													<>
														<Icons.Plus />
														<Icons.Vour />
													</>
												}
											</span>
											<span>{elm.title}</span>
										</span>
										:
										<TypeLink to={elm.titleinurl} onClick={() => menuClose('/' + elm.titleinurl)} style={elm.style && convertStylesStringToObject(elm.style)} className='main-title'>
											<span>
												{elm.title}
												{
													elm.children !== null &&
													<>
														<Icons.Plus />
														<Icons.Vour />
													</>
												}
											</span>
											<span>{elm.title}</span>
										</TypeLink>
									}
									{
										elm?.children && (
											<div id={"altmenu_" + i} className={"menu-children" + (elm?.children ? (elm?.children?.length > 2 && elm?.children?.[0]?.children) ? ' big' : '' : '')}>
												<div className="container">
													<div className="row justify-content-left">
														{
															elm?.children[0].children && elm?.children?.map((title, c) =>
																<div key={i + "_alt_" + c} className={elm.children <= 4 ? 'col-3' : 'col'}>
																	<h3>{title.titleinurl === "" ? title.title : <TypeLink to={title.titleinurl} onClick={() => menuClose("/" + title.titleinurl)}>{title.title}</TypeLink>}</h3>
																	<ul>
																		{
																			title.children && title.children.map((child, j) =>
																				<li key={c + "_c_list_" + j} className={(child.isbold ? " bold" : '')}>
																					{child.titleinurl === "" ? <span>{child.title}</span> : <TypeLink to={child.titleinurl} onClick={() => menuClose('/' + child.titleinurl)}>{child.title}</TypeLink>}
																				</li>
																			)
																		}
																	</ul>
																</div>
															)
														}
														{
															!elm?.children?.[0]?.children &&
															<div key={i + "_alt_"} className={elm.children <= 4 ? 'col-3' : 'col'}>
																<ul>
																{
																	elm?.children?.map((title, c) =>
																		<li key={c + "_c_list_"} className={(title.isbold ? " bold" : '')}>
																			{title.titleinurl === "" ? title.title : <TypeLink to={title.titleinurl} onClick={() => menuClose("/" + title.titleinurl)}>{title.title}</TypeLink>}
																		</li>
	   																)
																}
																</ul>
															</div>
														}
													</div>
												</div>
											</div>
										)
									}
								</li>
							)
						}
						{
							window.innerWidth <= 540 && config.app_name !== "ilmio" &&
							<FirsatUrunu style={{marginTop: 15}}/>
						}
					</ul>
				</div>
			</div>
		</React.Fragment>
	)
}

export default MainMenu
