import React, { useEffect, useState, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import axios from "axios";
import $ from "jquery";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as Icons from './../../icons/index'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import yup from "./../../../package/yup-init";

import Input from './../forms/input'
import Button from './../forms/button'
import Select from './../forms/select'
import Textarea from './../forms/textarea'

import { LanguageContext } from './../../../context/LanguageContext'

const schema = yup.object().shape({
	name: yup.string()
	.min(2)
	.max(15)
	.required(),
	surname: yup.string()
	.min(2)
	.max(15)
	.required(),
	addressText: yup.string()
	.min(10)
	.required(),
	phone: yup.string()
	.length(18)
	.required(),
	zipCode: yup.string()
});

const schema_company = yup.object().shape({
	name: yup.string()
	.min(2)
	.max(15)
	.required(),
	surname: yup.string()
	.min(2)
	.max(15)
	.required(),
	addressText: yup.string()
	.min(10)
	.required(),
	phone: yup.string()
	.length(18)
	.required(),
	companyName: yup.string()
	.min(2)
	.max(50)
	.required(),
	taxName: yup.string()
	.min(2)
	.max(25)
	.required(),
	zipCode: yup.string(),
	taxNo: yup.string()
	.min(2)
	.max(25)
	.required()
});

function Address( props ) {

	const nullData = {key: 0, value: "Seçim Yapınız"}

	const MySwal = withReactContent(Swal)
	const { t } = useTranslation()

	const {getCountries} = useContext(LanguageContext)

	const [country, setCountry] = useState(props.addressData?.country ? {...props.addressData?.country, key: props.addressData?.country?.code} : null)
	const [city, setCity] = useState(props.addressData?.city ? {...props.addressData?.city, key: props.addressData?.city?.id} : null)
	const [county, setCounty] = useState(props.addressData?.county ? {...props.addressData?.county, key: props.addressData?.county?.id} : null)

	const [countryData, setCountryData] = useState(getCountries()?.map(elm => { return {...elm, key: elm.code, value: elm.name}}))
	const [countyData, setCountyData] = useState([nullData])
	const [citiesData, setCitiesData] = useState([nullData])

	const [nonCurrent, setNonCurrent] = useState([])

	const [resultMessage, setResultMessage] = useState(true)
	const isFirstRun = useRef(true)

	const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver(props.choiceType ? schema : schema_company),
		mode: 'onBlur',
		defaultValues: {
			name: props.addressData?.name,
			surname: props.addressData?.surname,
			addressText: props.addressData?.addressText,
			phone: props.addressData?.phone,
			companyName: props.addressData?.companyName,
			taxName: props.addressData?.taxName,
			zipCode: props.addressData?.zipCode,
			taxNo: props.addressData?.taxNo
		}
	})

	useEffect(() => {
		if(country?.id > 0)
		{
			setCountryData(countryData.filter(e => e.key))
			props.changeAddress(null, {"country" : {id: country.id, code: country.key, key: country.key, name: country.value}})

			if(country.key !== "TR" && country.key !== "US"){
				setCounty(null)
			}
			const header = {
				headers: {
					countrycode: country.key
				}
			}

			axios.get("/GetCities", header)
			.then(res => {
				let cities = [nullData]
				if(res.data.isSuccess){
					let match = false

					for(var i = 0;i < res.data.data.length; i++){
						cities.push({key: res.data.data[i].id, value:  res.data.data[i].name})
					}
					if(cities){
						let index = -1
						if(city){
							index = cities.map(e => e.key).indexOf(city.key)
						}
						if(index <= -1 && !isFirstRun.current){
							setCity(cities[0])
						}else{
							const header = {
								headers: {
									cityid: city.key
								}
							}
							updateCounty(header)
						}
					}
				}
				setCitiesData(cities)
			})
		}
	}, [country])

	useEffect(() => {
		if(countryData?.filter(e => e?.id)?.length <= 0){
			const country = getCountries()?.map(elm => { return {...elm, key: elm.code, value: elm.name}})
			setCountryData([nullData, ...country])
		}else if(!country){
			setCountry(countryData[0])
		}
	}, [countryData])

	useEffect(() => {
		if(city && !isFirstRun.current){
			props.changeAddress(null, {"city" : {id: city.key, name: city.value}})
			if(city.key){

				setCitiesData(citiesData.filter(e => e.key))

				const header = {
					headers: {
						cityid: city.key
					}
				}

				if(country.key === "TR" || country.key === "US"){
					updateCounty(header)
				}
			}
		}else{
			isFirstRun.current = false
		}
	}, [city])

	useEffect(() => {
		if(county){
			props.changeAddress(null, {"county" : {id: county.key, name: county.value}})
		}else{
			props.changeAddress(null, {"county" : {id: 0, name: nonCurrent}})
		}
	}, [county])

	useEffect(() => {
		props.changeAddress(null, {"county" : {id: 0, name: nonCurrent}})
	}, [nonCurrent])

	const updateCounty = (header) => {
		axios.get("/GetCounties", header)
		.then(res => {
			if(res?.data?.isSuccess){
				let counties = []
				for(var i = 0;i < res.data.data.length; i++){
					counties.push({key: res.data.data[i].id, value: res.data.data[i].name})
				}

				let index = -1

				counties = counties.sort((a, b) => {
					const first = a.value.toLowerCase()
					const secound = b.value.toLowerCase()
					if(first > secound){
						return 1
					}
					if(first < secound){
						return -1
					}
					return 0
				})

				if(county){
					index = counties.map(e => e.key).indexOf(county.key)
				}

				if(index > -1){
					setCounty(counties[index])
				}

				setCountyData([nullData, ...counties])
			}
		})
	}

	const onSubmit = (formData) => {

	}

	const countryChange = (elm) => {
		setCountry(elm)
	}

	const cityChange = (elm) => {
		setCity(elm)
	}

	const countyChange = (elm) => {
		setCounty(elm)
	}

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
			{
				<>
					<div className="row pt-3">
						<div className="col-12 col-lg-6">
							<Input type="text" placeholder={t('AddressModal.name-placeholder')} name="name" autoComplete="given-name" transferref={register} error={errors?.name?.message} onChange={props.changeAddress}/>
							<Input type="text" placeholder={t('AddressModal.surname-placeholder')} name="surname" autoComplete="family-name" transferref={register} error={errors?.surname?.message} onChange={props.changeAddress}/>
							<Input type="tel" value={props.addressData.phone} placeholder={t('AddressModal.phone-placeholder')} name="phone" autoComplete="tel-local" transferref={register} error={errors?.phone?.message} onChange={props.changeAddress}/>
							{
								!props.choiceType && <>
									<Input type="text" placeholder={t('AddressModal.company')} name="companyName" transferref={register} error={errors?.companyName?.message} onChange={props.changeAddress}/>
									<Input type="text" placeholder={t('AddressModal.tax-placeholder')} name="taxName" transferref={register} error={errors?.taxName?.message} onChange={props.changeAddress}/>
									<Input type="text" placeholder={t('AddressModal.tax-no-placeholder')} name="taxNo" transferref={register} error={errors?.taxNo?.message} onChange={props.changeAddress}/>
								</>
							}
						</div>
						<div className="col-12 col-lg-6">
							<Select name="country" id="ulke" onlytext="true" selected={country?.key} clicked={countryChange} options={countryData}/>
							<Select name="city" id="sehir" onlytext="true" selected={city?.key} clicked={cityChange} options={citiesData}/>
							{
								(country?.key === "TR" || country?.key === "US" || country?.key === 0) ?
									<Select name="county" id="ilce" onlytext="true" selected={county?.key} clicked={countyChange} options={countyData}/>
								:
									<Input type="text" placeholder={t('AddressModal.non-county-placeholder')} name="nonCurrent" onChange={e => setNonCurrent(e.target.value)}/>
							}
							<Input type="text" transferref={register} error={errors?.zipCode?.message} placeholder={t('AddressModal.zip-placeholder')} name="zipCode" onChange={props.changeAddress}/>
							<Textarea name="addressText" placeholder={t('AddressModal.address-placeholder')} transferref={register} error={errors?.addressText?.message} onChange={props.changeAddress}/>
						</div>
					</div>
				</>
			}
			</form>
		</>
		)
}

export default Address
