import * as React from "react";

function SvgSecilStoreLogo(props) {
  return (
    <svg
      baseProfile="basic"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 10"
      width="1em"
      height="1em"
      {...props}
    >
      <g fill="currentColor">
        <path d="M2.1 6.4c0 1.3 1 1.8 2.3 1.8 1.4 0 1.9-.7 1.9-1.3 0-.7-.4-.9-.7-1.1-.8-.1-1.6-.3-2.8-.6C1.3 4.8.8 3.9.8 3 .8 1.3 2.4.5 4.1.5c1.9 0 3.4 1 3.4 2.7H5.9c0-1.1-.8-1.5-1.9-1.5-.7 0-1.6.3-1.6 1.1 0 .6.4.9 1.1 1.1.1.1 2.1.6 2.5.7 1.2.4 1.8 1.3 1.8 2.2 0 1.9-1.8 2.7-3.5 2.7-2 0-3.7-.9-3.7-3.1h1.5zM9.4 6.5c0 1 .5 1.8 1.7 1.8.8 0 1.3-.3 1.6-1H14c-.3 1.4-1.5 2.1-2.9 2.1C9.1 9.5 8 8.1 8 6.2c0-1.8 1.2-3.3 3.1-3.3 2 0 3.3 1.8 3 3.6H9.4zm3.3-.9c0-.9-.7-1.6-1.6-1.6-1 0-1.7.7-1.7 1.6h3.3zM18 8.3c.6-.1 1.1-.6 1.2-1.4h1.4c-.2 1.5-1.2 2.4-2.6 2.5V8.3zm1.2-3.1c-.1-.8-.7-1.2-1.5-1.2-.7 0-1.7.4-1.7 2.3 0 .9.3 1.8 1.2 2.1v1.1c-1.7-.2-2.6-1.5-2.6-3.2 0-1.9 1.1-3.3 3.2-3.3 1.5 0 2.8.7 2.9 2.2h-1.5zM21.2.7h1.4V2h-1.4V.7zm0 2.4h1.4v6.2h-1.4V3.1zM23.3.7h1.4v8.6h-1.4z" />
      </g>
      <g fill="currentColor">
        <path d="M31.518 5.466a3.648 3.648 0 00-.939-.388c-.387-.104-.759-.203-1.138-.3-.35-.084-.703-.167-1.082-.254a3.927 3.927 0 01-.937-.333 1.908 1.908 0 01-.661-.557c-.168-.224-.253-.53-.253-.908 0-.379.083-.694.247-.939a1.9 1.9 0 01.634-.598c.256-.15.546-.255.861-.315a4.794 4.794 0 011.995.03c.331.08.62.213.86.398.24.185.436.428.58.722.146.295.23.665.245 1.1v.03h.301v-.032c-.025-.484-.12-.9-.28-1.233a2.237 2.237 0 00-.652-.816 2.659 2.659 0 00-.95-.45 4.726 4.726 0 00-1.18-.138c-.346 0-.697.036-1.045.108-.35.073-.672.195-.953.364-.282.17-.517.401-.695.69-.18.289-.27.652-.27 1.08 0 .42.087.766.257 1.031.17.264.4.478.679.635.276.157.592.282.938.37.356.09.703.176 1.034.253.382.092.71.166 1.03.234.353.075.677.188.965.334.287.146.527.345.711.593.185.246.278.585.278 1.01 0 .36-.081.674-.243.934-.16.258-.373.473-.633.64a2.83 2.83 0 01-.887.362 4.87 4.87 0 01-2.245-.036 2.554 2.554 0 01-.976-.482 2.207 2.207 0 01-.634-.86c-.149-.35-.225-.785-.225-1.292v-.03h-.3v.03c-.009.545.07 1.018.235 1.406.166.388.404.71.707.956.3.244.664.426 1.082.541a5.218 5.218 0 002.46.042c.364-.086.695-.224.984-.413.29-.19.527-.433.706-.725.178-.29.268-.648.268-1.061 0-.453-.08-.818-.238-1.085a1.853 1.853 0 00-.641-.648zM35.133 9.026a8.004 8.004 0 01-.324.006c-.097 0-.193-.006-.285-.018a.497.497 0 01-.249-.107.667.667 0 01-.19-.277 1.48 1.48 0 01-.075-.53V3.394h1.394v-.3H34.01V1.146h-.301v1.948h-1.13v.3h1.13v4.754c0 .448.103.76.305.932.2.168.448.253.735.253.185 0 .314-.004.383-.013.067-.009.138-.016.209-.023l.027-.003v-.312l-.04.016a.606.606 0 01-.195.028z" />
        <path d="M40.104 3.946c-.239-.3-.536-.542-.881-.723a2.574 2.574 0 00-1.204-.274 2.6 2.6 0 00-1.216.274c-.346.18-.643.424-.882.723-.238.298-.42.648-.54 1.037-.12.39-.182.803-.182 1.23 0 .427.062.84.182 1.228.12.39.302.74.54 1.04.24.298.536.54.882.716.347.178.753.268 1.204.268.459 0 .868-.09 1.216-.268a2.69 2.69 0 00.881-.717c.239-.3.42-.65.54-1.039.12-.387.182-.8.182-1.229 0-.427-.061-.84-.182-1.229a3.163 3.163 0 00-.54-1.037zm.26 3.389c-.107.356-.27.675-.482.945a2.393 2.393 0 01-.79.653c-.308.161-.67.243-1.073.243-.412 0-.778-.082-1.087-.243a2.393 2.393 0 01-.79-.653 2.884 2.884 0 01-.482-.945 3.941 3.941 0 010-2.231c.108-.356.27-.675.483-.947.212-.272.478-.493.789-.66a2.305 2.305 0 011.087-.248c.402 0 .763.084 1.074.249.31.166.576.387.789.659.213.272.375.591.482.947.107.354.16.727.16 1.108 0 .39-.053.767-.16 1.123zM44.24 3.251V2.95h-.03l-.086-.001c-.338 0-.638.041-.89.122-.277.088-.521.211-.727.365a1.9 1.9 0 00-.486.544c-.1.17-.178.348-.234.535v-1.42h-.3v6.239h.3V5.804c0-.334.048-.662.143-.977.093-.314.24-.595.44-.838.194-.238.447-.43.752-.568.303-.139.671-.195 1.085-.172l.032.002zM49.13 6.261a4.33 4.33 0 00-.127-1.234 3.111 3.111 0 00-.486-1.056 2.496 2.496 0 00-.865-.742c-.352-.186-.775-.28-1.257-.28a2.557 2.557 0 00-1.898.858 3.247 3.247 0 00-.607 1.024c-.153.403-.23.868-.23 1.381 0 .46.052.892.156 1.289.106.398.269.749.486 1.044.22.297.506.528.848.688.326.154.72.232 1.171.232h.074c.709 0 1.313-.204 1.798-.602.484-.398.775-.97.864-1.697l.005-.034h-.3l-.004.025c-.104.67-.368 1.18-.79 1.515-.42.335-.958.504-1.598.504-.434 0-.806-.076-1.104-.224a2.034 2.034 0 01-.735-.617 2.71 2.71 0 01-.422-.922 4.9 4.9 0 01-.149-1.122h5.169v-.03zm-5.168-.271c.026-.416.105-.794.237-1.124.134-.34.313-.634.529-.876.216-.24.472-.426.76-.55.284-.127.597-.19.93-.19.396 0 .748.073 1.047.219.298.146.55.347.752.599.201.252.354.55.458.885.1.329.151.678.154 1.037h-4.867z" />
      </g>
    </svg>
  );
}

export default SvgSecilStoreLogo;
