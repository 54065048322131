import React, { useState } from "react";
import axios from 'axios'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import yup from "./../../../../package/yup-init";
import Toast from "./../../../../package/swal-init";

import Modal from './../../../global/modal/modal'
import Loading from './../../../../components/widgets/global/loading'
import Input from './../../../../components/widgets/forms/input'
import Button from './../../../../components/widgets/forms/button'

const schema = yup.object().shape({
	title: yup.string()
	.min(5)
	.required(),
	ad: yup.string()
	.min(2)
	.max(30)
	.required(),
	iban: yup.string()
	.min(24)
	.required()
})

function Add(props) {

	const [addIbanData, setAddIbanData] = useState(null)

	const { register: addRegiter, handleSubmit: addSubmit, errors: addErrors, setError: setAddError } = useForm({
		resolver: yupResolver(schema),
		reValidateMode: 'onChange'
	});

	const addNewIban = (data) => {
		const iban = data.iban.replaceAll('_', '').replaceAll(' ', '')
		if(iban.length < 26) {
			setAddError('iban', {
				type: "required",
				message: "Lütfen formata uygun giriniz"
			})
			return false
		}

		axios.post("/AddNewIban", {...data, fullname: data.ad})
		.then(res => {
			if(res.data?.isSuccess){
				Toast.fire({
					icon: 'success',
					title: 'Başarıyla eklendi'
				})
			}else{
				Toast.fire({
					icon: 'error',
					title: res.data.error
				})
			}
			props.updateHandler()
			props.modalClose()
		})
	}

	return (
		<Modal show={props.show} closeHandler={props.addModalClose}>
			<h2>IBAN EKLE</h2>
			<form onSubmit={addSubmit(addNewIban)}>
				<Input name="title" title="BAŞLIK *:" placeholder="BAŞLIK GİRİNİZ" error={addErrors?.title?.message} transferref={addRegiter} />
				<small className="d-block text-left green mb-3">* daha sonra hatırlamak için kısa isim veriniz</small>
				<Input name="ad" title="KART SAHİBİNİN ADI SOYADI *:" placeholder="ADINIZI GİRİNİZ" error={addErrors?.ad?.message} transferref={addRegiter} />
				<Input type="iban" name="iban" title="IBAN NUMARASI *:" placeholder="IBAN GİRİNİZ" error={addErrors?.iban?.message} transferref={addRegiter} />
				<Button type="submit" title="EKLE" className="btn"/>
			</form>
		</Modal>
	)
}

export default Add
