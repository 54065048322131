import * as React from "react";

function SvgNewWindow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M39.18 40H6.47c-.45 0-.82-.37-.82-.82v-3.27c0-.45.37-.82.82-.82.45 0 .82.37.82.82v2.45h31.08V7.29h-2.45c-.45 0-.82-.37-.82-.82 0-.45.37-.82.82-.82h3.27c.45 0 .82.37.82.82v32.71c-.01.45-.38.82-.83.82z"
      />
      <path
        fill="currentColor"
        d="M33.53 34.35H.82c-.45 0-.82-.37-.82-.82V.82C0 .37.37 0 .82 0h32.71c.45 0 .82.37.82.82v32.71c0 .45-.37.82-.82.82zM1.64 32.71h31.08V1.64H1.64v31.07z"
      />
    </svg>
  );
}

export default SvgNewWindow;
