import * as React from "react";

function SvgFastCargo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 600 600"
      width="1em"
      height="1em"
      {...props}
    >
      <g fill="currentColor">
        <path d="M180.72 102.3l255.39 80.48-27.92 11.98-227.47-92.46zM342.98 222.74l-13.26 5.69-91.49-41.59 104.75 35.9z" />
        <path d="M280.32 254.37v170.05a5.88 5.88 0 003.44 5.36l147.45 67.38a4.7 4.7 0 002.44.54 5.81 5.81 0 002.45-.53l147.44-67.39a5.88 5.88 0 003.44-5.36v-170c0-2.89-1.06-5.17-3.56-6.28l-147.31-65.36a6.11 6.11 0 00-4.92 0l-147.3 65.31c-2.51 1.11-3.57 3.54-3.57 6.28zM365.9 225l25-11.1 138.88 66.92v56.88L501 351.31v-57.49a5.91 5.91 0 00-3.34-5.31zm200.45 28.79l-33.6 15.36-127.68-61.5L433.65 195zm-126.81 70.93L489.23 302v58.6a5.88 5.88 0 008.41 5.32l40.58-19.15a5.88 5.88 0 003.38-5.32v-63.37l33.6-15.36v157.92l-135.66 62zM301 253.81l50.81-22.52 128.46 61.89-46.57 21.28zm-8.85 8.91l135.66 62v157.92l-135.66-62zM13.02 162.35l258.81 105.22v-24.26L13.02 162.35zM70.52 240.67l201.31 105.22v-24.26L70.52 240.67zM111.83 315.12l160 105.21v-24.26l-160-80.95z" />
      </g>
    </svg>
  );
}

export default SvgFastCargo;
