import $ from "jquery";

export function timeConvert(time){
	let timeSplit = time.split(" ")
	let timeCount = timeSplit[0] === "a" || timeSplit[0] === "an" ? 1 : timeSplit[0]

	if(timeSplit[1] === "few"){
		return (timeCount * 1000)
	}else if(timeSplit[1] === "minutes" || timeSplit[1] === "minute"){
		return (timeCount * 1000 * 60)
	}else if(timeSplit[1] === "hours" ||timeSplit[1] === "hour"){
		return (timeCount * 1000 * 60 * 60)
	}else if(timeSplit[1] === "days" || timeSplit[1] === "day"){
		return (timeCount * 1000 * 60 * 60 * 24)
	}else if(timeSplit[1] === "months" || timeSplit[1] === "month"){
		return (timeCount * 1000 * 60 * 60 * 24 * 30)
	}else if(timeSplit[1] === "years" || timeSplit[1] === "year"){
		return (timeCount * 1000 * 60 * 60 * 24 * 30 * 12)
	}
}

export function validateEmail(email) {
	const re = /^(([^<>()[\]\\ŞşöÖüÜıİçÇ.,;:\s@"]+(\.[^<>()[\]\\ŞşöÖüÜıİçÇ.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(String(email).toLowerCase())
}

export function sizeConverter(convert, value){
	if(convert === "US"){
		if(value === "32"){
			return 0
		}
		if(value === "34"){
			return 2
		}
		if(value === "36"){
			return 4
		}
		if(value === "38"){
			return 6
		}
		if(value === "40"){
			return 8
		}
		if(value === "42"){
			return 10
		}
		if(value === "44"){
			return 12
		}
		if(value === "46"){
			return 14
		}
		if(value === "48"){
			return 16
		}
		if(value === "50"){
			return 18
		}
	}

	return value
}

export function toNewSizes(value){
	if(value === "1"){
		return "44"
	}

	if(value === "2"){
		return "45"
	}

	if(value === "3"){
		return "46"
	}

	if(value === "4"){
		return "47"
	}

	if(value === "std"){
		return "STD"
	}

	return value
}

export function rePosition(id, delay = 0){
	setTimeout(function(){
		const content = $(id + " .modal-content")
		const windowH = window.innerHeight
		const contentH = content.innerHeight()
		var marginTop = 0;
		if(windowH > contentH){
			marginTop = ((windowH - contentH) / 2) - 28
		}else{
			marginTop = 0
		}
		content.attr("style", "margin-top:" + marginTop + "px")
	}, delay)
}

export function sortFirstNumber(a, b){
	let s1 = parseInt(a)
	let s2 = parseInt(b)

	if(!s1){
		s1 = bodySizeData[a.toUpperCase()]
	}
	if(!s2){
		s2 = bodySizeData[b.toUpperCase()]
	}

	if(s1 > s2){
		return 1
	}
	if(s1 < s2){
		return -1
	}
	return 0
}

export const bodySizeData = {
	"STD": 59,
	"XS": 60,
	"S": 61,
	"M": 62,
	"L": 63,
	"XL": 64,
	"XXL": 65,
	"2XL": 65,
	"XXXL": 66,
	"3XL": 66,
	"XXXXL": 67,
	"4XL": 67,
}

export const convertStylesStringToObject = stringStyles => typeof stringStyles === 'string' ? stringStyles
    .split(';')
    .reduce((acc, style) => {
      const colonPosition = style.indexOf(':')

      if (colonPosition === -1) {
        return acc
      }

      const
        camelCaseProperty = style
          .substr(0, colonPosition)
          .trim()
          .replace(/^-ms-/, 'ms-')
          .replace(/-./g, c => c.substr(1).toUpperCase()),
        value = style.substr(colonPosition + 1).trim()

      return value ? {...acc, [camelCaseProperty]: value} : acc
    }, {}) : {}


export const createFeaturesList = (data) => {
	let newList = {}
	if(!data){
		return
	}
	for (var i = 0; i < data.length; i++) {
		try{
			const elm = this.getFeature(data[i])
			if((newList["parents"] ?? []).indexOf(elm.parentCode) < 0){
				newList["parents"] = [...(newList['parents'] ?? []), elm.parentCode]
			}
			newList[elm.parentCode] = [...(newList[elm.parentCode] ?? []), data[i]]
		}catch{
		}
	}

	return newList
}

export const writeFeaturesList = (data) => {
	const newData = this.createFeaturesList(data)
	if(!newData?.parents){
		return []
	}

	const features = newData.parents.map(e => {
		return {code: e, child: newData[e]}
	})
	return features
}
