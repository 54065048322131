import React, { useContext } from 'react'
import { LanguageContext } from './../../context/LanguageContext'

function PaymentInstalment( props ) {

	const { toPriceConvert } = useContext(LanguageContext)

	return (
		<table className="table installment">
			<tbody>
				<tr>
					<td>Taksit</td>
					<td>Aylık Ödeme</td>
				</tr>
				{
					props.data?.map((elm, i) =>
						<tr key={i} onClick={() => props.setChoicesInstallment(elm)} className="pointer">
							<td>
								<span className={"check" + ( props.choicesInstallment.installment === elm.installment ? ' active' : '')}></span>
								{
									elm.installment > 1 ?
										<>{elm.installment} Taksit</>
									:
										<>Tek Çekim</>
								}
							</td>
							<td>
								<span>{toPriceConvert(elm.monthlyPayment)}</span>
							</td>
						</tr>
					)
				}
			</tbody>
		</table>
	)
}

export default PaymentInstalment
