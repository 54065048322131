import React, { useState, useEffect, useRef, useContext } from "react";
import queryString from 'query-string'
import axios from 'axios'
import moment from 'moment'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'

import config from './../../package/config'

import * as Icons from './../icons'

import { AuthContext } from './../../context/AuthContext'
import { LanguageContext } from './../../context/LanguageContext'
import { FiltersContext } from './../../context/FiltersContext'

import SeoComponent from './../../rComponents/global/seoComponent'
import RadioButton from './../../rComponents/global/radioButton'
import ReturnOrderModal from './../../rComponents/widgets/userPage/orders/returnModal'
import CancelOrderModal from './../../rComponents/widgets/userPage/orders/cancelModal'

import Loading from './../widgets/global/loading'
import Input from './../widgets/forms/input'
import Button from './../widgets/forms/button'

function OrderDetail(props) {

	const { t } = useTranslation()
	const { openModal, isAuth } = useContext(AuthContext)
	const { toPriceConvert } = useContext(LanguageContext)
	const { getColorName } = useContext(FiltersContext)

	const [step, setStep] = useState(0)
	const [orderData, setOrderData] = useState(0)
	const [data, setData] = useState(0)
	const [form, setForm] = useState({type: "mail"})
	const [chooseOrder, setChooseOrder] = useState(null)
	const [cancelData, setCancelData] = useState(null)

	const isFirstRun = useRef(true)

	useEffect(() => {
		if(isFirstRun.current && !isAuth){
			isFirstRun.current = false

			const queryStringParse = queryString.parse(props.location.search)
			const guid = queryStringParse?.guid
			if(guid){
				axios.get(`/GetOrderByGuid?guid=${guid}`)
				.then(res => {
					if(res?.data?.isSuccess){
						setStep(2)
						setData(res.data.data.detail)
						setOrderData(res.data.data.order)
						setForm({type: "phone"})
					}else{
						setData({error: "Sipariş Bulunamadı"})
						setStep(1)
					}
				})
			}else{
				setStep(1)
			}
		}
	})

	const checkOrder = e => {
		e.preventDefault()
		setStep(0)

		if(!form?.phone || form?.phone === ""){
			return false
		}

		let data = {code: form.order}

		/*
		if(form.type === "phone"){
			data = {...data, "phone": form.phone.replace(/[^0-9]+/g, '')}
		}else{
			data = {...data, "email": form.mail}
		}
		*/

		data = {...data, "phone": form.phone.replace(/[^0-9]+/g, '')}

		data = queryString.stringify(data)
		axios.get(`/GetOrderByCode?${data}`)
		.then(res => {
			if(res?.data?.isSuccess){
				setData(res.data.data.detail)
				setOrderData(res.data.data.order)
				setStep(2)
				setForm({type: "phone"})
			}else{
				setData({error: "Sipariş Bulunamadı"})
				setStep(1)
			}
		})
	}

	const changeForm = e => {
		setForm({...form, [e.target.name]: e.target.value})
	}

	const seoData = {
		title: "Sipariş Sorgula" + config.prefix + config.name,
		description: "Sipariş Sorgula"
	}

	return (
		<>
			<SeoComponent {...seoData}/>
			<div id="content">
				<div className="container">
					{
						isAuth &&
						<div className="p-5">
							<div className="errorMessage text-center mb-5">
								Üye girişi yaptığınız için sipariş sorgulayamazsınız!
							</div>
						</div>
					}
					{
						!isAuth &&
						<div className="row justify-content-center user-page">
						{
							step === 0 &&
							<div className="col-12 col-lg-4 p-5 mb-4">
								<Loading />
							</div>
						}
						{
							step === 1 &&
							<div className="col-12 col-lg-4 pt-5 pb-5">
								<h1 className="pb-4 text-center" style={{fontSize: 20, fontWeight: 600}}>Sipariş Sorgula</h1>
								{data?.error && <span className="d-block red pb-3 text-center">{data?.error}</span>}
								<form onSubmit={checkOrder}>
									<Input name="order" title="sipariş Numarası" placeholder="Sipariş Numarası" onChange={changeForm} value={form?.order}/>
								{/*
									<div className="d-flex pb-3 pt-3">
										<RadioButton title="Telefon" active={form.type === "phone"} onClick={() => setForm({...form, type: "phone"})}/>
										<RadioButton title="E-Posta" active={form.type === "mail"} onClick={() => setForm({...form, type: "mail"})}/>
									</div>
								*/}
									{
										// form.type === "phone" &&
										<Input type="tel" name="phone" title="Telefon Numarası" placeholder="Telefon Numarası" onChange={changeForm} value={form?.phone}/>
									}
									{
										/*
										form.type === "mail" &&
										<Input type="mail" name="mail" title="E-Posta Adresiniz" placeholder="E-Posta Adresiniz" onChange={changeForm} value={form?.mail}/>
										*/
									}
									<Button className="btn float-right" title="SORGULA"/>
								</form>
							</div>
						}
						{
							!!data && step === 2 &&
							<div className="col-12 col-lg-7 pt-5 pb-5">
								<ReturnOrderModal chooseOrder={chooseOrder} reset={() => {setChooseOrder(null)}}/>
								<CancelOrderModal cancelData={cancelData} setCancelData={setCancelData} reset={() => {setCancelData(null)}}/>
								<div className="scl-content-title">
									<span onClick={() => setStep(1)} className="btn user-title-btn base-regular light">
										<Icons.ArrowToLeft height=".8em"/> {t('UserPage.order-info.go-back')}
									</span>
									<span onClick={() => openModal('question', {orderCode: data?.code})} className="btn user-title-btn float-right base-regular light">{t('UserPage.order-info.new-message')}</span>
								</div>
								<div className="scl-section-content info">
									<h1 className="base-regular">{t('UserPage.order-info.title')}</h1>
									<div className="row bdr g-0">
										<div className="col-12 col-lg base-regular">SİPARİŞ DURUMU</div>
										<div className="col-12 col-lg">
											<span className="float-lg-right">{orderData?.orderstatus?.title}</span>
										</div>
									</div>
									<div className="row bdr g-0">
										<div className="col-12 col-lg base-regular">{t('UserPage.order-info.orderer')}</div>
										<div className="col-12 col-lg">
											<span className="float-lg-right">{data?.deliveryAddress?.name ?? "-"} {data?.deliveryAddress?.surname}</span>
										</div>
									</div>
									<div className="row bdr g-0">
										<div className="col-12 col-lg base-regular">{t('UserPage.order-info.order-date')}</div>
										<div className="col-12 col-lg">
											<span className="float-lg-right">{moment(data.dateTime).format("DD.MM.YYYY kk:mm")}</span>
										</div>
									</div>
									<div className="row bdr g-0">
										<div className="col-12 col-lg base-regular">{t('UserPage.order-info.order-code')}</div>
										<div className="col-12 col-lg">
											<span className="float-lg-right">{data.code}</span>
										</div>
									</div>
									{
										data.dsaContractPath &&
										<div className="row bdr g-0">
											<div className="col-12 col-lg base-regular">{t('UserPage.order-info.order-contract')}</div>
											<div className="col-12 col-lg">
												<span className="float-lg-right"><a href={data.dsaContractPath} target="_blank">{t('UserPage.order-info.order-contract-show')}</a></span>
											</div>
										</div>
									}
									{
										data.piiContractPath &&
										<div className="row bdr g-0">
											<div className="col-12 col-lg base-regular">{t('UserPage.order-info.order-contract2')}</div>
											<div className="col-12 col-lg">
												<span className="float-lg-right"><a href={data.piiContractPath} target="_blank">{t('UserPage.order-info.order-contract-show')}</a></span>
											</div>
										</div>
									}
									<div className="w-100"></div>
									<h1 className="base-regular">{t('UserPage.order-info.order-summary')}</h1>
									{
										data.details?.map((elm, i) =>
											<div className="row order-short g-0 align-items-center" key={i}>
												<div className="col-3 col-lg-2 pr-2 pr-lg-0">
													<img src={elm.variant.product.imagePath} width="80px" alt=""/>
												</div>
												<div className="col-6 col-lg-5">
													<span className="base-regular">{elm.variant.product.title}</span>
													<span>{t('UserPage.order-info.quantity')} : {elm.quantity}</span>
													<span>{t('UserPage.order-info.color')} : {getColorName(elm.variant.product.colorCode)}</span>
													<span>{t('UserPage.order-info.body')} : {elm.variant.name}</span>
												</div>
												<div className="col-3 col-lg-5">
													<div className="row align-items-center">
														<div className="col text-right text-lg-left">
															<span>
															{
																elm.statuses?.map((state, i) =>
																	<React.Fragment key={i}>
																		<span>
																		{state?.status?.title ?? "bbd"}
																		{
																			elm.statuses.length > 1 &&
																			<> ({state.quantity})</>
																		}
																		</span>
																		{
																			!state?.status?.id === 6 &&
																			<br/>
																		}
																	</React.Fragment>
																)
															}
															</span>
														</div>
														<div className="col text-right">
															{toPriceConvert(elm.sellingPrice)}
														</div>
													</div>
												</div>
											</div>
										)
									}
									<div className="row bdr g-0">
										<div className="col base-regular">{t('UserPage.order-info.product-total-price')}</div>
										<div className="col">
											<span className="float-lg-right">{toPriceConvert(data.orderTotalPrice)}</span>
										</div>
									</div>
									{
										data.totalDiscount > 0 &&
										<div className="row bdr g-0 green">
											<div className="col base-regular">{t('UserPage.order-info.discount')}</div>
											<div className="col">
												<span className="float-lg-right">{toPriceConvert(data.totalDiscount)}</span>
											</div>
										</div>
									}
									{
										data.cargo?.price > 0 &&
										<div className="row bdr g-0">
											<div className="col base-regular">
												{t('UserPage.order-info.cargo')} ({data?.cargo?.name})
											</div>
											<div className="col">
												<span className="float-lg-right">{toPriceConvert(data.cargo?.price, false, 2, false)}</span>
											</div>
										</div>
									}
									<div className="row bdr g-0">
										<div className="col base-regular">{t('UserPage.order-info.order-total-price')}</div>
										<div className="col">
											<span className="float-lg-right">{toPriceConvert(data.orderTotalDiscountedPrice)}</span>
										</div>
									</div>
									{
										data?.returnCode &&
										<div className="row bdr g-0">
											<div className="col base-regular">İade Bilgisi</div>
											<div className="col">
												<span className="float-lg-right">
												{
													data?.returnCode &&
													<a href={`${data?.cargo?.trackingurl}${data?.returnCode}`} target="_blank" className="">Kargo Takip</a>
												}
												</span>
											</div>
										</div>
									}
									<div className="w-100"></div>
									{
										(data?.deliveryAddress || data?.invoiceAddress) &&
										<>
										<h1 className="base-regular">{t('UserPage.order-info.address-info')}</h1>
										{
											[{...data?.deliveryAddress, type: t('UserPage.order-info.delivery')}, {...data?.invoiceAddress, type: t('UserPage.order-info.invoice')}].map((elm, i) =>
												<React.Fragment key={i}>
													{
														elm.name &&
														<div className="row bdr g-0" key={i}>
															<div className="col-12 col-lg-4">
																<span className="base-regular">{elm?.type}</span>
																<span>{elm?.name + " " + elm?.surname}</span>
															</div>
															<div className="col-12 col-lg-6">
																<span>{elm?.title && <>{elm.title}<br /></>}{elm?.city?.name} {elm?.county?.name} {elm?.country?.name} {elm?.phone} </span>
																<span>{elm?.addressText}</span>
															</div>
														</div>
													}
												</React.Fragment>
											)
										}
										</>
									}
									<div className="w-100"></div>
									<h1 className="base-regular">DİĞER</h1>
									{
										data?.returnCode &&
										<div className="row bdr g-0">
											<div className="col-12 col-lg-4">
												<span className="base-regular">İade Kodu: </span>
												<small>{data?.cargo?.name} <strong className="green">ücretsiz</strong> olarak gönderebilirsiniz</small>
											</div>
											<div className="col-12 col-lg-6">
												<span>{data.returnCode}</span>
											</div>
										</div>
									}
									{
										data?.extraNote &&
										<div className="row bdr g-0">
											<div className="col-12 col-lg-4">
												<span className="base-regular">Sipariş notu: </span>
											</div>
											<div className="col-12 col-lg-6">
												<span>{data?.extraNote}</span>
											</div>
										</div>
									}
									<div className="row bdr g-0">
										<div className="col-12 col-lg-4">
											<span className="base-regular">Hediye paketi olsun mu?</span>
										</div>
										<div className="col-12 col-lg-6">
											<span>{data?.isGift ? 'EVET' : 'HAYIR'}</span>
										</div>
									</div>
									<div className="w-100 mt-3"></div>
									{
										orderData?.returnenabled &&
										<span className="btn mr-3" onClick={() => {
											if(orderData?.returnmessage === "" || orderData?.returnmessage === null){
												setChooseOrder(orderData)
											}else{
												Swal.fire(orderData?.returnmessage, '', 'error')
											}
										}}>
											İade Et
										</span>
									}
									{
										orderData?.cancelationenabled &&
										<span className="btn mr-3" onClick={() => setCancelData(orderData)}>
											İptal Et
										</span>
									}
									<div className="w-100 mt-3"></div>
								</div>
							</div>
						}
						</div>
					}
				</div>
			</div>
		</>
	)
}

export default OrderDetail;
