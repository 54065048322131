import React from 'react'
import TypeLink from './../global/link'
import Loading from './../../components/widgets/global/loading'
import SwiperCore, { Navigation, Pagination, Scrollbar, Autoplay, Lazy } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

SwiperCore.use([Navigation, Pagination, Autoplay, Lazy]);

function HomeSlider(props) {
	return (
		<div className="banner not">
			<Swiper slidesPerView={1} resistance resistanceRatio={1} spaceBetween={10} className="homeFullSlider" lazy navigation loop>
				{
					props?.data?.map((elm, i) =>
						<SwiperSlide key={i}>
							<TypeLink to={elm?.url} className="w-100">
								<img src={elm?.img} className="d-block w-100" alt={elm?.title} />
							</TypeLink>
							<div className="swiper-lazy-preloader"></div>
						</SwiperSlide>
					)
				}
			</Swiper>
		</div>
	)
}

export default HomeSlider
