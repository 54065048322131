import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import styles from "./groupSlider.module.css"
import axios from 'axios'
import SwiperCore, { Navigation, Pagination, Scrollbar, Lazy } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

SwiperCore.use([Navigation, Pagination, Lazy])

function GroupSlider(props) {

	const [productData, setProductData] = useState(null)

	const isFirstRun = useRef(true)

	const oneSliderSize = window.innerWidth <= 540 ? 1 : 3

	useEffect(() => {
		if(isFirstRun.current && props?.data?.url){
			isFirstRun.current = false

			const requestHeader = {headers: {titleinurl: props?.data?.url}}

			isFirstRun.current = false
			const pageSize = props?.data?.pageSize ?? 12

			axios.get("/GetProducts?page=1&pagesize=" + pageSize, requestHeader)
			.then(res => {
				toGroup(res?.data?.data?.data)
			})
		}
	}, [props])


	const toGroup = (data) => {
		if(!data){
			return false
		}
		let gecici = []
		let newData = []

		for (var i = 0; i < data.length; i++) {
			if(gecici.length <= oneSliderSize){
				gecici = [...gecici, data[i]]
			}else{
				newData = [...newData, gecici]
				gecici = [data[i]]
			}
		}
		newData = [...newData, gecici]
		setProductData(newData)
	}

	return (
		productData &&
		<div className="banner">
			<div className="container">
				{props?.data?.title && <h2 className="p-2"><span>{props.data.title}</span></h2>}
				<div className={styles.groupSlider}>
				{
					<Swiper slidesPerView={1} spaceBetween={10} pagination={{ clickable: true }} lazy={true}>
						{
							productData.map((pData, i) =>
								<SwiperSlide key={i}>
									{
										pData.map((elm, c) =>
											<Link to={elm.url} className={`${styles.sliderProduct} ${styles.[`_${oneSliderSize}`] ?? ''}`} key={c}><img src={elm.imagePaths[0]} className="d-block w-100" alt={elm.title}/></Link>
										)
									}
								</SwiperSlide>
							)
						}
					</Swiper>
				}
				</div>
			</div>
		</div>
	)
}

export default GroupSlider
