import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { AuthContext } from './../../context/AuthContext'

function TypeLink(props) {

	let data = props ?? {}
	data = {...data, style: data.style ? data.style : {}}

	const { openModal } = useContext(AuthContext)

	if(!data?.to || data?.to?.includes("javascript:void(0)")){
		return <a {...data} to={undefined}>{data.children}</a>
	}

	if(data?.to?.modal){
		return <span {...data} to={undefined} onClick={() => openModal(data.to.modal)} className={data.className ?? '' + ' ' + data?.props?.className ?? ''}>
			{data.children}
		</span>
	}

	if(data?.to?.includes("http")){
		return <a {...data} to={undefined} href={data.to ?? ''} target="_blank" className={data.className ?? '' + ' ' + data?.props?.className ?? ''}>
			{data.children}
		</a>
	}

	if(data?.to?.includes("modal")){
		return <span {...data} to={undefined} className={"pointer " + data.className ?? '' + ' ' + data?.props?.className ?? ''}>
			{data.children}
		</span>
	}

	return <Link {...data} to={(data?.to[0] !== "/" ? "/" : "") + data?.to ?? ''}>
		{data.children}
	</Link>
}

export default TypeLink
