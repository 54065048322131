import React from 'react'
import styles from './basketOrderInfo.module.css'


function BasketOrderInfo(props) {
	const { data } = props
	return (
		<div className={styles.orderInfo}>
			<div className={`${styles.col} ${styles.orderCode}`}>
				<span>Sipariş Kodu</span>
				<span>{data.code.split("").map((e, i) => i%3 === 2 ? `${e} ` : e)}</span>
			</div>
			<div className={`${styles.col}`}>
				<span>Teslimat Adresi</span>
				<span>
					<span>
						{data.deliveryAddress.county?.name} / {data.deliveryAddress.city.name}
					</span>
					<span>
						{data.deliveryAddress.country.name}
					</span>
				</span>
			</div>
			<div className={`${styles.col}`}>
				<span>Fatura Adresi</span>
				<span>
					<span>
						{data.invoiceAddress.county?.name} / {data.invoiceAddress.city.name}
					</span>
					<span>
						{data.invoiceAddress.country.name}
					</span>
				</span>
			</div>
			<div className={`${styles.col}`}>
				<span>Sipariş Tutarı</span>
				<span>{data.totalPrice}</span>
			</div>
		</div>
	)
}

export default BasketOrderInfo
