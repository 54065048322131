import * as React from "react";

function SvgUser(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M24.26 20.87c3.6-1.75 6.08-5.43 6.08-9.69C30.34 5.23 25.5.39 19.55.39S8.77 5.23 8.77 11.18c0 4.37 2.62 8.14 6.37 9.83C6.71 23.13.38 31 .03 40h1.58c.42-9.78 8.51-18.01 18.4-18.01S37.98 30.22 38.39 40h1.58c-.35-9.21-6.98-17.22-15.71-19.13zm-4.7-.46c-5.09 0-9.23-4.14-9.23-9.23s4.14-9.23 9.23-9.23 9.23 4.14 9.23 9.23-4.14 9.23-9.23 9.23z"
      />
    </svg>
  );
}

export default SvgUser;
