import React, { useContext, useState, useEffect, useRef } from "react"
import * as Icons from './../../icons/index'
import config from './../../../package/config'
import { gaView } from './../../../package/google-analytic'
import { visitTag } from './../../../package/criteo'

import { useTranslation } from 'react-i18next'
import {FavoriteContext} from './../../../context/FavoriteContext'
import { FiltersContext } from './../../../context/FiltersContext'
import { LanguageContext } from './../../../context/LanguageContext'

import Loading from './../../widgets/global/loading'

function Favorite(props){

	const { t } = useTranslation()
	const { removeFavorite, favoriteData, updateFavorite } = useContext(FavoriteContext)
	const { getColorName } = useContext(FiltersContext)
	const { toPriceConvert } = useContext(LanguageContext)

	const isFirstRun = useRef(true)

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false
			gaView()
			visitTag()
			updateFavorite()
		}
	}, [props])

	return (
		<>
		<div className="scl-content-title">
			<h1 className="base-regular">{t('UserPage.favorite.title')}</h1>
		</div>
		<div className="scl-section-content list">

		{
			!favoriteData ?
				<Loading />
			:
			favoriteData.length <= 0 ? (
				<div className="basket-empty base-light order-empty">
					<Icons.NonBasket />
					<span dangerouslySetInnerHTML={{__html: t('UserPage.favorite.non', { 'interpolation': { escapeValue: false } })}}></span>
					<a href="/" title={t('UserPage.favorite.non-btn')} className="btn base-regular">{t('UserPage.favorite.non-btn')}</a>
				</div>
			) : (
				<>
					<div className="row g-0 head">
						<div className="col-8 col-lg-6">{t('UserPage.favorite.product')}</div>
						<div className="col-4 col-lg-2">{t('UserPage.favorite.price')}</div>
						<div className="col-lg-2 d-none d-lg-flex">{t('UserPage.favorite.stock')}</div>
					</div>
					{
						favoriteData.map((elm, i) =>
							<div key={i} className="row bdr g-0 base-regular">
								<div className="col-3 col-lg-2 head">
									<a href={"/" + elm.data.url}><img src={elm.data.imagepath !== "" ? elm.data.imagepath : config.noImage}  width="100px" alt=""/></a>
								</div>
								<div className="col-5 col-lg-4 head"><a href={"/" + elm.data.url}>{elm.data.title} <br/> <b>{t('Detail.info.color')}:</b> {getColorName(elm.data.colorcode)}</a></div>
								<div className="col-3 col-lg-2 head">{toPriceConvert(elm.data.price1)}</div>
								<div className="col-3 col-lg-3 head d-none d-lg-block">{elm.stock > 0 ? t('UserPage.favorite.in-stock') : t('UserPage.favorite.out-stock')}</div>
								<div className="col-1 col-lg-1 edit float-right text-center head">
									<span onClick={() => removeFavorite({ProductColorCode: elm.productcolorcode})}><Icons.Close /></span>
								</div>
							</div>
						)
					}
				</>
			)
		}
		</div>
		</>
	);
}

export default Favorite;
