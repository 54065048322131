import * as React from "react";

function SvgArrowUp(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M19.4 15.15c.16-.16.37-.25.59-.25.22 0 .44.09.59.25l7.57 7.57c.33.33.33.86 0 1.19-.33.33-.86.33-1.19 0L20 16.93l-6.97 6.97c-.16.16-.38.25-.59.25-.22 0-.43-.08-.59-.25a.839.839 0 010-1.19l7.55-7.56z"
      />
    </svg>
  );
}

export default SvgArrowUp;
