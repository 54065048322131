import * as React from "react";

function Svgİnstagram(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <g fill="currentColor">
        <path d="M20 7.49c4.08 0 4.56.02 6.17.09 1.49.07 2.3.32 2.83.53.71.28 1.22.61 1.75 1.14.53.53.86 1.04 1.14 1.75.21.54.46 1.35.53 2.83.07 1.61.09 2.09.09 6.17s-.02 4.56-.09 6.17c-.07 1.49-.32 2.3-.53 2.83-.28.71-.61 1.22-1.14 1.76-.53.53-1.04.86-1.75 1.14-.54.21-1.35.46-2.83.53-1.61.07-2.09.09-6.17.09s-4.56-.02-6.17-.09c-1.49-.07-2.3-.32-2.83-.53-.71-.28-1.22-.61-1.76-1.14-.53-.54-.86-1.05-1.14-1.76-.21-.54-.46-1.35-.53-2.83-.07-1.61-.08-2.09-.08-6.17s.02-4.56.09-6.17c.07-1.49.32-2.3.53-2.83.28-.71.61-1.22 1.14-1.75.53-.54 1.04-.87 1.75-1.15.54-.21 1.35-.46 2.83-.53 1.61-.07 2.09-.08 6.17-.08m0-2.75c-4.15 0-4.66.02-6.29.09-1.63.07-2.74.33-3.71.71-1 .39-1.85.91-2.7 1.76-.85.85-1.37 1.7-1.76 2.7-.38.97-.64 2.08-.71 3.7-.08 1.64-.09 2.15-.09 6.3s.02 4.66.09 6.29c.07 1.62.33 2.73.71 3.7.39 1 .91 1.85 1.76 2.7.85.85 1.7 1.37 2.7 1.76.97.38 2.08.64 3.7.71 1.63.07 2.15.09 6.29.09 4.15 0 4.66-.02 6.29-.09 1.62-.07 2.73-.33 3.7-.71 1-.39 1.85-.91 2.7-1.76.85-.85 1.37-1.7 1.76-2.7.38-.97.64-2.08.71-3.7.07-1.63.09-2.15.09-6.29 0-4.15-.02-4.66-.09-6.29-.07-1.62-.33-2.73-.71-3.7-.39-1-.91-1.86-1.76-2.7C31.85 6.45 31 5.93 30 5.54c-.97-.38-2.08-.64-3.7-.71-1.64-.08-2.15-.09-6.3-.09" />
        <path d="M20 12.16a7.84 7.84 0 100 15.68 7.84 7.84 0 000-15.68m0 12.93c-2.81 0-5.09-2.28-5.09-5.09s2.28-5.09 5.09-5.09 5.09 2.28 5.09 5.09-2.28 5.09-5.09 5.09M29.98 11.85a1.83 1.83 0 11-3.662-.002 1.83 1.83 0 013.662.002" />
      </g>
    </svg>
  );
}

export default Svgİnstagram;
