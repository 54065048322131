import * as React from "react";

function SvgMenuBold(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 384"
      width="1em"
      height="1em"
      {...props}
    >
      <g fill="currentColor">
        <path d="M0 277.333h384V320H0zM0 170.667h384v42.667H0zM0 64h384v42.667H0z" />
      </g>
    </svg>
  );
}

export default SvgMenuBold;
