import React from "react";
import styles from './iban.module.css'

function Iban(props) {
	const onClick = () => {
		if(props.selectHandler){
			props.selectHandler({...props.data})
		}
	}

	return (
		<div className={`${styles.iban} ${props.selectHandler ? styles.checking : ''} ${props.selected === props?.data.iBan ? styles.active : ''}`} onClick={onClick}>
			{props.selectHandler && <span className={styles.check}></span>}
			<span className={styles.ibanTitle}>{props?.data.title}</span>
			<span>{props?.data?.iBan}</span>
			<span>{props?.data?.fullName}</span>
			<b className={styles.delete} onClick={props?.deleteHandler}>SİL</b>
		</div>
	)
}

export default Iban;
