import React, { useState } from "react";
import axios from 'axios'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import yup from "./../../../../package/yup-init";
import Toast from "./../../../../package/swal-init";

import Modal from './../../../global/modal/modal'
import Loading from './../../../../components/widgets/global/loading'
import Input from './../../../../components/widgets/forms/input'
import Button from './../../../../components/widgets/forms/button'

const schema = yup.object().shape({
	fullName: yup.string()
	.min(2)
	.max(30)
	.required(),
	iBan: yup.string()
	.min(24)
	.required()
})

function IbanForm(props) {

	const { register: addRegiter, handleSubmit: onSubmit, errors: addErrors, setError: setAddError } = useForm({
		resolver: yupResolver(schema),
		reValidateMode: 'onChange'
	})

	const onSubmitHandler = (data) => {
		const iban = data?.iBan?.replaceAll('_', '').replaceAll(' ', '')
		if(iban?.length < 26) {
			setAddError('iBan', {
				type: "required",
				message: "Lütfen formata uygun giriniz"
			})
			return false
		}
		props.onSubmit(data)
	}

	return (
		<form onSubmit={onSubmit(onSubmitHandler)}>
			<Input name="fullName" title="KART SAHİBİNİN ADI SOYADI *:" placeholder="ADINIZI GİRİNİZ" error={addErrors?.fullName?.message} transferref={addRegiter} />
			<Input type="iban" name="iBan" title="IBAN NUMARASI *:" placeholder="IBAN GİRİNİZ" error={addErrors?.iBan?.message} transferref={addRegiter} />
			<Button type="submit" title="İLERLE" className="btn"/>
		</form>
	)
}

export default IbanForm
