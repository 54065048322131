import React from 'react'
import styles from './socialLoginButtons.module.css'
import * as Icons from './../../../components/icons'

import config from './../../../package/config'

function SocialLoginModal() {
	return (
		<>
			<div className={styles.social}>
				<a href={config.facebookLogin} className="btn facebook">
					<Icons.FacebookCircle />Facebook
				</a>
				<a href={config.googleLogin} className="btn facebook">
					<Icons.Google />Google
				</a>
			</div>
			<span className={styles.hr}><span>veya</span></span>
		</>
	)
}

export default SocialLoginModal
