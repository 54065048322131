import React from 'react'
import TypeLink from './../global/link'

function HomeCategories(props) {
	return (
		<div className={`banner category ${props?.className ?? ''}`}>
			<div className="container">
				{props.title && <h2><span>{props.title}</span></h2>}
				<div className="row g-3">
					{
						props?.data?.map((elm, i) =>
							<div className="col" key={i}>
								<TypeLink to={elm.url}>
									<img src={elm.img} alt={elm.title} loading="lazy"/>
								</TypeLink>
								<div className="pt-3 text-center">
									<TypeLink to={elm.url} className="underline">{elm.title}</TypeLink>
								</div>
							</div>
						)
					}
				</div>
			</div>
		</div>
	)
}

export default HomeCategories
