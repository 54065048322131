import * as React from "react";

function SvgHeartFull(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M20 37.33a1.91 1.91 0 01-1.37-.57L3.34 21.47a11 11 0 017.79-18.8 10.93 10.93 0 017.79 3.22L20 7l1.08-1.08a10.93 10.93 0 017.79-3.22 11 11 0 017.79 18.8L21.37 36.76a1.91 1.91 0 01-1.37.57z"
      />
    </svg>
  );
}

export default SvgHeartFull;
