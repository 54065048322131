import React from 'react'
import styles from './homeText.module.css'

function homeText(props){
	return (
		<div className="banner">
			<div className="container">
				<div className={`row g-0 ${styles.main}`}>
					<div className="col pointer" {...{...props, data: undefined}}>
						{props?.data && <p dangerouslySetInnerHTML={{__html: props.data}}></p>}
					</div>
				</div>
			</div>
		</div>
	)
}

export default homeText
