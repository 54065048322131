import * as React from "react";

function SvgCargo24(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <g fill="currentColor">
        <path d="M5.95 31.05c0 .28.23.5.5.5h6.78c.28 0 .5-.23.5-.5.01-.27-.22-.5-.5-.5H6.44a.5.5 0 00-.49.5zM7.34 29.11c0 .28.23.5.5.5.28 0 .5-.23.5-.5v-2.17h.99l-1.5-2.59-1.5 2.59h.99v2.17h.02zM11.34 29.11c0 .28.23.5.5.5.28 0 .5-.23.5-.5v-2.17h.99l-1.5-2.59-1.5 2.59h.99v2.17h.02zM31.58 20.58c-4.64 0-8.41 3.77-8.41 8.41s3.77 8.41 8.41 8.41 8.41-3.77 8.41-8.41-3.77-8.41-8.41-8.41zm.44 15.75v-1a.489.489 0 00-.49-.48c-.27 0-.48.21-.49.48v1c-3.7-.28-6.64-3.29-6.8-7.02h.9c.27-.01.48-.22.48-.49s-.21-.48-.48-.49h-.89a7.379 7.379 0 016.91-6.68v1.45c.01.27.22.48.49.48s.48-.21.49-.48v-1.45c3.62.28 6.51 3.17 6.78 6.79h-1.55c-.27.01-.48.22-.48.49s.21.48.48.49h1.56c-.23 3.72-3.2 6.69-6.91 6.91z" />
        <path d="M23.71 34.64H2.68c-.93 0-1.68-.75-1.68-1.67V5.26c0-.92.75-1.67 1.67-1.67h12.31v10.52c0 .3.18.57.45.69.1.04.2.06.3.06.19 0 .37-.07.51-.2l3.61-3.38 3.56 3.38a.75.75 0 001.26-.55V3.59h12.44c.92 0 1.67.75 1.67 1.67V23.3c.01.27.22.48.49.48s.48-.21.49-.48V5.26c0-1.47-1.19-2.66-2.66-2.66H2.67C1.2 2.6.01 3.79.01 5.26v27.7c0 1.47 1.19 2.66 2.66 2.66H23.7c.26-.01.48-.22.48-.49 0-.26-.21-.48-.47-.49zm-.08-21.21l-3.26-3.1a.756.756 0 00-1.03 0l-3.32 3.11v-9.7h7.61v9.69z" />
        <path d="M27.82 24.35c-.01 0-.01-.01 0 0l-.01-.01a.498.498 0 00-.69.01c-.19.19-.19.49-.01.69l4.07 3.99v4.29c.01.27.22.48.49.48s.48-.21.49-.48v-4.7l-4.34-4.27z" />
      </g>
    </svg>
  );
}

export default SvgCargo24;
