import React, { useState, useEffect, useEffectDebugger, useContext, useRef } from "react";
import { useHistory } from "react-router-dom"
import Pagination from "react-js-pagination";
import $ from "jquery";
import axios from 'axios';
import queryString from 'query-string'
import config from './../../package/config'
import { toNewSizes, sortFirstNumber, createFeaturesList } from './../../package/global-function'
import { gaAddImpression } from './../../package/google-analytic'
import { visitTag, listPageTag } from './../../package/criteo'

import { Trans } from 'react-i18next'
//import { useTranslation } from 'react-i18next';
import { FiltersContext } from './../../context/FiltersContext'
import { LanguageContext } from './../../context/LanguageContext'
import { BasketContext } from './../../context/BasketContext'

import * as Icons from './../icons/index'
import Product from './../widgets/list/product'
import Oneriler from './../widgets/list/oneriler'
import FirsatUrunu from './../widgets/list/firsaturunu'
import NotProduct from './../widgets/global/not-products'
import Breadcrumb from './../widgets/global/breadcrumb'
import Loading from './../widgets/global/loading'

import QuickModal from './../modal/quick'
import CombineModal from './../modal/combine'

import TypeLink from './../../rComponents/global/link'
import FilterItem from './../../rComponents/ui/list/filterItem'
import Combine from './../../rComponents/ui/list/combine'
import Banner from './../../rComponents/ui/list/banner'

import SeoComponent from './../../rComponents/global/seoComponent'
import { withTranslation } from 'react-i18next';

function t (s) {
	return <Trans i18nKey={s} />
}

function SortingName(props) {
	let key = ""
	switch (props.id) {
		case 0:
			key = "ListPage.sort.smart-sort"
			break;
		case 1:
			key = "ListPage.sort.price-grow"
			break;
		case 2:
			key = "ListPage.sort.price-decreas"
			break;
		case 3:
			key = "Yeniden eskiye"
			break;
		case 4:
			key = "Eskiden yeniye"
			break;
		default:
			key = "ListPage.sort.smart-sort"
			break;
	}
	return <Trans i18nKey={key} />
}

class List extends React.Component {

	//const history = useHistory()
	//const {t} = useTranslation()

	//const { getBrandName, getColorName, createFeaturesList, getFeature, getFeatureName, getGroupName, getCategoryName } = useContext(FiltersContext)
	//const { getChoicesLanguage, toPriceConvert } = useContext(LanguageContext)
	//const { utmCode, setUtmCode } = useContext(BasketContext)

	//let scrollingCount = 0
	//let scrollTop = 0

	/*const [loading, setLoading] = useState(true)
	const [data, setData] = useState([])
	const [filters, setFilters] = useState([])
	const [currentPage, setCurrentPage] = useState()
	const [totalProduct, setTotalProduct] = useState(0)
	const [totalPage, setTotalPage] = useState(-1)
	let initSectionFilters = {
		brands: [],
		colors: [],
		categories: [],
		features: [],
		groups: [],
		sizes: [],
		price: []
	}

	const [sectionFilters, setSectionFilters] = useState()

	const [sorting, setSorting] = useState()
	const [productColumn, setProductColumn] = useState(3)
	const [quickData, setQuickData] = useState([])
	const [combineData, setCombineData] = useState([])
	const [pathList, setPathList] = useState([])
	const [title, setTitle] = useState("")
	const [description, setDescription] = useState("")

	const isPageChange = useRef()
	const isFirstRun = useRef()
	const isSearchParams = useRef()
	const isBusy = useRef()
	const isRequest = useRef()
	const requestBlock = useRef(true)*/

	windowWidth = 0
	scrollingCount = 0
	scrollTop = 0

	initSectionFilters = {
		brands: [],
		colors: [],
		categories: [],
		features: [],
		groups: [],
		sizes: [],
		price: []
	}

	static contextType = FiltersContext;

	constructor (props) {
		super(props)

		console.log("constructor")

		const localListPageData = JSON.parse(sessionStorage.getItem('listPage'))
		let localData = null
		try{
			const url = '/' + this.props.match.params.url + (this.props.match.params.page > 1 ? "/" + this.props.match.params.page : '')
			localData = localListPageData[this.props.match.params.url]
		}catch{

		}

		var windowWidth = $(window).width();

		this.state = {
			data: null,
			filters: null,
			currentPage: parseInt(props?.match?.params?.page) || 1,
			totalProduct: 0,
			totalPage: -1,
			sectionFilters: {...localData?.sectionFilters, ...this.initSectionFilters, ...this.loadQueryString()},
			sorting: localData?.sorting || {id: 0},
			productColumn: window.innerWidth <= 540 ? 2 : 3,
			quickData: [],
			combineData: [],
			pathList: [],
			title: "",
			description: "",
			height: 150,
			fiyatlar:
				config.app_name === "secil" ? [["0","150"], ["150","250"], ["250","350"], ["350","450"], ["450","1000000"]] :
				config.app_name === "ilmio" && [["0","500"], ["500","1000"], ["1000","2000"], ["2000","1000000"]]
		}

		//setSorting({id: 0, text: t('ListPage.sort.smart-sort')})

		/*if(!utmCode?.lock){
			let utmData
			if(this.props.match.params.query){
				utmData = {code: "s-" + this.props.match.params.query, lock: false}
			}else{
				utmData = {code: "g-" + this.props.match.params.url.replace('/', ''), lock: false}
			}
			setUtmCode(utmData)
		}*/


		//yapılacak

		this.containerRef = React.createRef();
		this.sidebarRef = React.createRef();
	}


	/*useEffect(() => {
			if(!utmCode?.lock){
				let utmData
				if(this.props.match.params.query){
					utmData = {code: "s-" + this.props.match.params.query, lock: false}
				}else{
					utmData = {code: "g-" + this.props.match.params.url.replace('/', ''), lock: false}
				}
				setUtmCode(utmData)
			}

			windowWidth = $(window).width();
			if(windowWidth <= 540){
				setProductColumn(2)
			}
		}else{
			loadQueryString()
		}

		$(window).off().on('scroll', handleScroll)

		return () => {
			$(window).off()
		}

	/*
		if(!data && currentPage && isBusy.current !== currentPage){
			//updateData()
			var offset = $("#content").offset();
			setTimeout(() => {
				$("html").stop().animate({ scrollTop: offset.top - (window.innerWidth > 768 ? 80 : 10) }, 800);
			}, 50);
		}

		setTimeout(function(){
			$(".filter-title").off().on("click", function(){
				$(this).toggleClass("active");
				$(this).next("ul").slideToggle();
			})
			$(".scl-mobile-filter").off().on("click",function(){
				if($(this).hasClass("active")){
					$(this).removeClass("active");
					$(".scl-sidebar").animate({"left":(windowWidth <= 500 ? "-100%" : "-70%" )}, 600);
					$(".close-page-hover").hide();
					$("body,#root, .App").removeClass("modal-open");
				}else{
					$(this).addClass("active");
					$(".scl-sidebar").animate({"left":"0%"}, 600);
					$(".close-page-hover").show();
					$("body,#root, .App").addClass("modal-open");
				}
			});
		}, 200)
	}, [data])

	useEffect(() => {
		if(!loading) {
			this.props.history.replace('/')
		}
	}, [getChoicesLanguage()])
	*/

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
		visitTag()
		console.log("didmount")
		this.updateData()
	}

	componentWillUnmount() {
		console.log("willunmount")
		window.removeEventListener('scroll', this.handleScroll);
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevState.sorting != this.state.sorting || prevState.sectionFilters != this.state.sectionFilters) {
			console.log("sorting sectionFilters")
			this.updateData()
		}

		if(prevState.currentPage != this.state.currentPage) {
			console.log("currentPage")
			this.updateData()
		}

		if(prevProps.match.params.page != this.props.match.params.page) {
			console.log("page")
			this.setState({currentPage: parseInt(this.props.match.params.page) || 1})
		}

		if(this.props.match.params.url != prevProps.match.params.url) {
			console.log("url")
			this.setState({sorting: {id: 0}, sectionFilters: this.initSectionFilters, currentPage: 1})
		}

		if(this.props.history.location.search != prevProps.history.location.search) {
			console.log("search")
			this.setState({sectionFilters: {...this.initSectionFilters, ...this.loadQueryString()}})
		}

		if(prevState.data != this.state.data) {
			window.scroll({top: 0, left: 0, behavior: 'smooth' })
		}

		if (this.props.t !== prevProps.t) {
			console.log("t")
			this.props.history.replace('/')
		}
	}

	handleScroll(event) {
    let scrollTop = event.srcElement.body.scrollTop,
        itemTranslate = Math.min(0, scrollTop/3 - 60);

		const windowWidth = window.innerWidth;
		if(windowWidth > 768){
			if($(window).scrollTop() > 40){
				if(!$("header").hasClass("fixed")) {
					$("header").addClass("fixed")
					$("body").addClass("fixed-header")
				}
				const height = ($(".scl-product-list").height() + 150) + "px"
				$("#content .container").css("height", height)
			} else {
				$("header").removeClass("fixed")
				$("body").removeClass("fixed-header")
			}
		}
	}

	loadQueryString = () => {
		const queryStringParse = queryString.parse(this.props.location.search)
		const permissonQueryString = Object.keys(this.initSectionFilters)
		const getQueryStringNames = Object.keys(queryStringParse)
		const controlString = getQueryStringNames.filter(e => permissonQueryString.indexOf(e) > -1).join('-')

		//if(isSearchParams.current !== controlString){
			//isSearchParams.current = controlString

			Object.keys(queryStringParse).map(e =>
				Object.keys(this.initSectionFilters).indexOf(e) <= -1 && (delete queryStringParse[e])
			)

			Object.keys(queryStringParse).map(e => {
				if(e === "price"){
					queryStringParse[e] = [queryStringParse[e].split(',')]
				}else{
					queryStringParse[e] = queryStringParse[e].split(',')
				}
			})
			// initSectionFilters
			return queryStringParse
		//}
	}

	loadingLocalData = async (localData) => {
		//requestBlock.current = await (true)
		//isBusy.current = await localData.currentPage
		//isFirstRun.current = this.props.location.pathname

		this.setState({
			sorting: localData.sorting,
			totalProduct: localData.totalCount,
			filters: localData.filter,
			totalPage: localData.totalPage,
			description: localData.description,
			title: localData.title,
			pathList: localData.path
		}, () => {
			this.loadQueryString()
		})

		let windowWidth = $(window).width()
		if(windowWidth > 768){
			await setTimeout(function(){
				$("body").find(".scl-product-list").attr("style", 'min-height: ' + (localData.scrollTop) + 'px')
			}, 100)
		}
		//requestBlock.current = false
		/*setTimeout(function(){
			$("html, body, .App").stop().animate({ scrollTop: localData.scrollTop }, 0);
		}, 1000)*/
	}

	QuickHandler = (data) => {
		this.setState({quickData: [...this.state.quickData, data]})
	}

	addCombineList = async (data) => {
		if(this.state.combineData.indexOf(data) <= -1){
			this.setState({combineData: [...this.state.combineData, data]}, () => {
				this.modalOpen(data.id)
			})
		}
	}

	SectionFilters = (obj, data) => {
		//this.setState({currentPage: 1})
		//this.pageGo(1)

		//isBusy.current = 0
		let index = this.state.sectionFilters[obj].indexOf(data)
		let newFilters = null
		if(obj === "price"){
			if(!(JSON.stringify(this.state.sectionFilters[obj]) === JSON.stringify([data]))){
				newFilters = {...this.state.sectionFilters, [obj]: [data.code || data]}
			}else{
				newFilters = {...this.state.sectionFilters, [obj]: []}
			}
		}else if(index < 0){
			newFilters = {...this.state.sectionFilters, [obj]: [...this.state.sectionFilters[obj], data.code || data]}
		}else{
			let newValue = this.state.sectionFilters[obj]
			newValue.splice(index, 1)
			newFilters = {...this.state.sectionFilters, [obj]: newValue}
		}

		if(newFilters) {
			this.pushURL(newFilters)
			this.setState({sectionFilters: newFilters})
		}
	}

	pushURL = (newFilters = null, page = 1) => {
		let createSearch = null
		if(newFilters){
			createSearch = Object.keys(newFilters).filter(k => newFilters[k]?.length > 0).map(function(k) {
				return encodeURIComponent(k) + "=" + encodeURIComponent(newFilters[k]);
			}).join('&')
		}

		if(this.props.match.params.query){
			this.props.history.push(`/arama/${this.props.match.params.query}/${(page > 1 ? page : '')}${createSearch ? "?" + createSearch : ''}`, ['noScroll'])
		}else{
			this.props.history.push(`/${this.props.match.params.url}${(page > 1 ? '/' + page : '')}${createSearch ? "?" + createSearch : ''}`, ['noScroll'])
		}
	}

	updateData = async () => {
		console.log("update")
		this.setState({loading: true})
		let requestHeader = {headers: {}}
		let pageUrl = ""
		/* HEADER CREATE */
			if(!this.props.match.params.query){
				requestHeader.headers = {...requestHeader.headers, "TitleInUrl":  this.props.match.params.url}
				pageUrl = this.props.match.params.url
			}else{
				requestHeader.headers = {...requestHeader.headers, "Name":  encodeURI(this.props.match.params.query)}
				pageUrl = "arama/" + encodeURI(this.props.match.params.query)
			}
		/* HEADER CREATE */

		/* HEADER FİLTERS */
		if(this.state.sectionFilters) {
			if(this.state.sectionFilters.features.length > 0){
				let headerFeatures = {}
				for (var i = 0; i < this.state.sectionFilters.features.length; i++) {
					const th = this.context.getFeature(this.state.sectionFilters.features[i])
					const title = th.parentCode + "feature"
					if(headerFeatures[title]){
						headerFeatures = {...headerFeatures, [title]: [...(headerFeatures[title] ?? {}), th.code]}
					}else{
						headerFeatures = {...headerFeatures, [title]: [th.code]}
					}
				}
				requestHeader.headers = {...requestHeader.headers, ...headerFeatures}

			}

			if(this.state.sectionFilters.groups.length > 0){
				requestHeader.headers = {...requestHeader.headers, "Groups": this.state.sectionFilters.groups}
			}
			if(this.state.sectionFilters.categories.length > 0){
				requestHeader.headers = {...requestHeader.headers, "CategoryCode": this.state.sectionFilters.categories}
			}
			if(this.state.sectionFilters.colors.length > 0){
				requestHeader.headers = {...requestHeader.headers, "ColorCode": this.state.sectionFilters.colors}
			}
			if(this.state.sectionFilters.brands.length > 0){
				requestHeader.headers = {...requestHeader.headers, "BrandCode": this.state.sectionFilters.brands}
			}
			if(this.state.sectionFilters.sizes.length > 0){
				requestHeader.headers = {...requestHeader.headers, "Sizes": this.state.sectionFilters.sizes}
			}
			if(this.state.sectionFilters.price.length > 0){
				requestHeader.headers = {...requestHeader.headers, "MinPrice": this.state.sectionFilters.price[0][0], "MaxPrice": this.state.sectionFilters.price[0][1]}
			}
		}

		let queryString = ""
		if(this.state.sorting?.id > 0){
			queryString += "&sort=" + this.state.sorting.sort + "&desc=" + this.state.sorting.desc
		}

		console.log("updateData", queryString, requestHeader)

		await axios.get("/GetProducts?page=" + this.state.currentPage + queryString, requestHeader)
		.then(async res => {
			if(!res?.data?.isSuccess){
				if(res?.data?.product_source){
					if(res?.data?.product_source !== "ilmio"){
						this.props.history.push(res.data.product_titleinurl)
					}else{
						//history.replace("/404")
					}
				} else {
					if(res.data?.product_titleinurl){
						this.props.history.push(res.data.product_titleinurl)
					}else{
						//history.replace("/404")
					}
				}
			} else {

				const resData = res?.data?.data
				let concatData = []

				//if((this.props.match.params.url && (isFirstRun.current !== this.props.location.pathname)) || (this.props.match.params.query && (isFirstRun.current !== this.props.match.params.query))){
					if(resData.filters && !this.state.filters) {
						this.setState({filters: {...resData.filters, features: this.context.createFeaturesList(resData.filters.features)}})
					}

					if(this.props.match.params.query){
						//isFirstRun.current = this.props.match.params.query
					}else{
						//isFirstRun.current = this.props.location.pathname
					}
					if(resData?.data?.length <= 0){
						this.setState({data: [], totalProduct: 0, totalPage: 0})

						if(this.props.match.params.query){
							//history.replace("/404/" + this.props.match.params.query)
							return false
						}else{
							//history.replace("/404/")
							return false
						}
					}
				//}

				concatData = this.joinConstants((resData.data || []), resData.constants)
				this.setState({data: concatData})
				listPageTag(concatData.map(e => e.code))

				if(!this.props.match.params.query){
					this.setState({pathList: resData.Path ?? []})
					this.setState({title: resData.Title ?? ""})
					this.setState({description: resData.Description ?? ""})
				} else {
					this.setState({pathList: [{titleinurl: "", title: "Arama Sonuçları"}, {titleinurl: "", title: this.props.match.params.query}]})
					this.setState({title: "'" +this.props.match.params.query + "' için bulunan sonuçlar"})
					this.setState({description: ""})
				}

				let listPageData = JSON.parse(sessionStorage.getItem('listPage'))
				try{
					listPageData = listPageData[this.props.match.url]
				}catch {
					listPageData = {}
				}

				listPageData = {
					...listPageData,
					data: concatData,
					totalPage: Math.ceil(resData.totalCount / 36),
					totalCount: resData.totalCount,
					sectionFilters: this.state.sectionFilters,
					currentPage: this.state.currentPage,
					scrollTop: this.scrollTop,
					title: (resData?.Title),
					path: (resData?.Path),
					description: (resData?.Description),
					filter: {...resData.filters, features: this.context.createFeaturesList(resData?.filters?.features)},
					sorting: this.state.sorting
				}

				//yapılacak
				gaAddImpression({items: resData?.data?.map((e, i) => {
					return {
						id: e.code,
						name: e.title,
						//category: getCategoryName(e.categoryCode),
						brand: e.brandCode,
						//variant: getColorName(e.code?.split('C')?.[1]),
						list: pageUrl + "/"+ this.state.currentPage + this.props.location.search,
						position: i + 1,
					}
				})})

				sessionStorage.setItem("listPage", JSON.stringify({[this.props.match.params.url] : listPageData}))
				//requestBlock.current = false
				//isBusy.current = currentPage
				this.setState({totalProduct: resData.totalCount, totalPage: Math.ceil(resData.totalCount / 36)})
			}
		})
		this.setState({loading: false})
		//this.save()
	}

	filtersToggle = (e) => {
		if(e.currentTarget.children[1].style.display === "block") {
			e.currentTarget.children[0].classList.remove("active")
			e.currentTarget.children[1].style.display = "none"
		} else {
			e.currentTarget.children[0].classList.add("active")
			e.currentTarget.children[1].style.display = "block"
		}
		/*$(".filter-title").off().on("click", function(){
			$(this).toggleClass("active");
			$(this).next("ul").slideToggle();
		})
		$(".scl-mobile-filter").off().on("click",function(){
			if($(this).hasClass("active")){
				$(this).removeClass("active");
				$(".scl-sidebar").animate({"left":(windowWidth <= 500 ? "-100%" : "-70%" )}, 600);
				$(".close-page-hover").hide();
				$("body,#root, .App").removeClass("modal-open");
			}else{
				$(this).addClass("active");
				$(".scl-sidebar").animate({"left":"0%"}, 600);
				$(".close-page-hover").show();
				$("body,#root, .App").addClass("modal-open");
			}
		});*/
	}

	featuresToggle = (e) => {
		console.log(e.currentTarget)
		if(e.currentTarget.parentElement.children[1].style.display === "block") {
			e.currentTarget.parentElement.children[0].classList.remove("active")
			e.currentTarget.parentElement.children[1].style.display = "none"
		} else {
			e.currentTarget.parentElement.children[0].classList.add("active")
			e.currentTarget.parentElement.children[1].style.display = "block"
		}
	}

	sortingToggle = (e) => {
		$(".scl-content-sort").toggleClass("active");
		$(".close-page-click").slideToggle("active");
	}

	filtersOpen = (e) => {
		if($(".scl-mobile-filter").hasClass("active")){
			$(".scl-mobile-filter").removeClass("active");
			$(".scl-sidebar").animate({"left":(window.innerWidth <= 500 ? "-100%" : "-70%" )}, 600);
			$(".close-page-hover").hide();
			$("body,#root,.App").removeClass("modal-open");
		}else{
			$(".scl-mobile-filter").addClass("active");
			$(".scl-sidebar").animate({"left":"0%"}, 600);
			$(".close-page-hover").show();
			//$("body,#root,.App").addClass("modal-open");
		}
	}

	joinConstants = (data, constants=[]) => {
		let indexer = {}
		for (var i = 0; i < constants.length; i++) {
			indexer[constants[i].row +"-"+ constants[i].col] = constants[i]
		}

		let row=1, col=0, count=0
		let newData = []
		for (var i = 0; i < data.length; i++) {
			col++
			if(col % 4 === 0){
				col = 1
				row++
			}
			if(indexer.[row + "-" + col]) {
				newData[count] = indexer.[row + "-" + col]
				count++
				i--;
			}else{
				newData[count] = data[i]
				count++
			}
		}
		return newData
	}

	convertSizeNew = (list) => {
		return list.map(elm => {
			return toNewSizes(elm)
		})
	}

	ListWrite = (elm, i) => {
		if(elm){
			if(elm.type === "product"){
				return <Product data={elm.content} index={i} quickHandler={this.QuickHandler} replace={this.props.match?.params?.url} key={i}/>
			} else if(elm.type === "banner"){
				return <Banner width={elm.content.width} url={elm.content.url} image={elm.content.imagePath} key={i}/>
			} else if(elm.type === "combine"){
				return <Combine image={elm.content.imagePath} products={elm.content.products} look={<Trans i18nKey="ListPage.constants.title" />} onClickHandler={() => this.addCombineList({...elm, id: i})} key={i}/>
			} else {
				return <Product data={elm} index={i} quickHandler={this.QuickHandler} replace={this.props.match?.params?.url} key={i}/>
			}
		}else{
			console.error(i + ". Ürün bilgisi gelmiyor!");
		}
	}

	modalOpen = (id) => {
		$("#combine_" + id).show().addClass("show");
		$("body").addClass('modal-open');
		$("body").append('<div class="modal-backdrop fade show"></div>');
	}

	pageGo = page => {
		//requestBlock.current = true
		console.log("pagego", page)
		if(this.props.match.params.query){
			this.props.history.push(`/arama/${this.props.match.params.query}/${(page > 1 ? page : '')}${this.props.location.search}`)
		}else{
			this.props.history.push(`/${this.props.match.params.url}${(page > 1 ? '/' + page : '')}${this.props.location.search}`)
		}
		//this.setState({currentPage: page || 1})
		//updateData()
	}

	changeSorting = async (sortingObj) => {
		//requestBlock.current = true

		//setSorting(sortingObj)
		//setCurrentPage(1)
		this.setState({sorting: sortingObj})
		await this.save(() => {

		})
		await Promise.resolve().then(function () {
			return JSON.parse(sessionStorage.getItem("listPage"))
		});
		if(this.props.match.params.query){
			this.props.history.replace(`/arama/${this.props.match.params.query}/${(1 > 1 ? 1 : '')}${this.props.location.search}`)
		}else{
			this.props.history.replace(`/${this.props.match.params.url}${(1 > 1 ? '/' + 1 : '')}${this.props.location.search}`)
		}
		//this.setState({currentPage: 1})
		//this.props.history.replace('/'+this.props.match.params.url+'/1')

	}

	save = async (callback) => {
		const localListPageData = JSON.parse(sessionStorage.getItem('listPage'))

			let localData = null
			if(localListPageData) {
				localData = localListPageData[this.props.match.params.url]
			}
			const self = this
			if(localData){
				await Promise.resolve().then(function () {
					sessionStorage.setItem("listPage", JSON.stringify({[self.props.match.params.url]: {...localData, sectionFilters: self.state.sectionFilters, sorting: self.state.sorting}}))
				});
			} else  {
				await Promise.resolve().then(function () {
					sessionStorage.setItem("listPage", JSON.stringify({[self.props.match.params.url]: {sectionFilters: self.state.sectionFilters, sorting: self.state.sorting}}))
				});
			}
	}

	openChildFilter = e => {
		const elm = e.currentTarget
		const ul = elm.nextSibling
		const height = ul.childNodes.length * 32

		if(elm.classList.contains('active')){
			elm.classList.remove('active')
			ul.style = undefined
		}else{
			ul.style.height = `${height}px`
			elm.classList.add('active')
		}
	}

	seoData = state => {
		return {
			title: state.title + config.prefix + config.name,
			description: state.description,
			ogytpe: "product.group",
			canonical: `${config.url}${this.props?.match?.params?.url}`
		}
	}

	render () {
		return (
			<FiltersContext.Consumer>
			{
			({ getBrandName, getColorName, createFeaturesList, getFeature, getFeatureName, getGroupName, getCategoryName }) =>
			<LanguageContext.Consumer>
			{
			({ getChoicesLanguage, toPriceConvert }) =>
			<>
				<SeoComponent {...this.seoData(this.state)}/>
				<Breadcrumb data={this.state.pathList?.map(elm => {return {url: elm.titleinurl, title: elm.title}})} />
				{
					this.state.quickData.map((elm, i) =>
						<QuickModal {...this.props} key={i} data={elm}/>
					)
				}
				{
					this.state.combineData.map((elm, i) =>
						<CombineModal key={i} data={elm}/>
					)
				}
				<div id="content">
					<div className="container" ref={this.containerRef}>
						<div className="scl-sidebar" ref={this.sidebarRef} style={{height: (window.innerHeight - 120)}}>
							<h2 className="section-title filters-title">{t('ListPage.filter.title')}</h2>
							{
								this.state.sectionFilters && Object.values(this.state.sectionFilters).filter(e => e.length).length > 0 &&
								<>
									<h3 className="section-title filter-title">
									{t('ListPage.filter.section')}
									{
										Object.keys(this.state.sectionFilters).map(e => this.state.sectionFilters[e].length).reduce((a, b) => a + b, 0) > 1 &&
										<small className="clear-all red" onClick={() => {
											//setCurrentPage(1)
											this.setState({currentPage: 1, sectionFilters: this.initSectionFilters}, this.pushURL())
											//this.pushURL()
										}}>Tümünü Temizle</small>
									}
									</h3>
									<div className="sections">
										{
											Object.keys(this.state.sectionFilters).map((filter, c) =>
												this.state.sectionFilters.[filter].map((elm, i) =>
													<div className="kutu" key={c + "_" + i} target={filter} onClick={() => this.SectionFilters(filter, elm)}>
														{
															filter === "brands" ? getBrandName(elm) :
															filter === "features" ? getFeatureName(elm) :
															filter === "categories" ? getCategoryName(elm) :
															filter === "groups" ? getGroupName(elm) :
															filter === "colors" ? getColorName(elm) :
															filter === "sizes" ? "Beden:" + elm :
															filter === "price" ?
																elm[0] === "0" ?
																	<><div className="kutu">{toPriceConvert(elm[1], false, 0, false)}</div> <div className="arasi">ve Altı</div></>
																:
																	elm[1] > 2000 ?
																		<><div className="kutu">{toPriceConvert(elm[0], false, 0, false)}</div> <div className="arasi">ve Üstü</div></>
																	:
																		<><div className="kutu">{toPriceConvert(elm[0], false, 0, false)}</div> <div className="arasi">-</div> <div className="kutu">{toPriceConvert(elm[1], false, 0, false)}</div></>
															: elm
														}
														<Icons.Close />
													</div>
												)
											)
										}
									</div>
								</>
							}
							{
								this.state.filters?.brands?.length > 1 &&
								<FilterItem
									title={t('ListPage.filter.brand')}
									data={this.state.filters.brands}
									prefix="brand_"
									convert={getBrandName}
									choice={this.state.sectionFilters?.brands}
									checkHandler={elm => this.SectionFilters("brands", elm)}
									onClick={this.filtersToggle}
								/>
							}
							{
								this.state.filters?.features?.parents?.length > 0 && (
									<div>
										<h3 className="filter-title" onClick={this.featuresToggle}>
											{t('ListPage.filter.feature')}
											<Icons.Vour className="float-right"/>
											<Icons.Plus className="float-right"/>
										</h3>
										<ul className="filter-list">
											{
												this.state.filters?.features?.parents?.map((elm, i) =>
												<li key={i}>
													<h3 onClick={this.openChildFilter}>
														{getFeatureName(elm)}
														<Icons.Vour className="float-right"/>
														<Icons.Plus className="float-right"/>
													</h3>
													<ul>
														{
															(this.state.filters?.features[elm]?.sort((a, b) => {
																if(getFeatureName(a).toUpperCase().trim() > getFeatureName(b).toUpperCase().trim()){
																	return 1
																}
																if(getFeatureName(a).toUpperCase().trim() < getFeatureName(b).toUpperCase().trim()){
																	return -1
																}
																return 0
															}))?.map((child, c) =>
																<li key={c} className="form-check mb-2">
																	<label className="form-check-label" htmlFor={"ft_" + child + "_" + c}>
																		<input type="checkbox" className="form-check-input" name={"ft_" + child + "_" + c} id={"ft_" + child + "_" + c} onChange={() => this.SectionFilters("features", encodeURI(child))} checked={this.state.sectionFilters["features"].indexOf(encodeURI(child)) > -1}/>
																		<span><Icons.TickBold /></span>
																		{getFeatureName(child)}
																	</label>
																</li>
															)
														}
													</ul>
												</li>
												)
											}
										</ul>
									</div>
								)
							}
							{
								this.state.filters?.categories?.length > 0 &&
								<FilterItem
									title={t('ListPage.filter.category')}
									data={this.state.filters?.categories
									?.map(e => { return getCategoryName(e)})
									.sort((a, b) => {
										let first = a.toLowerCase()
										let second = b.toLowerCase()
										if(first > second){return 1}
										if(first < second){return -1}
										return 0
									})}
									prefix="categories_"
									choice={this.state.sectionFilters?.categories.map(e => { return getCategoryName(e)})}
									checkHandler={elm => {
										const index = this.state.filters?.categories?.map(e => { return getCategoryName(e)}).indexOf(elm)
										if(index > -1){
											this.SectionFilters("categories", this.state.filters.categories?.[index])
										}
									}}
									onClick={this.filtersToggle}
								/>
							}
							{
								this.state.filters?.colors?.length > 0 &&
								<FilterItem
									title={t('ListPage.filter.color')}
									data={this.state.filters.colors.sort((a, b) => {
										if(getColorName(a).toUpperCase().trim() > getColorName(b).toUpperCase().trim()){return 1}
										if(getColorName(a).toUpperCase().trim() < getColorName(b).toUpperCase().trim()){return -1}
										return 0
									})}
									prefix="color_"
									convert={getColorName}
									choice={this.state.sectionFilters?.colors}
									checkHandler={elm => this.SectionFilters("colors", elm)}
									onClick={this.filtersToggle}
								/>
							}
							{
								this.state.filters?.sizes?.length > 0 &&
								<FilterItem
									title={t('ListPage.filter.size')}
									data={this.state.filters.sizes.sort((a, b) => sortFirstNumber(a, b))}
									prefix="size_"
									choice={this.state.sectionFilters?.sizes}
									checkHandler={elm => this.SectionFilters("sizes", elm)}
									onClick={this.filtersToggle}
								/>
							}
							{
								this.state.filters?.colors &&
								<FilterItem
									title={t('ListPage.filter.price')}
									data={this.state.fiyatlar}
									checkHandler={elm => this.SectionFilters("price", elm)}
									choice={this.state.sectionFilters?.price?.[0]}
									prefix={"color_"}
									convert={(elm) => {
										return (elm[0] === "0" ?
											<>{toPriceConvert(elm[1], false, 0)} ve Altı</>
										:
											elm[1] > (config.app_name === "secil" ? 450 : config.app_name === "ilmio" ? 2000: 500) ?
												<>{toPriceConvert(elm[0], false, 0)} ve Üstü</>
											:
												<>{toPriceConvert(elm[0], false, 0)} - {toPriceConvert(elm[1], false, 0)}</>)
									}}
									onClick={this.filtersToggle}
								/>
							}
							{ config.app_name !== "ilmio" &&
								<FirsatUrunu/>
							}
						</div>
						<div className="scl-content list-page">
							{
								<div className={`scl-content-title${this.props.location.pathname === "/indirim" ? ' title-red' : ''}`}>
									<div className="scl-mobile-filter" onClick={this.filtersOpen}>
										{t('ListPage.filter.title')} <Icons.Plus />
									</div>
									<h1 className="float-left">{this.state.title} <small> ({this.state.totalProduct} {t('ListPage.title.product')})</small></h1>
									{ this.state.sorting &&
									<div className="scl-content-sort" onClick={this.sortingToggle}>
										<span>{this.state.sorting.id !== null ? <span><SortingName id={this.state.sorting.id} /></span> : t('ListPage.sort.title')} <Icons.ArrowDown /></span>
										<ul>
											<li className={this.state.sorting.id === 0 ? 'active' : ''} onClick={() => {
													this.changeSorting({id: 0})
												}}>
												<span><SortingName id={0} /></span>
											</li>
											<li className={this.state.sorting.id === 1 ? 'active' : ''} onClick={() => {
													this.changeSorting({id: 1, sort: "Price", desc: 0})
												}}>
												<span><SortingName id={1} /></span>
											</li>
											<li className={this.state.sorting.id === 2 ? 'active' : ''} onClick={() => {
													this.changeSorting({id: 2, sort: "Price", desc: 1})
											}}>
											<span><SortingName id={2} /></span>
											</li>

											<li className={this.state.sorting.id === 3 ? 'active' : ''} onClick={() => {
													this.changeSorting({id: 3, sort: "DateTime", desc: 1})
											}}>
											<span><SortingName id={3} /></span>
											</li>

											<li className={this.state.sorting.id === 4 ? 'active' : ''} onClick={() => {
													this.changeSorting({id: 4, sort: "DateTime", desc: 0})
											}}>
											<span><SortingName id={4} /></span>
											</li>
										</ul>
									</div>
									}
									<div className="clearfix"></div>
								</div>
							}
							{
								this.state.loading ?
									<Loading />
								: this.state.totalProduct <= 0 ?
									<>
										<NotProduct />
										<Oneriler />
									</>
									: this.state.data &&
										<>
											<div className={"scl-product-list" + ( this.state.productColumn === 4 ? ' four-column' : (this.state.productColumn === 2 ? ' two-column' : ' three-column'))}>
												{
													this.state.data?.map((elm, i) =>
														this.ListWrite(elm, i)
													)
												}
												{
													["", "", ""].filter((e, i) => i < (3 - (this.state.data.length % 3)))?.map((elm, i) =>
														<div className="scl-product"></div>
													)
												}
												<div className="clearfix"></div>
											</div>
											<Pagination
												activePage={this.state.currentPage}
												itemsCountPerPage={36}
												totalItemsCount={this.state.totalProduct}
												pageRangeDisplayed={window.innerWidth > 768 ? 10 : 5}
												onChange={this.pageGo}
												hideFirstLastPages
											/>
										</>
							}
							{this.state.description && <pre className="description" dangerouslySetInnerHTML={{__html: this.state.description}}></pre>}
						</div>
					</div>
				</div>
			</>
			}
			</LanguageContext.Consumer>
			}
			</FiltersContext.Consumer>
		)
	}
}

export default withTranslation()(List);
