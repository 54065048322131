import React from "react";
import { useTranslation } from 'react-i18next'

import * as Icons from './../../icons/index'

function NotProduct() {

	const { t } = useTranslation()

	return (
		<div className="scl-not-item">
			<Icons.CloseCircle />
			<h2>{t('ListPage.non')}</h2>
		</div>
	);
}

export default NotProduct;
