import * as React from "react";

function SvgPlus(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M27.32 19.01h-6.33v-6.36c0-.55-.44-.99-.99-.99s-.99.45-.99.99v6.36h-6.33c-.55 0-.99.45-.99.99 0 .55.44.99.99.99h6.33v6.36c0 .55.44.99.99.99s.99-.45.99-.99v-6.36h6.33c.55 0 .99-.45.99-.99 0-.55-.45-.99-.99-.99z"
      />
    </svg>
  );
}

export default SvgPlus;
