import React from 'react'
import TypeLink from './../global/link'

function HomeCategories(props) {
	return (
		<div className="banner">
			<div className="container">
				{props?.title && <h2 className="p-2 pt-0 font-mont bold"><span>{props.title}</span></h2>}
				<div className="row gy-5">
					{
						props?.data?.map((elm, i) =>
							<div {...elm.props} className="col" key={i}>
								<TypeLink to={elm?.url} className="w-100">
									<img src={elm?.img} alt={elm?.title} loading="lazy"/>
									<div className="pt-3 text-center">
										<span className="underline bold">{elm?.title}</span>
									</div>
								</TypeLink>
							</div>
						)
					}
				</div>
			</div>
		</div>
	)
}

export default HomeCategories
