import React, { Component } from "react";

class FullBanner extends Component {
	constructor(props) {
		super(props);

		this.state = {
			banner:props.banner,
			title:props.title,
			text:props.text,
		};
	}

	render() {
		return(
			<div className="full-banner base-light">
				<div className="container">
					<div className="row">
						<div className="col">
							<img src={this.state.banner} className="w-100" alt=""/>
							{
								this.state.title != null ?
									<h1>{this.state.title}</h1>
								:
									<></>
							}
							{
								this.state.text != null ?
									<span>{this.state.text}</span>
								:
									<></>
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default FullBanner;
