import React from 'react'
import TypeLink from './../global/link'

function OneBanner(props) {
	return (
		<div className="banner">
			<div className="container">
				<div className="row g-0">
					<div className="col">
						<TypeLink to={props.data?.[0]?.url} onClick={() => props.onClickHandler(props.data?.[0]?.url)} {...{...props, data: undefined}}>
							<img src={props.data?.[0]?.img} alt={props.data?.title} loading="lazy"/>
						</TypeLink>
					</div>
				</div>
			</div>
		</div>
	)
}

export default OneBanner
