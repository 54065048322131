import React, { useState, useRef, useEffect } from "react";
import axios from 'axios'
import Toast from "./../../../../package/swal-init";
import * as Icons from './../../../../components/icons/index'

import Loading from './../../../../components/widgets/global/loading'

import Iban from './../../../ui/userPage/ibans/iban'
import IbanList from './../../../ui/userPage/ibans/ibanList'
import NonItem from './../nonItem'

function List(props) {

	const [data, setData] = useState(null)

	const isFirstRun = useRef(true)

	useEffect(() => {
		if(isFirstRun.current !== props.updateHandler){
			isFirstRun.current = props.updateHandler
			updateData()
		}

		return () => {
			setData(null)
		}
	}, [props.updateHandler])

	const updateData = () => {
		axios.get("/GetUserIbans")
		.then(res => {
			setData(res.data.data || [])
		})
	}

	const deleteIban = (iban) => {
		axios.post("/DeleteIban", {iban: iban})
		.then(res => {
			if(res.data?.isSuccess){
				Toast.fire({
					icon: 'success',
					title: 'Başarıyla silindi'
				})
			}else{
				Toast.fire({
					icon: 'error',
					title: res.data.error
				})
			}
			updateData()
		})
	}

	return (
		<IbanList>
			{!data && <Loading />}
			{
				data?.map((e, i) =>
					<Iban data={e} deleteHandler={() => deleteIban(e.iBan)} selectHandler={props.selectHandler} selected={props.selected} key={i}/>
				)
			}
			{data?.length === 0 && <NonItem clickHandler={props.modalOpen} title="KAYITLI IBAN BULAMADIK" buttonTitle={!props.nonBtnClose && "IBAN EKLE"}/>}
		</IbanList>
	)
}

export default List
