import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import $ from 'jquery'

import * as Icons from './../../icons/index'

import { gaListClickProduct } from './../../../package/google-analytic';

import { useTranslation } from 'react-i18next';
import { FavoriteContext } from './../../../context/FavoriteContext'
import { AuthContext } from './../../../context/AuthContext'
import { LanguageContext } from './../../../context/LanguageContext'
import { FiltersContext } from './../../../context/FiltersContext'

import Image from './../global/image'

function Product(props){
	const [showQuick, setShowQuick] = useState(false)
	const [item, setItem] = useState(null)
	const [image, setImage] = useState(props?.data?.imagepath)
	const [activeFavorite, setActiveFavorite] = useState(false)

	const {t} = useTranslation();
	const { isAuth, setStateAuth } = useContext(AuthContext)
	const { addFavorite, removeFavorite, isFavorite } = useContext(FavoriteContext)
	const { toPriceConvert } = useContext(LanguageContext)
	const { getColorName, getCategoryName } = useContext(FiltersContext)

	useEffect(() => {
		if(props?.replace && props.data?.url){
			let url = props.data.url.split("/")
			url[1] = props.replace.replace("/", "")
			url = url.join('/')
			setItem({...props.data, url: `${url}`})
		}else{
			setItem(props.data)
		}
	}, [props])

	useEffect(() => {
		if(item?.imagePath || item?.imagePaths){
			if(item.imagePath || item.imagePaths.length > 0){
				setImage(item.imagePath ?? item.imagePaths[0])
			}
		}
	}, [item])

	const modalOpen = () => {
		$("#s_" + props.data.code).show().addClass("show");
		$("body").addClass('modal-open');
		$("body").append('<div class="modal-backdrop fade show"></div>');
	}

	const goDetail = () => {
		gaListClickProduct({
			id: item.code,
			name: item.title,
			category: getCategoryName(item.categoryCode),
			brand: item.brandCode,
			variant: getColorName(item.code?.split('C')?.[1]),
			position: props.index + 1,
			group: props.replace
		})
	}

	return (
		item?.price1 > 0 &&
		<div className="scl-product">
			{
				(item.attributes & 256) === 256 &&
				<div className="big-size">+44</div>
			}
			{
				(item.attributes & 32) === 32 &&
				<div className="only-online">Online Özel</div>
			}
			<Link to={"/" + item.url} className="images w-100" onClick={goDetail}>
				{
					<Image src={image} alt={item.title} picture="true"/>
				}
			</Link>
			<Link to={"/" + item.url} className="title" onClick={goDetail}>
				{
					(item.attributes & 2) === 2 &&
					<span className="green base-regular">YENİ - </span>
				}
				{/*<strong>{getBrandName(item.brandcode)}</strong>*/}
				{item.title}
			</Link>
			<div className={'price d-flex justify-content-center'}>
			{toPriceConvert([item.price1, item.price2], false, 2, true)}
			</div>
			{
				props.quickHandler &&
				<div className="hover-menu quick">
					<div className="buttons">
						<button className="quick" onClick={() => {
							goDetail()
							if(showQuick){
								modalOpen()
							}else{
								props.quickHandler(item)
								setShowQuick(true)
								setTimeout(function(){
									modalOpen()
								}, 400);
							}
						}}>{t('ListPage.product.quick-button')}<Icons.SearchPlus /></button>
					</div>
				</div>
			}
			{
				item.variantscount > 0 &&
				<div className="colors-num">
						+{item.variantscount} {t('ListPage.product.color')}
				</div>
			}
			{
				props.quickHandler &&
				<div className="favorite">
					<AuthContext.Consumer>
						{
							value =>
							isAuth ?
							(isFavorite(item.code) || activeFavorite) ?
								<span className="active" onClick={() => {
									removeFavorite({ProductColorCode: item.code})
									setActiveFavorite(false)
								}}><Icons.HeartFull /></span>
							:
								<span onClick={() => {
									addFavorite({ProductColorCode: item.code})
									setActiveFavorite(true)
								}}><Icons.Heart /></span>
							:
								<span onClick={() => setStateAuth('loginModalShow', true)}><Icons.Heart /></span>
						}
					</AuthContext.Consumer>
				</div>
			}
			{ item.hasOwnProperty('inStock') && !item.inStock ?
				<div className="not-in-stock quick">
					<div className="buttons">
						<button className="quick">{t('ListPage.product.out-of-stock')}</button>
					</div>
				</div>
			: ''}
		</div>
	);
}

export default Product
