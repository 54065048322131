import React, { useState, useContext } from 'react'
import styles from './addCoupon.module.css'
import Toast from './../../package/swal-init'

import * as Icons from './../../components/icons/index'
import Input from './../../components/widgets/forms/input'
import Button from './../../components/widgets/forms/button'

import { BasketContext } from './../../context/BasketContext'

function AddCoupon() {

	const { coupons, addCoupon, removeCoupon } = useContext(BasketContext)

	const [show, setShow] = useState(true)
	const [couponInput, setCouponInput] = useState("")
	const [error, setError] = useState(null)


	const openHandler = () => {
		setShow(true)
	}

	const closeHandler = () => {
		setTimeout(() => {
			setShow(false)
		}, 400)
	}

	const addCouponHandler = () => {
		if(coupons.length > 0){
			setError("1 Kupon kullanbilirsiniz")
			return false;
		}
		if(couponInput.length > 4 && couponInput.length < 18){
			const req = addCoupon(couponInput)
			req.then(res => {
				if(!res?.data?.isSuccess){
					setError("Geçersiz kupon")
				}else{
					setCouponInput("")
					setError(null)
				}
			})
		}else{
			setError("Geçersiz kupon")
		}
	}

	return (
		<div className={styles.addcoupon}>
			<div className={styles.coupons}>
			{
				coupons.map((elm, i) =>
					<span key={i}>{elm} <Icons.Close onClick={() => removeCoupon(elm)} /></span>
				)
			}
			</div>
			{
				false && coupons?.length <= 0 &&
				<div className={styles.title} onClick={() => {/*setShow(!show)*/}}>
					<span>İndirim Kuponuz mu var?</span>
					{!show && <Icons.PlusBold />}
					{show && <Icons.VourBold />}
				</div>
			}
			{
				show && coupons?.length <= 0 &&
				<form onSubmit={e => e.preventDefault()}>
					<div className="row">
						<div className="col-8">
								<Input name="name" error={error} placeholder="İndirim kodu" value={couponInput}
								onChange={e => {
									setError(null)
									setCouponInput(e.target.value.trim())
								}}
								onKeyPress={e => {
									if(e.key === 'Enter'){
										addCouponHandler()
									}
								}} autoFocus/>
						</div>
						<div className="col-4 p-1 pr-4">
							<Button title="EKLE" className="btn w-100" onClick={addCouponHandler} />
						</div>
					</div>
				</form>
			}
		</div>
	)
}

export default AddCoupon
