import React, {	Component } from 'react'
import $ from "jquery";

import Details from './../pages/details'
import { rePosition } from './../../package/global-function'

class CombimeModal extends Component {

	constructor(props){
		super(props)

		this.state = {
			productsData: [props.data.content.products[0]],
			activeDetail: props.data.content.products[0]
		}
	}

	componentDidMount(){
		if(window.innerWidth > 540){
			rePosition("#combine_" + this.props.data.id, 200);
		}
	}

	modalClose(code){
		$("#combine_" + this.props.data.id).hide().removeClass("show")
		$("body").removeClass('modal-open')
		$(".modal-backdrop").remove()
	}

	addProductsData = (data) => {
		if(this.state.productsData.indexOf(data) <= -1){
			this.setState({ productsData: [...this.state.productsData, data] })
		}

		this.setState({activeDetail: data})
	}

	render() {
		return (
			<div className="modal fade combimeModal" id={"combine_" + this.props.data.id} aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg scl-modal">
					<div className="modal-content">
						<div className="modal-body">
							<div className="scl-modal-close" onClick={() => this.modalClose(this.props.data.content.id)}>×</div>
							<div className="combine-products">
								{
									this.props.data.content.products.map((elm, i) =>
										<span key={i} className={elm === this.state.activeDetail ? 'active' : undefined} onClick={() => this.addProductsData(elm)}><img src={elm.imagePaths[0]} alt=""/></span>
									)
								}
							</div>
							{
								this.state.productsData.map((elm, i) =>
									<div key={i} className="detail-pages" style={{display: (elm === this.state.activeDetail ? 'block' : 'none')}}>
										<Details quick={true} isCombine={true} isDetail={this.props.isDetail} url={elm.url} />
									</div>
								)
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default CombimeModal
