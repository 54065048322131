import React, { useContext, useEffect, useState } from 'react'
import styles from './forgotPassword.module.css'

import * as Icons from './../../components/icons/index'

import Input from './../../components/widgets/forms/input'
import Button from './../../components/widgets/forms/button'
import Checkbox from './../../components/widgets/forms/checkbox'

import { useTranslation, Trans } from 'react-i18next';

function ForgotPassword(props) {

	const {t} = useTranslation()

	const [selectedService, setSelectedService] = useState(1)

	useEffect(() => {
		props.reset()
	}, [selectedService])

	return (
		<>
			{
				props.success && <span className="green mt-5 mb-5 d-block"><strong>{props.success}</strong></span>
			}
			{
				!props.success &&
				<div className={styles.forgotPassword}>
					<a className="nav-link active text-left" onClick={props.goLogin}>{t('LoginModal.forgot.back')}</a>
					<h2>{t('LoginModal.forgot.message')}</h2>
					{
						<>
							<span className="text-center mb-2">Yeni şifrenizi belirlemek için kayıtlı e-posta adresinizi veya telefon numaranızı yazınız</span>
							<span className="text-center mb-4 d-block">Şifre değiştirme linkini göndereceğiz.</span>
							{
								props.error?.length > 0 &&
								<div className="errorMessage">
									<Icons.CloseCircle />
									{props.error}
								</div>
							}
							<form onSubmit={props.onSubmit}>
								<div className="row g-0">
									<div className={styles.tabs}>
										<span className={selectedService === 1 && styles.active} onClick={() => setSelectedService(1)}>{t('LoginModal.forgot.mail-label')}</span>
										<span className={selectedService === 2 && styles.active} onClick={() => setSelectedService(2)}>Telefon</span>
									</div>
								</div>
								<div className="row g-0">
								{
									selectedService === 1 &&
									<div className="mb-3">
										<Input type="mail" autoComplete="email" placeholder={selectedService === 1 ? t('LoginModal.forgot.mail-placeholder') : t('LoginModal.forgot.phone-placeholder')} name="forgot_mail" error={props.errors?.forgot_mail?.message} transferref={props.register}/>
									</div>
								}
								{
									selectedService === 2 &&
									<div className="mb-3">
										<Input type="tel" autoComplete="tel-local" placeholder={t('LoginModal.forgot.phone-placeholder')} name="forgot_mail" error={props.errors?.forgot_mail?.message} transferref={props.register}/>
									</div>
								}
								</div>
								<div className="mb-3 mt-3 form-btn">
									<Button type="submit" className="btn" title={t('LoginModal.forgot.button')}/>
								</div>
							</form>
						</>
					}
				</div>
			}
		</>
	)
}

export default ForgotPassword
