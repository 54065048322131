import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { gaView } from './../../../package/google-analytic'
import { visitTag } from './../../../package/criteo'

import * as Icons from './../../icons/index'
import { useTranslation } from 'react-i18next'
import { LanguageContext } from './../../../context/LanguageContext'
import { FiltersContext } from './../../../context/FiltersContext'
import { AuthContext } from './../../../context/AuthContext'

function OrderInfo(props) {

	const history = useHistory()
	const { t } = useTranslation()
	const { toPriceConvert } = useContext(LanguageContext)
	const { getColorName } = useContext(FiltersContext)
	const { openModal } = useContext(AuthContext)

	const [data, setData] = useState({code: null})

	const isFirstRun = useRef(true)

	useEffect(() => {
		if(isFirstRun.current){
			isFirstRun.current = false
			gaView()
			visitTag()
			updateData()
		}
	}, [])

	const updateData = () => {
		axios.get("/GetOrderDetail?Code=" + props.match.params.id)
		.then(res => {
			if(res?.data?.isSuccess){
				setData(res?.data?.data || [])
			}else{
				history.push("/uye/siparislerim")
			}
		})
	}

	return (
		data.code &&
		<>
			<div className="scl-content-title">
				<span onClick={() => props.history.goBack()} className="btn user-title-btn base-regular light">
					<Icons.ArrowToLeft height=".8em"/> {t('UserPage.order-info.go-back')}
				</span>
				<span onClick={() => openModal('question')} className="btn user-title-btn float-right base-regular light">{t('UserPage.order-info.new-message')}</span>
			</div>
			<div className="scl-section-content info">
				<h1 className="base-regular">{t('UserPage.order-info.title')}</h1>
				<div className="row bdr g-0">
					<div className="col-12 col-lg base-regular">{t('UserPage.order-info.orderer')}</div>
					<div className="col-12 col-lg">
						<span className="float-lg-right">{data?.deliveryAddress?.name ?? "-"} {data?.deliveryAddress?.surname}</span>
					</div>
				</div>
				<div className="row bdr g-0">
					<div className="col-12 col-lg base-regular">{t('UserPage.order-info.order-date')}</div>
					<div className="col-12 col-lg">
						<span className="float-lg-right">{moment(data.dateTime).format("DD.MM.YYYY kk:mm")}</span>
					</div>
				</div>
				<div className="row bdr g-0">
					<div className="col-12 col-lg base-regular">{t('UserPage.order-info.order-code')}</div>
					<div className="col-12 col-lg">
						<span className="float-lg-right">{data.code}</span>
					</div>
				</div>
				{
					data.dsaContractPath &&
					<div className="row bdr g-0">
						<div className="col-12 col-lg base-regular">{t('UserPage.order-info.order-contract')}</div>
						<div className="col-12 col-lg">
							<span className="float-lg-right"><a href={data.dsaContractPath} target="_blank">{t('UserPage.order-info.order-contract-show')}</a></span>
						</div>
					</div>
				}
				{
					data.piiContractPath &&
					<div className="row bdr g-0">
						<div className="col-12 col-lg base-regular">{t('UserPage.order-info.order-contract2')}</div>
						<div className="col-12 col-lg">
							<span className="float-lg-right"><a href={data.piiContractPath} target="_blank">{t('UserPage.order-info.order-contract-show')}</a></span>
						</div>
					</div>
				}
				<div className="w-100"></div>
				<h1 className="base-regular">{t('UserPage.order-info.order-summary')}</h1>
				{
					data.details.map((elm, i) =>
						<div className="row order-short g-0 align-items-center" key={i}>
							<div className="col-3 col-lg-2 pr-2 pr-lg-0">
								<img src={elm.variant.product.imagePath} width="80px" alt=""/>
							</div>
							<div className="col-6 col-lg-5">
								<span className="base-regular">{elm.variant.product.title}</span>
								<span>{t('UserPage.order-info.quantity')} : {elm.quantity}</span>
								<span>{t('UserPage.order-info.color')} : {getColorName(elm.variant.product.colorCode)}</span>
								<span>{t('UserPage.order-info.body')} : {elm.variant.name}</span>
							</div>
							<div className="col-3 col-lg-5">
								<div className="row align-items-center">
									<div className="col text-right text-lg-left">
										<span>
										{
											elm.statuses.map((state, i) =>
												<React.Fragment key={i}>
													<span>
													{state?.status?.title ?? "bbd"}
													{
														elm.statuses.length > 1 &&
														<> ({state.quantity})</>
													}
													</span>
													{
														!state?.status?.id === 6 &&
														<br/>
													}
												</React.Fragment>
											)
										}
										</span>
									</div>
									<div className="col text-right">
										{toPriceConvert(elm.sellingPrice)}
									</div>
								</div>
							</div>
						</div>
					)
				}
				<div className="row bdr g-0">
					<div className="col base-regular">{t('UserPage.order-info.product-total-price')}</div>
					<div className="col">
						<span className="float-lg-right">{toPriceConvert(data.orderTotalPrice)}</span>
					</div>
				</div>
				{
					data.totalDiscount > 0 &&
					<div className="row bdr g-0 green">
						<div className="col base-regular">{t('UserPage.order-info.discount')}</div>
						<div className="col">
							<span className="float-lg-right">{toPriceConvert(data.totalDiscount)}</span>
						</div>
					</div>
				}
				{
					data.cargo?.price > 0 &&
					<div className="row bdr g-0">
						<div className="col base-regular">
							{t('UserPage.order-info.cargo')} ({data?.cargo?.name})
						</div>
						<div className="col">
							<span className="float-lg-right">{toPriceConvert(data.cargo?.price, false, 2, false)}</span>
						</div>
					</div>
				}
				{
					data.walletDiscount > 0 &&
					<div className="row bdr g-0">
						<div className="col base-regular">
							{t('UserPage.order-info.wallet')}
						</div>
						<div className="col">
							<span className="float-lg-right">{toPriceConvert(data?.walletDiscount, false, 2, false)}</span>
						</div>
					</div>
				}
				<div className="row bdr g-0">
					<div className="col base-regular">{t('UserPage.order-info.order-total-price')}</div>
					<div className="col">
						<span className="float-lg-right">{toPriceConvert(data.orderTotalDiscountedPrice)}</span>
					</div>
				</div>
				{
					data?.returnCode &&
					<div className="row bdr g-0">
						<div className="col base-regular">İade Bilgisi</div>
						<div className="col">
							<span className="float-lg-right">
							{
								data?.returnCode &&
								<a href={`${data?.cargo?.trackingurl}${data?.returnCode}`} target="_blank" className="">Kargo Takip</a>
							}
							</span>
						</div>
					</div>
				}
				<div className="w-100"></div>
				{
					(data?.deliveryAddress || data?.invoiceAddress) &&
					<>
					<h1 className="base-regular">{t('UserPage.order-info.address-info')}</h1>
					{
						[{...data?.deliveryAddress, type: t('UserPage.order-info.delivery')}, {...data?.invoiceAddress, type: t('UserPage.order-info.invoice')}].map((elm, i) =>
						<>
							{
								elm.name &&
								<div className="row bdr g-0" key={i}>
									<div className="col-12 col-lg-4">
										<span className="base-regular">{elm?.type}</span>
										<span>{elm?.name + " " + elm?.surname}</span>
									</div>
									<div className="col-12 col-lg-6">
										<span>{elm?.title}<br/>{elm?.city?.name} {elm?.county?.name} {elm?.country?.name} {elm?.phone} </span>
										<span>{elm?.addressText}</span>
									</div>
								</div>
							}
						</>
						)
					}
					</>
				}
				<div className="w-100"></div>
				<h1 className="base-regular">DİĞER</h1>
				{
					data?.returnCode &&
					<div className="row bdr g-0">
						<div className="col-12 col-lg-4">
							<span className="base-regular">İade Kodu: </span>
							<small>{data?.cargo?.name} <strong className="green">ücretsiz</strong> olarak gönderebilirsiniz</small>
						</div>
						<div className="col-12 col-lg-6">
							<span>{data.returnCode}</span>
						</div>
					</div>
				}
				{
					data?.extraNote &&
					<div className="row bdr g-0">
						<div className="col-12 col-lg-4">
							<span className="base-regular">Sipariş notu: </span>
						</div>
						<div className="col-12 col-lg-6">
							<span>{data?.extraNote}</span>
						</div>
					</div>
				}
				<div className="row bdr g-0">
					<div className="col-12 col-lg-4">
						<span className="base-regular">Hediye paketi olsun mu?</span>
					</div>
					<div className="col-12 col-lg-6">
						<span>{data?.isGift ? 'EVET' : 'HAYIR'}</span>
					</div>
				</div>
{
/*
					<div className="w-100"></div>
					<h1 className="base-regular">İADE BİLGİLERİ</h1>
					<div className="row bdr head gx-2 return">
						<div className="col">Ürün Adı</div>
						<div className="col">İade Sebebi</div>
						<div className="col">Durum</div>
						<div className="col">İşlem Tarihi</div>
					</div>

					<div className="row bdr gx-2 return">
						<div className="col"><a href={"#_s"}>KEMERLİ CEKET - Bej</a></div>
						<div className="col">Test Ediyorum</div>
						<div className="col green">İade Talep Edildi.</div>
						<div className="col">14/08/2020 17:08</div>
					</div>
*/
}
			</div>
		</>
	);
}

export default OrderInfo;
