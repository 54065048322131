import React, { useEffect, useContext, useState, useRef } from 'react'
import { Link, useHistory } from "react-router-dom"
import $ from "jquery";
import axios from 'axios'
import FormData from 'form-data'

import { useTranslation, Trans } from 'react-i18next'
import { AuthContext } from './../../context/AuthContext'
import { BasketContext } from './../../context/BasketContext'
import { LanguageContext } from './../../context/LanguageContext'
import { FiltersContext } from './../../context/FiltersContext'

import * as Icons from './../icons/index'
import BasketModal from './../modal/hover-basket'

import MainMenu from './../widgets/global/menu'
import Image from './../widgets/global/image'
import SliderText from './../../rComponents/web/sliderText'

import config from './../../package/config'

function Header(props) {
	const history = useHistory();
	const { t, i18n } = useTranslation();
	const { isAuth, logOut, getUserName, setStateAuth } = useContext(AuthContext)
	const { basketCount, clearBasket, updateBasket, basketData, firstUpdateBasket, utmCode, setUtmCode } = useContext(BasketContext)
	const { getLanguage, getChoicesLanguage, toPriceConvert, changeLanguage } = useContext(LanguageContext)
	const { filterData } = useContext(FiltersContext)

	const [searchActive, setSearchActive] = useState(false)
	const [searchText, setSearchText] = useState("")
	const [completeWindow, setCompleteWindow] = useState(false)
	const [suggestionsData, setSuggestionsData] = useState([])
	const [trendsData, setTrendsData] = useState([])
	const [completeData, setCompleteData] = useState([])
	const [resultCount, setResultCount] = useState(0)
  const searchInput = useRef(null);

	const pageControl =  useRef("")

	let windowWidth = $(window).width()

	const logOutHandler = () => {
		logOut()
		clearBasket()
	}

	history.listen((location, action) => {
		// location.pathname
		if(pageControl.current !== location.pathname){
			setCompleteWindow(false)
			setSearchText("")
			pageControl.current = location.pathname
		}
	});

	useEffect(() => {
		searchInput.current.click()
		searchInput.current.focus()

	}, [searchActive])

	useEffect(() => {
		if(windowWidth > 768){
			$( window ).scroll(function(e) {
				if(!$("header").hasClass("basket")) {
					if($(window).scrollTop() > 40){
						if(!$("header").hasClass("fixed")) {
							$("header").addClass("fixed")
							$("body").addClass("fixed-header")
						}
					} else {
						$("header").removeClass("fixed")
						$("body").removeClass("fixed-header")
					}
				}
			});
		} else {
			$("header .user-menu").click(function(){
				$("html, body, .App").stop().animate({ scrollTop: 0 }, 0);
			});
			$(".user-login .a, .search").off().click(function(){
				if(!$(this).hasClass("active")){
					$(".close-page-hover").click();
				}
			})
			$(".user-menu > ul > li > .a, .user-menu > ul > li > a, .logo, .other-brand").off().click(function(){
				if(!$(this).hasClass("active")){
					$(".close-page-hover").click();
				}
			})
			$(".login-user .a").off().click(function(){
				if(!$(this).hasClass("active")){
					$(".close-page-hover").click();
				}
				$(".close-page-hover").show();
				if($(this).hasClass("active")){
					$(this).removeClass("active");
					$("body,#root, .App").removeClass("modal-open");
					$(".login-user ul").animate({"right":"-100%"}, 600);
				} else {
					$(this).addClass("active");
					$("body,#root, .App").addClass("modal-open");
					$(".login-user ul").animate({"right":"0%"}, 600);
				}
			});

			$(".login-user ul li").off().click(function(){
				$(".login-user .a").removeClass("active");
				$("body,#root, .App").removeClass("modal-open");
				$(".login-user ul").animate({"right":"-100%"}, 600);
			});
		}

		/*$(".user-menu .open-search").off().click(function(){
			$(".search").addClass("active");
			$(".search input").focus();
			$(".search").stop().animate({"opacity": "1"}, 700);
		});*/
	}, [props])

	const searchChangeHandler = e => {
		setSearchText(e.target.value)
		if(e.target.value.length > 2){
			setCompleteWindow(true)
			searchComplete(e.target.value)
			$(".close-page-click").show()
			$("body").addClass("modal-open")
		}else{
			setCompleteWindow(false)
			$(".close-page-click").hide()
		}

		if (e.keyCode === 13) {
			toSearch()
		}
	}

	const toSearch = () => {
		if(searchText.length > 2){
			history.push("/arama/"+ searchText)
			setCompleteWindow(false)
			$(".search .close-search").click();
			$("body").removeClass("modal-open")
		}
	}

	const searchComplete = (text = searchText) => {
		let data = new FormData();
		data.append('name', text);
		let pagesize = windowWidth <= 768 && windowWidth >= 540 ? 6 : 8

		const cancelTokenSource = axios.CancelToken.source();

		axios.post("/Search?page=1&desc=0&pagesize=" + pagesize, data)
		.then(res => {
			if(res?.data?.isSuccess){
				const data = res.data.data
				setCompleteData(data.data)
				setSuggestionsData(data.suggestions)
				setTrendsData(data.trends)
				setResultCount(data.totalCount)
			}
		})

		cancelTokenSource.cancel();
	}

	const closeSearch = () => {
		setCompleteWindow(false)
		setSearchActive(false)

		/*if(windowWidth >= 540){
			$(".search").stop().animate({"opacity": "0"}, 0);
			$(".search").removeClass("active");
		}
		$("body").removeClass("modal-open")
		$(".close-page-click").hide();
		$(".search input").val("");*/
	}

	const searchProductClick = () => {
		if(!utmCode?.lock){
			const utmData = {code: "s-" + searchText, lock: false}
			setUtmCode(utmData)
		}
		$("body").find(".search .close-search").click()
	}

	return (
		<>
			<header>
				{
					<div className="top">
						<div className="container">
							{/*<div className="top-left float-left"></div>*/}
							{
								window.innerWidth > 540 && filterData?.campaigns?.['data']?.length > 0 &&
								<SliderText data={filterData?.campaigns?.['data']?.map((elm, i) => {return {text: elm.title, url: elm.titleInUrl}})} />
							}
							<div className="top-right">
								<div className="brands">
									{ config.app_name === "secil" ?
										<>
											<a href="https://www.ilmio.com"  className="other-brand ilmio" target="_blank"><Icons.İlmioLogo /></a>
											<span className="other-brand secil active" target="_blank"><Icons.SecilStoreLogo width="1.5em" /></span>
										</>
										: config.app_name === "ilmio" &&
										<>
											<a href="https://www.secilstore.com" class="other-brand ilmio" target="_blank"><Icons.SecilStoreLogo width="1.5em" /></a>
											<span className="other-brand secil active" target="_blank"><Icons.İlmioLogo /></span>
										</>
									}
								</div>
								{
								/*
								<div className="top-select">
									<span>{String(getChoicesLanguage('lang')).toUpperCase()} <Icons.ArrowDownBold2 width=".3em" height={undefined}/></span>
									<ul>
										{
											getLanguage().map((elm, i) =>
												<li key={"lang_" + i} onClick={() => {
													i18n.changeLanguage(elm.code)
													changeLanguage(elm)
												}}>
													{t("Header.language." + elm.lang)}
												</li>
											)
										}
									</ul>
								</div>
								*/
								}
							</div>
						</div>
					</div>
				}
				<div className="head">
					<div className="container">
						<Link to="/" className="logo">
							{ config.app_name === "secil" ?
								<Icons.SecilStoreLogo width="1.5em" height={undefined}/>
							: config.app_name === "ilmio" &&
								<Icons.İlmioLogo height={undefined}/>
							}
						</Link>
						<div className="user-menu">
							<ul className="list-inline m-0">
								<li className="list-inline-item">
									<span className={'a open-search ' + (searchActive ? 'd-none' : '')} onClick={() => {
										setSearchActive(true)
									 }}>
										<Icons.Search />
										{t('Header.user-list.search')}
									</span>
								</li>
								{/*<LanguageContext.Consumer>
								{
									({ welcomeModalOpen, getChoicesLanguage }) =>
									<li className="list-inline-item">
										<div onClick={() => welcomeModalOpen(true)}>
											<Icons.Location />
											{getChoicesLanguage()}
										</div>
									</li>
								}
								</LanguageContext.Consumer>*/}
								<AuthContext.Consumer>
									{contextState => (
										!isAuth ? (
										<>
											<li className="list-inline-item user-login">
												<span className="a" onClick={() => setStateAuth('loginModalShow', true)}>
													<Icons.Heart />
													{t('Header.user-list.favorite')}
												</span>
											</li>
										</>
									) : (
										<>
										<li className="list-inline-item">
											<Link to="/uye/favorilerim" title={t('Header.favorite')}>
												<Icons.Heart />
												{t('Header.user-list.favorite')}
											</Link>
										</li>
										</>
									))}
								</AuthContext.Consumer>
								<li className="list-inline-item open-basket" onMouseEnter={firstUpdateBasket}>
									<Link to="/sepet">
										<Icons.Bag />
										<BasketContext.Consumer>
											{value => (
												basketCount > 0 && <span className="notif">{basketCount}</span>
											)}
										</BasketContext.Consumer>
										{t('Header.user-list.basket')}
									</Link>
									{
										window.innerWidth > 768 &&
										<BasketContext.Consumer>
											{value => (
												basketCount > 0 && <BasketModal />
											)}
										</BasketContext.Consumer>
									}
								</li>
								<AuthContext.Consumer>
									{contextState => (
										!isAuth ? (
										<>
											<li className="list-inline-item user-login">
												<span className="a" onClick={() => setStateAuth('loginModalShow', true)}>
													<Icons.User />
													{t('Header.user-list.login')}
												</span>
											</li>
										</>
									) : (
										<>
										<li className="list-inline-item login-user">
											{
												window.innerWidth <= 540 &&
												<span className="a user-icon">
													<span>{String(getUserName() ?? t('Header.user-list.account')).substr(0,1)}</span>
													<span><Icons.CloseRegular /></span>
												</span>
											}
											{
												window.innerWidth > 540 &&
												<span className="a">
													<Icons.User />
													{getUserName() ?? t('Header.user-list.account')}
													 <Icons.ArrowDownBold width=".5em" style={{marginLeft: "10px"}} height={undefined}/>
												</span>
											}
											<ul>
												<li className="d-lg-none" style={{height: "40px"}}><span className="float-right close-usermenu"><Icons.CloseRegular /></span></li>
												<li><Link to={"/uye/siparislerim"}>{t('UserPage.menu.orders')}</Link></li>
												<li><Link to={"/uye/bilgilerim"}>{t('UserPage.menu.user-info')}</Link></li>
												<li><Link to={"/uye/adreslerim"}>{t('UserPage.menu.address')}</Link></li>
												<li><Link to={"/uye/mesajlarim"}>{t('UserPage.menu.message')}</Link></li>
												<li><Link to={"/uye/kuponlarim"}>{t('UserPage.menu.coupon')}</Link></li>
												<li><span className="hr"></span></li>
												<li><Link to={"/uye/alarmlarim"}>{t('UserPage.menu.alarm')}</Link></li>
												<li><Link to={"/uye/favorilerim"}>{t('UserPage.menu.favorite')}</Link></li>
												<li><Link to={"/uye/sifre-degistir"}>{t('UserPage.menu.new-password')}</Link></li>
												<li><span className="hr"></span></li>
												<li className="text-left" onClick={logOutHandler}>{t('UserPage.menu.logout')}</li>
											</ul>
										</li>
										</>
									))}
								</AuthContext.Consumer>
								<li className="list-inline-item mobile-menu-button">
									<span className="a">
										<Icons.MenuBold />
										<Icons.MenuBold />
										Menü
									</span>
								</li>
							</ul>
						</div>
						<div className={'search ' + (searchActive ||  windowWidth < 540 ? 'active' : 'd-none')} >
							<input ref={searchInput}  type="text" className="border-0" onBlur={() => {
								if(window.innerWidth > 768){
									setTimeout(closeSearch, 500)
								}
							}} placeholder={t('Header.search.placeholder')} onKeyUp={searchChangeHandler}/>
							<Icons.Search onClick={toSearch}/>
							{
								(completeWindow || windowWidth >= 540) &&
								<Icons.Close className="close-search" onClick={closeSearch}/>
							}
							{
								completeWindow &&
								<div className="search-complete">
									{
										completeData.length >0 &&
										<span className="result">{resultCount} {t('Header.search.result')}</span>
									}
									<div className="products">
									{
										completeData?.map((elm, i) =>
											<Link key={"p_" + i + elm.code} to={"/" + elm.url} onClick={() => searchProductClick()} className="scl-product">
												<span className="images w-100"><Image src={elm.imagePath} alt=""/></span>
												<span className="title">{elm.title}</span>
												{toPriceConvert([elm.price1, elm.price2])}
											</Link>
										)
									}
									<div className="clearfix"></div>
									</div>
									{
										suggestionsData.length > 0 &&
										<div className="sugges">
											<h3>{t('Header.search.sugges')}</h3>
											{
												suggestionsData.map((elm, i) =>
													<span key={i}><Link to={"/" + elm.url} onClick={ () => {
														setCompleteWindow(false)
														$("body").find(".search .close-search").click()
														$("body").removeClass("modal-open")
													}}>{elm.title}</Link></span>
												)
											}
										</div>
									}
									{
										trendsData?.length > 0 &&
										<div className="trends">
											<h3>{t('Header.search.trends')}</h3>
											{
												trendsData.map((elm, i) =>
													<span><Link to={"/arama/" + elm} onClick={ () => {
														$(".search .close-search").click()
														$("body").removeClass("modal-open")
													}}>{elm}</Link></span>
												)
											}
										</div>
									}
									{
										completeData.length <= 0 ?
											<span className="no-result">{t('Header.search.non')}</span>
										:
											<button className="btn" onClick={toSearch}>{t('Header.search.all')}</button>
									}
								</div>
							}
						</div>
					</div>
				</div>
			</header>
			<MainMenu {...{...props, history: history}} />
		</>
	)
}

export default Header
