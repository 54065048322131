import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form';
import yup from './../../package/yup-init'
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios'
import config from './../../package/config'
import { gaView } from './../../package/google-analytic'
import { visitTag } from './../../package/criteo'
import queryString from 'query-string'

import * as Icons from './../icons/index'

import SeoComponent from './../../rComponents/global/seoComponent'

import Input from './../widgets/forms/input'
import Button from './../widgets/forms/button'
import Loading from './../widgets/global/loading'

const schema = yup.object().shape({
	password_again: yup.string()
	.min(8)
	.max(32)
	.required(),
	password: yup.string()
	.min(8)
	.max(32)
	.required()
})

function RecoveryPassword(props) {

	const { register , handleSubmit, errors } = useForm({
		resolver: yupResolver(schema),
		mode: 'onBlur'
	})

	const [guid, setGuid] = useState(null)
	const [error, setError] = useState(null)
	const [success, setSuccess] = useState(null)
	const isFirstRun = useRef(true)

	useEffect(() => {
		if(isFirstRun.current){
			isFirstRun.current = false;
			gaView()
			visitTag()

			const parsed = queryString.parse(props.location.search);
			if('guid' in parsed){
				axios.post("/CheckUsableGuid", {}, {headers: {guid: parsed.guid}})
				.then(res => {
					if(res.data.isSuccess){
						setGuid(parsed.guid)
					}else{
						props.history.push('/')
					}
				})
			}else{
				props.history.push('/')
			}
		}
	}, [props])

	const onSubmit = (data) => {
		if(data.password !== data.password_again){
			setError("Şifreler uyuşmuyor")
			return false
		}

		axios.post("/RecoveryPassword", JSON.stringify(data.password), {headers: {guid: guid}})
		.then(res => {
			if(res.data.isSuccess){
				setSuccess("Şifreniz başarıyla değiştirildi")
			}else{
				setError(res.data.error)
			}
		})
	}

	const seoData = {
		title: "Şifre Yenile" + config.prefix + config.name,
		description: "Şifre Yenile",
		ogytpe: "article"
	}

	return (
		<>
			<SeoComponent {...seoData}/>
			<div id="Login">
				<div className="container pt-5 pb-5" style={{width: "500px"}}>
					{
						success &&
						<div className="successMessage pt-5 pb-5">
							<Icons.TickBold />
							{success}
						</div>
					}
					{
						!guid && <Loading />
					}
					{
						!success && guid &&
						<>
							{
								error &&
								<div className="errorMessage mb-4">
									<Icons.CloseCircle />
									{error}
								</div>
							}
							<h5 className="text-center">Şifreni Sıfırla</h5>
							<form className="pb-5" onSubmit={handleSubmit(onSubmit)}>
								<Input type="password" name="password" title="Yeni şifre" placeholder="Yeni şifre" error={errors?.password?.message} autoComplete="new-password" transferref={register}/>
								<Input type="password" name="password_again" title="Yeni şifre (tekrar)" placeholder="Yeni şifre (tekrar)" error={errors?.password_again?.message} autoComplete="new-password" transferref={register} />
								<Button type="submit" name="submit" placeholder="twitter" className="btn float-right" title="GÖNDER" />
							</form>
						</>
					}
				</div>
			</div>
		</>
	)
}

export default RecoveryPassword
