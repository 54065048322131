import React, { useState, useEffect, useRef } from 'react'
import styles from './modal.module.css'
import Swal from 'sweetalert2'

function Modal(props) {

	const [top, setTop] = useState(null)
	const [show, setShow] = useState(props.show)

	const modal = useRef(false)
	const content = useRef(false)

	useEffect(() => {
		if(props.show){
			openModal()
		}else{
			closeModal()
		}
	}, [props.show])

	useEffect(() => {
		rePosition()

		setTimeout(() => {
			rePosition()
		}, 1000)
	}, [props.rePosition])

	const openModal = () => {
		setShow(true)
		setTimeout(() => {
			if(modal.current){
				modal.current.style.opacity = 1
				document.body.classList.add('modal-open')
				rePosition()
			}
		}, 200)
	}

	const closeModal = () => {
		if(!props.closeAsk || !props.show){
			if(!modal.current){
				return false
			}

			modal.current.style.opacity = 0
			content.current.style.top = "-30%"

			setTimeout(() => {
				document.body.classList.remove('modal-open');
			}, 500)

			setTimeout(() => {
				setShow(false)
				setTop(null)

				if(props.closeHandler){
					props.closeHandler()
				}
			}, 500)
		}
	}

	const closeControll = (e, ask = false) => {
		if(e.target === e.currentTarget){
			if(props.closeAsk && props.show){
				props.closeAsk()
			}else{
				if(ask && (props.ask ?? true)){
					Swal.fire( {
						title: 'Kapatmak istediğinize emin misiniz?',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Evet, kapat!',
						cancelButtonText: 'Vazgeç',
					} )
					.then(result => {
						if ( result.isConfirmed ) {
							closeModal()
						}
					})
				}else{
					closeModal()
				}
			}
		}
	}

	const rePosition = () => {
		if(show){
			document.body.classList.add('modal-open')
		}

		const windowH = window?.innerHeight
		const contentH = content?.current?.clientHeight ?? 0
		var marginTop = 0
		if(windowH > contentH){
			marginTop = ((windowH - contentH) / 2)
		}else{
			marginTop = 0
		}
		setTop(marginTop)
	}

	return (
		<>
		{
			show &&
			<div className={styles.modal} onClick={() => {/*e => closeControll(e, true)*/}} ref={modal} {...props?.props?.main}>
				<div className={`${styles.container} ${styles.[props.size] ?? styles.mini}`} style={({top: top})} ref={content} {...props?.props?.container}>
					{
						props.closeButton !== false &&
						<span className={styles.close} onClick={closeControll} {...props?.props?.close}>×</span>
					}
					<div className={styles.content} {...props?.props?.content}>
						{props.children}
					</div>
				</div>
			</div>
		}
		</>
	)
}

export default Modal
