import React, { useContext, useEffect, useState, useRef } from "react";
import axios from 'axios'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from './../../../package/yup-init'
import Toast from './../../../package/swal-init'
import Swal from 'sweetalert2'
import { gaView } from './../../../package/google-analytic'
import { visitTag } from './../../../package/criteo'

import { useTranslation } from 'react-i18next'
import { LoadingContext } from './../../../context/LoadingContext'
import { AuthContext } from './../../../context/AuthContext'

import Modal from './../../../rComponents/global/modal/modal'

import Input from './../forms/input'
import Checkbox from './../forms/checkbox'
import Button from './../forms/button'

const shema = yup.object().shape({
	Name: yup.string()
	.min(2)
	.max(30)
	.required(),
	Surname: yup.string()
	.min(2)
	.max(20)
	.required(),
	Phone: yup.string()
	.max(18)
	.min(18)
	.required(),
	HasSubscription: yup.boolean()
	.required()
});

function UserInfo(props) {
	const { t, i18n } = useTranslation();
	const { data, isAuth, logOut, logIn, setStateAuth, loginExternal } = useContext(AuthContext)

	const [name, setName] = useState(null)
	const [surname, setSurname] = useState(null)
	const [phone, setPhone] = useState(null)
	const [mail, setMail] = useState(null)
	const [subscribe, setSubscribe] = useState(false)
	const [code, setCode] = useState("")
	const [isCode, setIsCode] = useState(false)
	const [codeTime, setCodeTime] = useState()
	const [userInfoShow, setUserInfoShow] = useState(false)
	const [isConfirm, setIsConfirm] = useState(false)
	const [cacheFormData, setCacheFormData] = useState(null)

	const isFirstRun = useRef(true)

	const { register , handleSubmit, errors } = useForm({
		resolver: yupResolver(shema),
		mode: 'onSubmit'
	})

	useEffect(() => {
		if(!data?.IsSmsActivated && isAuth){
			setIsCode(false)
			if(isFirstRun.current !== data?.Email && data){
				isFirstRun.current = data?.Email
				setTimeout(() => {
					gaView()
					visitTag()
					axios.get("/GetUserDetail")
					.then(res => {
						if(res?.data?.isSuccess){
							setMail(res.data.data.Email)
							setName(res.data.data.Name)
							setSurname(res.data.data.SurName)
							setPhone(res.data.data?.Phone?.replace("90", ""))
							setSubscribe(res.data.data.HasSubscription)
							setUserInfoShow(true)
						}
					})
				}, 1000)
			}else{
				setUserInfoShow(true)
			}
		}
	}, [isAuth, props])

	useEffect(() => {
		if(isConfirm){
			onSubmit(cacheFormData)
		}
	}, [isConfirm])

	const onSubmit = (formData) => {
		setCacheFormData(formData)
		if(!isConfirm){
			Swal.fire({
				title: '"' + formData.Phone + '" numaranıza doğrulama mesajı gönderilecek',
				text: "Bilgilerinizi onaylıyor musunuz?",
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				textColor: 'red',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Evet',
				cancelButtonText: 'Vazgeç',
			})
			.then(result => {
				if(result.isConfirmed){
					setIsConfirm(true)
				}
			})

			return false
		}

		let updateData = {...formData, Phone: formData?.Phone?.replace(/[^0-9]+/g, '')}

		if(code !== ""){
			updateData = {...updateData, Code: code}
		}

		axios.post("/UpdateUserDetail", updateData)
		.then(res => {
			if(res?.data?.isSuccess){
				if(res.data.statusCode === 200){
					Toast.fire({
						icon: 'success',
						title: t('UserPage.user-info.success')
					})
					setIsCode(false)
					setTimeout(()=> {
						document.body.classList.remove('modal-open')
						sessionStorage.removeItem("tempLI")
					}, 200)

					const tempLoginData = JSON.parse(sessionStorage.getItem("tempLI"))
					if(tempLoginData?.guid){
						loginExternal(tempLoginData.guid)
					}else{
						logIn(tempLoginData)
					}
				}else if(res.data.statusCode === 204 || res.data.statusCode === 808 || res.data.statusCode === 800){
					setIsCode(true)
				}
			}else{
				Toast.fire({
					icon: 'error',
					title: res.data.error
				})
			}
		})
	}

	const convertClock = (time) => {
		let second = time % 60
		let minutes = (time - second) / 60

		return  minutes.toString().padStart(2, "0") + ":" + second.toString().padStart(2, "0")
	}

	const countDown = () => {
		if(codeTime > 0){
			setCodeTime((codeTime - 1))
		}
	}

	const closeAsk = () => {
		if(!userInfoShow){
			return false
		}

		Swal.fire({
			title: 'Kapatmak istediğinize emin misiniz?',
			text: "Eğer bilgileri güncellemezseniz oturumunuz kapanacaktır!",
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			textColor: 'red',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Evet, kapat!',
			cancelButtonText: 'Vazgeç',
		})
		.then(result => {
			if(result.isConfirmed){
				setUserInfoShow(false)
				logOut()
			}
		})
	}

	return (
		isAuth && !data?.IsSmsActivated &&
		<Modal show={userInfoShow} rePosition={userInfoShow + isCode} closeAsk={closeAsk}>
			<div className="scl-content-title">
			{
				!isCode &&
				<>
					<p className="red" style={{fontSize: "15px", borderBottom: "1px solid #c3c3c3", padding: "0 0 20px", marginBottom: "20px"}}>Size daha iyi hizmet verebilmek için sitemizi yeniledik.<br/>Yeni sistemde bilgilerinizi güncellemeniz gerekmektedir</p>
					<h1 className="base-regular">Bilgilerim</h1>
				</>
			}
			</div>
			<div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="col-12 text-left pr-5">
							<div className={isCode && "d-none"}>
								<Input title={t('UserPage.user-info.mail')} name="mail" type="text" value={mail || ''} disabled/>

								<Input title={t('UserPage.user-info.name')} name="Name" type="text" value={name || ''} autoComplete="given-name" error={errors?.Name?.message} transferref={register} onChange={e => {setName(e.target.value)}}/>

								<Input title={t('UserPage.user-info.surname')} name="Surname" type="text" value={surname || ''} autoComplete="family-name" error={errors?.Surname?.message} transferref={register} onChange={e => {setSurname(e.target.value)}}/>

								<Input title={t('UserPage.user-info.phone')} name="Phone" type="tel" value={phone || ''} autoComplete="tel-local" error={errors?.Phone?.message} transferref={register} onChange={e => {setPhone(e.target.value)}}/>

								<Checkbox label={t('UserPage.user-info.subscribe')} name="HasSubscription" checked={subscribe} transferref={register} error={errors?.HasSubscription?.message} onChange={e => {setSubscribe((!subscribe) ? true : false)}}/>
							</div>

							{
								isCode &&
								<div className="row justify-content-end green">
									<div className="col-12">
										<Input title={t('UserPage.user-info.code')} name="code" type="text" onChange={e => {setCode(e.target.value)}} />
									</div>
								</div>
							}
							<div className="mb-3 form-btn text-right">
								<Button type="submit" title={isCode ? "DOĞRULA" : t('Global.update')} className="btn"/>
							</div>
						</div>
					</div>
				</form>
			</div>
		</Modal>
	)
}

export default UserInfo;
