import React, { createContext, Component } from 'react'
import axios from 'axios'
import i18next from 'i18next'

import Swal from 'sweetalert2'
import Toast from './../package/swal-init'

export const FavoriteContext = createContext()

class FavoriteContextProvider extends Component {
	constructor( props ) {
		super( props )

		this.state = {
			favoriteData: null,
			favoriteCount: 0,
			auth: false
		}
	}

	componentDidMount(){
		this.controlAuth(this.updateFavorite)
	}

	controlAuth = (callback = undefined) => {
		const auth = JSON.parse(localStorage.getItem("auth"))
		if(auth){
			if(callback){
				callback()
			}
			this.setState({auth: true})
		}
		return auth
	}

	updateFavorite = () => {
		const response = axios.get("/GetFavorites")
		const promiseData = response.then(res => res)

		promiseData.then(res => {
			if(res?.data?.isSuccess){
				this.setState({favoriteData: res.data.data}, () => this.setState({favoriteCount: this.state.favoriteData.length}))
			}
		})
		.catch(error => {

		})

		return promiseData
	}

	addFavorite = async (data) => {
		if(this.state.auth){
			axios.post("/AddToFavorites", data)
			.then(res => {
				this.updateFavorite()
				Toast.fire({
					icon: 'success',
					title: i18next.t('ContextMessage.favorite.success')
				})
			})
		}
	}

	removeFavorite = (data) => {
		if(this.state.auth){
			axios.post("/RemoveFromFavorites", data)
			.then(res => {
				this.updateFavorite()
				Toast.fire({
					icon: 'success',
					title: i18next.t('ContextMessage.favorite.delete')
				})
			})
		}
	}

	isFavorite = (code) => {
		if(this.state.favoriteData){
			for (var i = 0; i < this.state.favoriteData.length; i++) {
				if(this.state.favoriteData[i].productcolorcode === code){
					return true
				}
			}
		}
		return false
	}

	render() {
		return (
			<FavoriteContext.Provider value={{
				...this.state,
				addFavorite: this.addFavorite,
				isFavorite: this.isFavorite,
				removeFavorite: this.removeFavorite,
				updateFavorite: this.updateFavorite,
				controlAuth: this.controlAuth
			}}>
				{this.props.children}
			</FavoriteContext.Provider>
		)
	}
}

export default FavoriteContextProvider
