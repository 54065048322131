import React, { useEffect, useState, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { rePosition } from "./../../package/global-function";
import config from "./../../package/config";
import yup from "./../../package/yup-init";
import Toast from "./../../package/swal-init";
import FormData from 'form-data'
import fs from "fs"

import axios from "axios"
import $ from "jquery";
import withReactContent from 'sweetalert2-react-content'

import { AuthContext } from './../../context/AuthContext'
import { LanguageContext } from './../../context/LanguageContext'

import * as Icons from './../icons/index'
import Input from './../widgets/forms/input'
import Checkbox from './../widgets/forms/checkbox'
import Button from './../widgets/forms/button'
import Select from './../widgets/forms/select'
import Textarea from './../widgets/forms/textarea'

function ModalQuestion( props ) {

	const { t } = useTranslation()

	const { isAuth } = useContext(AuthContext)
	const { getCurrencyID } = useContext(LanguageContext)

	const [imagePath, setImagePath] = useState("")
	const [localImagePath, setLocalImagePath] = useState(null)
	const [localImage, setLocalImage] = useState(null)
	const [selectedOrder, setSelectedOrder] = useState(null)
	const [message, setMessage] = useState("")

	const [resultMessage, setResultMessage] = useState(true)
	const isFirstRun = useRef(true)

	const category = [t('QuestionModal.category._6')]
	const topic = [t('QuestionModal.topic._5')]

	useEffect( () => {
		if(isFirstRun.current){
			isFirstRun.current = false;
			$( ".scl-modal-close" ).click( function () {
				$( ".modal" ).click();
			} );
			var myModalEl = document.getElementById( 'questionAdvancedModal' )
			if ( myModalEl ) {
				myModalEl.addEventListener( 'shown.bs.modal', function ( e ) {
					rePosition("#questionAdvancedModal", 200)
				} )
			}
		}
	}, [ props] )

	useEffect( () => {
		$(".file-upload span").off().on('click', function(){
			$('.file-upload input[type=file]').click()
		});
	}, [localImagePath] )

	const imageUpload = (e) => {
		setLocalImagePath(URL.createObjectURL(e.target.files[0]))
		const file = setLocalImage(e.target.files[0])
	}

	const onSubmit = (e) => {
		e.preventDefault()

		if(localImage){

			let data = new FormData();
			data.append('file', localImage, localImage.name)
			axios.post(config.cdnUploadUrl, data, {
				...axios.defaults,
				headers: {
					...axios.defaults.headers,
					'Content-Type': 'multipart/form-data',
					'Access-Control-Allow-Origin': '*'
				},
				onUploadProgress: progressEvent => {
				}
			})
			.then(res => {
				let postData = {
					"Category": category[0],
					"Topic": topic[0],
					"QuestionText": "Sipariş Numarası:" + selectedOrder + " // Müşteri Notu: " + message,
					"ImagePath": res.data.body
				}

				axios.post('/AddNewQuestion', postData)
				.then(res => {
					if(res?.data?.isSuccess){
						$(".scl-modal-close").click()
						Toast.fire({
							icon: 'success',
							title: t('QuestionModal.success')
						})
					}
				})
			})
		}
	}

	const changeOrder = (data) => {
		setSelectedOrder(data.key)
	}

	return ( <div className="modal fade" id="questionAdvancedModal" aria-labelledby="questionAdvancedModal" aria-hidden="true">
		<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg scl-modal">
			<div className="modal-content" style={{paddingTop: "2%"}}>
				<div className="scl-modal-close">×</div>
				<div className="scl-modal-title">
					<h2>TADİLAT FİŞİ</h2>
				</div>
				<div className="modal-body">
					<div className="tab-content" id="myTabContent">
						<div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
							{
								resultMessage.length > 0 &&
								<div className="errorMessage"><Icons.CloseCircle />{resultMessage}</div>
							}
							<form onSubmit={onSubmit}>
								<div className="row">
									<div className="col">
										<Select name="order" label="ŞİPARİŞ" options={props.orders.map((elm, i) => {return {key: elm.code, value: "#" + elm.code + " ✧ " + elm.products.length + " Ürün ✧ " + elm.ordertotalprice + " "+ getCurrencyID(elm.currencyid, 'id', 'name')}})} selected={selectedOrder} clicked={changeOrder} />
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Textarea placeholder="İletmek istediğiniz notunuzu giriniz" name="question" max="200" rows="3" onChange={e => setMessage(e.target.value)}/>
									</div>
								</div>
								<div className="row">
									<div className="col">
										{
											!localImagePath &&
											<div className="col-12 file-upload">
												<input type="file" name="file" onChange={imageUpload} />
												<span>Yükle</span>
											</div>
										}
										{
											localImagePath &&
											<>
											<img src={localImagePath} alt=""/>
											<div className="mt-3 text-right">
												<span className="mt-3 pointer p-3" onClick={() => {setLocalImagePath(null)}}>İptal</span>
											</div>
											</>
										}
									</div>
								</div>
								<div className="row">
									<div className="col">
										<div className="mb-3 form-btn text-right">
											<Button type="submit" className="btn" title="Gönder" />
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> )
}

export default ModalQuestion
