import React from 'react'
import TypeLink from './../global/link'
import SwiperCore, { Navigation, Pagination, Scrollbar, Autoplay, Lazy } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

SwiperCore.use([Navigation, Pagination, Autoplay, Lazy]);

function HomeSlider(props) {
	return (
		<div className="banner not">
			<div className="container">
				<Swiper slidesPerView={1} spaceBetween={10} autoplay={{delay: 4000}} lazy navigation loop>
					{
						props?.data?.map((elm, i) =>
							<SwiperSlide key={i}>
								<TypeLink to={elm?.url} className="w-100" style={{minHeight: "500px"}}>
									<img data-src={elm?.img} className="d-block w-100 swiper-lazy" alt={elm?.title}/>
								</TypeLink>
								<div className="swiper-lazy-preloader"></div>
							</SwiperSlide>
						)
					}
				</Swiper>
			</div>
		</div>
	)
}

export default HomeSlider
