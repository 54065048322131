import React, { useEffect, useState, useRef, useContext } from 'react'
import styles from './subscribe.module.css'
import axios from 'axios'
import { useTranslation } from 'react-i18next';
import config from './../../package/config'
import { validateEmail } from './../../package/global-function'
import { gaView } from './../../package/google-analytic'
import { visitTag } from './../../package/criteo'
import * as Icons from './../icons/index'

import { AuthContext } from './../../context/AuthContext'

import SeoComponent from './../../rComponents/global/seoComponent'

import Input from './../widgets/forms/input'
import Button from './../widgets/forms/button'

function Subscribe(props) {

	const {t} = useTranslation()
	const { openModal } = useContext(AuthContext)

	const [mail, setMail] = useState("")
	const [isConfirm, setIsConfirm] = useState(false)
	const [error, setError] = useState(null)
	const [success, setSuccess] = useState(null)

	const isFirstRun = useRef(true)

	useEffect(() => {
		if(isFirstRun.current){
			isFirstRun.current = false;
			gaView()
			visitTag()
		}
	}, [props])


	const subscribeSend = () => {
		if(!isConfirm){
			setError('Bilgilendirme metnini okuyup kabul etmelisiniz')
			return false
		}

		if(mail.trim() === "" || !validateEmail(mail?.trim())){
			setError('Geçerli bir mail adresi girmelisiniz')
			return false
		}

		axios.get("/Subscribe", {headers: {email: mail.trim()}})
		.then(res => {
			if(res?.data?.isSuccess){
				setSuccess("Abone oldunuz!")
				setError(null)
				setMail("")
			}else{
				setError(res.data.error)
			}
		})
	}

	const seoData = {
		title: "Haftalık Bültene Abone Ol" + config.prefix + config.name,
		description: "Seçil Store Bültenlerinden haberdar ol, e posta aboneliği"
	}

	return (
		<>
			<SeoComponent {...seoData}/>
			<div className="container">
				<div className={`row ${styles.subscribeContainer}`}>
					{
						!success &&
						<div className="col col-lg-5 text-center">
							<h1 className={styles.title}>ABONE OL</h1>
							<div className="subscribe">
								<form onSubmit={e => e.preventDefault()}>
									<div className="row mb-4">
										<div className="col">
											{error && <span className="error danger text-center">{error}</span>}
										</div>
									</div>
									<div className="row">
										<div className="col">
											<Input placeholder={t('Footer.subscribe.input')} autoComplete="email" value={mail} onChange={e => setMail(e.target.value)}/>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<label className={`row ${styles.checkLabel}`} htmlFor="subscribe_check">
												<input type="checkbox" className="form-check-input" checked={isConfirm} onChange={() => {setIsConfirm(!isConfirm)}} id="subscribe_check" />
												<span className={styles.checked}><Icons.TickBold /></span>
												<span className={`green ${styles.checked}`} onClick={() => openModal('kvkk')}><strong>{t('Footer.subscribe.name')}</strong></span> {t('Footer.subscribe.kvkk')}
											</label>
										</div>
									</div>
									<div className="row pt-5">
										<div className="col">
											<Button type="button" onClick={subscribeSend} title={t('Footer.subscribe.button')} className="btn" />
										</div>
									</div>
								</form>
							</div>
						</div>
					}
					{
						success &&
						<div className="row justify-content-center">
							<div className="col col-lg-5">
								<div className={styles.success}>
									<Icons.TickBold className={styles.tick}/>
									Başarıyla Abone Oldunuz
								</div>
							</div>
						</div>
					}
				</div>
			</div>
		</>
	)
}

export default Subscribe
