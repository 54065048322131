import React, { useContext, useState, useEffect } from "react"
import {Link} from 'react-router-dom'
import axios from 'axios'

import { useTranslation } from 'react-i18next';
import { validateEmail } from './../../../package/global-function'
import Toast from './../../../package/swal-init'

import {BasketContext} from './../../../context/BasketContext'
import {LanguageContext} from './../../../context/LanguageContext'
import {AuthContext} from './../../../context/AuthContext'

import BasketProduct from './basket-product'
import * as Icons from './../../icons/index'
import Input from './../../widgets/forms/input'
import Loading from './../../widgets/global/loading'
import AddCoupon from './../../../rComponents/global/addCoupon'
import BasketGroupList from './../../../rComponents/global/basketGroupList'

function BasketContent(props) {

	const { t } = useTranslation()
	const { basketData, basketCount, totalPrice, subTotal, discountTotal, firstUpdateBasket, addBasket } = useContext(BasketContext)
	const { toPriceConvert } = useContext(LanguageContext)
	const { isAuth, setStateAuth } = useContext(AuthContext)

	const [isModal] = useState(props.modal)
	const [mailError, setMailError] = useState("")

	useEffect(() => {
		setMailError("")
	}, [props])

	const nonBasket = () => {
		return (
			<div className="basket-empty base-light">
				<Icons.NonBasket />
				<span>{t('Basket.non')}</span>
				<Link to="/" className="btn">{t('Basket.non-btn')}</Link>
			</div>
		)
	}

	const checkContinue = () => {
		if(basketData?.items?.length > 0 && subTotal === 0){
			setMailError("Stoğu olan ürünler eklemelisiniz")
			return
		}

		if(isAuth){
			props.changeStep(2)
		}else{
			setStateAuth('loginModalShow', true)
			setStateAuth('guestGo', true)
		}
	}

	return (
		<>
		{
			basketData?.items ?
			basketData.items.length > 0 ? (
				<>
				<div className="row">
					<div className="col-8 basket-product-list bgw mr-3">
						{
							!isModal &&
							<div className="row base-light">
								<div className="col-6">{t('Basket.product')}</div>
								<div className="col-3 pl-4 pr-4">{t('Basket.quantity')}</div>
								<div className="col-1">{t('Basket.price')}</div>
							</div>
						}
						{
							basketData.items.map((elm, i) =>
								<BasketProduct
									modal={isModal}
									key={i}
									data={elm}
								/>
							)
						}
						{
							!isModal &&
							basketData?.failCampaigns?.filter(e => e.promotional)?.map((e, i) =>
								<BasketGroupList {...e} key={i}/>
							)
						}
					</div>
					<div className="col bgw">
						<div className="row">
							<div className="basket-info bgw">
								{
									!isModal &&
									<>
										<div className="row">
											<h2>{t('Basket.summary')}</h2>
										</div>

										<div className="row base-light">
											<span>{t('Basket.product-price')} <small>{t('Basket.in-kdv')}</small></span>
											{subTotal > 0 ? toPriceConvert(subTotal) : <span>-</span>}
										</div>
										<div className={"row base-light" + (discountTotal > 0 ? ' green' : ' grey')}>
											<span>{t('Basket.discount')}</span>
											<span>{discountTotal > 0 ? toPriceConvert(discountTotal) : '-'}</span>
										</div>
										{/*<div className={"row" + (basketData?.cargo?.price <= 0 ? ' green' : '')}>
											<span>{t('Basket.cargo')}</span>
											{subTotal > 0 ? toPriceConvert(basketData?.cargo?.price) : <span>-</span>}
										</div>*/}
										<div className="row">
											<span>{t('Basket.total-price')}</span>
											{totalPrice > 0 ? toPriceConvert(totalPrice) : <span>-</span>}
										</div>
									</>
								}
								{
									isModal &&
									<>
										<div className="row"></div>
										<div className="row">
											<span>{t('Basket.product-price')} <small>{t('Basket.in-kdv')}</small></span>
											{toPriceConvert(subTotal)}
										</div>
									</>
								}
								{
									isAuth && !isModal &&
									<AddCoupon />
								}
							</div>
						</div>
						<div className="row">
							<div className="bgw p-4 pt-lg-0">
							{
								props.changeStep ?
									<div className="row">
										{
											mailError &&
											<span className="red mb-3">{mailError}</span>
										}
										<input type="button" className="btn" value={t('Basket.basket-confirm')} onClick={checkContinue}/>
									</div>
								:
									<div className="row"><Link className="btn" to="/sepet">{t('Basket.go-basket')}</Link></div>
							}
							</div>
						</div>
					</div>
				</div>
				</>
			) : nonBasket()
			: <Loading />
		}
		</>
	);
}

export default BasketContent;
