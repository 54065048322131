import * as yup from "yup"

if(localStorage.getItem('lang') === 'tr-TR' || !localStorage.getItem('lang')){
	yup.setLocale({
		mixed: {
			required: 'Bu alan zorunludur',
			oneOf: 'Bu alan zorunludur',
		},
		string: {
			email: 'Mail formatı uygun değil',
			matches: 'Format uygun değil',
			min: 'En az ${min} karakter olmalıdır',
			max: 'En fazla ${max} karakter olabilir',
			length: '${length} karakter uzunluğunda olmalıdır'
		},
		number: {
			min: 'En az ${min} karakter olmalıdır',
			max: 'En fazla ${max} karakter olabilir'
		}
	})
}

export default yup
