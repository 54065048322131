import React, { useEffect, useState} from 'react'
import PropTypes from 'prop-types'

// props.start Başlangıç değeri
// props.finish Bitiş değeri
// props.between Değişim aralığı
// props.direction Azalacak mı (false) / Aratacak mı (true)

function TimeCounter({start = 30, finish = 0, direction = false, between = 1, changeTime = 1000, visible = true, finishedHandler = undefined, onChange = undefined}) {

	const [seconds, setSeconds] = useState(start)

	useEffect(() => {
		if(onChange){
			onChange(seconds)
		}

		if(!direction){
			if(seconds > finish){
				setTimeout(() => {
					setSeconds(seconds - between)
				}, changeTime)
			}else{
				finised()
			}
		}else{
			if(seconds < finish){
				setTimeout(() => {
					setSeconds(seconds + between)
				}, changeTime)
			}else{
				finised()
			}
		}
	}, [seconds])

	useEffect(() => {
		setSeconds(start)
	}, [start])

	const finised = () => {
		if(start <= 0){
			return false
		}

		if(finishedHandler){
			finishedHandler()
		}
	}

	return (
		visible &&
		<>{seconds}</>
	)
}

export default TimeCounter
