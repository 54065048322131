import * as React from "react";

function SvgArrowToLeft(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M37.79 19.84c0-.6-.49-1.08-1.08-1.08H6.52l7.71-7.59c.43-.42.43-1.11.01-1.53l-.23-.24c-.42-.43-1.11-.43-1.53-.01l-9.89 9.72c-.24.24-.38.56-.38.89 0 .34.13.66.37.89l9.89 9.72c.43.42 1.11.41 1.53-.01l.23-.23c.42-.43.41-1.11-.01-1.53l-7.71-7.58H36.7c.6 0 1.08-.48 1.08-1.08l.01-.34z"
      />
    </svg>
  );
}

export default SvgArrowToLeft;
