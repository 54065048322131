import * as React from "react";

function SvgHeart(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M20 37.33c-.52 0-1-.2-1.37-.57L3.34 21.47c-4.29-4.3-4.29-11.29 0-15.58 2.08-2.08 4.84-3.22 7.79-3.22s5.71 1.14 7.79 3.22L20 6.97l1.08-1.08c2.08-2.08 4.84-3.22 7.79-3.22s5.71 1.14 7.79 3.22c4.3 4.3 4.3 11.29 0 15.58L21.37 36.76c-.37.37-.85.57-1.37.57zM11.13 4.94c-2.34 0-4.54.91-6.19 2.56-3.41 3.41-3.41 8.96 0 12.37L20 34.92l15.06-15.06c3.41-3.41 3.41-8.96 0-12.37a8.692 8.692 0 00-6.18-2.56c-2.34 0-4.54.91-6.18 2.56L20 10.18 17.31 7.5a8.658 8.658 0 00-6.18-2.56z"
      />
    </svg>
  );
}

export default SvgHeart;
