import * as React from "react";

function SvgSearch(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M39.44 36.37l-8.87-8.87c-.28-.28-.64-.42-1.01-.43l-.97-.97c2.23-2.75 3.57-6.26 3.57-10.07C32.16 7.19 24.97 0 16.14 0 7.31 0 .12 7.19.12 16.02s7.19 16.02 16.02 16.02c4.19 0 8-1.62 10.86-4.25l.96.96c.04.34.17.67.43.93l8.87 8.87c.59.59 1.55.59 2.14 0l.04-.04c.59-.59.59-1.56 0-2.14zm-23.3-6.25c-7.77 0-14.1-6.32-14.1-14.11 0-7.77 6.32-14.1 14.1-14.1s14.11 6.32 14.11 14.11c-.01 7.78-6.33 14.1-14.11 14.1z"
      />
    </svg>
  );
}

export default SvgSearch;
