import * as React from "react";

function SvgTwitter(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M6.76 29.05c2.69 1.73 5.89 2.73 9.33 2.73 11.3 0 17.68-9.54 17.3-18.1 1.19-.86 2.22-1.93 3.04-3.15-1.09.48-2.26.81-3.5.96a6.085 6.085 0 002.68-3.37c-1.18.7-2.48 1.2-3.86 1.48a6.082 6.082 0 00-4.44-1.92c-3.93 0-6.81 3.67-5.93 7.47-5.06-.25-9.54-2.68-12.54-6.36a6.103 6.103 0 001.88 8.13c-1-.03-1.94-.31-2.76-.76-.07 2.82 1.95 5.46 4.88 6.04-.86.23-1.8.29-2.75.1a6.086 6.086 0 005.68 4.23 12.22 12.22 0 01-9.01 2.52z"
      />
    </svg>
  );
}

export default SvgTwitter;
