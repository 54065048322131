import React from 'react'

function Loading(props) {
	return (
		<div {...props} className={"loading" + (props.className ? ` ${props.className}` : '')}>
			<div className="loader"><span></span></div>
		</div>
	)
}

export default Loading
