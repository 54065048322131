import React, { useEffect, useState, useRef, useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import styles from "./productGetSearch.module.css"
import axios from 'axios'
import SwiperCore, { Navigation, Pagination, Scrollbar, Lazy } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { LanguageContext } from './../../context/LanguageContext'

SwiperCore.use([Navigation, Pagination, Lazy])

function ProductGetSearch(props) {
	const sliderKey = useMemo(() => Math.random(), [props.data])

	const [productData, setProductData] = useState(null)

	const { toPriceConvert } = useContext(LanguageContext)

	const isFirstRun = useRef(true)

	const oneSliderSize = window.innerWidth <= 540 ? 1 : 4

	useEffect(() => {
		if(isFirstRun.current !== props?.data?.products?.join('') && props?.data?.products?.length > 0){
			isFirstRun.current = props.data.products.join('')

			let post = new FormData()
			let data = props?.data?.products
			post.append('name', data.join(','))
			const pageSize = data?.length ?? 10
			axios.post("/Search?page=1&pagesize=" + pageSize, post)
			.then(res => {
				const sortData = res.data?.data?.data?.sort((a, b) => {
					return props?.data?.products.indexOf(a.code) - props?.data?.products.indexOf(b.code)
				}) ?? []

				setProductData(toGroup(sortData))
			})
		}
	}, [props.data])

	const toGroup = (data) => {
		let gecici = []
		let newData = []

		for (var i = 0; i < data.length; i++) {
			if(gecici.length <= oneSliderSize){
				gecici = [...gecici, data[i]]
			}else{
				newData = [...newData, gecici]
				gecici = [data[i]]
			}
		}
		newData = [...newData, gecici]
		return newData
	}

	return (
		productData &&
		<div className={styles.groupSlider}>
			<div className="container">
				<h2 className={`p-2 ${styles.title}`}>{props?.data?.title}</h2>
				<Swiper slidesPerView={1} spaceBetween={10} pagination={{ clickable: true }} lazy={true} key={sliderKey}>
					{
						productData.map((pData, i) =>
							<SwiperSlide key={i}>
								{
									pData.map((elm, i) =>
										<Link key={i} to={`/${elm.url}`} className={`${styles.sliderProduct} ${styles.[`_${oneSliderSize}`] ?? ''}`}>
											<img src={elm.imagePath} className="d-block w-100" alt={elm.title} />
											<span>{elm.title}</span>
											{toPriceConvert([elm.price1, elm.price2])}
										</Link>
									)
								}
							</SwiperSlide>
						)
					}
				</Swiper>
			</div>
		</div>
	)
}

export default ProductGetSearch
