import * as React from "react";

function SvgEditPenBold(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M.02 39c0 .55.45 1 1 1h34.07c.55 0 1-.45 1-1V22.92h-2V38H2.02V5.89h15.06v-2H1.02c-.55 0-1 .45-1 1V39z"
      />
      <path
        fill="currentColor"
        d="M32.17.28c-.38-.38-1.04-.38-1.42 0L13.92 17.13a.99.99 0 00-.29.71v7.53c0 .55.45 1 1 1h7.52c.27 0 .52-.11.71-.29L39.69 9.23a.996.996 0 000-1.41L32.17.28zM21.74 24.37h-6.11v-6.12L31.46 2.4l6.11 6.12-15.83 15.85z"
      />
    </svg>
  );
}

export default SvgEditPenBold;
