import React, { useState, useContext } from 'react'
import {LanguageContext} from './../../../context/LanguageContext'

function PaymentOptions( props ) {

	const { toPriceConvert } = useContext(LanguageContext)

	return (
		<>
			<div className="row">
				<div className="col">
					<table className="table">
						<tbody>
							<tr className="active">
								<td>Taksit</td>
								<td>Aylık Ödeme</td>
								<td>Toplam</td>
							</tr>
							{
								[1, 3, 6].map((elm, i) =>
									<tr key={i}>
										<td>
											{elm !== 1 ? elm : "Tek Çekim"}
										</td>
										<td>
											{toPriceConvert(props.price / elm)}
										</td>
										<td>
											{toPriceConvert(props.price)}
										</td>
									</tr>
								)
							}
						</tbody>
					</table>
				</div>
			</div>
		</>
	)
}

export default PaymentOptions
