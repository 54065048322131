import React, {
	useState,
	useEffect,
	useRef
}
from 'react'
import $ from "jquery";

function Select(props) {
	const [id, setID] = useState(props.id)
	const [name, setName] = useState(props.name || [])
	const [options, setOptions] = useState(props.options || [])
	const [openSelect, setOpenSelect] = useState(false);
	const [bodySelected, setSelectBody] = useState((props.options ? props.options[0] : false) || {key: null, value: "Yükleniyor"})
	const isFirstRun = useRef(true)
	const wrapperRef = useRef(null)

	useEffect(() => {
		changeSelected()

		if(isFirstRun.current){
			document.addEventListener("click", handleClickOutside, false);
			return () => {
				document.removeEventListener("click", handleClickOutside, false);
			}
		}
	}, [props.selected, props.options])

	const handleClickOutside = event => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setOpenSelect(false);
		}
	}

	const changeSelected = async () => {
		setOptions(props.options || [])

		let match = false
		for(let i = 0; i < props.options?.length; i++){
			if(props.selected === props.options[i].key){
				setSelectBody(props.options[i])
				match = true
			}
		}

		if(!match && props.options?.length > 0){
			await setSelectBody(props.options[0])
			if(props.options.filter(e => e.key).length > 0){
				props.clicked(props.options[0])
			}
		}
	}

	return (
		<>
			<div className="mb-3" ref={wrapperRef}>
				{props.label && <label htmlFor={id} className="form-label w-100">{props.label}</label>}
				<div id={id} className={"scl-select p-0" + (props.error ? ' red' : '')}>
					<div onClick={() => setOpenSelect(!openSelect)} className={"selected-item" + (props.onlytext ? ' only-text' : '') + ( openSelect ? ' active' : '')}>
						<span>
							{bodySelected.value}
						</span>
					</div>
					<ul className={"scl-select-options" + (props.onlytext ? ' only-text' : '')}>
						{
							options.map((elm, i) =>
								<div key={i}
									className={ 'scl-select-item' + (props.selected === elm.key ? ' active selected' : '')}
									onClick={(() => {
										setSelectBody(elm)
										if(props.clicked){
											props.clicked(elm)
										}
										setOpenSelect(false)
									})}>
									<span>
										{elm.value}
									</span>
								</div>
							)
						}
					</ul>
				</div>
				{
					props.error &&  <span className="error danger mt-2">{props.error}</span>
				}
			</div>
		</>
	)
}

export default Select
