import React from 'react'
import TypeLink from './../global/link'

function homeBrands(props) {
	return (
		<div className="banner">
			<div className="container">
				<div className="row gy-5">
					{
						props?.data?.data?.map((elm, i) =>
							<div className="col" key={i}>
								<TypeLink to={elm?.url}>
									<img src={elm?.img} alt={elm?.title}/>
									<div className="text-center pt-3">
										<span className="underline bold">{elm?.title}</span>
									</div>
								</TypeLink>
							</div>
						)
					}
				</div>
			</div>
		</div>
	)
}

export default homeBrands
