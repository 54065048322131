import React from "react";
import TypeLink from './../../global/link'

function Banner({width, url, image}) {
	return (
		<div className={`scl-product _${width}`}>
			<TypeLink to={url}>
				<span className="banner w-100">
					<img src={image} alt={url}/>
				</span>
			</TypeLink>
		</div>
	)
}

export default Banner;
