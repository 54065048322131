import React, { createContext, Component } from 'react'
import axios from 'axios'
import config from './../package/config'
import i18next from 'i18next'

import Swal from 'sweetalert2'
import Toast from './../package/swal-init'

export const BasketContext = createContext()

class BasketContextProvider extends Component {
	constructor( props ) {
		super( props )
		this.state = {
			basketData: null,
			basketCount: 0,
			discountTotal: 0,
			subTotal: 0,
			totalPrice: 0,
			auth: localStorage.getItem("auth") ? true : false,
			notInclude: [],
			choicesExtra: [],
			coupons: [],
			installment: 0,
			binNumber: null,
			utmCode: null,
			hasCod: false,
			isGift: false,
			countryID: null,
			cargoID: null,
		}
	}

	componentDidMount(){
		const utm = JSON.parse(localStorage.getItem('utm'))
		this.setState({ utmCode: utm })
		if(this.state.auth && window.location.pathname !== "/sepet"){
				this.updateBasketCount()
		}else{
			this.updateBasket()
		}
	}

	firstUpdateBasket = () => {
		if(this.state.basketCount > (this.state.basketData?.items?.length ?? 0) || !this.state.basketData?.items){
			this.updateBasket()
		}
	}

	updateBasketCount = () => {
		if(!this.state.basketData?.items?.length){
			axios.get("/GetBasketCount")
			.then(res => {
				this.setState({basketCount: res.data?.data?.itemcount})
			})
		}
	}

	updateBasket = (wallet = null) => {
		let options = {headers: {'Content-Type': 'application/json'}}
		let basket = localStorage.getItem("localBasket")

		if(basket !== "undefined"){
			basket = JSON.parse(basket) ?? []
		}else{
			const auth = localStorage.removeItem("localBasket")
			basket = []
		}

		let data = []
		const auth = localStorage.getItem("auth")
		if(auth){
			localStorage.removeItem("localBasket")
			data = basket ?? []
			this.setState({auth: true})
		}else{
			this.setState({auth: false})
		}

		if(basket && !auth){
			data = basket.map(elm => {
				const index = this.state.choicesExtra.map(ext => {
					return ext.VariantCode
				}).indexOf(elm.variantCode)
				if(index > -1){
					return this.state.choicesExtra[index]
				}else{
					return elm
				}
			})
		}

		if(this.state.choicesExtra.length > 0 && auth){
			data = this.state.choicesExtra
		}

		if(this.state.notInclude.length > 0){
			options = {
				...options,
				headers: {
					...options.headers,
					"variantcodes": this.state.notInclude.join(),
				}
			}
		}

		if(this.state.binNumber?.length === 6){
			options = {
				...options,
				headers: {
					...options.headers,
					installment: this.state.installment,
					binnumber: this.state.binNumber
				}
			}
		}

		if(this.state.coupons?.length > 0 && auth){
			options = {
				...options,
				headers: {
					...options.headers,
					"couponcode": this.state.coupons[0],
				}
			}
		}

		if(this.state.hasCod){
			options = {
				...options,
				headers: {
					...options.headers,
					"hascod": this.state.hasCod,
				}
			}
		}

		if(this.state.isGift){
			options = {
				...options,
				headers: {
					...options.headers,
					"isgift": this.state.isGift,
				}
			}
		}

		if(this.state.countryID){
			options = {
				...options,
				headers: {
					...options.headers,
					"countrycode": this.state.countryID,
				}
			}
		}

		if(this.state.cargoID){
			options = {
				...options,
				headers: {
					...options.headers,
					"cargoID": this.state.cargoID,
				}
			}
		}

		if(wallet && wallet.length > 7) {
			options = {
				...options,
				headers: {
					...options.headers,
					"walletCode": wallet,
				}
			}
		}

		if(auth || data.length > 0){
			const request = axios.post("/GetBasket", data, options)
			const promise = request.then((res) => res)
			promise.then(res => {
				if(res?.data?.isSuccess){
					if(res?.data?.data?.items){
						this.setState({basketData: res.data.data})
						this.setState({basketCount: res.data.data.items.length})
						const stockControl = res.data.data.items.filter(e => e.detail.maxStock <= 0).filter(e => this.state.notInclude.indexOf(e.variantCode) < 0).map(e => e.variantCode)
						this.setState({notInclude: [...this.state.notInclude, ...stockControl]})

						this.setState({totalPrice: res.data.data.totalPrice, subTotal: res.data.data.subTotal, discountTotal: res.data.data.discount})
						const newBasket = this.state.basketData?.items?.map(e => {return {variantCode: e.variantCode, productColorCode: e.productColorCode, quantity: e.quantity, utm: e.utm, promotional: e.promotional}})
						if(!this.state.auth){
							localStorage.setItem("localBasket", JSON.stringify(newBasket))
						}
					}else{
						this.setState({basketData: {items: []}})
						this.setState({basketCount: 0})
						localStorage.removeItem("localBasket")

					}
				}
			})

			return promise
		}else{
			this.setState({basketData: {items: []}})
			this.setState({basketCount: 0})
			return new Promise((resolve, reject) => {resolve()}).then(e => e)
		}
	}

	addBasket = (data) => {
		delete data["datetime"]
		if(this.state?.utmCode?.code){
			data = {...data, utm: this.state?.utmCode?.code}
		}

		if(this.state.auth){
			const req = axios.post("/AddToBasket", data)
			const promise = req.then(res => res)

			return promise.then(res => {
				if(res?.data?.isSuccess){
					return this.updateBasket()
				}else{
					return res
				}
			})
		} else {
			const req = new Promise((resolve, reject) => {
				let basket = JSON.parse(localStorage.getItem("localBasket"))
				if(!basket) {
					basket = []
					localStorage.removeItem('localBasket')
				}
				const index = basket.map(function(e) {
					return e.variantCode
				}).indexOf(data.variantCode)
				let newBasket = []

				if(index > -1){
					let product = basket[index]
					product = { ...product, quantity: (product.quantity + data.quantity) }
					for(let i=0; i < basket.length; i++){
						if(i !== index){
							newBasket = [...newBasket, basket[i]]
						}else{
							newBasket = [...newBasket, product]
						}
					}
				}else{
					newBasket = [data, ...basket]
				}

				localStorage.setItem("localBasket", JSON.stringify(newBasket))

				return this.updateBasket().then(e => {
					resolve(e)
				})
			})
			return req.then(e => e)
		}
	}

	removeBasket = (data) => {
		if(this.state.auth){
			const req = axios.post("/RemoveFromBasket", data)
			const promise = req.then(e => e)
			return promise.then(res => {
				if(res?.data?.isSuccess){
					return this.updateBasket()
				}else{
					return res
				}
			})
		}else{
			const req = new Promise((resolve, reject) => {
				let basket = JSON.parse(localStorage.getItem("localBasket"))

				const index = basket.map(e => {
					return e.variantCode
				}).indexOf(data.variantCode)

				if(index > -1){
					let product = basket[index]
					const newQuantity = data.quantity <= 0 ? 0 : product.quantity - data.quantity
					product = {...product, quantity: newQuantity}
					let newBasket = []
					for(let i=0; i < basket.length; i++){
						if(i !== index){
							newBasket = [...newBasket, basket[i]]
						}else{
							if(newQuantity > 0){
								newBasket = [...newBasket, product]
							}
						}
					}

					localStorage.setItem("localBasket", JSON.stringify(newBasket))
					if(newBasket.length <= 0){
						this.setState({basketData: null, basketCount: 0})
					}

					return this.updateBasket().then(e => {
						resolve()
					})

					resolve()
				}else{
					reject()
				}
			})
			const promise = req.then(e => e)

			return promise
		}
	}

	isBasket = ({VariantCode, ProductColorCode}) => {
		if(!this.state.basketData) {
			 return false
		}
		if(!('items' in this.state.basketData)) {
			 return false
		}
		for (let i = 0; i < this.state.basketData.items.length; i++) {
			if(this.state.basketData.items[i].variantCode === VariantCode){
				return true
			}
		}
		return false
	}

	clearBasket = () => {
		localStorage.removeItem("basket")
		this.setState({
			basketData: {items: []},
			basketCount: 0,
			totalPrice: 0,
			auth: false
		})
	}

	setAuth = (value) => {
		this.setState({auth: value})
	}

	addNotInclude = async (VariantCode) => {
		const index = this.state.notInclude.indexOf(VariantCode)
		if(index < 0){
			await this.setState({notInclude: [...this.state.notInclude, VariantCode]})
		}else{
			let notInclude = this.state.notInclude
			notInclude.splice(index, 1)
			await this.setState({notInclude: notInclude})
		}
		this.updateBasket()
	}

	isNotInclude = (VariantCode) => {
		return this.state.notInclude.indexOf(VariantCode) < 0
	}

	setInstallment = value => {
		this.setState({installment: value})
	}

	setBinNumber = value => {
		this.setState({binNumber: value})
	}

	setStateBasket = (name, value, update = false) => {
		this.setState({[name]: value}, () => {
			if(update){
				this.updateBasket()
			}
		})
	}

	addChoicesExtra = async (data) => {
		let choicesExtra = this.state.choicesExtra
		.map(elm => {
			if(data.ExtraCampaign === elm.ExtraCampaign){
				return {...elm, LastChoice: false}
			}
			return elm
		})

		const index = choicesExtra
		.map(elm => {
			return elm.VariantCode
		}).indexOf(data.VariantCode)

		if(index >= 0){
			choicesExtra.splice(index, 1)
			await this.setState({choicesExtra: choicesExtra})
		}
		await this.setState({choicesExtra: [...choicesExtra, data]})
		this.updateBasket()
	}

	addCoupon = code => {
		const { coupons } = this.state
		if(coupons.indexOf(code) <= -1){
			const req = axios.get('/CheckCoupon', {headers: {couponcode: code}})
			const promise = req.then(res => res)

			promise.then(res => {
				if(res?.data?.isSuccess ){
					const newData = [...coupons, code]
					this.setState({coupons: newData}, () => this.updateBasket())
				}
			})

			return promise
		}
	}

	removeCoupon = code => {
		const newData = this.state.coupons.filter(e => e != code)
		this.setState({coupons: newData}, () => this.updateBasket())
	}

	setUtmCode = code => {
		this.setState({ utmCode: code })
		localStorage.setItem("utm", JSON.stringify(code))
	}

	render() {
		return (
			<BasketContext.Provider value={{
					...this.state,
					updateBasket: this.updateBasket,
					addBasket: this.addBasket,
					removeBasket: this.removeBasket,
					isBasket: this.isBasket,
					clearBasket: this.clearBasket,
					setAuth: this.setAuth,
					addNotInclude: this.addNotInclude,
					isNotInclude: this.isNotInclude,
					choicesExtra: this.choicesExtra,
					addChoicesExtra: this.addChoicesExtra,
					addBasketNotification: this.addBasketNotification,
					removeBasketNotification: this.removeBasketNotification,
					firstUpdateBasket: this.firstUpdateBasket,
					setBinNumber: this.setBinNumber,
					setInstallment: this.setInstallment,
					addCoupon: this.addCoupon,
					removeCoupon: this.removeCoupon,
					setUtmCode: this.setUtmCode,
					setStateBasket: this.setStateBasket,
				}}>
				{this.props.children}
			</BasketContext.Provider>
		)
	}
}

export default BasketContextProvider
