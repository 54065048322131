import React, { Component, createContext } from 'react';
import axios from 'axios'
import moment from 'moment'
import {timeConvert} from './../package/global-function'
import i18next from 'i18next';

export const LanguageContext = createContext();

class LanguageContextProvider extends Component {

	constructor (props){
		super( props )

		this.state = {
			client: {},
			data: {language: [], country: [], currency: []},
			userChoices: {choicesCountry: null, choicesCurrency: null, choicesLanguage: null},
			menu: [],
			welcome: false
		}
	}

	componentWillMount(){
		//this.updateMenu()
	}

	componentDidMount(){
		this.updateClient()
		this.updateCompanyActivity()

		let welcome = true
		if(localStorage.getItem("w")) {
			welcome = false
		}
		this.setState({welcome: welcome})
		localStorage.setItem("w", 1)
	}

	updateClient = async () => {
		if(!localStorage.getItem("userChoices")){
			axios.get('/GetClientInfo')
			.then(res => {
				this.setState({client: res.data.data, userChoices: {choicesCountry: res.data.data.Country, choicesCurrency: res.data.data.Currency, choicesLanguage: res.data.data.LangCode }}, this.choicesDataChange)
			})
		} else {
			const userChoices = JSON.parse(localStorage.getItem("userChoices"))
			//this.setState({client: JSON.parse(localStorage.getItem("client"))})
			this.setState({userChoices: {choicesCountry: userChoices.choicesCountry, choicesCurrency: userChoices.choicesCurrency, choicesLanguage: userChoices.choicesLanguage }}, this.choicesDataChange)
		}
	}

	choicesDataChange = () => {
		const choicesData =  this.state.userChoices
		axios.defaults.headers.common['settings'] = JSON.stringify({Country: choicesData.choicesCountry, Currency: choicesData.choicesCurrency, LangCode: choicesData.choicesLanguage })
		localStorage.setItem("userChoices", JSON.stringify(this.state.userChoices))
		i18next.changeLanguage(choicesData.choicesLanguage)
		console.log(choicesData, i18next.language)
	}

	clientDataChange = () => {
		let client = this.state.client
		const choicesData =  this.state.userChoices

		if(choicesData){
			this.setState({ userChoices: choicesData })
			if(choicesData?.choicesCountry && choicesData?.choicesCountry?.code){
				client.CountryCode = choicesData.choicesCountry.code
			}
			if(choicesData?.choicesCurrency && choicesData?.choicesCurrency?.id){
				client.Currency = choicesData.choicesCurrency.id.toString()
			}
			if(choicesData?.choicesLanguage && choicesData?.choicesLanguage?.code){
				client.LangCode = choicesData.choicesLanguage.code
				i18next.changeLanguage(choicesData.choicesLanguage.code)
			}
		}

		localStorage.setItem("client",  JSON.stringify(client))
		//axios.defaults.headers.common['settings'] = JSON.stringify(client)

	}

	/*updateMenu = () => {
		const menu = sessionStorage.getItem("menu")
		this.setState({menu: JSON.parse(menu)})
		if(!menu){
			axios.get('/GetMenu').then(res => {
				this.setState({menu: res?.data?.data || []})
				//sessionStorage.setItem("menu", JSON.stringify(res?.data?.data))
			});
		}
	}*/

	updateCompanyActivity = async () => {
		let data
		try {
			//data = JSON.parse(sessionStorage.getItem("activity"))
		} catch{}

		if(!data){
			await axios.get("/GetAllGeoData")
			.then(res => {
				data = {country: res.data.data.countries, currency: res.data.data.currencies, language: res.data.data.languages}
			})
		}else{
			if(!data?.currency?.length){
				await axios.get("/GetCurrencies")
				.then(res => {
					data = {...data, currency: res.data.data}
				})
			}

			if(!data?.language?.length){
				await axios.get("/GetLanguages")
				.then(res => {
					data = {...data, language: res.data.data}
				})
			}

			if(!data?.country?.length){
				await axios.get("/GetCountries")
				.then(res => {
					data = {...data, country: res.data.data}
				})
			}
		}

		this.setState({data: data})
		//sessionStorage.setItem("activity", JSON.stringify(data))
	}

	syncChoices = () => {
		const userChoices = this.state.userChoices
		const data = this.state.data
		const client = this.state.client
		let newChoices = userChoices
		let newClient = client

		if(!userChoices.choicesCountry){
			const index = data.country.map(e => e.code.toString()).indexOf(client.CountryCode)
			if(index > -1){
				newChoices = {...newChoices, choicesCountry: data.country[index]}
			}
		}else{
			newClient = {...newClient, CountryCode: userChoices.choicesCountry.code}
		}

		if(!userChoices.choicesLanguage){
			const index = data.language.map(e => e.code.toString()).indexOf(client.LangCode)
			if(index > -1){
				newChoices = {...newChoices, choicesLanguage: data.language[index]}
			}
		}else{
			newClient = {...newClient, LangCode: userChoices.choicesLanguage.code}
		}

		if(userChoices.choicesCountry){
			const index = data.currency.map(e => e.id).indexOf(newChoices.choicesCountry.currencyid)
			if(index > -1){
				newChoices = {...newChoices, choicesCurrency:  data.currency[index]}
				newClient = {...newClient, Currency:  data.currency[index]}
			}
		}

		localStorage.setItem("userChoices", JSON.stringify(newChoices))
		localStorage.setItem("client", JSON.stringify(newClient))
		//axios.defaults.headers.common['settings'] = JSON.stringify(client)
		this.setState({userChoices: newChoices}, this.clientDataChange)
		this.setState({client: newClient})
	}

	changeLanguage = (value, refresh=true) => {
		this.setState({ userChoices: {...this.state.userChoices, choicesLanguage: value}}, () => {
			//localStorage.setItem("client", JSON.stringify({...JSON.parse(localStorage.getItem("client")), LangCode: value.code}))
			//if(refresh){
				//window.location.reload();
				this.choicesDataChange()
				//this.updateMenu()
			//}
		});
	}

	changeCountry = (value) => {
		this.setState({ userChoices: {...this.state.userChoices, choicesCountry: value }}, () => {
			//localStorage.setItem("client", JSON.stringify({...JSON.parse(localStorage.getItem("client")), CountryCode: value.code}))
			//this.changeCurrency(this.findCurrency(value.currencyid))
			this.choicesDataChange()
		});
	}

	changeCurrency = (value) => {
		this.setState({ userChoices: {...this.state.userChoices, choicesCurrency: value}}, () => {
			//localStorage.setItem("client", JSON.stringify({...JSON.parse(localStorage.getItem("client")), Currency: value.id.toString()}))
			this.choicesDataChange()
		});
	}

	priceDiffirence = (data) => {
		let one=0, two=0, three=0
		if(data.length > 2){
			three = data[2]
		}
		if(data.length > 1){
			two = data[1]
		}
		one = data[0]

		if(three > 0){
			return [one, three]
		}else{
			if(two > 0 && (one !== two)){
				return [one, two]
			}else{
				return [one]
			}
		}
	}

	toPriceArr = (data, toFixed = 2) => {
			let price = data

			if(!Array.isArray(price)){
				price = [price]
			}

			price = price.map(elm => {
				if(typeof(elm) !== typeof(0)){
					return parseInt(elm)
				}
				return elm
			})

			price = this.priceDiffirence(price)

			return price = price.map(x => x.toFixed(toFixed))
	}

	toPrice = (data, toFixed = 2) => {
			let price = data

			if(!Array.isArray(price)){
				price = [price]
			}

			price = price.map(elm => {
				if(typeof(elm) !== typeof(0)){
					return parseInt(elm)
				}
				return elm
			})

			price = this.priceDiffirence(price)

			if(price.length === 1){
				if(price[0] === 0){
					return 0
				}
				return price[0].toFixed(toFixed)
			}

			return price[1].toFixed(toFixed)
	}

	toPriceConvert = (data, sign = false, toFixed = 2, html = true) => {
			let price = data

			let currency = this.getChoicesCurrency()
			let unit
			if(sign){
				unit = currency?.sign
			}else{
				unit = currency?.name
			}

			if(!Array.isArray(price)){
				price = [price]
			}

			price = price.map(elm => {
				if(typeof(elm) !== typeof(0)){
					return parseInt(elm)
				}
				return elm
			})

			price = this.priceDiffirence(price)
			if(price.length === 1){
				if(html){
					if(price[0] === 0){
						return <><span>Ücretsiz</span></>
					}
					return (<><span>{price[0].toFixed(toFixed)} {unit}</span></>)
				}else{
					if(price[0] === 0){
						return "Ücretsiz"
					}
					return (<><span>{price[0].toFixed(toFixed)} {unit}</span></>)
				}
			}

			//if(!sezon) {
				return (
					<>
						<span className="old">{price[0].toFixed(toFixed)} {unit}</span><span>{price[1].toFixed(toFixed)} {unit}</span>
					</>
				)
			//}

			/*return (
				<>
					<div className="amount d-flex flex-column align-items-center justify-content-center">
						<span>NET</span>
						<span>%{(100 - (price[1] * 100 / price[0])).toFixed(0)}</span>
					</div>
					<div className="prices d-flex flex-column align-items-center justify-content-center">
						<span className="priced default">{price[0].toFixed(toFixed)} {unit}</span>
						<span className="priced">{price[1].toFixed(toFixed)} {unit}</span>
					</div>
				</>
			)*/
	}

	getLanguage = () => {
		if(this.state.data){
			return this.state.data.language
		}
		return []
	}

	getCountries = () => {
		if(this.state.data){
			if('country' in this.state.data){
				return this.state.data.country.filter(e => e.code === "TR")
			}
		}
		return []
	}

	getCurrency = () => {
		if(this.state.data){
			return this.state.data.currency
		}
		return []
	}

	getChoicesLanguage = (value) => {
			if('choicesLanguage' in this.state.userChoices){
				return this.state.userChoices.choicesLanguage
			}
			return undefined
	}

	getChoicesCountry = () => {
		if('choicesCountry' in this.state.userChoices){
			return this.state.userChoices.choicesCountry
		}
		return undefined
	}

	getChoicesCurrency = () => {
		const defaultCurrency = {name: 'TL', id: 1, sign: "₺"}
		try{
			if('choicesCurrency' in this.state.userChoices) {
				for(let i in this.state.data.currency)	{
				  if(this.state.data.currency[i] && this.state.data.currency[i].id === parseInt(this.state.userChoices.choicesCurrency)) {
						return this.state.data.currency[i]
					}
				}
				return this.state.userChoices.choicesCurrency ?? defaultCurrency
			}else{
				return defaultCurrency
			}
		}catch{
			return defaultCurrency
		}
	}

	findLanguage = (value) => {
		if('choicesLanguage' in this.state.userChoices){
			const data = this.getLanguage()
			let index = data.map(elm => { return elm.code })
			if(index < 0){
				return data[0]
			}
			return data[index]
		}
	}

	findCountry = (value) => {
		if('choicesCountry' in this.state.userChoices){
			const data = this.getCountries()
			let index = data.map(elm => { return elm.code }).indexOf(value)
			if(index < 0){
				return data[0]
			}
			return data[index]
		}
	}

	findCurrency = (value) => {
		if('choicesCurrency' in this.state.userChoices){
			const data = this.getCurrency()
			let index = data.map(elm => { return elm.id }).indexOf(value)
			if(index < 0){
				return data[0]
			}
			return data[index]
		}
	}

	getCurrencyID = (value, option='id', returnOption=undefined) => {
		if(this.state.data){
			const index = this.state.data.currency.map(e => {
				return e[option]
			}).indexOf(value)
			if(index > -1){
				if(returnOption && returnOption in this.state.data.currency[index]){
					return this.state.data.currency[index][returnOption]
				}
				return this.state.data.currency[index]
			}
		}
		return false
	}

	welcomeModalOpen = (value) => {
		this.setState({welcome: value})
	}

	render() {
		return (
			<LanguageContext.Provider value={{
					...this.state,
					getLanguage: this.getLanguage,
					getCountries: this.getCountries,
					getCurrency: this.getCurrency,
					getCurrencyID: this.getCurrencyID,
					getChoicesLanguage: this.getChoicesLanguage,
					getChoicesCountry: this.getChoicesCountry,
					getChoicesCurrency: this.getChoicesCurrency,
					changeLanguage: this.changeLanguage,
					changeCountry: this.changeCountry,
					changeCurrency: this.changeCurrency,
					toPriceConvert: this.toPriceConvert,
					toPrice: this.toPrice,
					toPriceArr: this.toPriceArr,
					userChoices: this.state.userChoices,
					welcome: this.state.welcome,
					welcomeModalOpen: this.welcomeModalOpen
				}}>
				{this.props.children}
			</LanguageContext.Provider>
		);
	}
}

export default LanguageContextProvider;
