import React, { useState, useEffect, useRef } from "react";
import axios from 'axios'
import Toast from './../../../package/swal-init'
import { gaView } from './../../../package/google-analytic'
import { visitTag } from './../../../package/criteo'

import { useTranslation } from 'react-i18next'

import Input from './../forms/input'
import Button from './../forms/button'
import * as Icons from './../../icons/index'

function NewPassword(props) {

	const { t } = useTranslation();

	const [oldPassword, setOldPassword] = useState("")
	const [newPassword, setNewPassword] = useState("")
	const [newPasswordAgain, setNewPasswordAgain] = useState("")
	const [errors, setErrors] = useState(null)
	const isFirstRun = useRef(true)

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false
			gaView()
			visitTag()
		}
	}, [props])

	const updateUserDetail = () => {
		setErrors(null)
		const forms = [newPassword, newPasswordAgain, oldPassword]

		if(forms.length !== forms.filter(e => e.length > 0).length){
			setErrors("Tüm alanları doldurunuz")
			return false
		}

		if(forms.length !== forms.filter(e => e.length > 7).length){
			setErrors("Şifre en az 8 karakter olmalıdır")
			return false
		}

		if(newPassword !== newPasswordAgain){
			setErrors("Yeni şifreler birbiriyle eşleşmiyor")
			return false
		}

		const data = {
			"oldPassword": oldPassword,
			"newPassword": newPassword,
			"newPasswordAgain": newPasswordAgain
		}

		axios.post("/ResetPassword", data)
		.then(res => {
			if(res?.data?.isSuccess){
				Toast.fire({
					icon: 'success',
					title: t('UserPage.new-password.update')
				})
				setOldPassword("")
				setNewPassword("")
				setNewPasswordAgain("")
			}else{
				setErrors(res.data.error)
			}
		})
	}

	return (
		<>
			<div className="scl-content-title">
				<h1 className="base-regular">{t('UserPage.new-password.title')}</h1>
			</div>
			<div className="scl-section-content">
				<form>
					<div className="row">
						<div className="col-12 col-lg-7 pr-5">
							{
								errors &&
								<div className="errorMessage">
									<Icons.CloseCircle />
									{errors}
								</div>
							}
							<Input title={t('UserPage.new-password.old')} placeholder={t('UserPage.new-password.old')} name="oldPassword" type="password" autoComplete="current-password" value={oldPassword} onChange={e => {setOldPassword(e.target.value)}}/>
							<Input title={t('UserPage.new-password.new')} placeholder={t('UserPage.new-password.new')} name="newPassword" type="password" autoComplete="new-password" value={newPassword} onChange={e => {setNewPassword(e.target.value)}}/>
							<Input title={t('UserPage.new-password.new-again')} placeholder={t('UserPage.new-password.new-again')} name="newPasswordAgain" type="password" autoComplete="new-password" value={newPasswordAgain} onChange={e => {setNewPasswordAgain(e.target.value)}}/>
							<Button type="button" title={t('Global.update')} className="btn float-right" onClick={updateUserDetail}/>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}

export default NewPassword;
