import React, {
	Component
}
from 'react'
import $ from "jquery";
import { rePosition } from './../../package/global-function'

import Details from './../pages/details'

class ModalLogin extends Component {
	constructor( props ) {
		super( props )

		this.state = {
			data: props.data
		}
	}

	componentDidMount(){
		rePosition("#s_" + this.state.data.code, 500);
	}

	modalClose(code){
		$("#s_" + code).hide().removeClass("show");
		$("body").removeClass('modal-open');
		$(".modal-backdrop").remove();
	}

	render() {
		return (
			<>
				<div className="modal fade quickModal" id={"s_" + this.state.data.code} aria-labelledby="exampleModalLabel" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg scl-modal">
						<div className="modal-content">
							<div className="scl-modal-close" onClick={() => this.modalClose(this.state.data.code)}>×</div>
							<div className="modal-body">
								<Details {...this.props} quick={true} url={this.state.data.url} modalClose={() => this.modalClose(this.state.data.code)} />
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

export default ModalLogin
