import React, { useState, useEffect, useContext } from "react";
import {Link} from 'react-router-dom';
import axios from 'axios'
import * as Icons from '../../icons/index'
import queryString from 'query-string'
import { gaPurchase } from './../../../package/google-analytic'
import { salesTag } from './../../../package/criteo'

import { useTranslation } from 'react-i18next';
import { AuthContext } from "./../../../context/AuthContext"
import { BasketContext } from "./../../../context/BasketContext"
import { LanguageContext } from "./../../../context/LanguageContext"

import BasketOrderInfo from "./../../../rComponents/global/basketOrderInfo"

import Button from "./../forms/button"
import Checkbox from "./../forms/checkbox"

import BasketStepsBar from "./basket-steps-bar"

import Loading from "./../global/loading"

function BasketSuccess(props){

	const { t } = useTranslation()

	const { isAuth } = useContext(AuthContext)
	const { updateBasket } = useContext(BasketContext)
	const { toPriceConvert } = useContext(LanguageContext)

	const [ step, setStep ] = useState(props.step)
	const [ success, setSuccess ] = useState(null)

	useEffect(() => {
		localStorage.removeItem('localBasket')
		const gaData = JSON.parse(sessionStorage.getItem('ga'))
		if(gaData){
			sessionStorage.removeItem('ga')
			salesTag({id: gaData.purchase.id, item: gaData.items.map(e => {return {id: e.colorCode, price: e.price, quantity: e.quantity}})})
			gaPurchase(gaData)
		}

		const parsed = queryString.parse(props.location.search)
		axios.get('/GetOrderStatus', {headers:{code: parsed.success}})
		.then(res => {
			if(res.data?.data?.orderStatus?.id === 1 || res.data?.data?.orderStatus?.id === 9){
				setSuccess(res?.data?.data)
			}else{
				props.history.push("/")
			}
		})
	}, [props.step])

	useEffect(() => {
		if(success){
			updateBasket()
		}
	}, [success])

	return (
		<div className="basket-success">
		<div className="container">
			{
				!success &&
				<Loading />
			}
			{
				success &&
				<>
					<div className="row mb-4 justify-content-center">
						<div className="col-12 col-lg-8 p-4 bgw">
							<div className="row success">
								<Icons.TickBold />
								<h1>{t('Basket.payment-success.title')}</h1>
								<BasketOrderInfo data={{totalPrice: toPriceConvert(success.orderTotalDiscountedPrice), ...success}} />
								{
									isAuth &&
									<span>{t('Basket.payment-success.text')}</span>
								}
								{
									isAuth &&
									<span><Link to="/uye/siparislerim" className="btn">{t('Basket.payment-success.orders-btn')}</Link></span>
								}
								{
									!isAuth &&
									<span><Link to="/" className="btn">{t('Basket.payment-success.go-home')}</Link></span>
								}
							</div>
						</div>
					</div>
				</>
			}
			</div>
		</div>
	);
}

export default BasketSuccess;
