import React from 'react'
import TypeLink from './../global/link'
import SwiperCore, { Navigation, Pagination, Scrollbar, Lazy } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

SwiperCore.use([Navigation, Pagination, Lazy]);

function HomeHashtag(props) {
	return (
		<div className={`banner ${props?.className ?? ''}`}>
			<div className="container">
				<div className="row">
					<div className="col">
						<div className="row" {...props.props}>
							<div className="col">
								<Swiper slidesPerView={1} spaceBetween={10} lazy={true} pagination={{ clickable: true }}>
									{
										props.data.data.map((elm, i) =>
											<SwiperSlide key={i}>
												<TypeLink to={elm.url}>
													<img src={elm.img} className="d-block w-100" alt={elm.title} loading="lazy"/>
												</TypeLink>
											</SwiperSlide>
										)
									}
								</Swiper>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default HomeHashtag
