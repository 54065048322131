import React, { useState, useEffect } from "react";
import {Route, Redirect, Link, Switch} from "react-router-dom"
import $ from "jquery";
import { useTranslation } from 'react-i18next';
import config from './../../package/config'

import SeoComponent from './../../rComponents/global/seoComponent'

import OrderInfo from "./../widgets/user-page/order-info"
import Orders from "./../widgets/user-page/orders"
import UserInfo from "./../widgets/user-page/user-info"
import BillingAddress from "./../widgets/user-page/billing-address"
import Favorite from "./../widgets/user-page/favorite"
import Messages from "./../widgets/user-page/messages"
import Coupons from "./../widgets/user-page/coupons"
import Alarm from "./../widgets/user-page/alarm"
import Ibans from "./../widgets/user-page/ibans"
import NewPassword from "./../widgets/user-page/newPassword"
import Breadcrumb from "./../widgets/global/breadcrumb"

function UserPage(props) {

	const {t} = useTranslation()

	const [page, setPage] = useState(props.location.pathname.replace(props.match.path, ''))
	const [path, setPath] = useState(null)
	const [title, setTitle] = useState(null)

	const userMenu = [
		{url: "siparislerim", title: t('UserPage.menu.orders')},
		{url: "", title: t('UserPage.menu.orders')},
		{url: "/", title: t('UserPage.menu.orders')},
		{url: "bilgilerim", title: t('UserPage.menu.user-info')},
		{url: "sifre-degistir", title: t('UserPage.menu.new-password')},
		{url: "adreslerim", title: t('UserPage.menu.address')},
		{url: "mesajlarim", title: t('UserPage.menu.message')},
		{url: "kuponlarim", title: t('UserPage.menu.coupon')},
		{url: "alarmlarim", title: t('UserPage.menu.alarm')},
		{url: "favorilerim", title: t('UserPage.menu.favorite')},
		{url: "ibanlarim", title: t('UserPage.menu.iban')},
	]

	useEffect(() => {
		setPage(props.location.pathname.replace(props.match.path, ''))
		$("html, body, .App").stop().animate({ scrollTop: 0 }, 500);
		setPath(getPage())
	}, [props.location.pathname])

	const getPage = () => {
		const  newMenu = userMenu.map(function(e) {
			return e.url
		})

		const pageParse = page.split("/")?.filter(e => e !== "")
		const index = newMenu.indexOf(pageParse?.[0])
		if(index >= 0){
			setTitle(userMenu[index].title)
			return [{url: `/uye/${userMenu[index].url}`, title: userMenu[index].title}]
		}
		return []
	}

	const seoData = {
		title: title + config.prefix + config.name,
		description: title + config.prefix + config.name
	}

	return (
		<>
			<SeoComponent {...seoData}/>
			{
				path &&
				<Breadcrumb data={[{url: "/uye", title: t('UserPage.user-panel')}, ...path]}/>
			}
			<div id="content" className="user-page pb-5">
				<div className="container">
					<div className="scl-sidebar">
						<ul className="user-panel">
							{
								userMenu.map((elm, i) =>
									elm.url !== "/" && elm.url !== "" &&
									<li key={"up_" + i} className={page?.includes(elm.url) ? 'active' : ''} onClick={() => setPage(`/${elm.url}`)}><Link to={props.match.path + `/${elm.url}`} >{elm.title}</Link></li>
								)
							}
						</ul>
					</div>
					<div className="scl-content">
						<Switch>
							<Route path={props.match.path} exact component={Orders} />
							<Route exact path={props.match.path + "/siparislerim"} component={Orders} />
							<Route path={props.match.path + "/siparislerim/:id"} component={OrderInfo} />
							<Route path={props.match.path + "/bilgilerim"} component={UserInfo} />
							<Route path={props.match.path + "/sifre-degistir"} component={NewPassword} />
							<Route path={props.match.path + "/mesajlarim"} component={Messages} />
							<Route path={props.match.path + "/kuponlarim"} component={Coupons} />
							<Route path={props.match.path + "/adreslerim"} component={BillingAddress} />
							<Route path={props.match.path + "/favorilerim"} component={Favorite} />
							<Route path={props.match.path + "/alarmlarim"} component={Alarm} />
							<Route path={props.match.path + "/ibanlarim"} component={Ibans} />
							<Redirect from={props.match.path + "/*"} to='/404' />
						</Switch>
					</div>
				</div>
				<div className="clearfix"></div>
			</div>
		</>
	)
}

export default UserPage;
