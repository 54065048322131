import React, { useEffect, useState, useContext, useRef } from "react"
import { useHistory } from 'react-router-dom'
import { gaView } from './../../../package/google-analytic'
import { visitTag } from './../../../package/criteo'

import axios from 'axios'
import moment from 'moment'
import * as Icons from './../../icons/index'

import { useTranslation } from 'react-i18next'
import { AuthContext } from './../../../context/AuthContext'

import OrderTicket from "./ticket"
import Loading from './../global/loading'

function Messages(props) {

	const history = useHistory()
	const { t } = useTranslation()
	const { openModal } = useContext(AuthContext)

	const [ data, setData] = useState(null)
	const [ choicesMessage, setChoicesMessage] = useState(null)

	const isFirstRun = useRef(true)

	useEffect(() => {
		if(isFirstRun.current){
			isFirstRun.current = false
			gaView()
			visitTag()

			axios.get("/GetQuestions?page=1&pagesize=10")
			.then(res => {
				if(res?.data?.isSuccess){
					setData(res.data.data ?? [])
				}
			})
		}
	}, [props])

	useEffect(() => {
		findMessage()
	}, [props.location.pathname, data])

	const findMessage = () => {
		const path = props.location.pathname?.split('/');
		const pathLength = path?.length;
		if(pathLength <= 3){
			setChoicesMessage(null)
		}else{
			const id = parseInt(path[pathLength - 1])
			const selected = data?.filter(e => e.id === id)?.[0]
			setChoicesMessage(selected)
		}
	}

	return (
	<>
		{
			choicesMessage &&
			<>
				<div className="scl-content-title">
					<span onClick={() => {
						history.push(`/uye/mesajlarim`)
					}} className="btn user-title-btn base-regular light">
						<Icons.ArrowToLeft height=".8em"/> {t('UserPage.order-info.go-back')}
					</span>
				</div>
				<div className="row mt-4">
					<div className="col-3">
						<strong>Sorunuz :</strong>
					</div>
					<div className="col-9">
						{choicesMessage.question}
					</div>
				</div>
				<div className="row mt-4">
					<div className="col col-lg-6">
						<p>{(choicesMessage.answer ? choicesMessage.answer : t('UserPage.message.wait'))}</p>
					</div>
				</div>
			</>
		}
		{
			!choicesMessage &&
			<>
			<div className="scl-content-title">
				<h1 className="base-regular">{t('UserPage.message.title').toUpperCase()}
					<span className="btn user-title-btn float-right base-regular" onClick={() => openModal('question')}><Icons.PlusBold /> {t('UserPage.message.non-btn')}</span>
				</h1>

			</div>
			<div className="scl-section-content list">
				{
					!data ?
						<Loading />
					:
					data.length <= 0 ? (
						<div className="basket-empty base-light order-empty">
							<Icons.NonBasket />
							<span>{t('UserPage.message.non')}</span>
							<a title={t('UserPage.message.non-btn')} className="btn base-regular" onClick={() => openModal('question')}>{t('UserPage.message.non-btn')}</a>
						</div>
					) : (
						<>
						<div className="row g-0 head">
							<div className="col-6 col-lg-5">{t('UserPage.message.subject')}</div>
							<div className="col-6 col-lg-5">{t('UserPage.message.state')}</div>
							<div className="col-lg-2 d-none d-lg-block">{t('UserPage.message.date')}</div>
						</div>
						{
							data.length > 0 && data.map((elm, i) =>
								<div key={i} className="row g-2 g-lg-0 m-0 pt-lg-3 pb-lg-3 pointer" onClick={() => {
									history.push(`/uye/mesajlarim/${elm.id}`)
								}}>
									<div className="col-6 col-lg-5 head"><strong>{elm.category}</strong><br/>{elm.topic}</div>
									<div className="col-6 col-lg-5 head">{(elm?.answer ? t('UserPage.message.complete') : t('UserPage.message.wait'))}</div>
									<div className="col-6 col-lg-2 head">{moment(elm.datetime).format('DD/MM/YYYY')}</div>
									<div className="col d-block d-lg-none">
										<div className="go-detail">
											<Icons.ArrowRight height=".8em"/>
										</div>
									</div>
								</div>
							)
						}
						</>
					)
				}
			</div>
			<OrderTicket />
			</>
		}
	</>
	);
}

export default Messages;
