import React, { useState, useEffect } from 'react'
import styles from './tooltipPlusButton.module.css'
import * as Icons from './../../components/icons'

function TooltipPlusButton(props) {

	const [show, setShow] = useState(false)
	const [counter, setCounter] = useState(false)

	useEffect(() => {
		if(show && counter !== props.control.get){
			setShow(false)
		}
	}, [props.control])

	useEffect(() => {
		if(show){
			setCounter(props.control.get + 1)
			props.control.set(props.control.get + 1)
		}
	}, [show])

	return (
		<div className={styles.area} style={props?.data?.position}>
			<span className={styles.button} onClick={() => setShow(!show)}><span><Icons.PlusBold /></span></span>
			{
				show &&
				<div className={`${styles.text} ${styles.[(props.direction ?? "right")]}`}>

				<h3 className={styles.title}>{props?.data?.title} <span className={styles.close} onClick={() => setShow(false)}><span><Icons.Close /></span></span></h3>
				{props?.data?.text}
				</div>
			}
		</div>
	)
}

export default TooltipPlusButton
