import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import $ from "jquery";
import * as Icons from './../icons/index'

import { rePosition } from './../../package/global-function'

import TooltipPlusButton from './../../rComponents/global/tooltipPlusButton'

function SizeChart( props ) {

	const galaxiMessage = "GALA-Xi markası beden tablosu, büyük beden formatında yeniden ölçeklendirilmiştir."
	const bodyContent = {
		bel: "Bel ölçüsü için belinizin sağ ve sol kıvrım hattını belirledikten sonra mezuranızla bu iki noktayı sarmalı ve belinizin çevre uzunluğuna ulaşmalısınız.",
		kol: "Kol dirsekten hafifçe kırılmış durumdayken, omuz başından bilek kemiğine kadar mezura yardımıyla kol ölçünüzü alabilirsiniz.",
		basen: "Basen ölçünüzü elde etmek için ise ayakta dik bir şekilde durup ayaklarınızı birleştirdikten sonra mezurayı basen bölgenizi en kalın noktasından diğer noktasına kadar sarmalı ve birleşme noktasındaki ölçüyü bulmalısınız.",
		bacak: "Her iki bacağınızı iki yana açmadan dik pozisyonda ayakta durunuz, mezurayı pelvis kemiğinden ayak tabanına kadar düz bir hatta tutarak bacak boyu ölçünüzü alabilirsiniz.",
		gogus: "Göğüs ölçüsü alırken mezurayı koltuk altınızdan geçirerek ğöğsün en uç noktasından birleştirmelisiniz. Arkadan düz bir çizgi halinde tuttuğunuz mezurayı önde hafif yukarıda, göğüslerin üstünden geçecek biçimde birleştirerek ölçünüzü alabilirsiniz.",
	}
	const { t } = useTranslation()

	const [page, setPage] = useState(2)
	const [factor, setFaktor] = useState(1)
	const [counter, setCounter] = useState(1)

	const isFirstRun = useRef(true)

	useEffect(() => {
		if(isFirstRun.current){
			isFirstRun.current = false;
			$(".scl-modal-close").click(function () {
				$(".modal").click();
			});
		}
	}, [props])

	useEffect( () => {
		$(".table tr:not(.active) td:not(.active)").mouseenter(function(){
			var th = $(this).parent().parent();
			th.find("tr").removeClass("hover")
			th.find("td").removeClass("hover")
			th.find("td:not(.not)").removeClass("active")

			$(this).addClass("active")
			$(this).parent().addClass("hover")
			th.find("td[data-group=" + $(this).data("group") + "]").addClass("hover")
		}).mouseleave(function(){
			var th = $(this).parent().parent();
			th.find("tr").removeClass("hover")
			th.find("td").removeClass("hover")
			th.find("td:not(.not)").removeClass("active")
		})
	}, [page])

	const convertInc = (value) => {
		let calc = (value / factor)
		calc = Math.ceil(Math.round(calc * 10)) / 10
		return calc + (factor > 1 ? '"' : '')
	}

	const openTab = (e) => {
		$(e.currentTarget).parent().find(".feature-content").slideToggle(200)
		if($(e.currentTarget).parent().hasClass('active')){
			$(e.currentTarget).parent().removeClass('active')
		}else{
			$(e.currentTarget).parent().addClass('active')
			let offset = $(e.currentTarget).offset()
			let x = $("#sizeChartModal").scrollTop()
			if(offset){
				setTimeout(() => {
					let htmlTop = $("html").scrollTop()
					let toScroll = offset.top
					toScroll += x - htmlTop
					$("#sizeChartModal").stop().animate({ scrollTop: toScroll}, 300);
				}, 350)
			}
		}
	}

	return (
		<div className="modal fade" id="sizeChartModal" aria-labelledby="sizeChartModal" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg scl-modal">
				<div className="modal-content">
					<div className="scl-modal-close">×</div>
					<div className="scl-modal-title">
						<ul className="product-chart list-inline text-center">
							<li className={"list-inline-item" + (page === 1 ? ' active' : '')} onClick={() => setPage(1)}>{t('SizeChart.top')}</li>
							<li className={"list-inline-item" + (page === 2 ? ' active' : '')} onClick={() => setPage(2)}>{t('SizeChart.dress')}</li>
							<li className={"list-inline-item" + (page === 3 ? ' active' : '')} onClick={() => setPage(3)}>{t('SizeChart.bottom')}</li>
							<li className={"list-inline-item" + (page === 4 ? ' active' : '')} onClick={() => setPage(4)}>{t('SizeChart.shoes')}</li>
						</ul>
					</div>
					<div className="modal-body">
						<div className="product-feature">
						{
							page === 1 &&
							<>
								<div className="feature-item">
									<span className="base-bold" onClick={openTab}>{t('SizeChart.title')} <Icons.ArrowDownBold height=".6em"/></span>
									<div className="feature-content">
										<div className="row">
											<div className="col">
												<strong>
													<span onClick={() => setFaktor(1)} className={"pointer" + (factor > 1 ? '' : ' green')}>CM</span>
													<span onClick={() => setFaktor(2.54)} className={"ml-2 pointer" + (factor > 1 ? ' green' : '')}>INCH</span>
												</strong>
												<table className="table">
													<tbody>
														<tr className="active">
															<td colSpan="2">{t('SizeChart.body')}</td>
															<td data-group="1">36</td>
															<td data-group="2">38</td>
															<td data-group="3">40</td>
															<td data-group="4">42</td>
															<td data-group="5">44</td>
															<td data-group="6">46</td>
															<td data-group="7">48</td>
															<td data-group="8">50</td>
														</tr>
														<tr>
															<td className="active not" colSpan="2">{t('SizeChart.chest')}</td>
															<td data-group="1">{convertInc(86)}</td>
															<td data-group="2">{convertInc(90)}</td>
															<td data-group="3">{convertInc(94)}</td>
															<td data-group="4">{convertInc(98)}</td>
															<td data-group="5">{convertInc(102)}</td>
															<td data-group="6">{convertInc(108)}</td>
															<td data-group="7">{convertInc(112)}</td>
															<td data-group="8">{convertInc(116)}</td>
														</tr>
														<tr>
															<td className="active not" colSpan="2">{t('SizeChart.waist')}</td>
															<td data-group="1">{convertInc(66)}</td>
															<td data-group="2">{convertInc(70)}</td>
															<td data-group="3">{convertInc(74)}</td>
															<td data-group="4">{convertInc(78)}</td>
															<td data-group="5">{convertInc(82)}</td>
															<td data-group="6">{convertInc(88)}</td>
															<td data-group="7">{convertInc(92)}</td>
															<td data-group="8">{convertInc(96)}</td>
														</tr>
														<tr>
															<td className="active not" colSpan="2">{t('SizeChart.arm')}</td>
															<td data-group="1">{convertInc(59)}</td>
															<td data-group="2">{convertInc(59)}</td>
															<td data-group="3">{convertInc(60)}</td>
															<td data-group="4">{convertInc(60)}</td>
															<td data-group="5">{convertInc(60)}</td>
															<td data-group="6">{convertInc(60)}</td>
															<td data-group="7">{convertInc(60)}</td>
															<td data-group="8">{convertInc(60)}</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<div className="row mt-4">
											<div className="col">
												<h3>
													BÜYÜK BEDEN <span className="green pl-2">(GALA-Xi)</span>
													<small className="d-block mt-2" disabled>{galaxiMessage}</small>
												</h3>
												<strong>
													<span onClick={() => setFaktor(1)} className={"pointer" + (factor > 1 ? '' : ' green')}>CM</span>
													<span onClick={() => setFaktor(2.54)} className={"ml-2 pointer" + (factor > 1 ? ' green' : '')}>INCH</span>
												</strong>
												<table className="table">
													<tbody>
														<tr className="active">
															<td colSpan="2">{t('SizeChart.body')}</td>
															<td data-group="1">+44</td>
															<td data-group="2">+46</td>
															<td data-group="3">+48</td>
															<td data-group="4">+50</td>
															<td data-group="5">+52</td>
															<td data-group="6">+54</td>
															<td data-group="7">+56</td>
															<td data-group="8">+58</td>
														</tr>
														<tr>
															<td className="active not" colSpan="2">{t('SizeChart.chest')}</td>
															<td data-group="1">{convertInc(105)}</td>
															<td data-group="2">{convertInc(111)}</td>
															<td data-group="3">{convertInc(117)}</td>
															<td data-group="4">{convertInc(123)}</td>
															<td data-group="5">{convertInc(129)}</td>
															<td data-group="6">{convertInc(135)}</td>
															<td data-group="7">{convertInc(141)}</td>
															<td data-group="8">{convertInc(147)}</td>
														</tr>
														<tr>
															<td className="active not" colSpan="2">{t('SizeChart.waist')}</td>
															<td data-group="1">{convertInc(90)}</td>
															<td data-group="2">{convertInc(96)}</td>
															<td data-group="3">{convertInc(102)}</td>
															<td data-group="4">{convertInc(108)}</td>
															<td data-group="5">{convertInc(114)}</td>
															<td data-group="6">{convertInc(120)}</td>
															<td data-group="7">{convertInc(126)}</td>
															<td data-group="8">{convertInc(132)}</td>
														</tr>
														<tr>
															<td className="active not" colSpan="2">{t('SizeChart.arm')}</td>
															<td data-group="1">{convertInc(59)}</td>
															<td data-group="2">{convertInc(59)}</td>
															<td data-group="3">{convertInc(60)}</td>
															<td data-group="4">{convertInc(60)}</td>
															<td data-group="5">{convertInc(60)}</td>
															<td data-group="6">{convertInc(60)}</td>
															<td data-group="7">{convertInc(60)}</td>
															<td data-group="8">{convertInc(60)}</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>

							</>
						}
						{
							page === 2 &&
							<div className="feature-item">
								<span className="base-bold" onClick={openTab}>{t('SizeChart.title')} <Icons.ArrowDownBold height=".6em"/></span>
								<div className="feature-content">
									<div className="row">
										<div className="col">
											<strong>
												<span onClick={() => setFaktor(1)} className={"pointer" + (factor > 1 ? '' : ' green')}>CM</span>
												<span onClick={() => setFaktor(2.54)} className={"ml-2 pointer" + (factor > 1 ? ' green' : '')}>INCH</span>
											</strong>
											<table className="table">
												<tbody>
													<tr className="active">
														<td width="20%" colSpan="2">{t('SizeChart.body')}</td>
														<td data-group="1">36</td>
														<td data-group="2">38</td>
														<td data-group="3">40</td>
														<td data-group="4">42</td>
														<td data-group="5">44</td>
														<td data-group="6">46</td>
														<td data-group="7">48</td>
														<td data-group="8">50</td>
													</tr>
													<tr>
														<td className="active not" colSpan="2">{t('SizeChart.chest')}</td>
														<td data-group="1">{convertInc(86)}</td>
														<td data-group="2">{convertInc(90)}</td>
														<td data-group="3">{convertInc(94)}</td>
														<td data-group="4">{convertInc(98)}</td>
														<td data-group="5">{convertInc(102)}</td>
														<td data-group="6">{convertInc(108)}</td>
														<td data-group="7">{convertInc(112)}</td>
														<td data-group="8">{convertInc(116)}</td>
													</tr>
													<tr>
														<td className="active not" colSpan="2">{t('SizeChart.waist')}</td>
														<td data-group="1">{convertInc(66)}</td>
														<td data-group="2">{convertInc(70)}</td>
														<td data-group="3">{convertInc(74)}</td>
														<td data-group="4">{convertInc(78)}</td>
														<td data-group="5">{convertInc(82)}</td>
														<td data-group="6">{convertInc(88)}</td>
														<td data-group="7">{convertInc(92)}</td>
														<td data-group="8">{convertInc(96)}</td>
													</tr>
													<tr>
														<td className="active not" colSpan="2">{t('SizeChart.hip')}</td>
														<td data-group="1">{convertInc(92)}</td>
														<td data-group="2">{convertInc(96)}</td>
														<td data-group="3">{convertInc(100)}</td>
														<td data-group="4">{convertInc(104)}</td>
														<td data-group="5">{convertInc(108)}</td>
														<td data-group="6">{convertInc(114)}</td>
														<td data-group="7">{convertInc(118)}</td>
														<td data-group="8">{convertInc(122)}</td>
													</tr>
													<tr>
														<td className="active not" colSpan="2">{t('SizeChart.arm')}</td>
														<td data-group="1">{convertInc(59)}</td>
														<td data-group="2">{convertInc(59)}</td>
														<td data-group="3">{convertInc(60)}</td>
														<td data-group="4">{convertInc(60)}</td>
														<td data-group="5">{convertInc(60)}</td>
														<td data-group="6">{convertInc(60)}</td>
														<td data-group="7">{convertInc(60)}</td>
														<td data-group="8">{convertInc(60)}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
									<div className="row mt-4">
										<div className="col">
											<h3>
												BÜYÜK BEDEN <span className="green pl-2">(GALA-Xi)</span>
												<small className="d-block mt-2" disabled>{galaxiMessage}</small>
											</h3>
											<strong>
												<span onClick={() => setFaktor(1)} className={"pointer" + (factor > 1 ? '' : ' green')}>CM</span>
												<span onClick={() => setFaktor(2.54)} className={"ml-2 pointer" + (factor > 1 ? ' green' : '')}>INCH</span>
											</strong>
											<table className="table">
												<tbody>
													<tr className="active">
														<td colSpan="2">{t('SizeChart.body')}</td>
														<td data-group="1">+44</td>
														<td data-group="2">+46</td>
														<td data-group="3">+48</td>
														<td data-group="4">+50</td>
														<td data-group="5">+52</td>
														<td data-group="6">+54</td>
														<td data-group="7">+56</td>
														<td data-group="8">+58</td>
													</tr>
													<tr>
														<td className="active not" colSpan="2">{t('SizeChart.chest')}</td>
														<td data-group="1">{convertInc(105)}</td>
														<td data-group="2">{convertInc(111)}</td>
														<td data-group="3">{convertInc(117)}</td>
														<td data-group="4">{convertInc(123)}</td>
														<td data-group="5">{convertInc(129)}</td>
														<td data-group="6">{convertInc(135)}</td>
														<td data-group="7">{convertInc(141)}</td>
														<td data-group="8">{convertInc(147)}</td>
													</tr>
													<tr>
														<td className="active not" colSpan="2">{t('SizeChart.waist')}</td>
														<td data-group="1">{convertInc(90)}</td>
														<td data-group="2">{convertInc(96)}</td>
														<td data-group="3">{convertInc(102)}</td>
														<td data-group="4">{convertInc(108)}</td>
														<td data-group="5">{convertInc(114)}</td>
														<td data-group="6">{convertInc(120)}</td>
														<td data-group="7">{convertInc(126)}</td>
														<td data-group="8">{convertInc(132)}</td>
													</tr>
													<tr>
														<td className="active not" colSpan="2">{t('SizeChart.hip')}</td>
														<td data-group="1">{convertInc(112)}</td>
														<td data-group="2">{convertInc(118)}</td>
														<td data-group="3">{convertInc(124)}</td>
														<td data-group="4">{convertInc(130)}</td>
														<td data-group="5">{convertInc(136)}</td>
														<td data-group="6">{convertInc(142)}</td>
														<td data-group="7">{convertInc(148)}</td>
														<td data-group="8">{convertInc(154)}</td>
													</tr>
													<tr>
														<td className="active not" colSpan="2">{t('SizeChart.arm')}</td>
														<td data-group="1">{convertInc(59)}</td>
														<td data-group="2">{convertInc(59)}</td>
														<td data-group="3">{convertInc(60)}</td>
														<td data-group="4">{convertInc(60)}</td>
														<td data-group="5">{convertInc(60)}</td>
														<td data-group="6">{convertInc(60)}</td>
														<td data-group="7">{convertInc(60)}</td>
														<td data-group="8">{convertInc(60)}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						}
						{
							page === 3 &&
							<div className="feature-item">
								<span className="base-bold" onClick={openTab}>{t('SizeChart.title')} <Icons.ArrowDownBold height=".6em"/></span>
								<div className="feature-content">
									<div className="row">
										<div className="col">
											<span>
												<span onClick={() => setFaktor(1)} className={"pointer" + (factor > 1 ? '' : ' green')}>CM</span>
												<span onClick={() => setFaktor(2.54)} className={"ml-2 pointer" + (factor > 1 ? ' green' : '')}>INCH</span>
											</span>
											<table className="table">
												<tbody>
													<tr className="active">
														<td colSpan="2">{t('SizeChart.body')}</td>
														<td data-group="1">36</td>
														<td data-group="2">38</td>
														<td data-group="3">40</td>
														<td data-group="4">42</td>
														<td data-group="5">44</td>
														<td data-group="6">46</td>
														<td data-group="7">48</td>
														<td data-group="8">50</td>
													</tr>
													<tr>
														<td className="active not" colSpan="2">{t('SizeChart.waist')}</td>
														<td data-group="1">{convertInc(66)}</td>
														<td data-group="2">{convertInc(70)}</td>
														<td data-group="3">{convertInc(74)}</td>
														<td data-group="4">{convertInc(78)}</td>
														<td data-group="5">{convertInc(82)}</td>
														<td data-group="6">{convertInc(88)}</td>
														<td data-group="7">{convertInc(92)}</td>
														<td data-group="8">{convertInc(96)}</td>
													</tr>
													<tr>
														<td className="active not" colSpan="2">{t('SizeChart.hip')}</td>
														<td data-group="1">{convertInc(92)}</td>
														<td data-group="2">{convertInc(96)}</td>
														<td data-group="3">{convertInc(100)}</td>
														<td data-group="4">{convertInc(104)}</td>
														<td data-group="5">{convertInc(108)}</td>
														<td data-group="6">{convertInc(114)}</td>
														<td data-group="7">{convertInc(118)}</td>
														<td data-group="8">{convertInc(122)}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>

									<div className="row mt-4">
										<div className="col">
											<h3>
												BÜYÜK BEDEN <span className="green pl-2">(GALA-Xi)</span>
												<small className="d-block mt-2" disabled>{galaxiMessage}</small>
											</h3>
											<span>
												<span onClick={() => setFaktor(1)} className={"pointer" + (factor > 1 ? '' : ' green')}>CM</span>
												<span onClick={() => setFaktor(2.54)} className={"ml-2 pointer" + (factor > 1 ? ' green' : '')}>INCH</span>
											</span>
											<table className="table">
												<tbody>
													<tr className="active">
														<td colSpan="2">{t('SizeChart.body')}</td>
														<td data-group="1">+44</td>
														<td data-group="2">+46</td>
														<td data-group="3">+48</td>
														<td data-group="4">+50</td>
														<td data-group="5">+52</td>
														<td data-group="6">+54</td>
														<td data-group="7">+56</td>
														<td data-group="8">+58</td>
													</tr>
													<tr>
														<td className="active not" colSpan="2">{t('SizeChart.waist')}</td>
														<td data-group="1">{convertInc(90)}</td>
														<td data-group="2">{convertInc(96)}</td>
														<td data-group="3">{convertInc(102)}</td>
														<td data-group="4">{convertInc(108)}</td>
														<td data-group="5">{convertInc(114)}</td>
														<td data-group="6">{convertInc(120)}</td>
														<td data-group="7">{convertInc(126)}</td>
														<td data-group="8">{convertInc(132)}</td>
													</tr>
													<tr>
														<td className="active not" colSpan="2">{t('SizeChart.hip')}</td>
														<td data-group="1">{convertInc(112)}</td>
														<td data-group="2">{convertInc(118)}</td>
														<td data-group="3">{convertInc(124)}</td>
														<td data-group="4">{convertInc(130)}</td>
														<td data-group="5">{convertInc(136)}</td>
														<td data-group="6">{convertInc(142)}</td>
														<td data-group="7">{convertInc(148)}</td>
														<td data-group="8">{convertInc(154)}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						}
						{
							page === 4 &&
							<div className="feature-item active">
								<span className="base-bold" onClick={openTab}>{t('SizeChart.title')} <Icons.ArrowDownBold height=".6em"/></span>
								<div className="feature-content">
									<div className="row">
										<div className="col">
											<table className="table">
												<tbody>
													<tr className="active">
														<td colSpan="2">TR</td>
														<td data-group="1">35</td>
														<td data-group="2">36</td>
														<td data-group="3">37</td>
														<td data-group="4">38</td>
														<td data-group="5">39</td>
														<td data-group="6">40</td>
														<td data-group="7">41</td>
														<td data-group="8">42</td>
													</tr>
													<tr>
														<td className="active not" colSpan="2">EU</td>
														<td data-group="1">35</td>
														<td data-group="2">36</td>
														<td data-group="3">37</td>
														<td data-group="4">38</td>
														<td data-group="5">39</td>
														<td data-group="6">40</td>
														<td data-group="7">41</td>
														<td data-group="8">42</td>
													</tr>
													<tr>
														<td className="active not" colSpan="2">US</td>
														<td data-group="1">4</td>
														<td data-group="2">5</td>
														<td data-group="3">6</td>
														<td data-group="4">7</td>
														<td data-group="5">8</td>
														<td data-group="6">9</td>
														<td data-group="7">10</td>
														<td data-group="8">10.5</td>
													</tr>
													<tr>
														<td className="active not" colSpan="2">UK</td>
														<td data-group="1">2</td>
														<td data-group="2">3</td>
														<td data-group="3">4</td>
														<td data-group="4">5</td>
														<td data-group="5">6</td>
														<td data-group="6">7</td>
														<td data-group="7">8</td>
														<td data-group="8">9</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						}
						{
							page !== 4 &&
							<>
								<div className="feature-item">
									<span className="base-bold" onClick={openTab}>{t('SizeChart.convert')}<Icons.ArrowDownBold height=".6em"/></span>
									<div className="feature-content">
										<div className="row">
											<div className="col">
												<table className="table small">
													<tbody>
														<tr className="active">
															<td width="5%" height="100%" colSpan="2">TR/EU/FR</td>
															<td width="5%" height="100%" data-group="2">US</td>
															<td width="5%" height="100%" data-group="3">ITALY</td>
															<td width="5%" height="100%" data-group="4">RU</td>
															<td width="5%" height="100%" data-group="5">JAPAN</td>
															<td width="10%" height="100%" data-group="6">UK, IRELAND, NEW ZEALAND</td>
															<td width="10%" height="100%" data-group="7">GERMANY, AUSTRIA, SCANDINAVIA, NETHERLANDS</td>
															<td width="5%" height="100%" data-group="8">AUSTRALIA</td>
														</tr>
														<tr>
															<td data-group="0" className="active not">S</td>
															<td data-group="1" className="active not">36</td>
															<td data-group="2">4</td>
															<td data-group="3">40</td>
															<td data-group="4">42</td>
															<td data-group="5">9</td>
															<td data-group="6">8</td>
															<td data-group="7">34</td>
															<td data-group="8">10</td>
														</tr>
														<tr>
															<td data-group="0" className="active not">S</td>
															<td data-group="1" className="active not">38</td>
															<td data-group="2">6</td>
															<td data-group="3">42</td>
															<td data-group="4">44</td>
															<td data-group="5">11</td>
															<td data-group="6">10</td>
															<td data-group="7">36</td>
															<td data-group="8">12</td>
														</tr>
														<tr>
															<td data-group="0" className="active not">M</td>
															<td data-group="1" className="active not">40</td>
															<td data-group="2">8</td>
															<td data-group="3">44</td>
															<td data-group="4">46</td>
															<td data-group="5">13</td>
															<td data-group="6">12</td>
															<td data-group="7">38</td>
															<td data-group="8">14</td>
														</tr>
														<tr>
															<td data-group="0" className="active not">M</td>
															<td data-group="1" className="active not">42</td>
															<td data-group="2">10</td>
															<td data-group="3">46</td>
															<td data-group="4">48</td>
															<td data-group="5">15</td>
															<td data-group="6">14</td>
															<td data-group="7">40</td>
															<td data-group="8">16</td>
														</tr>
														<tr>
															<td data-group="0" className="active not">L</td>
															<td data-group="1" className="active not">44</td>
															<td data-group="2">12</td>
															<td data-group="3">48</td>
															<td data-group="4">50</td>
															<td data-group="5">17</td>
															<td data-group="6">16</td>
															<td data-group="7">42</td>
															<td data-group="8">18</td>
														</tr>
														<tr>
															<td data-group="0" className="active not">L</td>
															<td data-group="1" className="active not">46</td>
															<td data-group="2">14</td>
															<td data-group="3">50</td>
															<td data-group="4">52</td>
															<td data-group="5">19</td>
															<td data-group="6">18</td>
															<td data-group="7">44</td>
															<td data-group="8">20</td>
														</tr>
														<tr>
															<td data-group="0" className="active not">XL</td>
															<td data-group="1" className="active not">48</td>
															<td data-group="2">16</td>
															<td data-group="3">52</td>
															<td data-group="4">54</td>
															<td data-group="5">21</td>
															<td data-group="6">20</td>
															<td data-group="7">46</td>
															<td data-group="8">22</td>
														</tr>
														<tr>
															<td data-group="0" className="active not">XXL</td>
															<td data-group="1" className="active not">50</td>
															<td data-group="2">18</td>
															<td data-group="3">54</td>
															<td data-group="4">56</td>
															<td data-group="5">23</td>
															<td data-group="6">22</td>
															<td data-group="7">48</td>
															<td data-group="8">24</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>


										{ /*GALAXY DÖNÜŞÜM TABLOSU*/ }
										<div className="row mt-5">
											<div className="col">
												<h3>
													BÜYÜK BEDEN <span className="green pl-2">(GALA-Xi)</span>
													<small className="d-block mt-2" disabled>{galaxiMessage}</small>
												</h3>
												<table className="table small">
													<tbody>
														<tr className="active">
															<td width="5%" height="100%" colSpan="2">TR/EU/FR</td>
															<td width="5%" height="100%" data-group="1">US</td>
															<td width="5%" height="100%" data-group="2">ITALY</td>
															<td width="5%" height="100%" data-group="3">RU</td>
															<td width="5%" height="100%" data-group="4">JAPAN</td>
															<td width="10%" height="100%" data-group="5">UK, IRELAND, NEW ZEALAND</td>
															<td width="10%" height="100%" data-group="6">GERMANY, AUSTRIA, SCANDINAVIA, NETHERLANDS</td>
															<td width="5%" height="100%" data-group="7">AUSTRALIA</td>
														</tr>
														<tr>
															<td data-group="0" className="active not">XXL</td>
															<td className="active not">+44</td>
															<td data-group="1">14</td>
															<td data-group="2">50</td>
															<td data-group="3">52</td>
															<td data-group="4">19</td>
															<td data-group="5">18</td>
															<td data-group="6">44</td>
															<td data-group="7">20</td>
														</tr>
														<tr>
															<td data-group="0" className="active not">3XL</td>
															<td className="active not">+46</td>
															<td data-group="1">16</td>
															<td data-group="2">52</td>
															<td data-group="3">54</td>
															<td data-group="4">21</td>
															<td data-group="5">20</td>
															<td data-group="6">46</td>
															<td data-group="7">22</td>
														</tr>
														<tr>
															<td data-group="0" className="active not">4XL</td>
															<td className="active not">+48</td>
															<td data-group="1">18</td>
															<td data-group="2">54</td>
															<td data-group="3">56</td>
															<td data-group="4">23</td>
															<td data-group="5">22</td>
															<td data-group="6">48</td>
															<td data-group="7">24</td>
														</tr>
														<tr>
															<td data-group="0" className="active not">5XL</td>
															<td className="active not">+50</td>
															<td data-group="1">20</td>
															<td data-group="2">56</td>
															<td data-group="3">58</td>
															<td data-group="4">25</td>
															<td data-group="5">24</td>
															<td data-group="6">50</td>
															<td data-group="7">26</td>
														</tr>
														<tr>
															<td data-group="0" className="active not">6XL</td>
															<td className="active not">+52</td>
															<td data-group="1">22</td>
															<td data-group="2">58</td>
															<td data-group="3">60</td>
															<td data-group="4">27</td>
															<td data-group="5">26</td>
															<td data-group="6">52</td>
															<td data-group="7">28</td>
														</tr>
														<tr>
															<td data-group="0" className="active not">7XL</td>
															<td className="active not">+54</td>
															<td data-group="1">24</td>
															<td data-group="2">60</td>
															<td data-group="3">62</td>
															<td data-group="4">29</td>
															<td data-group="5">28</td>
															<td data-group="6">54</td>
															<td data-group="7">30</td>
														</tr>
														<tr>
															<td data-group="0" className="active not">8XL</td>
															<td className="active not">+56</td>
															<td data-group="1">26</td>
															<td data-group="2">62</td>
															<td data-group="3">64</td>
															<td data-group="4">31</td>
															<td data-group="5">30</td>
															<td data-group="6">56</td>
															<td data-group="7">32</td>
														</tr>
														<tr>
															<td data-group="0" className="active not">9XL</td>
															<td className="active not">+58</td>
															<td data-group="1">28</td>
															<td data-group="2">64</td>
															<td data-group="3">66</td>
															<td data-group="4">33</td>
															<td data-group="5">32</td>
															<td data-group="6">58</td>
															<td data-group="7">34</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
								<div className="feature-item active">
									<span className="base-bold" onClick={openTab}>{t('SizeChart.book')} <Icons.ArrowDownBold height=".6em"/></span>
									<div className="feature-content base-light">
									{
										window.innerWidth <= 540 &&
										<>
											{
												page === 1 &&
												<>
													<TooltipPlusButton data={{title: "KOL", text: bodyContent.kol, position: {left: "64%", top: "29%"}}} control={{set: setCounter, get: counter}}/>

													<TooltipPlusButton data={{title: "GÖĞÜS", text: bodyContent.gogus, position: {left: "39%", top: "40%"}}} direction="left" control={{set: setCounter, get: counter}}/>

													<TooltipPlusButton data={{title: "BEL", text: bodyContent.bel, position: {left: "56%", top: "57%"}}} control={{set: setCounter, get: counter}}/>

													<TooltipPlusButton data={{title: "BASEN", text: bodyContent.basen, position: {left: "31%", top: "70%"}}} direction="left" control={{set: setCounter, get: counter}}/>

													<img src="https://cdn.secilstore.com/docs/images/others/orj/efdf9c06-9661-4c00-8f2b-d908907aeeda.jpg" className="w-100" alt="size-guide"/>
												</>
											}
											{
												page === 2 &&
												<>
													<TooltipPlusButton data={{title: "KOL", text: bodyContent.kol, position: {left: "62%", top: "32%"}}} control={{set: setCounter, get: counter}}/>

													<TooltipPlusButton data={{title: "GÖĞÜS", text: bodyContent.gogus, position: {left: "37%", top: "42%"}}} direction="left" control={{set: setCounter, get: counter}}/>

													<TooltipPlusButton data={{title: "BEL", text: bodyContent.bel, position: {left: "58%", top: "57%"}}} control={{set: setCounter, get: counter}}/>

													<TooltipPlusButton data={{title: "BASEN", text: bodyContent.basen, position: {left: "36%", top: "70%"}}} direction="left" control={{set: setCounter, get: counter}}/>

													<img src="https://cdn.secilstore.com/docs/images/others/orj/795771f6-d2b2-4134-b63b-f1e62a3ca1a2.jpg" className="w-100" alt="size-guide"/>
												</>
											}
											{
												page === 3 &&
												<>
													<TooltipPlusButton data={{title: "BEL", text: bodyContent.bel, position: {left: "53%", top: "8%"}}} direction="left" control={{set: setCounter, get: counter}}/>

													<TooltipPlusButton data={{title: "BASEN", text: bodyContent.basen, position: {left: "65%", top: "20%"}}} control={{set: setCounter, get: counter}}/>

													<TooltipPlusButton data={{title: "BACAK BOYU ve İÇ DİKİŞ", text: bodyContent.bacak, position: {left: "61%", top: "57%"}}} control={{set: setCounter, get: counter}}/>

													<img src="https://cdn.secilstore.com/docs/images/others/orj/678fe3fc-a588-4d4e-8c7d-b5743b3502a3.jpg" className="w-100" alt="size-guide"/>
												</>
											}
										</>
									}
									{
										window.innerWidth > 540 &&
										<>
											{
												page === 1 &&
												<img src="https://cdn.secilstore.com/docs/images/others/orj/15d2cd1c-8bf8-48d1-823a-a67af4710fad.jpg" className="w-100" alt="size-guide"/>
											}
											{
												page === 2 &&
												<img src="https://cdn.secilstore.com/docs/images/others/orj/f03457d2-dd11-4d2d-87b0-cf0192c6b09c.jpg" className="w-100" alt="size-guide"/>
											}
											{
												page === 3 &&
												<img src="https://cdn.secilstore.com/docs/images/others/orj/1838fb5a-6143-41ca-8b2c-8ea3507e1071.jpg" className="w-100" alt="size-guide"/>
											}
										</>
									}
									</div>
								</div>
							</>
						}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SizeChart
