import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import $ from "jquery";

import Modal from './../../rComponents/global/modal/modal'
import Loading from './../widgets/global/loading'

function WordShowing( props ) {
	const [data, setData] = useState()
	const isFirstRun = useRef(true)

	useEffect( () => {
		if(isFirstRun.current){
			isFirstRun.current = false
			updateData()
		}
	}, [props.url])

	const updateData = () => {
		if(sessionStorage.getItem(props.url)){
			setData(sessionStorage.getItem(props.url))
		}else{
			let url = props.url
			if(props.url === "kvkk"){
				url = "/GetGDPR"
			}else if(props.url === "policy"){
				url = "/GetPrivacyPolicy"
			}else if(props.url === "agreement"){
				url = "/GetMembershipAgreement"
			}

			axios.get(url).then(res => {
				setTimeout(() => {
					setData(res.data.body)
					//sessionStorage.setItem(props.url, res.data.body)
				}, 2000)
			})
		}
	}

	return (
		<Modal {...props} rePosition={data}>
			<div className="scl-modal-title">
				{
					props.title &&
					<h2><b>{props.title}</b></h2>
				}
			</div>
			{
				data &&
				<div className="modal-body pdf-schema" dangerouslySetInnerHTML={{__html: data}}></div>
			}
			{
				!data &&
				<Loading />
			}
		</Modal>
	)
}

export default WordShowing
