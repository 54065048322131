import React from 'react'
import TypeLink from './../global/link'

function HomeBottom(props) {
	return (
		<div className="banner">
			<div className="container">
				<div className="row g-2">
					{
						props?.data?.map((elm, i) => {
							return <div className="col" key={i}>
									<TypeLink to={elm?.url}>
										<img src={elm?.img} alt="service" />
									</TypeLink>
								</div>
							}
						)
					}
				</div>
			</div>
		</div>
	)
}

export default HomeBottom
