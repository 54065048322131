import * as React from "react";

function SvgVour(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M27.43 21.09H12.57c-.48 0-.88-.39-.88-.88v-.43c0-.48.39-.88.88-.88h14.86c.48 0 .88.39.88.88v.43c0 .49-.4.88-.88.88z"
      />
    </svg>
  );
}

export default SvgVour;
