import React, { useEffect, useState, useContext, useRef } from "react"
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import * as Icons from './../../icons/index'
import Swal from 'sweetalert2'
import { gaView } from './../../../package/google-analytic'
import { visitTag } from './../../../package/criteo'
import Toast from "./../../../package/swal-init"

import { useTranslation } from 'react-i18next'
import { LoadingContext } from './../../../context/LoadingContext'
import { LanguageContext } from './../../../context/LanguageContext'
import { AuthContext } from './../../../context/AuthContext'

import OrderTicket from "./ticket"
import Input from './../../widgets/forms/input'
import Button from './../../widgets/forms/button'
import QuestionAdvanced from './../../modal/question-answar-advanced'
import Loading from './../../widgets/global/loading'
import Modal from './../../../rComponents/global/modal/modal'
import ReturnOrderModal from './../../../rComponents/widgets/userPage/orders/returnModal'
import CancelOrderModal from './../../../rComponents/widgets/userPage/orders/cancelModal'

function OrderContent(props) {

	const history = useHistory()
	const { t } = useTranslation()
	const { toPriceConvert } = useContext(LanguageContext)
	const { openModal } = useContext(AuthContext)

	const [data, setData] = useState(null)
	const [chooseOrder, setChooseOrder] = useState(null)
	const [cancelData, setCancelData] = useState(null)

	const isFirstRun = useRef(true)

	useEffect(() => {
		if(isFirstRun.current){
			isFirstRun.current = false
			gaView()
			visitTag()
			updateData()
		}
	}, [props])

	const updateData = () => {
		axios.get("/GetOrders?page=1&pagesize=50")
		.then(res => {
			setData(res?.data?.data || [])
		})
	}

	return (
		<>
			<ReturnOrderModal chooseOrder={chooseOrder} updateData={updateData} reset={() => {setChooseOrder(null)}}/>
			<CancelOrderModal cancelData={cancelData} setCancelData={setCancelData} updateData={updateData} reset={() => {setCancelData(null)}}/>
			<div className="scl-content-title">
				<h1 className="base-regular">
					{t('UserPage.orders.title')}
					<span className="btn user-title-btn float-right base-regular" onClick={() => openModal('question', {orders: [{title: 1}]})}>{t('UserPage.orders.new-message')}</span>
				</h1>
			</div>
			<div className="scl-section-content list">
				{
					!data ?
						<Loading />
					:
					data.length <= 0 ? (
						<div className="basket-empty base-light order-empty">
							<Icons.NonBasket />
							<span>{t('UserPage.orders.non')}</span>
							<a href="/" title={t('UserPage.orders.order')} className="btn base-regular">{t('UserPage.orders.non-btn')}</a>
						</div>
					) : (
						<>
						<div className="row g-0 head d-none d-lg-flex">
							<div className="col col-lg-3">{t('UserPage.orders.order')}</div>
							<div className="col col-lg-3">{t('UserPage.orders.date')}</div>
							<div className="col col-lg-3">{t('UserPage.orders.state')}</div>
							<div className="col col-lg-2">{t('UserPage.orders.total')}</div>
						</div>
						{
							data.map((elm, i) =>
							<React.Fragment key={i}>
								<div className="row g-2 g-lg-0 ">
									<div className="col-6 col-lg-3 head pointer" onClick={() => history.push(`/uye/siparislerim/${elm.code}`)}>
									<span className="base-regular">#{elm.code}</span>
									<br/>
									<small>({elm.products?.map(e => e.quantity).reduce((a, b) => a + b, 0)} {t('UserPage.orders.product')})</small>
									</div>
									<div className="col-6 col-lg-3 head">
										{moment(elm.datetime).format("DD/MM/YYYY kk:mm")}
									</div>
									<div className="col-6 col-lg-3 head">
										{elm.orderstatus.title}
										{
											(elm.cancelationenabled || elm.returnenabled || elm.trackingnumber) &&
											<div className="edit">
												{
													elm.cancelationenabled &&
													<span className="order-close green" onClick={() => setCancelData(elm)}>İptal et</span>
												}
												{
													elm.returnenabled &&
													<span className="order-close green" onClick={() => {
														if(elm.returnmessage){
															Toast.fire({
																icon: 'warning',
																title: elm.returnmessage
															})
														}else{
															setChooseOrder(elm)
													}}}>İade et</span>
												}
												{
													elm?.trackingnumber && elm?.cargo?.trackingurl &&
													<a href={`${elm?.cargo?.trackingurl}${elm?.trackingnumber}`} target="_blank" className="btn">Kargo Takip {elm?.cargo && <>({elm?.cargo?.name})</>}</a>
												}
											</div>
										}
									</div>
									<div className="col-6 col-lg-2 head">{toPriceConvert(elm.ordertotaldiscountedprice)}</div>
									<div className="col">
										<span className="d-none d-lg-block pointer text-right" onClick={() => history.push(`/uye/siparislerim/${elm.code}`)}>Detay</span>
										<div className="go-detail d-block d-lg-none" onClick={() => history.push(`/uye/siparislerim/${elm.code}`)}>
											<Icons.ArrowRight height=".8em"/>
										</div>
									</div>
								</div>
								{
									elm.returncode &&
									<div className="btn" style={{marginTop: -10, padding: "5px 10px", fontSize: "1em", width: "100%"}} onClick={() => {
										navigator.clipboard.writeText(elm.returncode)
										Toast.fire({icon: "success", title: "Kopyalandı"})
									}}>
										<strong>"{elm.returncode}"</strong>
										<small>{elm.cargo.name} iade koduyla iadenizi gerçekleştirebilirsiniz</small>
									</div>
								}
							</React.Fragment>
							)
						}
						</>
					)
				}
			</div>
			<OrderTicket />
		</>
	);
}

export default OrderContent;
