import React, { useEffect, useState } from 'react'
import * as Icons  from './../../components/icons/index'

const styles = {
	title: {
		margin: 0,
		padding: '10px 0',
		cursor: 'pointer',
	},
	titlebtn: {
		float: 'right',
	}
}

function OpenArea(props) {
	const [show, setShow] = useState(props.show ?? false)
	useEffect(() => {
		if(!props.reset && props.show === undefined){
			setShow(false)
		}
	}, [props.reset])

	const openContent = () => {
		if(props.openHandle){
			props.openHandle()
		}

		setShow(!show)
	}

	return (
		<div className="row">
			<div className="col">
				<h2 onClick={openContent} style={styles.title}>
					<span dangerouslySetInnerHTML={{__html: props.title}}></span>
					{!show && <Icons.PlusBold style={styles.titlebtn}/>}
					{show && <Icons.VourBold style={styles.titlebtn}/>}
				</h2>
				{
					props.scroll !== false ?
					(
						show &&
						<div className="scroll-container">
							{
								props.children
							}
						</div>
					):(
						show &&
						<div>
							{
								props.children
							}
						</div>
					)
				}
			</div>
		</div>
	)
}


export default OpenArea
