import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom"
import $ from "jquery"
import * as Icons from './../../icons/index'
import { validateEmail } from './../../../package/global-function'

import { useTranslation } from 'react-i18next';
import { LoadingContext } from "./../../../context/LoadingContext"
import { BasketContext } from "./../../../context/BasketContext"
import { LanguageContext } from "./../../../context/LanguageContext"

import OpenArea from './../../../rComponents/global/openArea'
import Address from "./non-address"

import Button from "./../forms/button"
import Input from "./../forms/input"
import Checkbox from "./../forms/checkbox"

import BasketStepsBar from "./basket-steps-bar"

function BasketOrderNon(props){

	const history = useHistory()
	const { t } = useTranslation()
	const { notInclude, basketData, setStateBasket, cargoID } = useContext(BasketContext)
	const { toPriceConvert } = useContext(LanguageContext)

	const [step, setStep] = useState(props.step)
	const [choiceType, setChoiceType] = useState(props?.choicesDeliveryAddress?.isPersonal ?? true)
	const [invoiceResultMessage, setInvoiceResultMessage] = useState(true)
	const [deliveryResultMessage, setDeliveryResultMessage] = useState(true)

	useEffect(() => {
		const sessionMail = sessionStorage.getItem('nonMemberMail')
		if(!sessionMail || !validateEmail(sessionMail)){
			history.push('/sepet')
		}else{
			props.setNonMemberMail(sessionMail)
		}
	}, [props.step])

	const changeAddress = (e, data=null) => {
		if(data){
			props.setChoicesDeliveryAddress({...props.choicesDeliveryAddress, ...data})
		}else{
			props.setChoicesDeliveryAddress({...props.choicesDeliveryAddress, [e.target.name] : e.target.value})
		}
	}

	const changeInvoice = (e, data=null) => {
		if(data){
			props.setChoicesInvoiceAddress({...props.choicesInvoiceAddress, ...data})
		}else{
			props.setChoicesInvoiceAddress({...props.choicesInvoiceAddress, [e.target.name] : e.target.value})
		}
	}

	const adressControl = (data, setErrorMessage) => {
		let error = 0
		setErrorMessage(t('Basket.address.error'))

		if(choiceType === "on"){
			props.setChoicesDeliveryAddress({...props.choicesDeliveryAddress, isPersonal: false})
			props.setChoicesInvoiceAddress({...props.choicesInvoiceAddress, isPersonal: false})
		}else{
			props.setChoicesDeliveryAddress({...props.choicesDeliveryAddress, isPersonal: true})
			props.setChoicesInvoiceAddress({...props.choicesInvoiceAddress, isPersonal: true})
		}

		if(!('country' in data)){
			error++
		}else if(data.country){
			if(data.country.id < 0) {
				error++
			}
		}else{
			if(data.nonCurrent.trim() === ""){
				error++
			}
		}

		if(!('city' in data)){
			error++
		}else if(data.city){
			if(data.city.id <= 0) {
				error++
			}
		}else{
			if(data.nonCurrent.trim() === ""){
				error++
			}
		}

		if(!('county' in data)){
			error++
		}else if(data.county){
			if(data.county.id <= 0) {
				error++
			}
		}else{
			if(data.nonCurrent.trim() === ""){
				error++
			}
		}

		if(!('name' in data)){
			error++
		}else{
			if(data.name.length <= 1){
				error++
			}
			if(data.name.length > 15){
				error++
				setErrorMessage("Adın en fazla 15 karakter olabilir")
			}
		}

		if(!('surname' in data)){
			error++
		}else{
			if(data.surname.length <= 1){
				error++
			}
			if(data.surname.length > 15){
				error++
				setErrorMessage("Soyadın en fazla 15 karakter olabilir")
			}
		}

		if(!('phone' in data)){
			error++
		}else{
			if(data.phone === ""){
				error++
			}

			if(data.phone.length !== 18){
				error++
				setErrorMessage("Telefon numarasını uygun formatta giriniz")
			}
		}

		if(!('addressText' in data)){
			error++
		}else{
			if(data.addressText === ""){
				error++
			}

			if(data.addressText.length < 10){
				error++
				setErrorMessage("Adresiniz en az 10 karakter olmalıdır")
			}

			if(data.addressText.length > 150){
				error++
				setErrorMessage("Adresiniz en az 150 karakter olmalıdır")
			}
		}
		if(error <= 0){
			setErrorMessage("")
		}
		return error
	}

	const changeStep = () => {
		let error = 0;
		error += adressControl(props.choicesDeliveryAddress, setDeliveryResultMessage)

		if(!props.useSame){
			error += adressControl(props.choicesInvoiceAddress, setInvoiceResultMessage)
		}

		if(error <= 0){
			props.setInsertAdress(true)
			props.changeStep(3)
		}else{
			$("html, body, .App").stop().animate({ scrollTop: 100 }, 1000);
		}
	}

	return (
		<div className="basket-order">
			<div className="container">
				<BasketStepsBar step={step} changeStep={props.changeStep} />
				{
					basketData?.shipping &&
					<div className="row pt-0 pb-0 mb-lg-0 justify-content-center">
						<div className="col-12 col-lg-7 mb-3">
							<div className="row cargo-select">
								{
									basketData.shipping.map((e, i) =>
										<div className={`col-12 col-lg-4 ml-lg-3 bgw cargo${e.id === cargoID ? ' active' : ''}`} onClick={() => setStateBasket('cargoID', (e.id), true)} key={i}>
											<span><strong>{e.name}</strong> <span className="float-right">{toPriceConvert(e.price)}</span></span>
										</div>
									)
								}
							</div>
						</div>
					</div>
				}
				<div className="row mb-4 pt-0 pb-0 mb-5 mb-lg-0 justify-content-center">
					<div className="bgw col-12 col-lg-7">
						<div className="p-lg-5 pt-3 pb-2">
							<h2>{t('Basket.address.delivery')}</h2>
							{
								deliveryResultMessage?.length > 0 && <div className="row p-lg-3"><div className="errorMessage"><Icons.CloseCircle />{deliveryResultMessage}</div></div>
							}
							<Address choiceType={choiceType} addressData={props.choicesDeliveryAddress} changeAddress={changeAddress} />
							<Checkbox name="isPersonal" label={t('Basket.address.is-personal')} checked={!choiceType} onChange={(e) => {
								changeAddress(e, {isPersonal: !choiceType})
								setChoiceType(!choiceType)
							}}/>
						</div>
						<div className="p-lg-5 pt-3">
							<h2>{t('Basket.address.invoice')}</h2>
							{
								!props.useSame &&
								<>
									{
										invoiceResultMessage?.length > 0 && <div className="row p-lg-3"><div className="errorMessage"><Icons.CloseCircle />{invoiceResultMessage}</div></div>
									}
									<Address choiceType={choiceType} addressData={props.choicesInvoiceAddress} changeAddress={changeInvoice} />
								</>
							}
							<Checkbox name="useSame" label={t('Basket.address.use-same')} className="mt-3" checked={props.useSame} onChange={e => {
								changeAddress(e, {useSame: !props.useSame})
								props.setUseSame(!props.useSame)
							}}/>
						</div>
					</div>
				</div>
				<div className="row justify-content-center mobile-fixed">
					<div className="col-12 col-lg-7">
						<div className="row justify-content-end">
							<div className="col-12 col-lg-6 p-lg-0 mt-lg-4">
								<Button className="btn w-100 float-right" title={t('Basket.go-payment')} onClick={changeStep}/>
							</div>
						</div>
					</div>
				</div>
				<div className="clear-fix"></div>
			</div>
		</div>
	);
}

export default BasketOrderNon;
