import * as React from "react";

function SvgBag(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M30.95 13.25v-2.34C30.95 4.89 26.06 0 20.04 0S9.13 4.89 9.13 10.91v2.34H0V40h39.88V13.25h-8.93zm-20.4-2.34c0-5.23 4.26-9.49 9.49-9.49s9.49 4.26 9.49 9.49v2.34H10.55v-2.34zm27.92 27.67H1.42V14.66h7.72v6.75c0 .05 0 .1.01.15l.01.43h1.42l-.02-.48c0-.03-.01-.07-.01-.1v-6.75h18.99v6.75c0 .03 0 .07-.01.1l-.02.48h1.42l.01-.43c0-.05.01-.1.01-.15v-6.75h7.52v23.92z"
      />
    </svg>
  );
}

export default SvgBag;
