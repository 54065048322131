import React from 'react'

function Button(props)  {

	return (
		<button {...{...props, title: undefined}}>{props.title}{props.children}</button>
	)
}

export default Button
