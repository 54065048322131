import React, { useState, useRef, useEffect } from 'react'

import Loading from './loading'

function Image(props) {

	const [image, setImage] = useState(props.src)
	const [isImageLoad, setIsImageLoad] = useState(false)

	const refImage = useRef()

	useEffect(() => {
		setImage(props.src)
	}, [props.src])

	useEffect(() => {
		if(!refImage?.current?.complete){
			setIsImageLoad(false)
		}else{
			setIsImageLoad(true)
		}
	}, [image])

	const handleImageLoaded = () => {
		if (!isImageLoad) {
			setIsImageLoad(true)

			if(props.onLoad){
				props.onLoad()
			}
		}
	}

	return (
		<>
			{
				image &&
				<img {...props} src={image} ref={refImage} loading="lazy" onLoad={handleImageLoaded}/>
			}
			{
				!isImageLoad &&
				<>
					<Loading className="loading fix"/>
				</>
			}
		</>
	)
}

export default Image
