import React, { Component, createContext } from 'react';
import Giris from './giris'
import axios from 'axios'
import moment from 'moment'
import {timeConvert} from './../package/global-function'
import config from './../package/config'

export const AuthContext = createContext();

class AuthContextProvider extends Component {
	state = {
		locked: config.locked,
		isAuth: false,
		data: null,
		loginModalShow: false,
		guestGo: false,
		leaveAuth: false,
		isBusy: false,
		openModals: {}
	}

	componentDidMount(){
		axios.interceptors.response.use(response => {
			return response
		}, error => {
			if(!this.state.isBusy){
				this.setState({isBusy: true})
				try {
					const { status } = error.response
					if(status === 401){
						const refreshReq = this.refreshToken(true, false)
						refreshReq.then(res => {
							if(!res?.data?.isSuccess){
								this.setState({loginModalShow: true, leaveAuth: true})
								setTimeout(() => {
									this.setState({isBusy: false})
								}, 1000)
							}
						})
					}
				} catch (e) {}
			}
			return error;
		});
		this.isLogIn()
	}

	localStorageListener = () => {
		if(this.state.data?.Expiration){
			let time = moment(this.state.data?.Expiration).fromNow(true)
			time = timeConvert(time)
			// Token Yenile
			if(time <= (1000 * 60 * 5)){
				this.refreshToken()
			}
		}

		if(this.state.data?.AuthTime){
			const now = new Date().getTime();
			const date = new Date(this.state.data?.AuthTime).getTime();
			const betweenTime = now - date
			const betweenSeconds = parseInt(betweenTime / 1000)
			// Oturumu düşür
			const oneDayTime = 60 * 60 * 24
			if(betweenSeconds > oneDayTime) {
				this.setState({leaveAuth: true, loginModalShow: true})
			}
		}
	}

	isLogIn = () => {

		if(localStorage.getItem("locked")){
			this.setState({locked: true})
		}

		const authData = JSON.parse(localStorage.getItem("auth"))
		if(authData?.Token){
			this.setState({data: authData, isAuth: true}, () => {
				this.localStorageListener()
				setInterval(this.localStorageListener, (1000 * 60 * 2))
			})
		} else {
			this.setState({isAuth: false})
		}
	}

	logIn = (data) => {
		const response = axios.post("/login", data)
		const promiseData = response.then((res) => res.data)
		promiseData.then(res => {
			if(res?.isSuccess){
				let loginData
				if(data.remember){
					loginData = res.data
				}else{
					loginData = {...res.data, AuthTime: new Date()}
				}

				if(!res.data.IsSmsActivated){
					sessionStorage.setItem("tempLI", JSON.stringify(data))
				}

				localStorage.setItem("auth", JSON.stringify(loginData))
				this.setState({isAuth: true, data: loginData})
			}
		})

		return promiseData
	}

	loginExternal = (guid) => {
		const response = axios.post("/LoginExternal", {}, {headers: {eg: guid}})
		const promiseData = response.then((res) => res.data)
		promiseData.then(res => {
			if(res?.isSuccess){
				let loginData= res.data
				// loginData = {...res.data, AuthTime: new Date()}

				if(!res.data.IsSmsActivated){
					sessionStorage.setItem("tempLI", JSON.stringify({guid: guid}))
				}

				localStorage.setItem("auth", JSON.stringify(loginData))
				this.setState({isAuth: true, data: loginData})
			}
		})

		return promiseData
	}

	refreshToken = (again = false, out = true) => {
		if(this.state?.data?.RefreshToken && this.state?.isAuth){
			const request = axios.post("/RefreshToken", {RefreshToken: this.state.data.RefreshToken})
			const promise = request.then(e => e)

			promise.then(res => {
				if(res?.data?.isSuccess){
					let loginData
					if(this.state.data?.AuthTime){
						loginData = {...res?.data?.data, AuthTime: this.state.data.AuthTime}
					}else{
						loginData = res?.data?.data
					}
					this.setState({data: loginData, isAuth: true})
					localStorage.setItem("auth", JSON.stringify(loginData))
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + res?.data?.data?.Token
				} else {
					if(!again){
						this.refreshToken(true)
					}else{
						if(out){
							this.logOut()
						}
					}
				}
			})

			return promise
		}else{
			const req = new Promise((resolve, reject) => {
				setTimeout(resolve(), 200)
			})

			return req.then(e => e)
		}
	}

	register = (data) => {
		const options = {}
		const response = axios.post("/signup", data, options)

		const promiseData = response.then((res) => res.data)

		return promiseData
	}

	logOut = () => {
		const req = new Promise((resolve, reject) => {
			this.setState({data: null})
			this.setState({isAuth: false}, () => {
				localStorage.removeItem("auth")
				delete axios.defaults.headers.common['Authorization']
				delete axios.defaults.headers.common['UserID']
				resolve({isSuccess: true})
			})
		})

		return req.then(e => e)
	}

	toggleAuth = () => {
		this.setState({ locked: !this.state.locked})
		localStorage.setItem("locked", "NOTlock")
	}

	setStateAuth = (name, value) => {
		this.setState({[name]: value})
	}

	getUserName = () => {
		try {
			const { Name, Surname } = this.state.data
			return  Name + " " + Surname
		}
		catch {
			return null
		}
	}

	getUserMail = () => {
		try {
			const { Email } = this.state.data
			return Email
		}
		catch {
			return null
		}
	}

	addDiscoveryProduct = (product) => {
		let discoveryProdusts = localStorage.getItem('lastDiscovery')
		let newData = JSON.parse(discoveryProdusts)

		if(discoveryProdusts){
			let lengthProducts = newData?.length
			newData = newData.filter((elm, i) => elm !== product)
			if(lengthProducts > 20){
				newData = newData.filter((elm, i) => i)
			}
			newData = [...newData, product]
		}else{
			newData = [product]
		}

		localStorage.setItem('lastDiscovery', JSON.stringify(newData))
	}

	openModal = (name, props) => {
		this.setState({openModals: {...this.openModals, [name]: {show: true, ...props}}})
	}

	closeModal = (name) => {
		const modals = this.openModals
		delete modals?.[name]
		this.setState({openModals: modals ?? {}})
	}

	returnRendering(){
		if(this.state.locked){
			return this.props.children
		} else {
			return <Giris />
		}
	}

	render() {
		return (
			<AuthContext.Provider value={{
				...this.state,
				toggleAuth: this.toggleAuth,
				isLogIn: this.isLogIn,
				logIn: this.logIn,
				loginExternal: this.loginExternal,
				logOut: this.logOut,
				register: this.register,
				getUserName: this.getUserName,
				getUserMail: this.getUserMail,
				setStateAuth: this.setStateAuth,
				addDiscoveryProduct: this.addDiscoveryProduct,
				openModal: this.openModal,
				closeModal: this.closeModal
			}}>
				{this.returnRendering()}
			</AuthContext.Provider>
		);
	}
}

export default AuthContextProvider;
