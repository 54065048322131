import * as React from "react";

function SvgNonBasket(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <g fill="currentColor">
        <path d="M32.12 40H7.88a4.19 4.19 0 01-4.12-3.56l-.19-1.32.81-.12-.82.12-.74-5 .83-.12-.82.12L.57 14.77A4.161 4.161 0 014.69 10H10c0-5.51 4.49-10 10-10s10 4.49 10 10h5.31a4.161 4.161 0 014.12 4.77l-2.26 15.35-.82-.12.82.12-.74 5-.81-.12.82.12-.19 1.32c-.31 2.03-2.08 3.56-4.13 3.56zM5.35 35.83l.05.36a2.514 2.514 0 002.47 2.14h24.24c1.23 0 2.29-.92 2.47-2.14l.05-.36H5.35zm-.24-1.66H34.9l2.89-19.64a2.505 2.505 0 00-2.47-2.87H30v1.67h.83V15H27.5v-1.67h.83v-1.67H11.67v1.67h.83V15H9.17v-1.67H10v-1.67H4.69c-.73 0-1.42.32-1.89.87a2.47 2.47 0 00-.58 2l2.89 19.64zM11.67 10h16.67c0-4.6-3.74-8.33-8.33-8.33S11.67 5.4 11.67 10z" />
        <circle cx={24.21} cy={19.32} r={1.32} />
        <circle cx={15.29} cy={19.32} r={1.32} />
        <path d="M25.7 29.34v-.01-.02c-.02-3.13-2.57-5.67-5.7-5.67-3.13 0-5.68 2.54-5.7 5.67v.04c.01.2.17.36.38.36.2 0 .37-.16.38-.36v-.01-.01A4.946 4.946 0 0120 24.41c2.72 0 4.93 2.2 4.94 4.92v.02c.01.2.17.36.38.36.21.01.37-.16.38-.37 0 .01 0 .01 0 0z" />
      </g>
    </svg>
  );
}

export default SvgNonBasket;
