import React, { useEffect, useState, useContext, useRef } from "react"
import { Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import * as Icons from './../../icons/index'
import { gaView } from './../../../package/google-analytic'
import { visitTag } from './../../../package/criteo'

import { useTranslation } from 'react-i18next'
import { LanguageContext } from './../../../context/LanguageContext'
import { FiltersContext } from './../../../context/FiltersContext'

import OrderTicket from "./ticket"
import Loading from "./../global/loading"

function Coupons(props) {

	const { t } = useTranslation()
	const { getCurrencyID } = useContext(LanguageContext)
	const { getGroupName, getGroupUrl } = useContext(FiltersContext)

	const [ data, setData] = useState(null)
	
	const isFirstRun = useRef(true)

	useEffect(() => {

		if(isFirstRun.current){
			isFirstRun.current = false
			gaView()
			visitTag()

			axios.get("/GetCoupons?page=1&pagesize=10")
			.then(res => {
				if(res?.data?.isSuccess){
					setData(res.data.data || [])
				}
			})
		}
	}, [props])

	return (
			<>
			<div className="scl-content-title">
				<h1 className="base-regular">{t('UserPage.coupon.title').toUpperCase()}</h1>
			</div>
			<div className="scl-section-content list coupons">
				{
					!data ?
						<Loading />
					:
					data.length <= 0 ? (
						<div className="basket-empty base-light order-empty">
							<Icons.NonBasket />
							<span>{t('UserPage.coupon.non')}</span>
						</div>
					) : (
						<>
						<div className="row g-0 head">
							<div className="col-4">{t('UserPage.coupon.code')}</div>
							<div className="col-4">{t('UserPage.coupon.discount')}</div>
							<div className="col-3">{t('UserPage.coupon.expire')}</div>
						</div>
						{
							data.length > 0 && data.map((elm, i) =>
								<div key={i} className="row g-0 pt-lg-2 pb-lg-2">
									<div className="col-4 head">
										<strong>{elm.code}</strong>
										<br/>
										<small>
										{
											elm.attributes === 64 ?
												t('UserPage.coupon.all-product')
											:
												<Link to={`/${getGroupUrl(elm.leadgroupid)}`}>{getGroupName(elm.leadgroupid)} {t('UserPage.coupon.group')}</Link>
										}
										</small>
									</div>
									<div className="col-4 head">{elm.discount ? "%" + elm.discount  : elm.value + " " + getCurrencyID(elm.currencyid, 'id', 'name')} {t('UserPage.coupon.discount')}</div>
									<div className="col-3 head">{elm.isactive ? moment(elm.expire).format('DD/MM/YYYY HH:mm') : t('UserPage.coupon.expire-true')}</div>
								</div>
							)
						}
						</>
					)
				}
			</div>
			<OrderTicket />
			</>
	);
}

export default Coupons;
