import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function Breadcrumb(props) {

	const { t, i18n } = useTranslation();

	const [data, setData] = useState(null)

	useEffect(() => {
		setData(props.data)
	}, [props])

	return (
		<nav aria-label="breadcrumb">
			<div className="container">
				<ol className="breadcrumb base-light">
					<li className="breadcrumb-item">
						<Link to="/">{t('Global.home')}</Link>
					</li>
					{
						data && data.map((elm, i) =>
							<li key={"breadcrumb_" + i} className="breadcrumb-item">
								{elm.url ? <Link to={elm.url}>{elm.title}</Link> : <span>{elm.title}</span>}
							</li>
						)
					}
				</ol>
			</div>
		</nav>
	)
}

export default Breadcrumb
