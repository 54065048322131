import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import queryString from 'query-string'
import config from './../../package/config'
import { gaView } from './../../package/google-analytic'
import { visitTag } from './../../package/criteo'
import * as Icons from './../icons/index'

import SeoComponent from './../../rComponents/global/seoComponent'

import Button from './../widgets/forms/button'
import Loading from './../widgets/global/loading'

function Unsubscribe(props) {

	const [success, setSuccess] = useState(null)
	const [error, setError] = useState(null)
	const [permission, setPermission] = useState(false)
	const [queryStrings, setQueryStrings] = useState(null)
	const isFirstRun = useRef(true)

	useEffect(() => {
		if(isFirstRun.current){
			isFirstRun.current = false;
			gaView()
			visitTag()

			const parsed = queryString.parse(props.location.search);
			if(!(parsed.email && parsed.token)){
				props.history.replace("404")
			}else{
				setQueryStrings(parsed)
				setPermission(true)
			}
		}
	}, [props])

	const onSubmit = (data) => {
		const options = {
			headers: {
				email: queryStrings.email,
				token: queryStrings.token
			}
		}
		axios.get("/Unsubscribe", options)
		.then(res => {
			if(res?.data?.isSuccess){
				setSuccess(true)
			}else{
				setError("Geçersiz işlem !")
			}
		})
	}

	const seoData = {
		title: "Abonelikten çık" + config.prefix + config.name,
		description: "Abonelikten çık"
	}

	return (
		<>
			<SeoComponent {...seoData}/>
			<div className="container mt-5 pt-5 pb-3">
				{
					!permission &&
					<Loading />
				}
				{
					permission &&
					<div className="row mt-3">
						<div className="col text-center">
							{
								success && !error &&
								<>
									<h5 className="text-center pb-3">Seni Özleyeceğiz..</h5>
									<img src="/img/sad.png" style={{margin: 'auto', width: '250px', opacity: .8, marginTop: '4em'}} alt="" />
									<div className="text-right mt-5 pt-5">
										<Icons.UpPoint width={undefined} height="5em" className="r180 red"/>
										Tekrar abone olmak istersen buradan olabilirsin 🥺
									</div>
								</>
							}
							{
								!success && !error &&
								<div className="pb-5 mb-5">
									<h5 className="text-center pb-2">Abonelikten çıkmak istediğinize emin misiniz?</h5>
									<Button type="submit" name="submit" placeholder="twitter" className="btn" title="ABONELİKTEN ÇIK" onClick={onSubmit}/>
								</div>
							}
							{
								error &&
								<div className="row justify-content-center pb-5 mb-5">
									<div className="col col-lg-6">
										<div className="errorMessage"><Icons.CloseCircle />{error}</div>
									</div>
								</div>
							}
						</div>
					</div>
				}
			</div>
		</>
	)
}

export default Unsubscribe
