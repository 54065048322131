import React, { useState, useEffect, useContext} from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'

import { AuthContext } from './../../../../context/AuthContext'

import Modal from './../../../global/modal/modal'

import PhoneSmsCode from './../../../ui/global/phoneSmsCode'
import IbanAdd from './../../userPage/ibans/add'
import IbanList from './../../userPage/ibans/list'
import IbanForm from './../../userPage/ibans/form'

import Loading from './../../../../components/widgets/global/loading'
import Input from './../../../../components/widgets/forms/input'
import Button from './../../../../components/widgets/forms/button'

function CancelModal(props) {

	const { isAuth } = useContext(AuthContext)

	const [step, setStep] = useState(1)
	const [smsCode, setSmsCode] = useState(null)
	const [codeTime, setCodeTime] = useState(null)
	const [addShow, setAddShow] = useState(false)
	const [updateCounter, setUpdateCounter] = useState(0)
	const [isBusy, setIsBusy] = useState(false)

	useEffect(() => {
		if(props.cancelData && !props.cancelData?.orderChoices && step === 1){
			orderCancelCheck(props.cancelData);
		}
		if(props.cancelData && props.cancelData?.bank && step === 1){
			orderCancel();
		}
	}, [props.cancelData])

	useEffect(() => {
		if(!isAuth && smsCode){
			orderCancel(undefined)
		}
	}, [smsCode])

	const addModalOpen = () => {
		setAddShow(true)
	}

	const addModalClose = () => {
		setAddShow(false)
	}

	const updateHandler = () => {
		setUpdateCounter(updateCounter + 1)
	}

	const orderCancel = (e) => {
		if(e){
			e.preventDefault()
		}

		let error
		let newData = {
			code: props.cancelData?.orderChoices?.code ?? props.cancelData?.code
		}

		if(props.cancelData?.orderChoices?.askiban){
			if(!props.cancelData?.bank?.name?.trim()?.length || !props.cancelData?.bank?.iban?.trim()?.length){
				error = "Lütfen bilgileri doğru giriniz"
			}

			newData = {...newData, bank: props.cancelData.bank}
		}

		if(smsCode){
			newData = {...newData, smsCode: smsCode.code}
		}

		if(!error){
			axios.post("/CancelOrder", newData)
			.then(res => {
				if(res?.data?.statusCode === 200){
					if(props.updateData){
						props.updateData()
					}
					Swal.fire('Sipariş iptal edildi', '', 'success')
					props.setCancelData(null)
					setStep(1)
				}

				if(res?.data?.statusCode === 204 || res?.data?.statusCode === 800 || res?.data?.statusCode === 807){
					setStep(2)
					setCodeTime(res.data.data ?? 200)
					if(!res?.data?.isSuccess){
						props.setCancelData({...props.cancelData, error: res.data.success})
					}else{
						props.setCancelData({...props.cancelData, error: res.data.error})
					}
				}

				if(res?.data?.statusCode === 700){
					Swal.fire('Bir sorun oluştu, lütfen sonra deneyiniz!', '')
				}
			})
		}else{
			props.setCancelData({...props.cancelData, error: error})
		}
	}

	const orderCancelCheck = () => {
		const order = props.cancelData
		const askiban = order.askiban

		Swal.fire({
			title: 'İptal etmek istediğinize emin misiniz?',
			showCancelButton: true,
			cancelButtonText: 'Vazgeç',
			confirmButtonText: `Evet, iptal et`,
			showLoaderOnConfirm: true,
		}).then((result) => {
			if (result.isConfirmed) {
				if(askiban){
					props.setCancelData({...props.cancelData, orderChoices: order})
				}else{
					orderCancel(undefined)
				}
			}
		})
	}

	const ibanFormChange = data => {
		props.setCancelData({...props.cancelData, bank: {iban: data.iBan, name: data.fullName}})
	}

	return (
		<Modal show={(!!props.cancelData?.orderChoices || step === 2)} size={isAuth && "large"} closeHandler={() => props.setCancelData(null)} rePosition={isBusy}>
			{
				step === 1 &&
				<>
					<div className="red pb-3">{props.cancelData?.error ?? "Geri ödeme için hesap bilgileriniz gerekiyor"}</div>
					{
						isAuth && !isBusy &&
						<>
							<IbanList updateHandler={updateCounter} selectHandler={ibanFormChange} selected={props.cancelData?.bank?.iban} nonBtnClose={true}/>
							<IbanAdd show={addShow} modalClose={addModalClose} updateHandler={updateHandler}/>
							<div className="row mt-3">
								<div className="col text-left">
									<Button className="btn" title="IBAN EKLE" onClick={addModalOpen}/>
								</div>
								<div className="col text-right">
									<Button className="btn" title="TAMAMLA" onClick={orderCancel}/>
								</div>
							</div>
						</>
					}
					{
						!isAuth && !isBusy &&
						<IbanForm onSubmit={ibanFormChange}/>
					}
				</>
			}
			{
				step === 2 && !isBusy &&
				<PhoneSmsCode time={codeTime} error={props?.cancelData?.error} onSubmit={e => setSmsCode(e)} />
			}
			{
				isBusy && <Loading />
			}
		</Modal>
	)
}

export default CancelModal
