import * as React from "react";

function SvgTickBold(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <g fill="currentColor">
        <path d="M16.31 26.66l-6.22-7.6a.555.555 0 00-.78-.08c-.24.2-.27.55-.08.79l7.06 8.63c.11.13.27.19.43.2.16 0 .32-.07.42-.2l13.58-16.1c.2-.23.17-.59-.06-.79a.554.554 0 00-.78.06l-12.72 15.1c-.23.27-.63.26-.85-.01z" />
        <path d="M16.72 29.4h-.08c-.4-.04-.73-.21-.96-.49l-7.06-8.63c-.47-.58-.39-1.43.18-1.9.57-.47 1.42-.39 1.89.19l6.04 7.38 12.54-14.87c.23-.28.56-.44.92-.47.36-.03.71.08.98.32a1.37 1.37 0 01.16 1.91l-13.58 16.1c-.26.29-.63.46-1.03.46zm-.18-1.5zm.17-.24l.02.02.02-.02h-.04z" />
      </g>
    </svg>
  );
}

export default SvgTickBold;
