import React from 'react'
import TypeLink from './../global/link'

function HomeHashtag(props) {
	return (
		<div className="banner p-5 pb-2 pt-2">
			<div className="container">
				<div className="row g-3">
					{
						props?.data?.data.map((elm, i) =>
							<div className="col" key={i}>
								<TypeLink to={elm?.url}>
									<img src={elm?.img} alt={props?.data?.title}/>
								</TypeLink>
							</div>
						)
					}
				</div>
			</div>
		</div>
	)
}

export default HomeHashtag
