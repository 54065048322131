import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Icons from './../../icons/index'
import Swal from 'sweetalert2'
import $ from 'jquery'
import axios from 'axios'
import config from './../../../package/config'
import { gaCheckout, gaPurchase } from './../../../package/google-analytic'

import { useTranslation } from 'react-i18next';
import { AuthContext } from "./../../../context/AuthContext"
import { BasketContext } from "./../../../context/BasketContext"
import { FiltersContext } from "./../../../context/FiltersContext"
import { LanguageContext } from './../../../context/LanguageContext'

import Input from "./../forms/input"
import Button from "./../forms/button"
import Checkbox from "./../forms/checkbox"
import Textarea from "./../forms/textarea"
import BasketStepsBar from "./basket-steps-bar"
import PaymentOptions from './../detail/payment-options'
import Loading from './../global/loading'


import PhoneSmsCode from './../../../rComponents/ui/global/phoneSmsCode'

import Modal from './../../../rComponents/global/modal/modal'
import OpenArea from './../../../rComponents/global/openArea'

import PaymentInstalment from './../../../rComponents/global/paymentInstalment'

function BasketOrder(props){

	const history = useHistory()
	const { t } = useTranslation()
	const { basketData, totalPrice, subTotal, discountTotal, notInclude, setBinNumber, setStateBasket, cargoID } = useContext(BasketContext)
	const { getColorName } = useContext(FiltersContext)
	const { toPriceConvert, toPrice } = useContext(LanguageContext)
	const { isAuth } = useContext(AuthContext)

	const [resultMessage, setResultMessage] = useState(false)
	const [lastError, setLastError] = useState(null)
	const [errors, setErrors] = useState(null)
	const [installmentsData, setInstallmentsData] = useState(null)

	const [activePaymentType, setActivePaymentType] = useState([])
	const [paymentForm, setPaymentForm] = useState(null)
	const [paymentOrderCode, setPaymentOrderCode] = useState(null)
	const [paymentError, setPaymentError] = useState(null)
	const [tryAgain, setTryAgain] = useState(false)
	const [contractConfirm, setContractConfirm] = useState(false)
	const [mobilePaymentStep, setMobilePaymentStep] = useState(1)
	const [banks, setBanks] = useState(null)
	const [smsStep, setSmsStep] = useState(0)
	const [smsTime, setSmsTime] = useState(0)

	const [walletCode, setWalletCode] = useState(null)
	const [wallet, setWallet] = useState(null)

	const trChar = {"ş": "s", "ö": "o", "ç": "c", "ü": "u", "ğ": "g", "ı": "i", "İ": "I", "Ş": "S", "Ç": "C", "Ğ": "G", "Ü": "U", ".": ""}

	const payDoor = [
		{cash: true, key:3, name: t('Basket.payment.options.cash')},
		{cash: false, key: 3, name: t('Basket.payment.options.not-cash')}
	]

	useEffect(() => {
		if(Object.keys(errors ?? {})?.length > 0){
			$("html, body, .App").stop().animate({ scrollTop: 100}, 500);
		}
	}, [errors])

	useEffect(() => {
		getPaymentTypes()

		axios.get('/GetBanksForMoneyTransfer')
		.then(res => {
			if(res?.data?.isSuccess){
				setBanks(res.data?.data.map(e => {
					return {...e, key: 1, id: e.code, info: `${e.iBan} <br /> ${e.info}`, cash: false}
				}))
			}
		})
	}, [props.step])

	useEffect(() => {
		if(activePaymentType?.length > 0){
			if(activePaymentType.includes(2)){
				props.setChoicesPaymentType({key: 2, name: "Kredi Kartı"})
			}else if(activePaymentType.includes(1)){
				props.setChoicesPaymentType(banks[0])
			}else if(activePaymentType.includes(3)){
				props.setChoicesPaymentType(payDoor[0])
			}
		}
	}, [activePaymentType])

	useEffect(() => {
		getBankInfo()
	}, [totalPrice])

	const getPaymentTypes = () => {
		axios.get('/GetPaymentTypes')
		.then(res => {
			if(res?.data?.isSuccess){
				setActivePaymentType(res.data?.data?.map(e => e.id) ?? [])
			}
		})
	}

	const completeOrder = async (smsCode = "") => {
		if(!contractConfirm && window.innerWidth <= 768){
			setMobilePaymentStep(2)
			return false
		}

		if(parseInt(totalPrice) > 0 && !props?.choicesPaymentType || !props?.choicesPaymentType?.key){
			setResultMessage("Ödeme yönteminizi seçiniz")
			setErrors({...errors, PaymentType : {message: "Bir seçim"}})
			return
		}

		if(!contractConfirm){
			Swal.fire({
				title: 'Sözleşmeleri onaylamalısın!'
			})
			return false
		}

		if(parseInt(totalPrice) > 0 && props.choicesPaymentType.key === 2){
			if(props?.creditCard?.CardNumber?.replace(/ |_/gi, "")?.length !== 16){
				setResultMessage("Kart Numarasını doğru girmelisiniz!")
				setErrors({...errors, CardNumber: {message: "Yanlış format!"}})
				return
			}
			if(props?.creditCard?.HolderName?.trim()?.split(' ')?.length < 2 || !props?.creditCard?.HolderName){
				setResultMessage("AD SOYAD girilmelisiniz")
				setErrors({...errors, HolderName: {message: "Ad Soyad giriniz"}})
				return
			}

			if(props?.creditCard?.Month?.length !== 2 || props?.creditCard?.Year?.length !== 2){
				setResultMessage("Kart Son kullanım Tarihini doğru formatta girmelisiniz")
				setErrors({...errors, Date: {message: "Kart Tarihi yanlış"}})
				return
			}

			if(props?.creditCard?.CVV?.length !== 3){
				setResultMessage("CVV Doğru giriniz")
				setErrors({...errors, CVV: {message: "CVV Doğru giriniz"}})
				return
			}
		}

		if(!props.choicesPaymentType && props.choicesPaymentType?.key !==2){
			setResultMessage(t('Basket.payment.error'))
			$("html, body, .App").stop().animate({ scrollTop: 100}, 300);
			return false
		}

		if(smsStep === 0 && !isAuth && props.choicesPaymentType.key === 3){
			setSmsStep(1)
			return false
		}

		const request = props.orderInsert(smsCode, props.walletCode)
		request.then(res => {
			if([204, 800, 805, 807, 808].indexOf(res.statusCode) >= 0){
				setSmsStep(2)
				if(res?.data){
					setSmsTime(res.data)
				}
				if(res.statusCode === 805){
					setResultMessage("Sadece Türkiye kayıtlı telefon numaraları")
				} else if(res.statusCode === 807){
					setResultMessage("Doğrulama kodu yanlış")
				}
			} else {
				setResultMessage(res.error ?? "")
			}

			if(res.statusCode === 200){
				const gaData = {
					items: basketData.items.map(e => {
						return {
							id: e.variantCode,
							title: e.detail.product.title,
							brand: e.detail.product.brandCode,
							variant: getColorName(e.detail.product.colorCode),
							colorCode: e.productColorCode,
							price:  e.sellingPrice,
							quantity: e.quantity
						}
					}),
					purchase: {
						id: res.data.Code,
						affiliation: 'SecilStore',
						revenue: toPrice(totalPrice),
						shipping: toPrice(basketData?.cargo?.price)
					}
				}

				let checkoutOption
				if(props.choicesPaymentType?.key === 1){
					checkoutOption = "HAVALE"
				}else if(props.choicesPaymentType?.key === 2){
					checkoutOption = "SANAL POS"
				}else if(props.choicesPaymentType?.key === 3){
					checkoutOption = "KAPIDA " + (props.choicesPaymentType.cash ? 'NAKİT' : 'KREDİ KARTI')
				}

				gaCheckout(gaData, {step: 3, option: checkoutOption})
				sessionStorage.setItem('ga', JSON.stringify(gaData))

				if(props.choicesPaymentType?.key === 3 || props.choicesPaymentType?.key === 1 || (props.choicesPaymentType?.key === 2 && !res.data.PaymentForm && props.wallet)){
					history.push(`/sepet/siparis-ozeti?success=${res.data.Code}`)
				}else{
					setPaymentForm(res.data.PaymentForm)
					setPaymentOrderCode(res.data.Code)
				}
			}else{
				$("html, body, .App").stop().animate({ scrollTop: 100}, 300);
			}
		})
	}

	const setCreditCard = e => {
		if(e.target.name === "Date"){
			var value = e.target.value.split('/')
			props.setCreditCard({...props.creditCard, Month: value[0], Year: value[1]})
		}else{
			props.setCreditCard({...props.creditCard, [e.target.name]: e.target.value})
		}

		if(e.target.name === "CardNumber"){
			getBankInfo()
		}
	}

	const getBankInfo = () => {
		let str = props?.creditCard?.CardNumber
		if(str){
			str = str.replace(/(_)/gi, "").replace(/ /gi, "")
			const price = totalPrice
			if(str.length > 6){
				str = str.slice(0, 6)
				setBinNumber(str)
				setErrors({...errors, CardNumber: undefined})

				axios.get(`/GetBankInfo?price=${price}&cardnumber=${str}`)
				.then(res => {
					if(res?.data?.isSuccess){
						setInstallmentsData(res.data.data)
						props.setChoicesInstallment(res.data?.data?.installments[0])
					}else{
						setInstallmentsData(null)
						props.setChoicesInstallment(null)
					}
				})
			}else{
				if(installmentsData){
					setInstallmentsData(null)
				}
			}
		}
	}

	const tabChange = (value) => {
		setResultMessage("")
		if(value === 3){
			setStateBasket("hasCod", true)
		}else{
			setStateBasket("hasCod", false)
		}

		if(value === 1){
			props.setChoicesPaymentType({key: 2, name: "Kredi Kartı"})
		}else if(value === 2){
			props.setChoicesPaymentType(banks[0])
		}else if(value === 3){
			props.setChoicesPaymentType(payDoor[0])
		}else{
			props.setChoicesPaymentType(null)
		}
	}

	const orderStatus = () => {
		if(paymentOrderCode){
			axios.get('/GetOrderStatus', {headers:{code: paymentOrderCode}})
			.then(res => {
				if(res.data?.data?.id === 7){
					setPaymentForm(null)
					setPaymentError(res.data.data.title)
				}
			})
		}
	}

	const walletInfo = (_walletCode) => {
		setWalletCode(_walletCode)
		if(_walletCode) {
			axios.get('/GetWallet', {params: {code: _walletCode}})
			.then(res => {
				if(res.data?.data) {
					setWallet(res.data?.data)
					setActivePaymentType([2])
				} else {
					getPaymentTypes()
					setWallet(null)
					props.setWalletCode(null)
				}
			})
		} else {
			getPaymentTypes()
			setWallet(null)
			props.setWalletCode(null)
		}
	}

	const contractControl = () => {
		if(!props.preliminaryInformation || !props.distanceSalesAgreement){
			props.updateContract()
		}
	}

	return (
		<div className="basket-order">
			<div className="container">
				<BasketStepsBar step={props.step} changeStep={props.changeStep}/>
				{
					paymentError?.length > 0 &&
					<div className="row pb-4 justify-content-center">
						<div className="col-8">
							<div className="errorMessage paymentError mb-5"><Icons.CloseCircle />
							Ödeme Alınamadı <br/>
							{paymentError}</div>
							<span onClick={() => {
								setPaymentForm(null)
								setPaymentOrderCode(null)
								setPaymentError(null)
							}} className="btn float-right">Tekrar Dene</span>
						</div>
					</div>
				}
				{
					paymentForm &&
					<div className="row d-flex justify-content-center position-relative">
						<Loading className="fix" style={{height: '100px', background: "transparent"}} />
						<iframe src={"data:text/html," + encodeURIComponent(paymentForm)} onLoad={orderStatus} style={{minHeight: `${window.innerHeight}px`}}/>
					</div>
				}
				{
					!paymentForm && !paymentError &&
					<div className="row d-flex justify-content-center pb-5 pb-md-0">
						<div className="col-12 col-lg-6 mr-lg-3 mb-4">
							<h1 className="tabs mb-4">
								{activePaymentType.includes(2) && <span className={props.choicesPaymentType?.key === 2 ? 'active': undefined} onClick={() => tabChange(1)}>KREDİ KARTI</span>}
								{activePaymentType.includes(1) && <span className={props.choicesPaymentType?.key === 1 ? 'active': undefined} onClick={() => tabChange(2)}>HAVALE/EFT</span>}
								{activePaymentType.includes(3) && <span className={props.choicesPaymentType?.key === 3 ? 'active': undefined} onClick={() => tabChange(3)}>{t('Basket.payment-type.door')}</span>}
							</h1>
							{
								smsStep !== 2 && resultMessage.length > 0 &&
								<div className="row pb-4">
									<div className="errorMessage"><Icons.CloseCircle />{resultMessage}</div>
								</div>
							}
							<div className="row p-3 p-lg-4 bgw">
								<div className="col">
									{
										props.choicesPaymentType?.key === 2 &&
										<div className="row flex-lg-row">
											<div className="col-12 col-lg-7">
												<form method="POST" onSubmit={e => e.preventDefault()}>
													<Input type="card" name="CardNumber" value={props?.creditCard?.CardNumber} autoComplete="cc-number" error={errors?.CardNumber?.message} title="KART NUMARASI *" onChange={setCreditCard} onBlur={e => {
														let value = e.target?.value
														value = value.replace(/(_)/gi, "").replace(/ /gi, "")
														if(value?.length !== 16){
															setErrors({...errors, CardNumber: {message:"Uygunsuz kart numarası"}})
														}
													}}/>
													<Input type="text" name="HolderName" onChange={setCreditCard} value={props?.creditCard?.HolderName} autoComplete="cc-name" title="KARTIN ÜZERİNDEKİ AD SOYAD *"/>
													<div className="row">
														<div className="col-12 col-lg-8">
															<Input type="last" name="Date" title="SON KULLANMA TARİHİ *" error={errors?.Date?.message} value={props?.creditCard?.Month+"/"+props?.creditCard?.Year} autoComplete="cc-exp" onChange={setCreditCard} onKeyUp={e => {
																var ss = e.target.value.split('/')
																if(parseInt(ss[0]) > 12){
																	setErrors({...errors, "Date": {message: "Tarih geçmiş"}})
																}else if(parseInt(ss[1]) < 21){
																	setErrors({...errors, "Date": {message: "Tarih geçmiş"}})
																}else{
																	setErrors({...errors, "Date": null})
																}
															}}/>
														</div>
														<div className="col-12 col-lg-4">
															<Input onChange={setCreditCard} name="CVV" value={props?.creditCard?.CVV} title="CVV *" autoComplete="cc-csc"/>
														</div>
													</div>
												</form>
											</div>
											<div className="col-12 col-lg-5 mb-4">
												<div className="credit-card">
													<div className="bank">
														{installmentsData?.logo && <img src={installmentsData?.logo} alt={installmentsData?.name}/>}
													</div>
													<div className="cardno">{props?.creditCard?.CardNumber?.replace(/_/gi, '')}</div>
													<div className="name">{props?.creditCard?.HolderName}</div>
													<div className="date">{props?.creditCard?.Month && props?.creditCard?.Year ? (props?.creditCard?.Month + "/" + props?.creditCard?.Year) : '' }</div>
													<div className="card-brand">
														{installmentsData?.brandLogo && <img src={installmentsData?.brandLogo} alt={installmentsData?.brand}/>}
													</div>
												</div>
												<div className="row mt-lg-4 bgw">
													<div className="col-12">
														{
															installmentsData &&
															<PaymentInstalment setChoicesInstallment={props.setChoicesInstallment} choicesInstallment={props.choicesInstallment} data={installmentsData?.installments}/>
														}
														{
															!installmentsData && <div>Taksit seçeneklerini görmek için kart numarasını girmelisiniz</div>
														}
													</div>
												</div>
											</div>
										</div>
									}
									{
										props.choicesPaymentType?.key === 1 &&
										<>
											<h1>ÖDEME YAPACAĞINIZ BANKA HESAP NUMARASINI SEÇİNİZ</h1>
											<div className="choices-select">
												{
													banks.map((elm, i) =>
														<span key={i} className={"item" + (elm.id === props.choicesPaymentType?.id ? ' active': '')}
														onClick={() => {
															props.setChoicesPaymentType(elm)
															setResultMessage("")
														}}>
															<div className="row align-items-center text-center text-lg-left">
																<div className="col-12 col-lg-3">
																	<img src={elm.logo} alt={elm.info}/>
																</div>
																<div className="col-12 col-lg-9 pt-3 pt-lg-0" dangerouslySetInnerHTML={{__html: elm.info}}></div>
															</div>
															<span className="check"></span>
														</span>
													)
												}
											</div>
										</>
									}
									{
										props.choicesPaymentType?.key === 3 &&
										<>
											<h1>{t('Basket.payment.door-title')}</h1>
											<div className="choices-select">
												{
													payDoor.map((elm, i) =>
														<span
															key={i}
															className={"item" + (elm.name === props.choicesPaymentType?.name ? ' active': '')}
															onClick={() => {
																props.setChoicesPaymentType(elm)
																setResultMessage("")
															}}
															><span className="check"></span>{elm.name}</span>
													)
												}
											</div>
										</>
									}
								</div>
							</div>
							{ props.walletOn &&
							<div className="row mt-4 bgw">
								<div className="col">
									<OpenArea title={t('Basket.wallet-title')} show={false} scroll={false}>
										<div class="form input-group mb-3">
											<Input type="text" name="WalletCode" onChange={ e => walletInfo(e.target.value) } value={walletCode} placeholder="kod" />
											<Icons.Close className="input-button" onClick={ e => walletInfo("") }/>
											<button type="button" class="btn btn-sm" onClick={ e => props.setWalletCode(walletCode) }>Uygula</button>
										</div>
										{ wallet &&
											<div className="row green">
												<div className="col-12 pb-3">
													<span>{t('Basket.wallet-amount')}: </span><span>{`${wallet?.amount} ${wallet?.currency?.name}`}</span>
												</div>
											</div>
										}
									</OpenArea>
								</div>
							</div>
							}
							<div className="row mt-4 bgw">
								<div className="col">
									<OpenArea title="SİPARİŞ NOTU" show={true} scroll={false}>
										<Textarea name="extraNote" placeholder="İletmek istediğiniz mesajı yazınız." max={100} onChange={e => props.setExtraNote(e.target.value)}/>
										{/*<Checkbox name="isGift" label="Hediye paketi olsun mu? <small>(4,90 TL)</small>" checked={props.isGift} onChange={() =>  {
											const newValue = !props.isGift
											props.setIsGift(newValue)
											setStateBasket('isGift', newValue, true)
										}}/>*/}
									</OpenArea>
								</div>
							</div>
							<div className="row mt-4 bgw">
								<div className="col">
									<OpenArea title="ADRESLERİM" scroll={false}>
										<div className="row mt-2 pt-1 pb-1">
											<div className="col col-lg-6">
												<h1>{t('Basket.address.delivery')}</h1>
												<div className="address-select">
												{
													[props.choicesDeliveryAddress].map((elm, i) =>
													<div key={i} className={"address w-100" + (elm.isPersonal ? ' personal' : '')} style={{height: "auto"}}>
														{elm.title && <span>{elm.title}</span>}
														<span>{elm.name}</span>
														<span>{elm.city.name} / {elm.county.name}</span>
														<span>{elm.phone}</span>
														<span>{elm.addressText}</span>
														{
															!elm.isPersonal &&
															<>
																<span>{elm.companyName}</span>
																<span>{elm.taxName} / {elm.taxNo}</span>
															</>
														}
														<span className="edit" onClick={() => props.changeStep(2)}><Icons.EditPenBold /></span>
													</div>
													)
												}
												</div>
											</div>
											<div className="col col-lg-6">
												<h1>{t('Basket.address.invoice')}</h1>
												<div className="address-select">
												{
													[(props.useSame ? props.choicesDeliveryAddress : props.choicesInvoiceAddress )].map((elm, i) =>
														<div key={i} className={"address w-100" + (elm.isPersonal ? ' personal' : '')} style={{height: "auto"}}>
															{elm.title && <span>{elm.title}</span>}
															<span>{elm.name}</span>
															<span>{elm.city.name} / {elm.county.name}</span>
															<span>{elm.phone}</span>
															<span>{elm.addressText}</span>
															{
																!elm.isPersonal &&
																<>
																	<span>{elm.companyName}</span>
																	<span>{elm.taxName} / {elm.taxNo}</span>
																</>
															}
															<span className="edit" onClick={() => props.changeStep(2)}><Icons.EditPenBold /></span>
														</div>
													)
												}
												</div>
											</div>
										</div>
									</OpenArea>
								</div>
							</div>
							<div className="row mt-4 bgw">
								<div className="col">
									<OpenArea title="ÖN BİLGİLENDİRME FORMU" reset={props.preliminaryInformation} openHandle={contractControl}>
										{!props.preliminaryInformation && <Loading />}
										{props.preliminaryInformation && <pre className="scl-scroll" dangerouslySetInnerHTML={{__html: props.preliminaryInformation}}></pre>}
									</OpenArea>
								</div>
							</div>
							<div className="row mt-4 bgw">
								<div className="col">
									<OpenArea title="MESAFELİ SATIŞ SÖZLEŞMESİ" openHandle={contractControl}>
										{!props.distanceSalesAgreement && <Loading />}
										{props.distanceSalesAgreement && <pre className="scl-scroll" dangerouslySetInnerHTML={{__html: props.distanceSalesAgreement}}></pre>}
									</OpenArea>
								</div>
							</div>
						</div>
						<div className="col-12 col-lg-4">
							<div className="row p-3 p-lg-5 basket-info bgw">
								<div className="col">
									<div className="row">
										<h2>{t('Basket.summary')}</h2>
									</div>
									<div className="row base-light">
										<div className="col-7 p-0">{t('Basket.product')}</div>
										<div className="col-2 text-center">{t('Basket.quantity')}</div>
										<div className="col-3 text-right">{t('Basket.price')}</div>
									</div>
									{
										basketData?.items.map((elm, i) => {
											if(notInclude.indexOf(elm.variantCode) <= -1){
												return <div key={i} className="row dashedTB pb-2 pt-2 align-items-center">
													<div className="col-3 pr-3 p-0"><img src={elm.detail.product.imagePath !== "" ? elm.detail.product.imagePath : config.noImage} width="100%" alt=""/></div>
													<div className="col-4 p-0">
														<span>{/*elm.detail.product.brandCode*/}{elm.detail.product.title}</span>
														<span className="base-light w-100 text-left mt-2" disabled>{t('Basket.color')}: {getColorName(elm.detail.product.colorCode)}</span>
														<span className="base-light w-100 text-left mt-2" disabled>{t('Basket.body')}: {elm.detail.name}</span>
													</div>
													<div className="col-2 text-center">{elm.quantity}</div>
													<div className="col-3 base-light text-right p-0 price">
														{toPriceConvert(elm.totalPriceWithoutDiscount)}
													</div>
												</div>
											}
											return undefined
										})
									}
									<div className="row base-light">
										<span>{t('Basket.product-price')} <small>{t('Basket.in-kdv')}</small></span>
										<span>{toPriceConvert(subTotal)}</span>
									</div>
									{
										discountTotal > 0 &&
										<div className="row green base-light">
											<span>{t('Basket.discount')}</span>
											<span>{toPriceConvert(discountTotal)}</span>
										</div>
									}
									{
										basketData?.cargo?.serviceCharge > 0 && props.choicesPaymentType?.key === 3 &&
										<div className="row">
											<span>{t('Basket.cargoService')}</span>
											<span>{toPriceConvert(basketData?.cargo?.serviceCharge)}</span>
										</div>
									}
									{
										basketData?.giftPrice > 0 &&
										<div className="row">
											<span>{t('Basket.giftService')}</span>
											<span>{toPriceConvert(basketData?.giftPrice)}</span>
										</div>
									}
									<div className="row green">
										<span>{t('Basket.cargo')} <small>{basketData.shipping?.filter(e => e.id === cargoID)?.[0]?.name}</small></span>
										<span>{toPriceConvert(basketData?.cargo?.price ?? 0)}</span>
									</div>
									{
										props.walletDiscount ?
										<div className="row green">
											<span>{t('Basket.wallet-discount')}</span>
											<span>{toPriceConvert('-'+props.walletDiscount)}</span>
										</div>
										: ''
									}
									<div className="row ">
										<span>{t('Basket.total-price')}</span>
										<span>{toPriceConvert(totalPrice)}</span>
									</div>
								</div>
							</div>
							<div className="row d-none d-md-block">
								<div className="col pt-4 pt-lg-0">
									<Checkbox label="Ön Bilgilendirme Formu'nu ve Mesafeli Satış Sözleşmesi'ni okudum, onaylıyorum." onChange={() => setContractConfirm(!contractConfirm)}/>
									<Button className="btn w-100" title={t('Basket.payment-complete')} onClick={() => completeOrder()} disabled={props.isBusy}/>
								</div>
							</div>
							<div className="row g-0 mobile-fixed d-flex d-md-none">
								{
									mobilePaymentStep === 2 &&
									<div className="col-12">
										<Checkbox label="Ön Bilgilendirme Formu'nu ve Mesafeli Satış Sözleşmesi'ni okudum, onaylıyorum." onChange={() => {
											setTimeout(() => {
												setMobilePaymentStep(1)
											}, 500)
											setContractConfirm(!contractConfirm)
										}}/>
									</div>
								}
								<div className="col-5 pl-2 price">
									<span>Ödenecek Tutar</span>
									{toPriceConvert(totalPrice)}
								</div>
								<div className="col-7">
									<Button className="btn w-100" title={t('Basket.payment-complete')} onClick={() => completeOrder()} disabled={props.isBusy}/>
								</div>
							</div>
						</div>
					</div>
				}
				<Modal show={smsStep > 0} closeButton={false}>
					{
						smsStep === 1 &&
						<div style={{fontSize: 16, lineHeight: 1.8}}>
							"{props.choicesDeliveryAddress.phone}" numarasına doğrulama kodu gönderilecektir
							<div className="pt-4">
								<Button className="btn float-right" title="Tamam" onClick={() => completeOrder()} />
								<Button className="btn btn-reverse float-right mr-4" title="Vazgeç" onClick={() => setSmsStep(0)} />
							</div>
							<div className="clearfix"></div>
						</div>
					}
					{
						smsStep === 2 &&
						<PhoneSmsCode time={smsTime} error={resultMessage} onCancel={() => setSmsStep(0)} onSubmit={e => completeOrder(e?.code)} onChange={() => setResultMessage(null)} finishedHandler={() => {
							setSmsTime(0)
						}}/>
					}
				</Modal>
				<div className="clear-fix"></div>
			</div>
		</div>
	)
}

export default BasketOrder;
