import React, { Component, createContext, } from 'react'
import Loading from './../components/widgets/global/loading'
export const LoadingContext = createContext()

class LoadingContextProvider extends Component {
	constructor( props ) {
		super( props )

		this.state = {
			loadingState: false
		}
	}

	closeLoading = () => {
		this.setState({loadingState: false})
	}

	openLoading = () => {
		this.setState({loadingState: true})
	}

	loadingRender = () => {
		return this.state.loadingState && <Loading />
	}

	render() {
		return (
			<LoadingContext.Provider value={{...this.state, closeLoading: this.closeLoading, openLoading: this.openLoading, loadingRender: this.loadingRender}}>
				{this.props.children}
			</LoadingContext.Provider>
		)
	}
}

export default LoadingContextProvider
