import React from 'react'
import styles from './login.module.css'
import config from './../../package/config'

import SocialLoginButtons from './../ui/modal/socialLoginButtons'

import Input from './../../components/widgets/forms/input'
import Button from './../../components/widgets/forms/button'
import Checkbox from './../../components/widgets/forms/checkbox'

import * as Icons from './../../components/icons'

import { useTranslation } from 'react-i18next'

function Login(props) {

	const {t} = useTranslation()

	return (
		<div className={styles.login}>
			<h2>{t('LoginModal.login.message')}</h2>
			{
				props.error?.length > 0 &&
				<div className="errorMessage mb-4">
					<Icons.CloseCircle />
					{props.error}
				</div>
			}
			{
				props.leaveAuth &&
				<div className="red text-center p-3">Oturumun düştü tekrar giriş yapman gerekecek</div>
			}
			{ config.app_name === "secil" &&
				<SocialLoginButtons />
			}
			<form onSubmit={props.onSubmit}>
				<div className="mb-3">
					<Input type="text" placeholder={t('LoginModal.login.mail-placeholder')} name="login_name" error={props.errors?.login_name?.message} autoComplete="email" transferref={props.register}/>
				</div>
				<div className="input-group mb-3">
					<Input type="password" placeholder={t('LoginModal.login.pass-placeholder')} name="login_password" autoComplete="current-password" error={props.errors?.login_password?.message} transferref={props.register}/>
				</div>
				<div className="row mt-2">
					<div className="col text-left">
						<Checkbox label={t('LoginModal.login.rememberme')} name="remember" transferref={props.register}/>
					</div>
					<div className="col text-right pointer" onClick={props.goForgotPassword}>Şifremi unuttum</div>
				</div>
				<div className="mt-3">
					<Button type="submit" className="btn" title={t('LoginModal.login.button')}/>
				</div>
			</form>
			{/*
				<h3 className="hr"><span>{t('LoginModal.login.or')}</span></h3>
			<div className="mb-3 fb-btn">
				<button type="button" className="btn">{t('LoginModal.login.facebook')}</button>
			</div>
			*/}
		</div>
	)
}

export default Login
