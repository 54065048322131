import * as React from "react";

function SvgVourBold(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M30.39 21.38H9.57c-.61 0-1.11-.49-1.11-1.11v-.55c0-.61.49-1.11 1.11-1.11h20.82c.61 0 1.11.49 1.11 1.11v.55c0 .61-.5 1.11-1.11 1.11z"
      />
    </svg>
  );
}

export default SvgVourBold;
