import React, { useContext, useEffect, useRef } from "react";
import * as Icons from './../../icons/index'
import { gaView } from './../../../package/google-analytic'
import config from './../../../package/config'
import { visitTag } from './../../../package/criteo'

import { useTranslation } from 'react-i18next'
import { FavoriteContext } from './../../../context/FavoriteContext'
import { LoadingContext } from './../../../context/LoadingContext'
import { FiltersContext } from './../../../context/FiltersContext'
import { AlarmContext } from './../../../context/AlarmContext'
import { LanguageContext } from './../../../context/LanguageContext'

function Favorite(props){

	const { t, i18n } = useTranslation()
	const { openLoading, closeLoading, loadingRender, loadingState } = useContext(LoadingContext)
	const { alarmData, getData, removeAlarm } = useContext(AlarmContext)
	const { getColorName } = useContext(FiltersContext)
	const { toPriceConvert } = useContext(LanguageContext)

	const isFirstRun = useRef(true)

	useEffect(() => {
		if(isFirstRun.current){
			isFirstRun.current = false
			gaView()
			visitTag()
			getData()
		}

		openLoading()
		if(!!alarmData){
			closeLoading()
		}
	}, [props])

	useEffect(() => {
		if(!!alarmData){
			closeLoading()
		}
	}, [alarmData])

	return (
		<>
		<div className="scl-content-title">
			<h1 className="base-regular">{t('UserPage.alarm.title').toUpperCase()}</h1>
		</div>
		<div className="scl-section-content list">
			{
				!alarmData || loadingState ?
					loadingRender()
				:
				alarmData.length <= 0 ? (
					<div className="basket-empty base-light order-empty">
						<Icons.NonBasket />
						<span>{t('UserPage.alarm.non')}</span>
						<a href="/" title="Alışverişe Başla" className="btn base-regular">{t('UserPage.alarm.non-btn')}</a>
					</div>
				) : (
					<>
					<div className="row g-0 head">
						<div className="col-7 col-lg-6">{t('UserPage.alarm.product')}</div>
						<div className="col-3 col-lg-2">{t('UserPage.alarm.price')}</div>
						<div className="col-lg-2 d-none d-lg-block">{t('UserPage.alarm.stock')}</div>
					</div>
					{
						alarmData.map((elm, i) =>
							<div key={i} className="row bdr g-0 base-regular">
								<div className="col-2 head">
									<a href={"/" + elm.product.url}><img src={elm.product.imagepath !== "" ? elm.product.imagepath : config.noImage} width="100px" alt=""/></a>
								</div>
								<div className="col-5 col-lg-4 head"><a href={"/" + elm.product.url}>{elm.product.title} <br/> <b>{t('Detail.info.color')}:</b> {getColorName(elm.product.colorcode)} <br /><b>{t('Detail.info.size')}:</b> {elm.size}</a></div>
								<div className="col-3 col-lg-2 head">{toPriceConvert(elm.product.price1)}</div>
								<div className="col-3 col-lg-3 head d-none d-lg-block">{elm.stock > 0 ? t('UserPage.alarm.in-stock') : t('UserPage.alarm.out-stock')}</div>
								<div className="col-1 edit float-right text-center head">
									<span onClick={() => removeAlarm({id: elm.id, variantCode: elm.code, type: elm.type})}><Icons.Close /></span>
								</div>
							</div>
						)
					}
					</>
				)
			}
		</div>
		</>
	);
}

export default Favorite;
