import React, { createContext, Component } from 'react'
import axios from 'axios'
export const GeoContext = createContext()

class GeoContextProvider extends Component {
	constructor( props ) {
		super( props )

		this.state = {
			requestData: [],
			geoData: []
		}
	}
/*
	componentDidMount(){
		const geodata = JSON.parse(sessionStorage.getItem("country"));
		if(geodata){
			this.setState({geoData: geodata})
		}else{
			this.updateData()
		}
	}
*/
	updateData(){
		axios.get("/GetAllGeoData")
		.then(res => {
			this.setState({requestData: res.data.data.countries})
		})
		this.toSmartData()
	}

	getGeoData = () => {
		return this.state.geoData
	}

	toSmartData = () => {
		const data = this.state.requestData
		let country = []
		for(var c = 0;c < data.length; c++){
			country.push({
				value: data[c].name,
				key: data[c].lang,
			})
		}

		this.setState({geoData: country})
		sessionStorage.setItem("country", JSON.stringify(country))
	}

	render() {
		return (
			<GeoContext.Provider value={{...this.state, getGeoData: this.getGeoData}}>
				{this.props.children}
			</GeoContext.Provider>
		)
	}
}

export default GeoContextProvider
