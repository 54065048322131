import * as React from "react";

function SvgCloseRegular(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="5 5 30 30"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M30.71 9.05a.555.555 0 00-.79.01L20.4 18.8c-.22.22-.57.22-.79 0l-9.53-9.74a.565.565 0 00-.79-.01c-.22.22-.22.57-.01.79l9.56 9.77c.21.22.21.56 0 .78l-9.56 9.77c-.21.22-.21.57.01.79.22.22.57.21.79-.01l9.53-9.74c.22-.22.57-.22.79 0l9.53 9.74c.21.22.57.22.79.01.22-.22.22-.57.01-.79l-9.56-9.77a.562.562 0 010-.78l9.56-9.77c.2-.22.2-.57-.02-.79z"
      />
    </svg>
  );
}

export default SvgCloseRegular;
