import React, { useState, useEffect, useContext, useRef } from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from 'axios'
import * as Icons from "./../../icons/index"
import { gaView } from './../../../package/google-analytic'
import { visitTag } from './../../../package/criteo'

import { useTranslation } from 'react-i18next'
import { LoadingContext } from './../../../context/LoadingContext'

import ModalAddress from './../../modal/address'

function BillingAddress(props) {

	const { t } = useTranslation();
	const MySwal = withReactContent(Swal)
	const { loadingState, openLoading, closeLoading, loadingRender } = useContext(LoadingContext)

	const [data, setData] = useState(null)
	const [editData, setEditData] = useState(false)

	const isFirstRun = useRef(true)

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false
			gaView()
			visitTag()

			addressUpdate()
		}
	}, [props])

	const addressUpdate = () => {
		openLoading()
		axios.get("/GetUserAddresses")
		.then(res => {
			setData(res.data.data || [])
			closeLoading()
		})
	}

	const addressDelete = (key) => {
		const data = {
			ID: key
		}
		MySwal.fire({
			title: t('UserPage.address.delete-modal.title'),
			text: t('UserPage.address.delete-modal.title'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			cancelButtonText: t('UserPage.address.delete-modal.cancel'),
			confirmButtonText: t('UserPage.address.delete-modal.confirm')
		}).then((result) => {
			if (result.isConfirmed) {
				axios.post("/DeleteAddress", data)
				.then(res => {
					MySwal.fire({
						title: t('UserPage.address.delete-modal.success'),
						icon: 'success',
						timer: 1500,
						confirmButtonText: t('UserPage.address.delete-modal.ok')
					})
					addressUpdate()
				})

			}
		})
	}

	return (
		<>
			<ModalAddress
				onSuccess={() => addressUpdate()}
				editData={editData} />
			<div className="scl-content-title">
				<h1 className="base-regular active">
					{t('UserPage.address.title')}
					<span className="btn user-title-btn float-right base-regular" data-bs-toggle="modal" data-bs-target="#adressModal" onClick={() => setEditData(false)}><Icons.PlusBold /> {t('UserPage.address.new-address')}</span>
				</h1>
				<div className="clearfix"></div>
			</div>
			<div className="scl-section-content list">
				{
					!data || loadingState ?
						loadingRender()
					:
						data.length <= 0 ? (
							<div className="basket-empty base-light order-empty">
								<Icons.NonBasket />
								<span>{t('UserPage.address.non')}</span>
								<a href="/" title={t('UserPage.address.new-address').toUpperCase()} className="btn base-regular" data-bs-toggle="modal" data-bs-target="#adressModal" onClick={() => setEditData(false)}>{t('UserPage.address.new-address').toUpperCase()}</a>
							</div>
						) : (
							<>
							<div className="row g-0 head">
								<div className="col-3">ADRES ADI</div>
								<div className="col-5">ADRES</div>
								<div className="col-2">TİP</div>
							</div>
							{
								data.map((elm, i) =>
									<div key={i} className="row g-0">
										<div className="col-3 head"><strong>{elm.title}</strong></div>
										<div className="col-5">{elm.name} - {elm.country.name}<br/>{elm.city.name} {elm.county.name}  <br/>{elm.phone}</div>
										<div className="col-2">
											{elm.isPersonal ? t('UserPage.address.invoice') : t('UserPage.address.corporate')}
											{!elm.isPersonal ?
												(
													<>
														<br/> {elm.taxName} - {elm.taxNo}
													</>
												) : undefined}
										</div>
										<div className="col-12 col-lg-2 edit float-right text-right mt-3 mt-lg-0">
											<span onClick={() => setEditData(elm)} data-bs-toggle="modal" data-bs-target="#adressModal">{t('Global.edit')}</span>
											<span onClick={() => addressDelete(elm.id)}><Icons.Close /></span>
										</div>
									</div>
								)
							}
							</>
						)
				}

			</div>
		</>
	);
}

export default BillingAddress;
