import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styles from './login.module.css'
import axios from 'axios'
import queryString from 'query-string'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2'

import yup from './../../package/yup-init'
import Toast from './../../package/swal-init'
import { validateEmail } from './../../package/global-function'
import { gaView } from './../../package/google-analytic'
import { visitTag } from './../../package/criteo'

import Modal from './../../rComponents/global/modal/modal'
import Login from './../../rComponents/global/login'
import Register from './../../rComponents/global/register'
import ForgotPassword from './../../rComponents/global/forgotPassword'

import { AuthContext } from './../../context/AuthContext'
import { BasketContext } from './../../context/BasketContext'
import { FavoriteContext } from './../../context/FavoriteContext'

const loginSchema = yup.object().shape({
	login_name: yup.string()
	.required(),
	login_password: yup.string()
	.min(3)
	.max(32)
	.required(),
	remember: yup.boolean()
})

const registerSchema = yup.object().shape({
	register_name: yup.string()
	.min(2)
	.max(30)
	.required(),
	register_surname: yup.string()
	.min(2)
	.max(20)
	.required(),
	register_mail: yup.string()
	.matches(/^(([^<>()[\]\\ŞşöÖüÜıİçÇ.,;:\s@"]+(\.[^<>()[\]\\ŞşöÖüÜıİçÇ.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
	.required(),
	register_phone: yup.string()
	.max(18)
	.min(18)
	.required(),
	register_password: yup.string()
	.min(8)
	.max(32)
	.required(),
	is_contractcheck: yup.boolean()
	.required()
	.oneOf([true]),
	is_subscribe: yup.boolean()
	.required()
})

const forgotSchema = yup.object().shape({
	forgot_mail: yup.string()
	.required()
})

function LoginModal(props) {

	const { loginModalShow, logIn, register, setStateAuth, isAuth, guestGo, data, leaveAuth, openModal, logOut } = useContext(AuthContext)
	const { updateBasket, setAuth } = useContext(BasketContext)
	const { controlAuth } = useContext(FavoriteContext)

	const [tab, setTab] = useState(props.tab ?? 1)
	const [successMsg, setSuccessMsg] = useState("")
	const [errorMsg, setErrorMsg] = useState("")
	const [registerStep, setRegisterStep] = useState(1)
	const [registerCode, setRegisterCode] = useState("")
	const [rememberMe, setRememberMe] = useState(false)

	useEffect(() => {
		setSuccessMsg("")
		setErrorMsg("")
		setRegisterStep(1)
		setRegisterCode("")
		setRememberMe(false)
		pushURL()
	}, [tab])

	useEffect(() => {
		setTab(props.tab ?? 1)
	}, [props.tab])

	useEffect(() => {
		pushURL()
	}, [loginModalShow])

	const { register: loginRegister, handleSubmit: loginHandleSubmit, errors: loginErrors } = useForm({
		resolver: yupResolver(loginSchema),
		mode: 'onBlur'
	})

	const { register: registerRegister , handleSubmit: registerHandleSubmit, errors: registerErrors } = useForm({
		resolver: yupResolver(registerSchema),
		mode: 'onBlur'
	})

	const { register: forgotRegister, handleSubmit: forgotHandleSubmit, errors: forgotErrors, reset: forgotReset } = useForm({
		resolver: yupResolver(forgotSchema),
		mode: 'onBlur'
	})

	const loginOnSubmit = (loginData) => {

		let data = {
			email: loginData.login_name.toLowerCase(),
			password: loginData.login_password,
			remember: loginData.remember
		}

		const login = logIn(data)
		login.then(req => {
			if(req.isSuccess){
				setErrorMsg("")
				if(leaveAuth){
					setStateAuth('leaveAuth', false)
					window.location.reload()
				}
				setTimeout(function(){
					setStateAuth('loginModalShow', false)
					const options = {
						headers: {
							'Authorization': 'Bearer ' + req.data.Token,
							'UserID': req.data.UserID
						}
					}
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + req.data.Token
					axios.defaults.headers.common['UserID'] = req.data.UserID
					updateBasket(options)
					controlAuth()
					Toast.fire({
						icon: 'success',
						title: 'Başarıyla giriş yapıldı'
					})
				}, 200)
			}else{
				setErrorMsg(req.error)
			}
		})
	}

	const registerOnSubmit = (registerData) => {
		let data = {
			Name: registerData.register_name,
			Surname: registerData.register_surname,
			EMail: registerData.register_mail.toLowerCase(),
			Password: registerData.register_password,
			Phone: registerData.register_phone.replace(/[^0-9]+/g, ''),
			IsContractCheck: registerData.is_contractcheck,
			HasSubscription: registerData.is_subscribe
		}

		if(registerCode !== ""){
			data = {...data, Code: registerCode}
		}

		const response = register(data)

		response.then(request => {
			if(request.statusCode === 200){
				loginOnSubmit({login_name: registerData.register_mail, login_password: registerData.register_password})
			}else if(request.statusCode === 204 || request.statusCode === 800 || request.statusCode === 807){
				setErrorMsg("")
				setRegisterStep(2)
				if(request.statusCode === 807){
					setErrorMsg("Doğrulama kodu yanlış")
				}
			} else if(request.statusCode === 801){
				setErrorMsg("Sözleşmeyi onaylamadan kayıt olamazsınız")
			} else if(request.statusCode === 802){
				setErrorMsg("Doğru e-posta adresi girmelisiniz")
			} else if(request.statusCode === 803 || request.statusCode === 804){
				setErrorMsg("Lütfen tüm alanları doldurun")
			} else if(request.statusCode === 805){
				setErrorMsg("Sadece Türkiye kayıtlı telefon numaraları")
			} else if(request.statusCode === 806){
				setErrorMsg("Şifrenizde en az bir büyük, bir küçük harf ve bir sayı bulunmalıdır, en az 8 karakter en fazla 32 karakter olabilir")
			} else if(request.statusCode === 16160){
				setErrorMsg("E-posta Adresiniz zaten kullanılıyor giriş yapmayı yada farklı bir eposta kullanmayı deneyin")
			} else if(request.statusCode === 16140){
				setErrorMsg("Telefon numaranız zaten kullanılıyor giriş yapmayı yada farklı bir telefon numarası kullanmayı deneyin")
			}else{
				setErrorMsg(request.error)
			}
		})
	}

	const forgotOnSubmit = (data) => {
		const isMail = data.forgot_mail.includes('@')
		let headers
		let phone
		if(!isMail){
			phone =  data.forgot_mail.replace(/[^0-9]+/g, '')
			headers = {
				headers: {
					phone: phone
				}
			}
		}else{
			headers = {
				headers: {
					email: data.forgot_mail
				}
			}
		}

		axios.post("/ForgotPassword", {}, headers)
		.then(res => {
			if(res?.data?.isSuccess){
				setSuccessMsg(`Şifre yenileme bağlantını ${isMail ? "E-Posta adresine" : `"${data.forgot_mail}" numaranıza`} gönderdik.`)
				setTimeout(() => {
					setStateAuth('loginModalShow', false)
				}, 3000)
				setTimeout(() => {
					setSuccessMsg("")
					setTab(1)
				}, 4000)
			}else{
				setErrorMsg(res.data.error)
			}
		})
	}

	const closeModal = to => {
		setStateAuth('loginModalShow', false)
		setStateAuth('guestGo', false)
		setTab(1)
		if(leaveAuth){
			setStateAuth('leaveAuth', false)
			logOut()
			updateBasket()
		}
		if(to){
			props.history.push(to)
		}
	}

	const continueGuest = e => {
		e.preventDefault()
		Swal.fire({
			title: 'E-Posta Adresiniz',
			input: 'text',
			inputAttributes: {
				autocapitalize: 'off'
			},
			showCancelButton: true,
			confirmButtonText: 'Devam et',
			cancelButtonText: 'Vazgeç',
			showLoaderOnConfirm: true,
			preConfirm: (mail) => {
				if(!validateEmail(mail)){
					Swal.showValidationMessage("Mail adresinizi doğru formatta girmelisiniz")
					return false
				}
				return axios.get("/CheckUserEmail?email=" + encodeURI(mail))
				.then(res => {
					if (!res?.data?.isSuccess){
						throw new Error("Mail adresinizi doğru formatta girmelisiniz")
					}
					if (res?.data?.data){
						throw new Error("Bu epostanın üyeliği var giriş yapın veya başka bir eposta kullanın")
					}
					sessionStorage.setItem('nonMemberMail', mail)
					closeModal("/sepet/teslimat-bilgileri")

					return res.data
				})
				.catch(error => {
					let errors = String(error).replace("Error:", '')
					Swal.showValidationMessage(`${errors}`)
				})
			},
			allowOutsideClick: () => !Swal.isLoading()
		}).then((result) => {
		})
	}

	const pushURL = () => {
		gaView()
		visitTag()
		let queryStringParse = queryString.parse(props.location.search)
		const qsKeys = Object.keys(queryStringParse)
		const windowName = tab === 1 ? 'loginWindow' : 'registerWindow'
		const isLoginWindow = qsKeys.indexOf(windowName)

		delete queryStringParse?.loginWindow
		delete queryStringParse?.registerWindow

		if(loginModalShow){
			if(isLoginWindow < 0){
				queryStringParse = {...queryStringParse, [windowName] : true}
				props.history.replace(props.location.pathname + "?" + queryString.stringify(queryStringParse))
			}
		}else{
			if(isLoginWindow >= 0){
				props.history.replace(props.location.pathname + "?" + queryString.stringify(queryStringParse))
			}
		}
	}

	return (
		<Modal show={loginModalShow} closeHandler={closeModal} rePosition={(tab + successMsg + registerCode + registerStep)}>
			{
				tab !== 3 &&
				<div className={styles.tabs}>
					<span className={tab === 1 ? styles.active : undefined} onClick={() => setTab(1)}>GİRİŞ YAPIN</span>
					<span className={tab === 2 ? styles.active : undefined} onClick={() => setTab(2)}>ÜYE OLUN</span>
				</div>
			}
			{
				tab === 1 &&
				<Login
					goForgotPassword={() => setTab(3)}
					error={errorMsg}
					errors={loginErrors}
					register={loginRegister}
					onSubmit={loginHandleSubmit(loginOnSubmit)}
				/>
			}
			{
				tab === 2 &&
				<Register
					step={registerStep}
					error={errorMsg}
					errors={registerErrors}
					register={registerRegister}
					setRegisterCode={setRegisterCode}
					openModal={openModal}
					onSubmit={registerHandleSubmit(registerOnSubmit)}
				/>
			}
			{
				tab === 3 &&
				<ForgotPassword
					goLogin={() => setTab(1)}
					error={errorMsg}
					errors={forgotErrors}
					reset={forgotReset}
					success={successMsg}
					register={forgotRegister}
					onSubmit={forgotHandleSubmit(forgotOnSubmit)}
				/>
			}
			{
				leaveAuth &&
				<div className="red text-center p-3">Oturumun düştü tekrar giriş yapman gerekecek</div>
			}
			{
				guestGo &&
				<div className={styles.guest}>
					<span onClick={continueGuest}>Üye olmadan devam et</span>
				</div>
			}
		</Modal>
	)
}

export default LoginModal
