import React, { useContext, useEffect, useState, useRef } from "react";
import axios from 'axios'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from './../../../package/yup-init'
import Toast from './../../../package/swal-init'
import { gaView } from './../../../package/google-analytic'
import { visitTag } from './../../../package/criteo'

import { useTranslation } from 'react-i18next'

import Input from './../forms/input'
import Checkbox from './../forms/checkbox'
import Button from './../forms/button'

const shema = yup.object().shape({
	Name: yup.string()
	.min(2)
	.max(30)
	.required(),
	Surname: yup.string()
	.min(2)
	.max(20)
	.required(),
	Phone: yup.string()
	.max(18)
	.min(18)
	.required(),
	HasSubscription: yup.boolean()
	.required()
});

function UserInfo(props) {
	const { t, i18n } = useTranslation();

	const [phone, setPhone] = useState("")
	const [code, setCode] = useState("")
	const [isCode, setIsCode] = useState(false)
	const [codeTime, setCodeTime] = useState()

	const isFirstRun = useRef(true)

	const { register , handleSubmit, errors, setValue, unregister } = useForm({
		resolver: yupResolver(shema),
		mode: 'onSubmit'
	})

	useEffect(() => {
		if(isFirstRun.current === true){
			isFirstRun.current = false
			gaView()
			visitTag()

			axios.get("/GetUserDetail")
			.then(res => {
				const user = res?.data?.data
				setValue('Name', user?.Name)
				setValue('Email', user?.Email)
				setValue('Surname', user?.SurName)
				setValue('HasSubscription', user?.HasSubscription)
				setPhone(user?.Phone?.replace("90", ""))
				unregister("Email")
			})
		}
	}, [props])

	const onSubmit = (formData) => {
		let data = {...formData, Phone: formData?.Phone?.replace(/[^0-9]+/g, '')}

		if(code !== ""){
			data = {...data, Code: code}
		}

		axios.post("/UpdateUserDetail", data)
		.then(res => {
			if(res?.data?.isSuccess){
				if(res.data.statusCode === 200){
					Toast.fire({
						icon: 'success',
						title: t('UserPage.user-info.success')
					})
					setIsCode(false)
				}else if(res.data.statusCode === 204 || res.data.statusCode === 808){
					setIsCode(true)
				}
			}else{
				Toast.fire({
					icon: 'error',
					title: res.data.error
				})
			}
		})
	}

	return (
		<>
			<div className="scl-content-title">
				<h1 className="base-regular">{t('UserPage.user-info.title')}</h1>
			</div>
			<div className="scl-section-content">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="col-12 col-lg-7 pr-5">
							{
								!isCode &&
								<>
									<Input title={t('UserPage.user-info.mail')} name="Email" type="text" transferref={register} disabled/>

									<Input title={t('UserPage.user-info.name')} name="Name" type="text" autoComplete="given-name" error={errors?.Name?.message} transferref={register}/>

									<Input title={t('UserPage.user-info.surname')} name="Surname" type="text" autoComplete="family-name" error={errors?.Surname?.message} transferref={register}/>

									<Input title={t('UserPage.user-info.phone')} name="Phone" type="tel" autoComplete="tel-local" value={phone} error={errors?.Phone?.message} transferref={register}/>

									<Checkbox label={t('UserPage.user-info.subscribe')} name="HasSubscription" transferref={register} error={errors?.HasSubscription?.message}/>
								</>
							}
							{
								isCode &&
								<div className="row justify-content-end green">
									<div className="col-5">
										<Input title={t('UserPage.user-info.code')} name="code" type="text" onChange={e => {setCode(e.target.value)}} />
									</div>
								</div>
							}
							<div className="mb-3 form-btn text-right">
								<Button type="submit" title={t('Global.update')} className="btn"/>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}

export default UserInfo;
