import React from "react";

import * as Icons from './../../../components/icons'

function FilterItem(props) {
	return (
		<div onClick={props.onClick}>
			<h3 className="filter-title">
				{props.title}
				<Icons.Vour className="float-right"/>
				<Icons.Plus className="float-right"/>
			</h3>
			<ul className="filter-list">
			{
				props.data?.map((elm, i) =>
					<li key={i} className="form-check mb-2">
						<label className="form-check-label" htmlFor={props.prefix + (Array.isArray(elm) ? elm[1] : elm)}>
							<input type="checkbox" onChange={() => props?.checkHandler(elm)} className="form-check-input" id={props.prefix + (Array.isArray(elm) ? elm[1] : elm)} checked={Array.isArray(elm) ? JSON.stringify(props.choice) === JSON.stringify(elm) : props.choice.indexOf(elm) > -1}/>
							<span><Icons.TickBold /></span>
							{
								props?.convert &&
								props.convert(elm)
							}
							{
								!props?.convert &&
								elm
							}
						</label>
					</li>
				)
			}
			</ul>
		</div>
	)
}

export default FilterItem;
