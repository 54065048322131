import * as React from "react";

function SvgCloseCircle(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M21.19 20l6.97-6.97c.33-.33.33-.86 0-1.19a.839.839 0 00-1.19 0L20 18.81l-6.97-6.97a.839.839 0 00-1.19 0c-.33.33-.33.86 0 1.19L18.81 20l-6.97 6.97c-.33.33-.33.86 0 1.19.16.16.38.25.59.25.22 0 .43-.08.59-.25L20 21.19l6.97 6.97c.16.16.38.25.59.25.21 0 .43-.08.59-.25.33-.33.33-.86 0-1.19L21.19 20z"
      />
      <path
        fill="currentColor"
        d="M39.81 20C39.81 9.08 30.92.19 20 .19 9.08.19.19 9.08.19 20S9.08 39.81 20 39.81c10.92 0 19.81-8.89 19.81-19.81zM1.87 20C1.87 10 10 1.87 20 1.87 30 1.87 38.13 10 38.13 20 38.13 30 30 38.13 20 38.13 10 38.13 1.87 30 1.87 20z"
      />
    </svg>
  );
}

export default SvgCloseCircle;
