import config from './config';

let isActive = false
const deviceType = /iPad/.test( navigator.userAgent ) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test( navigator.userAgent ) ? "m" : "d"

export const criteoStart = () => {
	if (!config.criteo) {
		return false
	}

	isActive = true

	window.criteo_q = window.criteo_q || [];
}

export const visitTag = () => {
	if(!isActive){
		return false
	}

	window.criteo_q.push(
		{event: "setSiteType",type: deviceType},
		{event: "viewPage" }
	)
}

export const homePageTag = () => {
	if(!isActive){
		return false
	}

	window.criteo_q.push(
		{event: "setSiteType",type: deviceType},
		{event: "viewHome"}
	)
}

export const listPageTag = (list) => {
	if(!isActive){
		return false
	}

	window.criteo_q.push(
		{ event: "setSiteType", type: deviceType},
		{ event: "viewList", item: list}
	)
}

export const detailTag = (code) => {
	if(!isActive){
		return false
	}

	window.criteo_q.push(
		{event: "setSiteType", type: deviceType},
		{event: "viewItem", item: code}
	)
}

export const basketTag = (data) => {
	if(!isActive){
		return false
	}

	window.criteo_q.push(
		{
			event: "setSiteType",
			type: deviceType,
		}, {
			event: "viewBasket",
			item: data,
		}
	)
}

export const salesTag = (data) => {
	if(!isActive){
		return false
	}

	window.criteo_q.push(
		{event: "setSiteType", type: deviceType},
		{event: "trackTransaction", ...data}
	)
}
