import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next';
import { FiltersContext } from './../../context/FiltersContext'

import Occasion from './../global/occasion'
import GroupSlider from './../global/groupSlider'

import WebText from './../web/homeText'
import WebBottom from './../web/homeBottom'

import WebSlider from './../web/homeSlider'
import WebFullSlider from './../web/homeFullSlider'
import WebOneBanner from './../web/oneBanner'
import WebCategories from './../web/homeCategories'
import WebTrends from './../web/homeTrends'
import WebHashtag from './../web/homeHashtag'
import WebBigBanner from './../web/homeBigBanner'
import WebBrands from './../web/homeBrands'

import SliderText from './../mobile/sliderText'
import MobileSlider from './../mobile/homeSlider'
import MobileOneBanner from './../mobile/oneBanner'
import MobileCategories from './../mobile/homeCategories'
import MobileTrends from './../mobile/homeTrends'
import MobileHashtag from './../mobile/homeHashtag'
import MobileBrands from './../mobile/homeBrands'
import MobileOneClick from './../mobile/oneClick'

import { gaBannerClick } from './../../package/google-analytic'

function HomePageRender(props) {

	const onClickHandler = (url) => {
		console.log(url)
		gaBannerClick({url: url})
	}

	const { i18n } = useTranslation()
	const { filterData } = useContext(FiltersContext)
	const lang = i18n.language
	const device = window.innerWidth >= 768 ? "desktop" : "mobile"
	const data = props?.data?.[device] ?? props?.data?.[device] ?? []

	const findComponent = ({type, title, device, props}) => {
		switch(device + type){
			case "desktopslider":
				return <WebSlider {...props}/>
			break;

			case "desktopfullSlider":
				return <WebFullSlider {...props}/>
			break;

			case "desktoponeBanner":
				return <WebOneBanner onClickHandler={onClickHandler} {...props}/>
			break;

			case "desktopcategories":
				return <WebCategories {...props}/>
			break;

			case "desktoptrends":
				return <WebTrends {...props}/>
			break;

			case "desktophashtag":
				return <WebHashtag {...props}/>
			break;

			/* MOBILE */

			case "mobilesliderText":
				return <SliderText {...props}/>
			break;

			case "mobilecampaingSliderText":
				return <SliderText {...props} data={filterData?.campaigns?.['data']?.map((elm, i) => {return {text: elm.title, url: elm.TitleInUrl}})}/>
			break;

			case "mobileslider":
				return <MobileSlider {...props}/>
			break;

			case "mobileoneBanner":
				return <MobileOneBanner onClickHandler={onClickHandler} {...props}/>
			break;

			case "mobileoneClick":
				return <MobileOneClick {...props}/>
			break;

			case "mobilecategories":
				return <MobileCategories {...props}/>
			break;

			case "mobiletrends":
				return <MobileTrends {...props}/>
			break;

			case "mobilehashtag":
				return <MobileHashtag {...props}/>
			break;

			default:
				switch(type){
					case "occasion":
						return <Occasion {...props}/>
					break;

					case "bottom":
						return <WebBottom {...props}/>
					break;

					case "space":
						return <div {...props} className={"space " + props.className}></div>
					break;

					case "text":
						return <WebText {...props}/>
					break;

					case "groupSlider":
						return <GroupSlider {...props}/>
					break;

					case "bigBanner":
						return <WebBigBanner {...props}/>
					break;

					default:
						console.log(type)
						return <></>
					break;
				}
			break;
		}
	}

	return (
		data?.map((e, i) =>
			findComponent({...e, device: device, props: {...e.props, key: i, data: e.data, title: e.title}})
		)
	)
}

export default HomePageRender
