import React, { useEffect, useState, useContext, useRef } from 'react'
import styles from './question.module.css'
import { useTranslation } from 'react-i18next'
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import yup from "./../../package/yup-init"
import { rePosition } from "./../../package/global-function"
import Toast from "./../../package/swal-init"
import FormData from 'form-data'
import fs from "fs"
import axios from "axios"
import withReactContent from 'sweetalert2-react-content'

import { AuthContext } from './../../context/AuthContext'
import { LanguageContext } from './../../context/LanguageContext'

import Modal from './../../rComponents/global/modal/modal'

import * as Icons from './../icons/index'

import Input from './../widgets/forms/input'
import Checkbox from './../widgets/forms/checkbox'
import Button from './../widgets/forms/button'
import Select from './../widgets/forms/select'
import Textarea from './../widgets/forms/textarea'

const schema = yup.object().shape({
	name: yup.string()
	.min(2)
	.max(30)
	.required(),
	surname: yup.string()
	.min(2)
	.max(20)
	.required(),
	question: yup.string()
	.min(10)
	.required()
})

const schemaAuth = yup.object().shape({
	question: yup.string()
	.min(10)
	.required()
})

function ModalQuestion(props){

	const { t } = useTranslation()
	const { isAuth, closeModal } = useContext(AuthContext)
	const { getCurrencyID } = useContext(LanguageContext)

	const category = [
		{key: 0, value: "Seçim Yapınız"},
		//{key: 1, value: t('QuestionModal.category._1')},
		{key: 1, value: t('QuestionModal.category._2')},
		{key: 2, value: t('QuestionModal.category._3')},
		{key: 3, value: t('QuestionModal.category._4')},
		{key: 4, value: t('QuestionModal.category._5')}
	]
	const topic = [
		{key: 0, value: "Seçim Yapınız"},
		{key: 1, value: t('QuestionModal.topic._1')},
		{key: 2, value: t('QuestionModal.topic._2')},
		{key: 3, value: t('QuestionModal.topic._3')},
		{key: 4, value: t('QuestionModal.topic._4')}
	]

	const { register, handleSubmit, errors, getValues, setError, reset } = useForm({
		mode: 'onChange',
		resolver: yupResolver(isAuth ?  schemaAuth : schema)
	})

	const [tab, setTab] = useState(1)
	const [selectedService, setSelectedService] = useState(2)
	const [selectedCategory, setSelectCategory] = useState({key: 0, value: category[0]})
	const [selectedTopic, setSelectTopic] = useState({key: 0, value: topic[0]})
	const [orders, setOrders] = useState(null)
	const [selectedOrder, setSelectedOrder] = useState(null)

	const isFirstRun = useRef(true)
	const isDetailModal = props.detail ?? false

	useEffect(() => {
		if(isFirstRun.current){
			isFirstRun.current = false
		}
	}, [props])

	useEffect(() => {
		if(selectedCategory.key === 2){
			updateOrders()
		}else{
			setSelectedOrder(null)
		}
	}, [selectedCategory])

	const updateOrders = () => {
		if(!orders && isAuth){
			axios.get("/GetOrders?page=1&pagesize=50")
			.then(res => {
				setOrders(res?.data?.data || [])
			})
		}
	}

	const onSubmitAuthFormControl = (form) => {
		let error = 0
		if(selectedCategory.key <= 0){
			error++
			setError("category", {
				type: "non",
				message: "Lütfen seçim yapınız"
			})
		}

		if(selectedTopic.key <= 0){
			error++
			setError("topic", {
				type: "non",
				message: "Lütfen seçim yapınız"
			})
		}

		if(getValues('question')?.length <= 10){
			error++;
			setError("question", {
				type: "non",
				message: "Lütfen sorununuzu açıklayınız"
			})
		}

		return error
	}


	const onSubmitFormNonAuthControl = () => {
		const fields = {
			name: getValues('name'),
			surname: getValues('surname'),
			mail: getValues('mail'),
			phone: getValues('phone')
		}

		Object.keys(fields).map(e => {
			if(!fields.[e]){
				setError([e], {
					type: "required",
					message: "Bu alan zorunludur"
				})
			}
		})

		let error = 0
		if(fields.name === "" || fields.surname === ""){
			error++
		}

		if(selectedService === 1){
			if(fields.mail === "" || !yup.string().email().isValidSync(fields.mail)){
				error++
			}
		}

		if(selectedService === 2){
			if(fields.phone?.replace("90", "") === ""){
				error++
			}

			if(fields.phone.length !== 18){
				setError("phone", {
					type: "non",
					message: "Telefon numaranızı formata uygun giriniz"
				})
				error++
			}
		}

		if(selectedService === 3){
			if(fields.mail === "" || fields.phone === ""){
				error++
			}
		}

		return error
	}

	const onSubmit = (form) => {

		const error = isAuth ? onSubmitAuthFormControl(form) : onSubmitFormNonAuthControl(form)

		let data = {
			"Name" : form.name,
			"Surname" : form.surname,
			"Email" : form.mail,
			"Phone" : form.phone,
			"QuestionText": form.question
		}

		if(!isDetailModal){
			try{
				if(selectedCategory.value === "" || selectedTopic.value === ""){
					return false
				}
				data = {...data, "Category": selectedCategory.value, "Topic": selectedTopic.value}
			}
			catch {
				return false
			}
		}else{
			data = {...data, "Category": category[4], "Topic": topic[1]}
		}

		if(props.products?.length){
			data = {...data,
				"QuestionText": `${data.QuestionText}
				Ürün: ${props.products[0].url}
				Renk: ${props.products[0].color}
				Beden: ${props.products[0].body}
				VariantCode: ${props.products[0].variantCode}`}
		}

		if(selectedOrder){
			data = {...data,
				"QuestionText": `${data.QuestionText}
				Sipariş No: ${selectedOrder}`}
		}
		if(props.orderCode){
			data = {...data,
				"QuestionText": `${data.QuestionText}
				Sipariş No: ${props.orderCode}`}
		}

		if(isAuth){
			delete data.Name
			delete data.Surname
			delete data.Mail
			delete data.Phone
		}

		if(error <= 0){
			axios.post('/AddNewQuestion', data)
			.then(res => {
				if(res?.data?.isSuccess){
					Toast.fire({
						icon: 'success',
						title: t('QuestionModal.success')
					})
					reset()
					closeModal('question')
				}else{
					Toast.fire({
						icon: 'error',
						title: res?.data?.error
					})
				}
			})
		}
	}

	const changeCategory = (e) => {
		setSelectCategory(e)
	}

	const changeTopic = (e) => {
		setSelectTopic(e)
	}

	const stepChange = (e) => {
		e.preventDefault()
		let error = 0
		if(selectedCategory.key <= 0){
			error++
			setError("category", {
				type: "non",
				message: "Lütfen seçim yapınız"
			})
		}

		if(selectedTopic.key <= 0){
			error++
			setError("topic", {
				type: "non",
				message: "Lütfen seçim yapınız"
			})
		}

		if(getValues('question')?.length <= 10){
			error++;
			setError("question", {
				type: "non",
				message: "Lütfen sorununuzu açıklayınız"
			})
		}

		if(error <= 0){
			setTab(2)
		}
	}

	const resetError = e => {
		setError([e], {
			type: "non",
			message: null
		})
	}

	return (
		<Modal {...props} rePosition={{...props.rePosition, selectedService}}>
			<h2>{ tab === 1 ? t('QuestionModal.title') : 'İLETİŞİM BİLGİLERİ'}</h2>
			<div className="tab-content text-left" id="myTabContent">
				<div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							{
								tab === 2 && !isAuth &&
								<span onClick={() => setTab(1)} className="pb-2 pointer">← Bilgileri değiştir</span>
							}
							<div className={`col-12${tab === 2 ? ' d-none' : ' d-block'}`}>
								{
									!isDetailModal &&
									<>
										<Select name="category" label="KATEGORİ" options={category} selected={selectedCategory.key} error={errors?.category?.message} clicked={e => {
											resetError('category')
											changeCategory(e)
										}}/>
										{
											orders?.length ? selectedCategory.key === 2 &&
											<Select name="order" label="SİPARİŞ" options={orders.map(elm => {return {key: elm.code, value: "#" + elm.code + " ✧ " + elm.products.length + " Ürün ✧ " + elm.ordertotalprice + " "+ getCurrencyID(elm.currencyid, 'id', 'name')}})} error={errors?.order?.message} clicked={elm => {
												resetError('order')
												setSelectedOrder(elm.key)
											}
											}/>
										: '' }
										<Select name="topic" label="KONU" options={topic} selected={selectedTopic.key} error={errors?.topic?.message} clicked={e => {
											resetError('topic')
											changeTopic(e)
										}}/>
									</>
								}
								<Textarea placeholder={t('QuestionModal.text')} placeholder={t('QuestionModal.text-placeholder')} name="question" error={errors?.question?.message} rows="7" max="2000" transferref={register}/>
								<div className="mb-3">
									{
										props?.products?.length &&
										<span>EK:</span>
									}
									{
										props.products?.map((elm, i) =>
											<div className="row mt-3 border border-4" key={i}>
												<div className="col-1 p-0">
													<img src={elm.img} alt=""/>
												</div>
												<div className="col d-flex flex-column justify-content-around">
													<span>{elm.title}</span>
													<span>{elm.color} / {elm.body}</span>
												</div>
											</div>
										)
									}
								</div>
							</div>
							<div className={`col-12${tab === 1 ? ' d-none' : ' d-block'}`}>
								<Input type="text" title={t('QuestionModal.name')} placeholder={t('QuestionModal.name-placeholder')} name="name" autoComplete="given-name" error={errors?.name?.message} transferref={register}/>
								<Input type="text" title={t('QuestionModal.surname')} placeholder={t('QuestionModal.surname-placeholder')} name="surname" autoComplete="family-name" error={errors?.surname?.message} transferref={register}/>
								<label className="form-label">Sizinle nasıl iletişim kuralım ?</label>
								<div className={styles.tabs}>
									<span className={selectedService === 2 && styles.active} onClick={() => setSelectedService(2)}>Telefon</span>
									<span className={selectedService === 1 && styles.active} onClick={() => setSelectedService(1)}>{t('LoginModal.forgot.mail-label')}</span>
									{/*<span className={selectedService === 3 && styles.active} onClick={() => setSelectedService(3)}>Her ikiside</span>*/}
								</div>
								{
									(selectedService === 1 || selectedService === 3) &&
									<Input type="mail" title={t('QuestionModal.mail')} placeholder={t('QuestionModal.mail-placeholder')} name="mail" autoComplete="email" error={errors?.mail?.message} transferref={register}/>
								}
								{
									(selectedService === 2 || selectedService === 3) &&
									<Input type="tel" title={t('QuestionModal.phone')} placeholder={t('QuestionModal.phone-placeholder')} name="phone" autoComplete="tel-local" error={errors?.phone?.message} transferref={register}/>
								}
							</div>
							<div className="mb-3 form-btn text-right">
								{
									isAuth || tab === 2 ?
										<Button type="submit" className="btn" title="Gönder"/>
									:
										<Button type="button" className="btn" title="İlerle" onClick={stepChange}/>
								}
							</div>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	)
}

export default ModalQuestion
