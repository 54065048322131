import * as React from "react";

function SvgEyeClose(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path fill="currentColor" d="M8.3 16.05z" />
      <path d="M9.74 15.95l-.64-.22z" />
      <path
        fill="currentColor"
        d="M39.59 26.19l-4.75-1.63c2.42-2.28 3.86-4.23 3.93-4.33l.28-.38-.28-.38c-.09-.12-2.17-2.93-5.56-5.78-4.53-3.81-9.23-5.82-13.59-5.82s-9.06 2.01-13.59 5.82c-.28.23-.55.47-.81.7L.96 12.93a.678.678 0 00-.44 1.28l3.57 1.23C1.86 17.6.53 19.38.46 19.48l-.28.38.28.38c.09.12 2.17 2.93 5.56 5.78 4.53 3.81 9.23 5.82 13.59 5.82s9.06-2.01 13.59-5.82c.17-.14.33-.28.49-.42l5.45 1.87c.35.12.74-.07.86-.42a.672.672 0 00-.41-.86zm-19.98 4.37c-8.79 0-16.27-8.76-17.83-10.7.55-.69 1.85-2.24 3.68-3.94l2.55.88 1.28.44 2.58.88c-.13.56-.2 1.14-.2 1.74 0 4.37 3.56 7.93 7.93 7.93 3.17 0 5.9-1.87 7.17-4.56l5.49 1.88c-3.27 2.74-7.76 5.45-12.65 5.45zM8.24 16.15c.01-.04.04-.06.06-.1-.02.04-.04.06-.06.1zm14.61 3.07c-1.43 0-2.6-1.17-2.6-2.6v-.64h-.64c-1.53 0-2.84.89-3.48 2.17l-2.63-.9a6.652 6.652 0 016.11-4.03c3.67 0 6.65 2.98 6.65 6.65 0 .58-.08 1.15-.22 1.69l-2.62-.9c.05-.25.08-.52.08-.79v-.64h-.65zm-.7 1.21c-.09.38-.27.73-.5 1.03-.48.6-1.21.99-2.03.99-1.43 0-2.59-1.16-2.6-2.58v-.02c0-.47.13-.91.35-1.29.36-.62.96-1.08 1.68-1.24.17.92.67 1.72 1.36 2.29.49.41 1.08.7 1.74.82zm-6.4-.99c-.01.14-.02.27-.02.41 0 2.14 1.74 3.88 3.88 3.88a3.9 3.9 0 003.3-1.84l2.65.91c-1.09 2.19-3.34 3.7-5.95 3.7-3.67 0-6.65-2.98-6.65-6.65 0-.45.05-.9.13-1.33l2.66.92zm17.7 4.65l-6.19-2.13c.19-.67.29-1.37.29-2.1 0-4.37-3.56-7.93-7.93-7.93-3.3 0-6.13 2.03-7.33 4.9l-2.54-.87-.65-.23.64.22-1.28-.44-1.84-.63c3.31-2.83 7.93-5.73 12.99-5.73 8.79 0 16.27 8.76 17.83 10.7-.58.74-1.99 2.42-3.99 4.24z"
      />
    </svg>
  );
}

export default SvgEyeClose;
