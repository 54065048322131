import React, {
	useEffect,
	useState
}
from 'react'
import Loading from "./../../widgets/global/loading"
import * as Icons from "./../../icons/index"

function Number(props){
	const [count, setCount] = useState(props.value)

	useEffect(() => {
		if(props.max > props.value){
			setCount(props.value)
		}else{
			setCount(props.max)
		}
	}, [props])

	const valuePlus = () => {
		const newCount = count + 1;
		if(newCount <= props.max){
			if(props.valuePlus){
				props.valuePlus(newCount)
			}else{
				if(props.changeHandler){
					props.changeHandler(newCount)
				}
			}
		}
	}

	const valueMinus = () => {
		const newCount = count - 1;
		if(newCount > 0){
			if(props.valueMinus){
				props.valueMinus(newCount)
			}else{
				if(props.changeHandler){
					props.changeHandler(newCount)
				}
			}
		}
	}

	return (
		<>
			<div className="input-number">
				{
					props.isDisabled && <Loading />
				}
				<input type="number" value={count} readOnly/>
				<span id={props.plusID} className="plus" onClick={valuePlus}><Icons.Plus /></span>
				<span className="sour" onClick={valueMinus}><Icons.Vour /></span>
			</div>
		</>
	)
}
export default Number
