import * as React from "react";

function SvgFacebook(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M15.76 36.83h6.78V19.86h4.73l.5-5.68h-5.23v-3.24c0-1.34.27-1.87 1.56-1.87h3.67v-5.9h-4.69c-5.04 0-7.32 2.22-7.32 6.47v4.53h-3.52v5.75h3.52v16.91z"
      />
    </svg>
  );
}

export default SvgFacebook;
