import React, { useEffect, useState, useRef} from "react"
import axios from 'axios'
import $ from "jquery"
import config from './../../package/config'
import { gaView } from './../../package/google-analytic'
import { visitTag } from './../../package/criteo'

import { useTranslation } from 'react-i18next'

import SeoComponent from './../../rComponents/global/seoComponent'

import FullBanner from './../widgets/homepage/full-banner'
import Select from './../widgets/forms/select'

function Malls(props){

	const { t } = useTranslation()

	const [data, setData] = useState([])
	const [citys, setCitys] = useState([{id: 0, Name: 'Ankara'}])
	const [choicesCity, setChoicesCity] = useState(0)

	const isFirstRun = useRef(true)

	useEffect(() => {
		if(isFirstRun.current){
			isFirstRun.current = false
			gaView()
			visitTag()

			axios.get("/GetStores")
			.then(res => {
				setData(res.data.data ?? [])
			})
			$("html, body, .App").stop().animate({ scrollTop: 0 }, 0)
		}
	}, [props])

	useEffect(() => {
		createDataFilter()
	}, [data])

	const createDataFilter = () => {
		let citys = data.map(elm => {
			return elm.city
		}).filter((value, index, self) => {
			return self.map(e => {return e.id}).indexOf(value.id) === index
		})
		setCitys([{"id": 0, "name": t('Malls.all')}, ...citys])
	}

	const cityChange = (elm) => {
		setChoicesCity(elm.key)
	}

	const seoData = {
		title: "Mağazalarımız" + config.prefix + config.name,
		description: "SecilStore Mağazalarımızı Haritada görüntüleyebilir, telefon numarası ve konumlarını öğrenebilirsiniz"
	}

	return (
		<>
			<SeoComponent {...seoData}/>
			<FullBanner banner="img/magazalar.jpg" title={t('Malls.title')} text={t('Malls.text')}/>
			<span className="hr container"></span>
			<div id="content" className="malls">
				<div className="container">
					<div className="scl-sidebar">
						<Select name="city" id="city" label={t('Malls.city')} onlytext="true" selected={choicesCity} clicked={cityChange} options={citys.map(elm => { return {key: elm.id, value: elm.name}})}/>
					</div>
					<div className="scl-content">
						<div className="row g-5 pb-5">
							{
								data.map((elm, i) =>
									{
										if(choicesCity <= 0 || choicesCity === elm.city.id){
											return <React.Fragment key={"store_" + i}>
												<div className="col-12 col-md-6 col-lg-4">
													<h2 className="base-regular">{elm.name}</h2>
													<address>
														{elm.address} <br/>
														{elm.county.name} / {elm.city.name} <br/>
														{t('Malls.phone')}: <a href={"tel:" + elm.phone}>{elm.phone}</a>
													</address>
													<a href={"https://www.google.com.tr/maps/@" + elm.coordx +","+ elm.coordy + ",15z"} target="_blank" className="pt-2 mb-4">{t('Malls.show-map')}</a>
												</div>
											</React.Fragment>
										}
									}
								)
							}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Malls;
