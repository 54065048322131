import React from 'react'
import { Helmet } from "react-helmet";

function SeoComponent(props) {
	return (
		<Helmet>
			{props?.title && <title>{props.title}</title>}
			{props?.title && <meta name="description" content={props.description} />}

			<meta name="Author" content="SEÇİL ETEK GİYİM SANAYİ VE TİCARET ANONİM ŞİRKETİ - www.secilstore.com"/>
			<meta name="Rating" content="General"/>
			<meta name="Distribution" content="Global"/>
			<meta name="Copyright" content="© 2021 SecilStore"/>

			{"<!-- Schema.org markup for Google+ -->"}
			{props?.title && <meta itemprop="name" content={props.title}/>}
			{props?.description && <meta itemprop="description" content={props.description} />}
			{props?.image && <meta itemprop="image" content={props?.image}/>}

			{"<!-- Twitter Card data -->"}
			<meta name="twitter:site" content={props.url || window.location.href}/>}
			{props?.title && <meta name="twitter:title" content={props.title}/>}
			{props?.description && <meta name="twitter:description" content={props.description}/>}
			{props?.image && <meta name="twitter:image" content={props?.image}/>}
			{props?.image && <meta name="twitter:card" content="summary_large_image"/>}
			<meta name="twitter:card" content="summary"/>
			<meta name="twitter:creator" content="@secilstore"/>

			{"<!-- Open Graph data -->"}
			{props?.title && <meta property="og:title" content={props.title}/>}
			<meta property="og:url" content={props.url || window.location.href}/>
			{props?.description && <meta property="og:description" content={props.description}/>}
			{props?.ogytpe && <meta property="og:type" content={props.ogytpe}/>}
			{props?.image && <meta property="og:image" content={props?.image}/>}
			<meta property="og:site_name" content="SecilStore"/>

			{props?.canonical && <link rel="canonical" href={props?.canonical}/>}
			<meta name="robots" content={props?.robots || "index, follow"}/>
		</Helmet>
	)
}

export default SeoComponent
