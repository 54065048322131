import axios from 'axios';
import config from './config'

let auth = localStorage.getItem('auth')
/* AXIOS SETTINGS */
	axios.defaults.baseURL = config.endPoint
	if(auth){
		auth = JSON.parse(auth)
		axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth.Token;
		axios.defaults.headers.common['UserID'] = auth.UserID;
	}

	axios.defaults.headers.common['Content-Type'] = 'application/json; charset=UTF-8';
	axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
	axios.defaults.headers.get['Content-Type'] = 'application/json; charset=UTF-8';

	axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
	/*if(localStorage.getItem('client')){
		axios.defaults.headers.common['settings'] = localStorage.getItem('client')
	}else{
		axios.defaults.headers.common['settings'] = '{"CountryCode":"TR","LangCode":"tr-TR","Currency":"1"}'
	}*/
	if(localStorage.getItem("userChoices")) {
		const ayar = JSON.parse(localStorage.getItem("userChoices"))
		axios.defaults.headers.common['settings'] = JSON.stringify({"Country": ayar.choicesCountry, "LangCode": ayar.choicesLanguage, "Currency": ayar.choicesCurrency})
	} else {
		axios.defaults.headers.common['settings'] = '{"Country":"TR","LangCode":"tr-TR","Currency":"1"}'
	}

	//axios.defaults.headers.common['settings'] = '{"Country":"TR","LangCode":"tr-TR","Currency":"1"}'

	/*
	axios.interceptors.request.use(request => {
		return request;
	}, error => {
		return Promise.reject(error);
	});

	axios.interceptors.response.use(response => {
		return response;
	}, error => {
		console.warn(error.message)
		return Promise.reject(error);
	});*/
/* AXIOS SETTINGS */
