import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import * as Icons from './../../icons/index'

function BasketStepsBar(props) {

	const { t } = useTranslation()

	const [step, setStep] = useState(props.step)

	useEffect(() => {
		setStep(props.step)
	}, [props])

	return (
		<div className="steps">
			<ul className="list-inline">
				<li className="list-inline-item">
					<span className={"check" + (step > 1 ? ' active': '')}><Icons.TickBold />1</span>
					<span className="pointer" onClick={() => props.changeStep(2)}>{t('Basket.steps.address')}</span>
				</li>
				<li className="list-inline-item">
					<span className={"check" + (step > 2 ? ' active': '')}><Icons.TickBold />2</span>
					<span>{t('Basket.steps.info')}</span>
				</li>
				<li className="list-inline-item">
					<span className={"check" + (step > 3 ? ' active': '')}><Icons.TickBold />3</span>
					<span>{t('Basket.steps.summary')}</span>
				</li>
			</ul>
		</div>
	);
}
export default BasketStepsBar;
