import React, { useState, useEffect, useRef, useContext } from "react";
import axios from 'axios'
import Swal from 'sweetalert2'

import { FiltersContext } from './../../../../context/FiltersContext'
import { AuthContext } from './../../../../context/AuthContext'

import Modal from './../../../global/modal/modal'
import ChooseProduct from './../../../global/product'

import PhoneSmsCode from './../../../ui/global/phoneSmsCode'
import IbanAdd from './../../userPage/ibans/add'
import IbanList from './../../userPage/ibans/list'
import IbanForm from './../../userPage/ibans/form'

import Loading from './../../../../components/widgets/global/loading'
import Button from './../../../../components/widgets/forms/button'

function ReturnOrderModal(props) {

	const { getColorName } = useContext(FiltersContext)
	const { isAuth } = useContext(AuthContext)

	const [returnData, setReturnData] = useState(null)
	const [returnError, setReturnError] = useState(null)
	const [returnRequest, setReturnRequest] = useState(false)
	const [reasonCodes, setReasonCodes] = useState(null)
	const [returnStep, setReturnStep] = useState(1)
	const [returnIban, setReturnIban] = useState(null)
	const [smsCode, setSmsCode] = useState(null)
	const [addShow, setAddShow] = useState(false)
	const [updateCounter, setUpdateCounter] = useState(0)
	const [codeTime, setCodeTime] = useState(null)

	useEffect(() => {
		if(props.chooseOrder && !reasonCodes){
			axios.get("/GetReturnReasonCodes")
			.then(res => {
				if(res?.data?.data){
					const reason = res.data.data?.map(e => {return {key: e.code, value: e.title}})
					setReasonCodes([{key: 0, value: "Seçim yapın.."},...reason])
				}
			})
		}
	}, [props.chooseOrder])

	useEffect(() => {
		if(!isAuth && returnIban?.iBan && returnIban?.fullName){
			onSubmitReturn()
		}
	}, [returnIban])

	useEffect(() => {
		if(!isAuth && smsCode){
			onSubmitReturn()
		}
	}, [smsCode])

	const addModalOpen = () => {
		setAddShow(true)
	}

	const addModalClose = () => {
		setAddShow(false)
	}

	const updateHandler = () => {
		setUpdateCounter(updateCounter + 1)
	}

	const toggleReturnData = (e, change = false) => {
		setReturnError("")
		const index = returnData?.map(elm => elm?.variantCode)?.indexOf(e?.variantCode) ?? -1

		if(change){
			if(index > -1){
				const data = returnData?.filter(elm => elm.variantCode !== e.variantCode)
				setReturnData([...data, e])
			}
		}else{
			if(index < 0){
				setReturnData([...returnData ?? [], e])
			}else{
				const data = returnData?.filter(elm => elm.variantCode !== e.variantCode)
				setReturnData(data ?? [])
			}
		}
	}

	const onSubmitReturn = e => {

		setReturnError("")
		if(!(returnData?.length > 0)){
			setReturnError("Hiç bir ürün seçmediniz")
			return
		}

		if(props.chooseOrder.askiban && !returnIban){
			setReturnError("Lütfen iade bilgilerini giriniz!")
			return
		}

		const reasonCheck = returnData?.filter(e => e.reasonCode === 0)
		if(reasonCheck.length > 0){
			setReturnError("Lütfen iade sebeblerini seçiniz")
			return
		}

		const notChoose = props.chooseOrder.products.filter(e => {
			const index = returnData.map(e => e.variantCode).indexOf(e.variantcode)
			if(index <= -1){
				return true
			}
			if(e.quantity - returnData[index].quantity > 0){
				return true
			}
			return false
		})

		let newData = {
			code: props.chooseOrder.code,
			details: returnData
		}

		if(props.chooseOrder.askiban){
			newData = {...newData, bank: {iban: returnIban.iBan, name: returnIban.fullName}}
		}

		if(smsCode){
			newData = {...newData, smsCode: smsCode.code}
		}

		if(!smsCode){
			Swal.fire({
				text: notChoose.length > 0 ? 'Bu sipariş için başka iade hakkın olmayacak' : 'Tüm ürünler iade edilecek',
				title: 'Emin misin?',
				showCancelButton: true,
				confirmButtonText: `Evet`,
				cancelButtonText: `Vazgeç`,
			}).then((result) => {
				if (result.isConfirmed) {
					returnPost(newData)
				}
			})
		}else{
			returnPost(newData)
		}


		return false
	}

	const returnPost = data => {
		setReturnRequest(true)
		axios.post('/ReturnOrder', data)
		.then(res => {
			if(res?.data?.statusCode === 204 || res?.data?.statusCode === 800 || res?.data?.statusCode === 807){
				if(!res?.data?.isSuccess){
					setReturnError(res.data.success)
				}else{
					setReturnError(res.data.error)
				}
				setCodeTime(res.data.data ?? 200)
				setReturnStep(3)
				setReturnRequest(false)
			}

			if(res?.data?.statusCode === 200){
				Swal.fire('İade talebiniz alındı!', 'En yakın ' + props.chooseOrder?.cargo?.name + ' şubesinden "' + res?.data?.data + '" koduyla ücretsiz olarak kargoya verebilirsiniz!', 'success')
			}

			if(res?.data?.statusCode === 700){
				Swal.fire(res?.data?.error, '', 'error')
				setReturnRequest(false)
			}

			return res
		})
		.then(res => {
			if(res?.data?.statusCode === 200 || res?.data?.statusCode === 700){
				returnModalReset()
				if(props?.updateData){
					props.updateData()
				}
			}
		})
	}

	const onReturnContinue = () => {
		if(!(returnData?.length > 0)){
			setReturnError("Hiç bir ürün seçmediniz")
			return
		}

		const reasonCheck = returnData?.filter(e => e.reasonCode === 0)
		if(reasonCheck.length > 0){
			setReturnError("Lütfen iade sebeblerini seçiniz")
			return
		}

		setReturnStep(2)
	}

	const returnModalReset = () => {
		props.reset()
		setReturnData(null)
		setReturnStep(1)
		setSmsCode(null)
		setReturnIban(null)
		setReturnError(null)
		setReasonCodes(null)
		setReturnRequest(false)
	}

	return (
		<Modal show={(!!props.chooseOrder)} size={(isAuth && returnStep === 2) && "large"} closeHandler={returnModalReset} rePosition={returnRequest + returnStep}>
			<>
			{
				returnStep === 1 && !returnRequest && props.chooseOrder &&
				<>
					<h2 className="pb-3">İptal etmek istediğiniz ürünleri seçin</h2>
					{
						props.chooseOrder.products.map(e => {
							return {
								code: e.variantcode,
								img: e.detail.imagepath,
								title: e.detail.title,
								quantity: e.quantity,
								color: getColorName(e.detail.colorcode)
							}
						})?.map((elm, i) =>
							<ChooseProduct key={i} data={elm} onClick={toggleReturnData} chooseData={returnData} reasonCodes={reasonCodes}/>
						)
					}
					<div className="red pb-1 pt-3">{returnError}</div>
					{
						props.chooseOrder.askiban ?
							<span className="btn mt-4" onClick={onReturnContinue}>İLERLE</span>
						:
							<span className="btn mt-4" onClick={onSubmitReturn}>İade Et</span>
					}
				</>
			}
			{
				returnStep === 2 && !returnRequest &&
				<>
					<span className="text-left mb-3 d-block pointer" onClick={() => setReturnStep(1)}>← Seçimi değiştir</span>
					<p className="red">Geri ödeme için hesap bilgileriniz gerekiyor</p>
					{returnError && <div className="red pb-1 pt-3">{returnError}</div>}
					{
						isAuth &&
						<>
							<IbanList updateHandler={updateCounter} selectHandler={e => {setReturnIban(e)}} selected={returnIban?.iBan} nonBtnClose={true}/>
							<IbanAdd show={addShow} modalClose={addModalClose} updateHandler={updateHandler}/>
							<div className="row mt-3">
								<div className="col text-left">
									<Button className="btn" title="IBAN EKLE" onClick={addModalOpen}/>
								</div>
								<div className="col text-right">
									<Button className="btn" title="TAMAMLA" onClick={onSubmitReturn}/>
								</div>
							</div>
						</>
					}
					{
						!isAuth &&
						<IbanForm onSubmit={e => setReturnIban(e)}/>
					}
				</>
			}
			{
				returnStep === 3 && !returnRequest &&
				<PhoneSmsCode time={codeTime} error={returnError} onSubmit={e => setSmsCode(e)} />
			}
			{
				returnRequest && <Loading />
			}
			</>
		</Modal>
	)
}
export default ReturnOrderModal;
