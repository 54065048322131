import React from 'react'
import TypeLink from './../global/link'

function HomeBigBanner(props) {
	return (
		<div className="banner">
			<div className="container">
				<div {...{...props, data: undefined}} className={`row g-3 ${props.className ?? ''}`}>
					{
						props?.data?.map((elm, i) =>
							<div {...elm.props} className="col-6" key={i}>
								<TypeLink to={elm?.url}>
									<img src={elm?.img} alt={elm?.title} loading="lazy"/>
								</TypeLink>
							</div>
						)
					}
				</div>
			</div>
		</div>
	)
}

export default HomeBigBanner
