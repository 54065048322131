import React, { useEffect, useState, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import axios from "axios";
import $ from "jquery";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as Icons from './../icons/index'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import yup from "./../../package/yup-init";
import { rePosition } from "./../../package/global-function";

import Input from './../widgets/forms/input'
import Button from './../widgets/forms/button'
import Select from './../widgets/forms/select'
import Textarea from './../widgets/forms/textarea'

import { LanguageContext } from './../../context/LanguageContext'

const schema = yup.object().shape({
	address_name: yup.string()
	.min(2)
	.max(30)
	.required(),
	person_name: yup.string()
	.min(2)
	.max(30)
	.required(),
	person_surname: yup.string()
	.min(2)
	.max(20)
	.required(),
	address_text: yup.string()
	.min(10)
	.required(),
	person_phone: yup.string()
	.max(18)
	.min(18)
	.required(),
	zip_code: yup.string()
});

const schema_company = yup.object().shape({
	address_name: yup.string()
	.min(2)
	.max(30)
	.required(),
	person_name: yup.string()
	.min(2)
	.max(30)
	.required(),
	person_surname: yup.string()
	.min(2)
	.max(20)
	.required(),
	address_text: yup.string()
	.min(10)
	.required(),
	person_phone: yup.string()
	.max(18)
	.min(18)
	.required(),
	company_name: yup.string()
	.min(2)
	.max(50)
	.required(),
	tax_name: yup.string()
	.min(2)
	.max(25)
	.required(),
	zip_code: yup.string(),
	tax_no: yup.string()
	.min(2)
	.max(25)
	.required()
});

function ModalAddress( props ) {

	const nullData = {key: 0, value: "Seçim Yapınız"}

	const MySwal = withReactContent(Swal)
	const { t } = useTranslation()

	const {getCountries} = useContext(LanguageContext)

	const [isModal, setModal] = useState(props.isModal ?? true)

	const [addresName, setAddresName] = useState("")
	const [personName, setPersonName] = useState("")
	const [personSurname, setPersonSurname] = useState("")
	const [personPhone, setPersonPhone] = useState("")
	const [addressText, setAddressText] = useState("")
	const [personelAdress, setPersonelAddress] = useState(true)
	const [zipCode, setZipCode] = useState("")

	const [companyName, setCompanyName] = useState("")
	const [taxName, setTaxName] = useState("")
	const [taxNo, setTaxNo] = useState("")

	const [nonCounty, setNonCounty] = useState("")

	const [countryData, setCountryData] = useState([nullData])
	const [countyData, setCountyData] = useState([nullData])
	const [citiesData, setCitiesData] = useState([nullData])

	const [countyID, setCountyID] = useState(null)
	const [countryID, setCountryID] = useState(null)
	const [cityID, setCityID] = useState(null)

	const [resultMessage, setResultMessage] = useState(true)
	const [error, setError] = useState({})
	const [editDataID, setEditDataID] = useState(-1)
	const isFirstRun = useRef(true)

	const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver( personelAdress ? schema : schema_company),
		reValidateMode: 'onChange'
	});

	useEffect(() => {
		if(isFirstRun.current){
			const country = getCountries()?.map(elm => { return {key: elm.code, value: elm.name}})
			if(country?.length > 0){
				setCountryData([nullData, ...country])

				$( ".scl-modal-close" ).click( function () {
					$( ".modal" ).click();
				} );

				var myModalEl = document.getElementById( 'adressModal' )
				if ( myModalEl ) {
					myModalEl.addEventListener( 'shown.bs.modal', function ( e ) {
						rePosition("#adressModal")
					} )
				}

				isFirstRun.current = false;
			}
		}
	}, [props, countryData])

	useEffect(() => {
		const item = props.editData
		if(item){
			setEditDataID(item.id)
			setAddresName(item.title)
			setPersonName(item.name)
			setPersonSurname(item.surname)
			setPersonPhone(item.phone)
			setAddressText(item.addressText)
			setZipCode(item.zipCode)

			setPersonelAddress(item.isPersonal)
			setCompanyName(item.companyName)
			setTaxName(item.taxName)
			setTaxNo(item.taxNo)

			setCountryID(item.country?.code)
			setCityID(item.city?.id)
			setCountyID(item.county?.id)
		}else{
			resetFormData()
		}
	}, [props.editData])

	useEffect(() => {
		if(countryID){
			setCountryData(countryData.filter(e => e.key))
			if(countryID !== "TR" && countryID !== "US"){
				setCountyID(null)
			}

			const header = {
				headers: {
					countrycode: countryID
				}
			}
			axios.get("/GetCities", header)
			.then(res => {
				let cities = []
				let match = false

				for(var i = 0;i < res.data.data.length; i++){
					cities.push({key: res.data.data[i].id, value:  res.data.data[i].name})
				}

				setCitiesData([nullData, ...cities])
			})
		}
	}, [countryID])

	useEffect(() => {
		if(cityID){
			setCitiesData(citiesData.filter(e => e.key))
			const header = {
				headers: {
					cityid: cityID
				}
			}
			if(countryID === "TR" || countryID === "US"){
				axios.get("/GetCounties", header)
				.then(res => {
					if(res?.data?.isSuccess){
						let county = []
						for(var i = 0;i < res.data.data.length; i++){
							county.push({key: res.data.data[i].id, value: res.data.data[i].name})
						}

						if(county){
							const index = county.map(e => e.key).indexOf(countyID)
						}

						setCountyData([nullData, ...county])
					}
				})
			}
		}
	}, [cityID])

	const resetFormData = () => {
		isFirstRun.current = true
		setEditDataID(-1)
		setAddresName("")
		setPersonName("")
		setPersonSurname("")
		setPersonPhone("")
		setAddressText("")
		setZipCode("")

		setCountryID(null)
		setCountryData([nullData])
		setCountyID(null)
		setCountyData([nullData])
		setCityID(null)
		setCitiesData([nullData])
	}

	const onSubmit = (formData) => {
		setError({})
		if(!countryID){
			setError({country: {message: "Bir Ülke Seçin"}})
			return
		}

		if(!cityID){
			setError({city: {message: "Bir Şehir Seçin"}})
			return
		}

		if(!countyID){
			setError({county: {message: "Bir İlçe Seçin"}})
			return
		}

		let data = {
			"Title" : formData.address_name,
			"Name" : formData.person_name,
			"Surname" : formData.person_surname,
			"Phone" : formData.person_phone.replace(/[^0-9]+/g, ''),
			"AddressText" : formData.address_text,
			"IsPersonal" : personelAdress,
			"ZipCode" : zipCode,
			"IsMain" : false,
			"City": {"ID" : cityID}
		}

		if(countryID === "TR" || countryID === "US"){
			data = {...data, "County" : {"ID": countyID}}
		} else {
			data = {...data, "County" : {"ID": 0, "Name": nonCounty}}
		}

		if(!personelAdress){
			data = {
				...data,
				"CompanyName" : formData.company_name,
				"TaxName" : formData.tax_name,
				"TaxNo" : formData.tax_no
			}
		}

		if(editDataID > 0){
			data = {
				...data,
				ID: editDataID
			}
		}

		axios.post(editDataID < 0 ? "/AddNewAddress" : '/UpdateAddress', data)
		.then(res => {
			if(res?.data?.isSuccess){
				$(".scl-modal-close").click()
				props.onSuccess()
				MySwal.fire({
					title: editDataID < 0 ? t('AddressModal.success') : t('AddressModal.update'),
					icon: 'success',
					timer: 1500,
					confirmButtonText: t('AddressModal.ok')
				})
				resetFormData()
			}else{
				$(".modal-open .modal").animate({scrollTop: 0}, 500)
				setResultMessage(res?.data?.error)
			}
		})
	}

	const countryChange = (elm) => {
		setCountryID(elm.key)
	}

	const cityChange = (elm) => {
		setCityID(elm.key)
	}

	const countyChange = (elm) => {
		setCountyID(elm.key)
	}

	const content = () => {
		return <>
		<div className="scl-modal-title">
			<ul className="nav nav-tabs" id="myTab" role="tablist">
				<li className="nav-item" role="presentation">
					<a className="nav-link active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab" aria-controls="login" aria-selected="true" onClick={() => setPersonelAddress(true)}>{t('AddressModal.invoice')}</a>
				</li>
				<li className="nav-item" role="presentation">
					<a className="nav-link" id="profile-tab" data-bs-toggle="tab" href="#profile" role="tab" aria-controls="register" aria-selected="false" onClick={() => setPersonelAddress(false)}>{t('AddressModal.delivery')}</a>
				</li>
			</ul>
		</div>
		<div className="modal-body">
			<div className="tab-content" id="myTabContent">
				<div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
					{
						resultMessage.length > 0 && <div className="errorMessage mt-4"><Icons.CloseCircle />{resultMessage}</div>
					}
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row pt-3">
							<div className="col-12 col-lg-6">
								<Input type="text" title={isModal && t('AddressModal.title')} placeholder={t('AddressModal.title-placeholder')} name="address_name" transferref={register} error={errors?.address_name?.message} value={addresName} onChange={e => {
										setAddresName(e.target.value)
									}}/>
								<Input type="text" title={isModal && t('AddressModal.name')} placeholder={t('AddressModal.name-placeholder')} name="person_name" transferref={register} error={errors?.person_name?.message} value={personName} autoComplete="given-name" onChange={e => {
										setPersonName(e.target.value)
									}}/>
								<Input type="text" title={isModal && t('AddressModal.surname')} placeholder={t('AddressModal.surname-placeholder')} name="person_surname" transferref={register} error={errors?.person_surname?.message} value={personSurname} autoComplete="family-name" onChange={e => {
										setPersonSurname(e.target.value)
									}}/>
								<Input type="tel" title={isModal && t('AddressModal.phone')} placeholder={t('AddressModal.phone-placeholder')} name="person_phone" transferref={register} error={errors?.person_phone?.message} value={personPhone} autoComplete="tel-local" onChange={e => {
										setPersonPhone(e.target.value)
									}}/>
								{
									!personelAdress && <>
										<Input type="text" title={isModal && t('AddressModal.company')} placeholder={t('AddressModal.company')} name="company_name" transferref={register} error={errors?.company_name?.message} value={companyName} onChange={e => {
												setCompanyName(e.target.value)
											}}/>
										<Input type="text" title={isModal && t('AddressModal.tax')} placeholder={t('AddressModal.tax-placeholder')} name="tax_name" transferref={register} error={errors?.tax_name?.message} value={taxName} onChange={e => {
												setTaxName(e.target.value)
											}}/>
										<Input type="text" title={isModal && t('AddressModal.tax-no')} placeholder={t('AddressModal.tax-no-placeholder')} name="tax_no" transferref={register} error={errors?.tax_no?.message} value={taxNo} onChange={e => {
												setTaxNo(e.target.value)
											}}/>
									</>
								}
							</div>
							<div className="col-12 col-lg-6">
								<Select name="country" id="ulke" label={isModal && t('AddressModal.country')} error={error?.country?.message} onlytext="true" selected={countryID} clicked={countryChange} options={countryData}/>
								<Select name="city" id="sehir" error={error?.city?.message} label={isModal && t('AddressModal.city')} onlytext="true" selected={cityID} clicked={cityChange} options={citiesData}/>
								{
									(countryID === "TR" || countryID === "US" || countryID === 0 || !countryID) ?
										<Select name="county" error={error?.county?.message} id="ilce" label={isModal && t('AddressModal.county')} onlytext="true" selected={countyID} clicked={countyChange} options={countyData}/>
									:
										<Input type="text" title={isModal && t('AddressModal.non-county')} placeholder={t('AddressModal.non-county-placeholder')} name="non_current" value={nonCounty} onChange={e => setNonCounty(e.target.value)}/>
								}
								<Input type="text" transferref={register} error={errors?.zip_code?.message} title={isModal && t('AddressModal.zip')} placeholder={t('AddressModal.zip-placeholder')} name="zip_code" value={zipCode} autoComplete="postal-code" onChange={e => {
										setZipCode(e.target.value)
									}}/>
								<Textarea id="adres" transferref={register} error={errors?.address_text?.message} name="address_text" label={isModal && t('AddressModal.address')} placeholder={t('AddressModal.address-placeholder')} value={addressText} onChange={e => {
										setAddressText(e.target.value)
									}}/>
							</div>
							{
								isModal &&
								<div className="mb-3 form-btn">
									<Button type="submit" className="btn" title={(editDataID > 0 ? t('AddressModal.save') : t('AddressModal.add'))}/>
								</div>
							}
						</div>
					</form>
				</div>
			</div>
		</div>
		</>
	}
	return (
		isModal ?
			<ModalComp>
				{ content() }
			</ModalComp>
		:
			content()
	)
}

function ModalComp(props){
	return (
	<div className="modal fade" id="adressModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg scl-modal">
			<div className="modal-content">
				<div className="scl-modal-close">×</div>
				{props.children}
			</div>
		</div>
	</div>)
}

export default ModalAddress
