import React from "react"
import styles from './nonItem.module.css'

import * as Icons from './../../../components/icons/index'

function NonItem(props) {
	return (
		<div className={styles.nonItem}>
			<div className={styles.container}>
				<Icons.NonBasket />
				<span>{props?.title}</span>
				{props?.buttonTitle && <a className="btn base-regular pointer" onClick={props?.clickHandler}>{props.buttonTitle}</a>}
			</div>
		</div>
	)
}

export default NonItem
