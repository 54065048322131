import React from 'react'
import TypeLink from './../global/link'
import * as Icons from './../../components/icons'

function OneClick(props) {
	return (
		<div className="banner one-click">
			{
				props.data.map((elm, i) =>
					<TypeLink to={elm.url} key={i}>
						{elm.title}
						<Icons.ArrowDownBold height=".8em"/>
					</TypeLink>
				)
			}
		</div>
	)
}

export default OneClick
