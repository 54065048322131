import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import config from './config'

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";

const Languages = ['tr-TR', 'en-US'];

i18n
.use(ChainedBackend)
.use(LanguageDetector)
// .use(Backend)
.use(initReactI18next)
.init({
	fallbackLng: 'tr-TR',
	debug: false,
	whitelist: Languages,
	interpolation: {
		escapeValue: false,
	},
	backend: {
		// loadPath: 'https://cdn.secilikart.com/docs/lang/{{lng}}/translation.json',
		// loadPath: `${process.env.REACT_APP_LANGUAGE_CDN_URL}/{{lng}}/translation.json`,
		backends: [
			LocalStorageBackend,
			HttpBackend
		],
		backendOptions: [{
			loadPath: '/locales/{{lng}}/translation.json',
			//loadPath: `${process.env.REACT_APP_LANGUAGE_CDN_URL}/{{lng}}/translation.json`,
			expirationTime: 5 * 60 * 1000,
			prefix: 'scl_',
			allowMultiLoading: true,
		},
		{
			loadPath: '/locales/{{lng}}/translation.json',
			//loadPath: `${process.env.REACT_APP_LANGUAGE_CDN_URL}/{{lng}}/translation.json`,
		}
	]
	},
	detection: {
		lookupLocalStorage: "lang",
		lookupQuerystring: "lang"
	},
	transEmptyNodeValue: '',
	transSupportBasicHtmlNodes: true,
	transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span'],
})

//document.documentElement.lang = (localStorage.getItem('lang') ?? 'tr-TR') === "tr-TR" ? 'tr' : 'en'

export default i18n;
