import * as React from "react";

function SvgTicket(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M37.05 18.57v-.26c0-.82-.67-1.49-1.49-1.49h-1.03v-2.29C34.53 6.52 28.01 0 20 0S5.47 6.52 5.47 14.53v2.29H4.44c-.82 0-1.49.67-1.49 1.49v.26c-1.44.3-2.52 1.58-2.52 3.11v5.89c0 1.53 1.08 2.8 2.52 3.11v.26c0 .82.67 1.49 1.49 1.49H7.8c.82 0 1.49-.67 1.49-1.49V18.32c0-.82-.67-1.49-1.49-1.49H6.77v-2.29C6.77 7.24 12.71 1.3 20 1.3s13.23 5.93 13.23 13.23v2.29H32.2c-.82 0-1.49.67-1.49 1.49v12.62c0 .82.67 1.49 1.49 1.49h.19v.19c0 2.89-2.35 5.24-5.24 5.24h-1.03v-.19c0-.82-.67-1.49-1.49-1.49h-4.21c-.82 0-1.49.67-1.49 1.49v.84c0 .82.67 1.49 1.49 1.49h4.21c.59 0 1.09-.35 1.34-.84h1.19c3.61 0 6.54-2.93 6.54-6.54v-.19h1.87c.82 0 1.49-.67 1.49-1.49v-.26c1.44-.3 2.52-1.58 2.52-3.11v-5.89c0-1.52-1.09-2.79-2.53-3.1zM2.95 29.32c-.71-.27-1.22-.95-1.22-1.75v-5.89c0-.8.51-1.48 1.22-1.75v9.39zm4.85-11.2c.11 0 .19.09.19.19v12.62c0 .11-.09.19-.19.19H4.44a.19.19 0 01-.19-.19V18.32c0-.11.09-.19.19-.19H7.8zM24.82 38.5c0 .11-.09.19-.19.19h-4.21a.19.19 0 01-.19-.19v-.84c0-.11.09-.19.19-.19h4.21c.11 0 .19.09.19.19v.84zm10.93-7.57c0 .11-.09.19-.19.19H32.2a.19.19 0 01-.19-.19V18.32c0-.11.09-.19.19-.19h3.36c.11 0 .19.09.19.19v12.61zm2.53-3.36c0 .8-.51 1.48-1.22 1.75v-9.39c.71.27 1.22.95 1.22 1.75v5.89z"
      />
    </svg>
  );
}

export default SvgTicket;
