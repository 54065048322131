import React, { useContext, useEffect, useState, useRef } from "react";
import {Route, Switch, Redirect} from "react-router-dom"
import queryString from 'query-string'
import moment from 'moment'
import axios from 'axios'
import { gaStart } from './../../package/google-analytic'
import { criteoStart } from './../../package/criteo'
import { timeConvert } from './../../package/global-function'
import Toast from './../../package/swal-init'

import UserDetails from './../widgets/user-page/user-info-secil-transfer'

import Index from './index'
import List from './list'
import Malls from './malls'
import Detail from './details'
import Null from './null'
import Basket from './basket'
import UserPage from './user-page'
import Help from './help'
import Login from './login'
import RecoveryPassword from './recovery-password'
import Unsubscribe from './unsubscribe'
import Subscribe from './subscribe'
import OrderDetail from './orderDetail'

import { AuthContext } from './../../context/AuthContext'
import { BasketContext } from './../../context/BasketContext'
import { FavoriteContext } from './../../context/FavoriteContext'

import Header from './../static/ilmio-header'
import Footer from './../static/ilmio-footer'

import LoginModal from './../../widgets/modal/login'
import BannerRender from './../../rComponents/ui/global/bannersRender'

import Welcome from './../modal/welcome'
import { withTranslation } from 'react-i18next';

function Routing(props) {

	const { isAuth, data, loginExternal } = useContext(AuthContext)
	const { utmCode, setUtmCode, updateBasket } = useContext(BasketContext)
	const { controlAuth } = useContext(FavoriteContext)

	const [headerShow, setHeaderShow] = useState(false)

	const isFirstRun = useRef(true)

	useEffect(() => {
		if(isFirstRun.current){
			isFirstRun.current = false
			gaStart()
			criteoStart()
		}

		let path = props.location.pathname
		path = path.split('/')
		if(path.length > 2){
			if(path[2] === "teslimat-bilgileri" || path[2] === "odeme-bilgileri" || path[2] === "siparis-ozeti"){
				setHeaderShow(false)
			}else{
				setHeaderShow(true)
			}
		}else{
			setHeaderShow(true)
		}

		let item = JSON.parse(localStorage.getItem('utm'))
		if(item?.lock){
			const now = new Date().getTime()
			const date = new Date(item?.expiration).getTime()
			const betweenTime = now - date
			const betweenSeconds = parseInt(betweenTime / 1000)
			if(betweenSeconds > 0 || !item?.expiration){
				localStorage.removeItem('utm')
			}
		}

		const queryStringParse = queryString.parse(props.location.search)
		if(queryStringParse?.u){
			const expiration = moment().add(1, 'days').format()
			const utmData = {code: queryStringParse?.u, lock: true, expiration: expiration}
			setUtmCode(utmData)
		}

		if(queryStringParse?.eg){
			loginExternal(queryStringParse?.eg)
			.then(req => {
				if(req.isSuccess){
					setTimeout(function(){
						const options = {
							headers: {
								'Authorization': 'Bearer ' + req.data.Token,
								'UserID': req.data.UserID
							}
						}
						axios.defaults.headers.common['Authorization'] = 'Bearer ' + req.data.Token
						axios.defaults.headers.common['UserID'] = req.data.UserID
						updateBasket(options)
						controlAuth()
						Toast.fire({
							icon: 'success',
							title: 'Başarıyla giriş yapıldı'
						})
					}, 200)
				}
			})

			delete queryStringParse.eg
			//props.history.replace(props.location.pathname + "?" + queryString.stringify(queryStringParse))
		}
	}, [props])

	function ListComponent(...props) {
		const ls = (...t) => <List {...props} {...t} />

		return withTranslation()(ls);
	}

	return (
		<>
			{
				headerShow &&
				<Header />
			}
			{/*<Welcome />*/}
			<UserDetails />
			<Switch>
				{/*
				<Route path="/ayakkabi" render={routeProps =>
					<BannerRender {...routeProps} fileName={"/ayakkabi"} title="Ayakkabi" description="Deneme" />
				}>
				</Route>
				*/}
				<Route path="/uye" component={ isAuth ? UserPage : Login } />
				<Route exact path="/" component={Index} />
				<Route path="/giris" component={Login} />
				<Route path="/magaza" component={Malls} />
				<Route path="/sepet" component={Basket} />
				<Route path="/sifre-yenile" component={RecoveryPassword} />
				<Route path={["/yardim/:id", "/yardim"]} component={Help} />
				<Route path='/abone-iptal' component={Unsubscribe} />
				<Route path='/abone-ol' component={Subscribe} />
				<Route path='/siparis-detay' component={OrderDetail} />
				<Route path='/404' component={Null} />
				<Route path={["/arama/:query/:page", "/arama/:query"]} render={(props) => <List {...props} />} />
				<Route path="/:lang/:category/:product" component={Detail} />
				<Route path={["/:url/:page", "/:url"]} render={(props) => <List {...props} />} />
				<Redirect from='*' to='/404'/>
			</Switch>
			<LoginModal {...props}/>
			{
				headerShow &&
				<Footer />
			}
		</>
	);
}

export default Routing;
