import React, { useState, useEffect, useContext } from "react";
import {Link} from 'react-router-dom';
import axios from 'axios'
import * as Icons from "./../../icons/index"

import { useTranslation } from 'react-i18next';
import { BasketContext } from "./../../../context/BasketContext"
import { LanguageContext } from "./../../../context/LanguageContext"

import OpenArea from './../../../rComponents/global/openArea'
import ModalAddress from "./../../modal/address"

import Button from "./../forms/button"
import Checkbox from "./../forms/checkbox"
import Loading from "./../global/loading"

import BasketStepsBar from "./basket-steps-bar"

function BasketOrder(props){

	const { t } = useTranslation()
	const { notInclude, basketData, setStateBasket, cargoID } = useContext(BasketContext)
	const { toPriceConvert } = useContext(LanguageContext)

	const [step, setStep] = useState(props.step)
	const [editData, setEditData] = useState()

	useEffect(() => {
		setStep(props.step)
		addressUpdate()
	}, [props.step])

	const addressUpdate = () => {
		axios.get("/GetUserAddresses")
		.then(res => {
			props.setDataAddress(res?.data?.data || [])

			let isMain = res?.data?.data?.filter(e => e.isMain)
			if(!props.choicesDeliveryAddress?.id && isMain?.length <= 0 && res?.data?.data?.length > 0){
				props.setChoicesDeliveryAddress(res?.data?.data[0])
				props.setChoicesInvoiceAddress(res?.data?.data[0])
			}

			if(!props.choicesDeliveryAddress?.id && isMain?.length > 0){
				props.setChoicesDeliveryAddress(isMain[0])
				props.setChoicesInvoiceAddress(isMain[0])
			}
		})
	}

	const addressSelect = (e, elm) => {
		if(!e.target?.classList?.contains('editing')){
			props.setChoicesDeliveryAddress(elm)
		}
	}

	return (
		<div className="basket-order">
			<ModalAddress onSuccess={() => addressUpdate()} editData={editData}/>
			<div className="container">
				<BasketStepsBar step={step} changeStep={props.changeStep} />
				{
					basketData?.shipping &&
					<div className="row justify-content-center">
						<div className="col-12 mb-3 col-lg-8">
							<div className="row cargo-select">
							{
								basketData.shipping.map((e, i) =>
									<div className={`col-12 col-lg-4 ml-lg-3 bgw cargo${e.id === cargoID ? ' active' : ''}`} onClick={() => setStateBasket('cargoID', (e.id), false)} key={i}>
										<span><strong>{e.name}</strong> <span className="float-right">{toPriceConvert(e.price)}</span></span>
									</div>
								)
							}
							</div>
						</div>
					</div>
				}
				<div className="row mb-5 mb-lg-0 justify-content-center">
					<div className="col-12 col-lg-8 p-4 bgw">
						<div className="row">
						<h1>{t('Basket.address.delivery')}</h1>
							<div className="address-select">
								{
									!props.dataAddress ?
										<Loading />
									:
									(
										<>
											{
												props.dataAddress?.map((elm, i) =>
													<div
														key={i}
														className={
															"address pointer" +
															(elm.isPersonal ? ' personal' : '') +
															(props.choicesDeliveryAddress.id === elm.id ? ' active' : '')
														}
														onClick={e => addressSelect(e, elm)}>
														<span className="check"></span>
														<span>{elm.title}</span>
														<span>{elm.name} {elm.surname}</span>
														<span>{elm.city.name} / {elm.county.name}</span>
														<span>{elm.addressText}</span>
														{
															!elm.isPersonal &&
															<>
																<span>{elm.companyName}</span>
																<span>{elm.taxName} / {elm.taxNo}</span>
															</>
														}
														<span className="green float-right editing" data-bs-toggle="modal" data-bs-target="#adressModal" onClick={() => setEditData(elm)}>Düzenle</span>
													</div>
												)
											}
											<div className="new-address address text-center p-3 pointer" data-bs-toggle="modal" data-bs-target="#adressModal" onClick={() => setEditData(false)}>
												<span>{t('Basket.address.new')}</span>
												<span><Icons.Plus /></span>
											</div>
										</>
									)
								}
							</div>
						</div>
						<div className="row pt-3">
							<div className="col-12">
								<Checkbox name="usesame" label={t('Basket.address.use-same')} checked={props.useSame} onChange={() => {
									props.setUseSame(!props.useSame)
								}}/>
							</div>
						</div>
						{
							!props.useSame &&
							<div className="row pt-5">
								<div className="col">
									<h1>{t('Basket.address.invoice')}</h1>
									<div className="address-select">
										{
											!props.dataAddress ?
												<Loading />
											:
											<>
												{
													props.dataAddress.map((elm, i) =>
														<div
															key={i}
															className={"address pointer" + (props.choicesInvoiceAddress.id === elm.id ? ' active': '')}
															onClick={() => props.setChoicesInvoiceAddress(elm)}
															>
															<span className="check"></span>
															<span>{elm.title}</span>
															<span>{elm.name} {elm.surname}</span>
															<span>{elm.city.name} / {elm.county.name}</span>
															<span>{elm.addressText}</span>
															{
																!elm.isPersonal &&
																<>
																	<span>{elm.companyName}</span>
																	<span>{elm.taxName} / {elm.taxNo}</span>
																</>
															}
															<span className="green float-right editing" data-bs-toggle="modal" data-bs-target="#adressModal" onClick={() => setEditData(elm)}>Düzenle</span>
														</div>
													)
												}
												<div className="new-address address text-center p-3" data-bs-toggle="modal" data-bs-target="#adressModal">
													<span>{t('Basket.address.new')}</span>
													<span><Icons.Plus /></span>
												</div>
											</>
										}
									</div>
								</div>
							</div>
						}
					</div>
				</div>
				<div className="row mt-4 justify-content-center mobile-fixed">
					<div className="col-12 col-lg-8 p-0">
						<div className="col-12 col-lg-5 pl-lg-5 float-right">
							<Button className={"btn w-100" + (!props?.choicesDeliveryAddress?.id ? ' disabled' : '')} title={t('Basket.go-payment')} onClick={() => {
								if(props?.choicesDeliveryAddress?.id){
									props.setInsertAdress(true)
									props.changeStep(3)
								}
							}}/>
						</div>
					</div>
				</div>
				<div className="clear-fix"></div>
			</div>
		</div>
	);
}

export default BasketOrder;
