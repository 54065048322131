const secilMain = {
	app_name: "secil",
	name: "Seçil Store",
	prefix: " - ",
	cdnUploadUrl: 'https://cdn.secilstore.com/FileUpload/UploadAlterationReceipt',
	cdnURL: 'https://cdn.secilstore.com',
	cdnWashingRules: "https://cdn.secilstore.com/docs/yikama-talimatlari/",
	noImage: "https://cdn.ilmio.com/docs/images/others/orj/ce17595a-2802-4122-b562-cac276580d5b.jpg",
	facebookLogin:"https://login.secilstore.com/v1/facebook-login?returnurl=http://secilstore.com",
	googleLogin:"https://login.secilstore.com/v1/google-login?returnurl=http://secilstore.com",
	hrefControl: true,
	analytics: false,
	criteo: false,
	locked: true
}

const ilmioMain = {
	app_name: "ilmio",
	name: "ILMIO",
	prefix: " - ",
	cdnUploadUrl: 'https://cdn.ilmio.com/FileUpload/UploadAlterationReceipt',
	cdnURL: 'https://cdn.ilmio.com',
	cdnWashingRules: "https://cdn.ilmio.com/docs/yikama-talimatlari/",
	noImage: "https://cdn.ilmio.com/docs/images/others/orj/ce17595a-2802-4122-b562-cac276580d5b.jpg",
	hrefControl: true,
	analytics: false,
	criteo: false,
	locked: true
}


const options = (process.env.REACT_APP_NAME === "secil") ? {
	prod: {
		...secilMain,
		url: 'https://www.secilstore.com',
		endPoint: 'https://api.secilstore.com/data',
		analytics: 'UA-56296910-1',
		criteo: true,
	},
	beta: {
		...secilMain,
		url: 'https://www.secilikart.com',
		endPoint: 'https://api.secilikart.com/data',
		facebookLogin:"https://login.secilikart.com/v1/facebook-login?returnurl=http://secilikart.com",
		googleLogin:"https://login.secilikart.com/v1/google-login?returnurl=http://secilikart.com",
		locked: false,
		hrefControl: false
	},
	dev: {
		...secilMain,
		url: 'http://localhost:3000',
		endPoint: 'https://api.secilikart.com/data',
		facebookLogin:"https://login.secilikart.com/v1/facebook-login?returnurl=http://localhost:3000",
		googleLogin:"https://login.secilikart.com/v1/google-login?returnurl=http://localhost:3000",
		hrefControl: false,
	}
} : (process.env.REACT_APP_NAME === "ilmio") && {
	prod: {
		...ilmioMain,
		url: 'https://www.ilmio.com',
		endPoint: 'https://api.ilmio.com/data',
		analytics: "UA-190337258-1",
		criteo: true
	},
	beta: {
		...ilmioMain,
		url: 'https://ilmio.secilikart.com',
		endPoint: 'https://apiilmio.secilikart.com/data',
		locked: false,
		hrefControl: false
	},
	dev: {
		...ilmioMain,
		url: 'http://localhost:3000',
		endPoint: 'https://apiilmio.secilikart.com/data',
		hrefControl: false
	}
}

const config = (process.env.REACT_APP_STAGE === "beta") ? options.beta : (process.env.REACT_APP_STAGE === "dev") ? options.dev : options.prod

export default config
