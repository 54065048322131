import React, { useEffect, useState, useContext, useRef } from 'react'
import { Link } from 'react-router-dom'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Zoom } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Lightbox from 'react-image-lightbox'
import 'swiper/swiper-bundle.min.css'
import 'react-image-lightbox/style.css'
import 'react-slideshow-image/dist/styles.css'

import $ from 'jquery'
import * as Icons from './../../icons'

import Image from './../global/image'
import Loading from './../global/loading'
import { AuthContext } from './../../../context/AuthContext'
import { FiltersContext } from './../../../context/FiltersContext'
import { BasketContext } from './../../../context/BasketContext'
import { FavoriteContext } from './../../../context/FavoriteContext'
import { AlarmContext } from './../../../context/AlarmContext'
import {LanguageContext} from './../../../context/LanguageContext'
import {LoadingContext} from './../../../context/LoadingContext'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Zoom])

function DetailImage(props) {

	const { isAuth, addDiscoveryProduct, setStateAuth } = useContext(AuthContext)
	const { getWhiteColor, whiteData, getBrandName } = useContext(FiltersContext)
	const { addBasket, isBasket } = useContext(BasketContext)
	const { addFavorite, isFavorite, removeFavorite } = useContext(FavoriteContext)
	const { addAlarm } = useContext(AlarmContext)
	const { toPriceConvert, toPrice } = useContext(LanguageContext)
	const { loadingRender, closeLoading, openLoading } = useContext(LoadingContext)

	const [choicesImageData, setChoicesImageData] = useState(props.choicesImageData)
	const [bigImageData, setBigImageData] = useState([])
	const [choicesImage, setChoicesImage] = useState(props.choicesImageData[0])
	const [choicesColorData, setChoicesColorData] = useState(props.choicesColorData)
	const [activeIndex, setActiveIndex] = useState(0)
	const [thumbsSwiper, setThumbsSwiper] = useState(null)
	const [activeFavorite, setActiveFavorite] = useState(false)

	const [showZoom, setShowZoom] = useState(false)

	useEffect(() => {
		setChoicesImageData(props.choicesImageData)
		if(props.choicesImageData){
			setBigImageData(props.choicesImageData.map(e => {
				if(e.includes("/half/")){
					return e.replace('/half/', '/orj/')
				}
				return e
			}))
		}

		setActiveIndex(0)
		setChoicesImage(props.choicesImageData[0])
		setChoicesColorData(props.choicesColorData)
	}, [props.choicesImageData])

	const goImage = (i) => {
		if(i > choicesImageData.length - 1){
			i = 0
		}
		if(i < 0){
			i = choicesImageData.length - 1
		}
		setActiveIndex(i)
		setChoicesImage(choicesImageData[i])
	}

	return (
		<>
			{
				!choicesImage ?
					<Loading />
				:
				<>
					{
						showZoom &&
						<Lightbox
							mainSrc={bigImageData[activeIndex]}
							nextSrc={bigImageData[(activeIndex + 1) % bigImageData.length]}
							prevSrc={bigImageData[(activeIndex + bigImageData.length - 1) % bigImageData.length]}
							onCloseRequest={() => {
								$("body").removeClass("modal-open")
								setShowZoom(false)
							}}
							reactModalProps={{zoomLevel: 3}}
							onMovePrevRequest={() => setActiveIndex((activeIndex + bigImageData.length - 1) % bigImageData.length)}
							onMoveNextRequest={() => setActiveIndex((activeIndex + 1) % bigImageData.length)}
							enableZoom={true}
							animationDuration="300"
						/>
					}
							<>
								<div className="big-image">
									{
										window.innerWidth > 768 ?
										(
											<>
											{
												choicesImage && <Image src={choicesImage} alt="" onClick={() => goImage(activeIndex+1)}/>
											}
											</>
										) : (
											<Swiper key={choicesImageData.length} slidesPerView={1} spaceBetween={10} pagination={{ clickable: true }} onSlideChange={(e) => { setActiveIndex(e.activeIndex) }}>
												{
													choicesImageData?.map((elm, i) =>
														<SwiperSlide key={i}>
															<Image src={elm} alt="" picture="true"/>
														</SwiperSlide>
													)
												}
											</Swiper>
										)
									}
									{
										!props.isCombine && !props.isModal &&
										<span className="zoom" onClick={() => {
											$("body").addClass("modal-open")
											setShowZoom(true)
										}}>
										<Icons.SearchPlus />
										</span>
									}
									{ props.indirimYuzdesi &&
									<div className="sol-ust">
										<span>%{props.indirimYuzdesi}</span>
									</div>
									}
									{ !props.isCombine && !props.isModal && isAuth && props.data ? (isFavorite(props.data.code + "C" + props.choicesColor.code) || props.activeFavorite) ?
										<div className="sag-ust favorite active" onClick={() => {
												removeFavorite({ProductColorCode: props.data.code + "C" + props.choicesColor.code})
												setActiveFavorite(false)
											}}><Icons.HeartFull className="heart"/>
										</div>
										:
										<div className="sag-ust favorite"
											onClick={() => {
												addFavorite({ProductColorCode: props.data.code + "C" + props.choicesColor.code})
												setActiveFavorite(true)
											}}><Icons.Heart className="heart"/>
										</div>
										:
										<div className="sag-ust favorite" onClick={() => setStateAuth('loginModalShow', true)}><Icons.Heart className="heart"/></div>
									}
									{window.innerWidth > 768 && !props.isCombine && props.isModal && <Link to={`/` + props.url} className="go-detail">Ürüne git <span>►</span></Link>}
								</div>
								<div className="thump-slider">
									<ul className="list-inline thump-image">
										{/*<li className="up" onClick={() => goImage(activeIndex - 1)}><i className="icon-arrow-right r270"></i></li>*/}
										{
											choicesImageData && choicesImageData.map((elm, i) =>
												<li key={choicesColorData + "-" + i} className={"list-inline-item" + (elm === choicesImage ? ' active': '')} onClick={() => goImage(i)}>
												{
													!props.isCombine &&
													<Image src={elm.replace('/half/', '/quarter/')} alt=""/>
												}
												</li>
											)
										}
										{/*<li className="down" onClick={() => goImage(activeIndex+1)}><i className="icon-arrow-right r90"></i></li>*/}
									</ul>
								</div>
								{!props.isDetail && props.isCombine && <Link to={`/` + props.url} className="go-detail">Ürüne git <span>►</span></Link>}
							</>
				</>
			}
		</>
	)
}

export default DetailImage
