import React, { useState, useEffect } from 'react'
import axios from 'axios'
import TypeLink from './../../../rComponents/global/link'
import config from './../../../package/config'

function FirsatUrunu(props) {

	const [data, setData] = useState(null)

	useEffect(() => {
		axios.get(config.url + '/firsaturunu.json')
		.then(res => {
			setData(res?.data)
		})
	}, [props])

	return (
		<>
			{	data &&
			<TypeLink className="main-title" {...props} to={data.url}><span><img src={data.img} alt="Fırsat Ürünü"/></span></TypeLink>
			}
		</>
	)
}

export default FirsatUrunu
