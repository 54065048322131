import * as React from "react";

function SvgİlmioLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 797 304"
      width="1em"
      height="1em"
      {...props}
    >
      <g fill="currentColor">
        <path d="M77.623 4.341v6.699H56.75v281.27h20.872v6.68H.993v-6.68h20.892V11.04H.994V4.34h76.629zM217.165 292.31a65.458 65.458 0 0027.352-23.594 99.13 99.13 0 0013.576-30.274 125.23 125.23 0 005.208-37.172h11.49l-9.403 97.72H87.065v-6.68h20.255V11.04H87.065V4.34h74.541v6.699h-19.42v281.27h74.979zM520.699 4.341v6.699h-19.202v281.27h19.202v6.68h-74.343v-6.68h20.275V26.665l-71.42 269.821-6.48.219-79.552-266.065v261.672h23.376v6.679h-53.67v-6.68h23.377V11.04h-23.357V4.34h58.242l67.883 229.052L465.578 4.341h55.12zM606.77 4.341v6.699h-20.872v281.27h20.872v6.68h-76.63v-6.68h20.892V11.04h-20.891V4.34h76.629zM706 1.022q45.54 0 68.916 29.617 21.09 26.358 21.09 71.223v104.616q0 44.288-21.09 69.116-22.959 27.172-68.916 27.153t-68.897-27.153q-20.891-24.828-20.891-69.116V101.862q0-44.865 20.891-71.203Q660.698 1.022 706 1.022zm51.384 72.037q0-29.042-10.655-45.938Q733.59 6.647 706 6.647T665.49 27.12q-10.615 16.916-10.615 45.938V235.38q0 62.018 51.145 62.018t51.365-62.018V73.059z" />
      </g>
    </svg>
  );
}

export default SvgİlmioLogo;
