import React, { PureComponent, useContext } from "react"
import { Trans } from 'react-i18next'
import { FiltersContext } from './../../context/FiltersContext'
import { AuthContext } from './../../context/AuthContext'
import Toast from './../../package/swal-init'
import FormData from 'form-data'

import $ from "jquery"
import axios from 'axios'
import config from './../../package/config'
import { gaView } from './../../package/google-analytic'
import { visitTag, detailTag } from './../../package/criteo'

import * as Icons from './../icons/index'

import PaymentOptions from './../widgets/detail/payment-options'
import SizeChart from './../modal/size-chart'

import Breadcrumb from './../widgets/global/breadcrumb'
import LastEnterProductList from './../widgets/global/last-enter'

import DetailImage from './../widgets/detail/detailImage'
import DetailInfo from './../widgets/detail/detailInfo'
import CombineModals from './../../rComponents/widgets/list/combineModals'
import ProductGetSearch from './../../rComponents/global/productGetSearch'
import ProductDiscovery from './../../rComponents/global/productDiscovery'
import SeoComponent from './../../rComponents/global/seoComponent'
import {LanguageContext} from './../../context/LanguageContext'
import { withTranslation } from 'react-i18next';

class Detail extends PureComponent {

	static contextType = LanguageContext

	constructor(props) {
		super(props)

		this.selectColor = this.selectColor.bind(this)
		this.shareSocialMediaHref = this.shareSocialMediaHref.bind(this)
		this.shareRef = React.createRef();

		this.state = {
			data: null,
			replaceUrl: null,
			isFreeCargo: false,
			quickActive: props.quick ? true  : false,
			choicesImageData: false,
			choicesColorData: false,
			choicesBodyData: false,
			choicesBody: false,
			choicesColor: false,
			linkData: [],
			factor: 1,
			shareShow: false,
			lastDiscovery: null
		}
	}

	componentWillMount() {
		this.setState({dil: this.context.userChoices.choicesLanguage})
	}

	componentDidMount(){
		this.updateData()
		if(!this.state.quickActive && !this.state.isCombine){
			$("header").removeClass("fixed")
			$("body").removeClass("fixed-header")
			$("body").removeClass("modal-open")
			$(".modal-backdrop").remove()
			this.setState({replaceUrl: this.props.match.params.category})
		}
		/* ÜRÜN ÖZELLİKLERİ AÇILIR AYARI */
		$("#detail .detail-content ul li:not(.not-tab)").click(function(){
			const index = $(this).index()
			$("#detail .detail-content ul li").removeClass("active")
			$(this).addClass("active")

			$(".tab-content").removeClass("active")
			$(".prod-tabs .tab-content").eq(index).addClass("active")
		});

		/* ÜRÜN ÖZELLİKLERİ AÇILIR AYARI */
		const lb = () => {
			var height = $("body").find(".big-image").last().height()
			if(height > 0){
				$("body").find(".thump-image").attr('style', 'max-height: ' + height + 'px')
			}else{
				setTimeout(lb, 500)
			}
		}
		setTimeout(lb, 500)
	}

	componentDidUpdate(prevProps, prevState){
		if(!this.props.quick){
			const isData = this.state.linkData.map(e => {
				let url = e.split("/")
				url[1] = this.state.replaceUrl
				url = "/" + url.join('/')

				return url
			}).indexOf(this.props.location.pathname)

			if(prevProps.location.pathname !== this.props.location.pathname  && isData === -1){
				this.updateData()
			}
		}else{
			if(prevProps.url !== this.props.url){
				this.updateData()
			}
		}

		if (this.props.t !== prevProps.t) {
			this.props.history.replace('/')
		}
	}

	updateData = () => {
		if(this.state.choicesImageData?.length > 0){
			this.setState({choicesImageData: this.state.choicesImageData.map(e => 'default')})
		}

		const options = {headers: {}}
		let url = ""
		if(this.state.quickActive){
			url = "/GetProductDetail?url=" + this.props.url
		}else{
			$("html, body, .App").stop().animate({ scrollTop: 0 }, 0)
			url = "/GetProductDetail?url=" + this.props.location.pathname
		}

		axios.get(url, options)
		.then(res => {
			if(res?.data?.isSuccess) {
				let data = res.data.data

				this.setState({linkData: data?.colorsdetail?.map(e => e.url)})

				if(!data.colorsdetail){
					data = {...data, colorsdetail: []}
				}else{
					let colorsdetail = data.colorsdetail
					colorsdetail = colorsdetail.map(detail => {
						if(detail.variantsdetail === null){
							return {...detail, variantsdetail: []}
						}else{
							return detail
						}
					})
					data = {...data, colorsdetail: colorsdetail}
				}

				this.setState({data: data}, () => {
					let match = false
					for(let i = 0;i < this.state.data.colorsdetail.length; i++){
						if(this.state.data.colorsdetail[i].imagepaths.length > 0){
							match = true
							this.selectColor(this.state.data.colorsdetail[i])

							return false
						}
					}
					if(!match){
						this.selectColor(this.state.data.colorsdetail[0])
					}
				})

				this.setState({lastDiscovery: JSON.parse(localStorage.getItem("lastDiscovery"))})
			}else{
				if(this.props?.history && this.props.history?.length > 2){
					this.props.history.goBack()
				}else{
					window.location.href = config.url
				}
			}
		})
		// $(".close-page-hover").hover()
		// $(".close-page-click").click()
	}

	selectColor = (elm) => {
		const variants = elm.variantsdetail?.sort((a, b) => parseInt(a.name) - parseInt(b.name))
		this.setState({choicesImageData: elm.imagepaths.length > 0 ? elm.imagepaths : [config.noImage]})
		this.setState({choicesBodyData: variants})
		this.setState({choicesColorData: elm.colorinfo})
		const variantStockFilter = variants.filter(e => e.stock > 0)
		this.setState({choicesBody: variantStockFilter.length > 0 ? variantStockFilter[0] : variants[0] })
		this.setState({choicesColor: {...elm.colorinfo[0], isdomestic: elm.isdomestic}})

		if(elm.relations.length > 0){
			this.setState({relationsDetail: elm.relations})
		}

		let url = elm.url
		if(this.state.replaceUrl){
			url = url.split("/")
			url[1] = this.state.replaceUrl
			url = url.join('/')
		}

		if(!this.state.quickActive){
			this.props.history.replace(`/${url}${this.props.location.search}`)
		}
		setTimeout(() => {
			gaView()
			visitTag()
			detailTag(this.state?.data?.id)
		}, 200)
	}

	setChoicesBody = (elm) => {
		this.setState({choicesBody: elm})
	}

	getBrandName = (value) => {
		return (<FiltersContext.Consumer>{({getBrandName}) => (getBrandName(value))}</FiltersContext.Consumer>)
	}

	getFeatureName = (value) => {
		return (<FiltersContext.Consumer>{({getFeatureName}) => (getFeatureName(value))}</FiltersContext.Consumer>)
	}

	getFeatureParent = (value) => {
		return (<FiltersContext.Consumer>{({getFeatureParent}) => (getFeatureParent(value))}</FiltersContext.Consumer>)
	}
	shareSocialMedia(service = ""){
		let url = window.location.href

		if(service === "twitter"){
			url = "https://twitter.com/intent/tweet?url=" + url
		}else if(service === "whatsapp"){
			window.innerWidth > 768 ?
				url = "https://web.whatsapp.com/send?text=" + url + " bu ürünü satın almak istiyorum" +  "&phone=905333714040"
			:
				url = "https://api.whatsapp.com/send?text=" + url + " bu ürünü satın almak istiyorum" + "&phone=905333714040"
		}else if(service === "friend"){
			window.innerWidth > 768 ?
				url = "https://web.whatsapp.com/send?text=" + url
			:
				url = "https://api.whatsapp.com/send?text=" + url
		}else if(service === "facebook"){
			url = "https://www.facebook.com/sharer/sharer.php?u=" + url
		}else if(service === "pinterest"){
			url = "http://pinterest.com/pin/create/link/?url=" + url
		}else if(service === "linkedin"){
			url = "https://www.linkedin.com/sharing/share-offsite/?url=" + url
		}else if(service === "mail"){
			url = 'mailto://?subject=Bu elbiseyi görmelisin&body=' + url
		}else{
			url = ""
		}

		if(url !== ""){
			window.open(url, 'share-dialog', 'width=760,height=536')
		}
	}


	shareSocialMediaHref(service = ""){
		let url = window.location.href

		if(service === "twitter"){
			url = "https://twitter.com/intent/tweet?url=" + url
		}else if(service === "whatsapp"){
			window.innerWidth > 768 ?
				url = "https://web.whatsapp.com/send?text=" + url + " bu ürünü satın almak istiyorum" +  "&phone=905333714040"
			:
				url = "https://api.whatsapp.com/send?text=" + url + " bu ürünü satın almak istiyorum" + "&phone=905333714040"
		}else if(service === "friend"){
			window.innerWidth > 768 ?
				url = "https://web.whatsapp.com/send?text=" + url
			:
				url = "https://api.whatsapp.com/send?text=" + url
		}else if(service === "facebook"){
			url = "https://www.facebook.com/sharer/sharer.php?u=" + url
		}else if(service === "pinterest"){
			url = "http://pinterest.com/pin/create/link/?url=" + url
		}else if(service === "linkedin"){
			url = "https://www.linkedin.com/sharing/share-offsite/?url=" + url
		}else if(service === "mail"){
			url = 'mailto://?subject=Bu elbiseyi görmelisin&body=' + url
		}else{
			url = ""
		}

		return url
	}

	featuresToggle = (e) => {
		$(e.currentTarget).parent().find(".feature-content").slideToggle()
		if($(e.currentTarget).parent().hasClass('active')){
			$(e.currentTarget).parent().removeClass('active')
		}else{
			$(e.currentTarget).parent().addClass('active')
		}
	}

	convertInc = (value) => {
		let calc = (value / this.state.factor)
		calc = Math.ceil(Math.round(calc * 10)) / 10
		return calc + (this.state.factor > 1 ? ' "' : '')
	}

	copyShareUrl = e => {
		navigator.clipboard.writeText(window.location.href)
		this.setState({shareShow: false})
		Toast.fire({
			icon: 'success',
			title: 'Kopyalandı'
		})
	}

	controlWorking = e => {
		const time = new Date().toLocaleTimeString('tr-TR', { hour: '2-digit', minute: '2-digit' }).split(':')
		const hour = time[0]
		// const minute = time[1]
		if(hour >= 18){
			e.preventDefault()
			Toast.fire({
				icon: 'info',
				title: 'Mesajla ve telefonla sipariş servisimiz hafta içi 09:00-17:00 saatleri arasında hizmet vermektedir.'
			})
		}
	}

	render() {
		let header = <></>
		let footer = <></>
		let breadcrumb = <></>

		if(!this.state.quickActive){
			header = <><SizeChart /></>
			breadcrumb =
			<>
				{
					this.state.data &&
					<Breadcrumb data={[
							{url: "", title: (this.getBrandName(this.state.data.brandcode))},
							{url: "", title: (this.state.data.detail.Title) + " - " + this.state.choicesColor.title}
					]}/>
				}
			</>
		}

		const seoData = {
			title: this.state.data?.detail?.Title + config.prefix + config.name,
			description: this.state.data?.detail?.Description,
			image: this.state?.choicesImageData?.[0],
			ogytpe: "product.item",
			canonical: `${config.url}${this.props?.match?.url}`
		}

		const prices = this.state.choicesBody && this.state.choicesBody?.prices?.length > 0 && this.context.toPriceArr([this.state.choicesBody.prices[0].Price1, this.state.choicesBody.prices[0].Price2, this.state.choicesBody.prices[0].Price3], 2)

		return (
			<>
				{
					!this.state.quickActive &&
					<SeoComponent {...seoData}/>
				}
				{header}
				{breadcrumb}
				<div id="detail">
					<div className="container">
						<div className="detail-left">
							<DetailImage
								{...this.props}
								indirimYuzdesi={prices && prices.length > 1 && ((100 - (prices[1] * 100 / prices[0])).toFixed(0))}
								data={this.state.data}
								choicesColorData={this.state.choicesColorData}
								choicesColor={this.state.choicesColor}
								choicesBodyData={this.state.choicesBodyData}
								choicesBody={this.state.choicesBody}
								setChoicesBody={this.setChoicesBody}
								selectColor={this.selectColor}
								quickActive={this.state.quickActive}
								choicesImageData={this.state.choicesImageData}
								modalClose={this.props.modalClose}
								url={this.props.url}
								isModal={this.state.quickActive}
								isCombine={this.props.isCombine}
								isDetail={this.props.isDetail}
							/>
							{
								window.innerWidth > 540 && !this.state.quickActive && this.state?.data?.code && this.state.choicesColorData?.[0]?.code &&
								<CombineModals id={this.state?.data?.code + 'C' + this.state.choicesColorData?.[0]?.code} isDetail/>
							}
						</div>
						<div className="detail-right">
							{
								<DetailInfo
									{...this.props}
									data={this.state.data}
									choicesColorData={this.state.choicesColorData}
									choicesColor={this.state.choicesColor}
									choicesBodyData={this.state.choicesBodyData}
									choicesBody={this.state.choicesBody}
									setChoicesBody={this.setChoicesBody}
									selectColor={this.selectColor}
									quickActive={this.state.quickActive}
									choicesImageData={this.state.choicesImageData}
									modalClose={this.props.modalClose}
								/>
							}
							<div className="services">
								<ul>
								{ config.app_name === "secil" ?
									<li>
										<Icons.CargoTag height={undefined}/>
										<span className="base-light">
											<strong>400TL ÜZERİ</strong><br/>
											<b className="green">ÜCRETSİZ</b> KARGO.
										</span>
									</li>
								: config.app_name === "ilmio" &&
									<>
									<li>
										<Icons.FastCargo height={undefined}/>
										<span className="base-light">
											<strong>1-5 GÜN</strong><br/>İÇİNDE KARGODA.
										</span>
									</li>
									<li>
										<Icons.CargoTag height={undefined}/>
										<span className="base-light">
											<strong>400TL ÜZERİ</strong><br/>
											<b className="green">ÜCRETSİZ</b> KARGO.
										</span>
									</li>
									</>
								}
								</ul>
							</div>
							<div className="product-feature">
								<div className="feature-item">
									<span className="base-bold" onClick={this.featuresToggle}>
										<Trans i18nKey="Detail.properties.title" />
										<Icons.ArrowDownBold height=".6em" />
									</span>
									<div className="feature-content">
									{
										this.state.data?.detail &&
										<>
											<div className="row">
												{
													this.state.data.detail.Description &&
													<div className="col-12 mb-2">
														<p dangerouslySetInnerHTML={{__html: this.state.data.detail.Description}}></p>
													</div>
												}
												{
													this.state?.data?.features &&
													<div className="col-6 mb-2">
														<strong className="mb-2"><Trans i18nKey="Detail.properties.product-feature" /></strong>
														<ul className="disc">
															<FiltersContext.Consumer>
																{
																	({writeFeaturesList, getFeatureName}) =>
																	writeFeaturesList(this.state.data.features).filter(e => getFeatureName(e.code) !== 'Kumaş').map((elm, i) =>
																		<React.Fragment key={elm.code}>
																			{false && <li key={elm.code}>{getFeatureName(elm.code)} PARENT</li>}
																			{
																				elm.child.map((child, c) =>
																					<li key={child}>{getFeatureName(child)}</li>
																				)
																			}
																		</React.Fragment>
																	)
																}
															</FiltersContext.Consumer>
														</ul>
													</div>
												}
												<div className="col-6 mb-2">
													<strong className="mb-2"><Trans i18nKey="Detail.properties.fabric-contents"/></strong>
													<ul className="disc">
														<FiltersContext.Consumer>
															{
																({writeFeaturesList, getFeatureName}) =>
																writeFeaturesList(this.state.data.features).filter(e => ['Kol', 'Boy', 'Detay', 'Silüet', 'Yaka', 'Silüet', 'Model', 'Kesim & Kalıp', 'Desen'].indexOf(getFeatureName(e.code)) <= -1).map((elm, i) =>
																	<React.Fragment key={elm.code}>
																		{false && <li key={i}>{getFeatureName(elm.code)} PARENT</li>}
																		{
																			elm.child.map((child, c) =>
																				<li key={`${i}-${c}`}>{getFeatureName(child)}</li>
																			)
																		}
																	</React.Fragment>
																)
															}
														</FiltersContext.Consumer>
														{
															this.state.data.detail.WashingInstruction.FabricContents.length > 0 &&
															<>
															{
																this.state.data.detail?.WashingInstruction?.FabricContents?.map((elm, i) =>
																	<li key={i}>%{elm.Rate} {elm.Title}</li>
																)
															}
															</>
														}
													</ul>
												</div>
												<div className="col-6 mb-2">
													<strong className="mb-2"><Trans i18nKey="Detail.properties.washing-rules"/></strong>
													<ul className="scl-yt">
														{
															this.state.data.detail.WashingInstruction.WashingRules.map((elm, i) =>
																<li key={i}><img src={config.cdnWashingRules + elm.Icon + ".png"} alt={elm.Title} width="25px"/><span className="pl-2 mt-2">{elm.Title}</span></li>
															)
														}
														{
															this.state.choicesColor.isdomestic &&
															<li className="local"><img src="https://cdn.ilmio.com/docs/yikama-talimatlari/yerli-uretim-siyah.svg"  width="80px" alt=""/></li>
														}
													</ul>
												</div>
												{
													(this.state.data?.Info?.ModelSize &&
														(
															this.state.data?.Info?.ModelSize?.Body ||
															this.state.data?.Info?.ModelSize?.Height > 0 ||
															this.state.data?.Info?.ModelSize?.Size !== ""
														)
													) &&
													<div className="col-6 mb-2">
														<strong className="mb-2"><Trans i18nKey="Detail.properties.model-size.title" /></strong>
														<ul>
															{this.state.data.Info.ModelSize.Size && <li><b className="base-regular"><Trans i18nKey="Detail.properties.model-size.body" /></b>: {this.state.data.Info.ModelSize.Size}</li>}
															{this.state.data.Info.ModelSize.Height > 0 && <li><b className="base-regular"><Trans i18nKey="Detail.properties.model-size.height" /></b>: {this.state.data.Info.ModelSize.Height} cm</li>}
															{this.state.data.Info.ModelSize.Body && <li><b className="base-regular"><Trans i18nKey="Detail.properties.model-size.measure" /></b>: {this.state.data.Info.ModelSize.Body}</li>}
														</ul>
													</div>
												}
											</div>
										</>
									}
									</div>
								</div>
								{
									this.state.data?.Info?.Sizes.filter(e => e.Values?.length).length > 1 &&
										<div className="feature-item">
											<span className="base-bold" onClick={this.featuresToggle}>
												<Trans i18nKey="Detail.properties.product-sizes" />
												<Icons.ArrowDownBold height=".6em" />
											</span>
											<div className="feature-content">
												<div className="col-12">
													<h3>
														<span onClick={() => this.setState({factor: 1})} className={"pointer" + (this.state.factor > 1 ? '' : ' green')}>CM</span>
														<span onClick={() => this.setState({factor: 2.54})} className={"ml-2 pointer" + (this.state.factor > 1 ? ' green' : '')}>INCH</span>
													</h3>
													<table className="table">
														<tbody>
															{
																this.state.data.Info.Sizes.map((elm, i) =>
																	elm.Values.length > 0 &&
																	<tr key={i}>
																		<td><Trans i18nKey={"Detail.properties.size-titles." + elm.Title.toLowerCase()} /></td>
																		{
																			elm.Values.map((td, i) =>
																				<td key={i}>{this.convertInc(td)}</td>
																			)
																		}
																	</tr>
																)
															}
														</tbody>
													</table>
												</div>
											</div>
										</div>
								}
								<div className="feature-item">
									<span className="base-bold" onClick={this.featuresToggle}>
										<Trans i18nKey="Detail.delivery.title" />
										<Icons.ArrowDownBold height=".6em" />
									</span>
									<div className="feature-content" >
										<p><Trans i18nKey="Detail.delivery.content" /></p>
									</div>
								</div>
								<div className="feature-item">
									<span className="base-bold" onClick={this.featuresToggle}>
										<Trans i18nKey="Detail.return.title" />
										<Icons.ArrowDownBold height=".6em" />
									</span>
									<div className="feature-content base-light">
										<p><Trans i18nKey="Detail.return.content" /></p>
									</div>
								</div>
								<div className="feature-item">
									<span className="base-bold" onClick={this.featuresToggle}>
										<Trans i18nKey="Detail.change.title" />
										<Icons.ArrowDownBold height=".6em" />
									</span>
									<div className="feature-content base-light">
										<p><Trans i18nKey="Detail.change.content" /></p>
									</div>
								</div>
								{
								<div className="feature-item">
									<span className="base-bold" onClick={this.featuresToggle}>
										<Trans i18nKey="Detail.payment-options" />
										<Icons.ArrowDownBold height=".6em" />
									</span>
									<div className="feature-content base-light">
										<PaymentOptions price={
											(this.state.choicesBody?.prices?.[0]?.Price1 > this.state.choicesBody?.prices?.[0]?.Price2 ?
												this.state.choicesBody?.prices?.[0]?.Price1 :
												this.state.choicesBody?.prices?.[0]?.Price2 > this.state.choicesBody?.prices?.[0]?.Price3 ?
												this.state.choicesBody?.prices?.[0]?.Price2 :
												this.state.choicesBody?.prices?.[0]?.Price3)} />
									</div>
								</div>
								}
								{
									!this.state.quickActive &&
									<AuthContext.Consumer>
									{
									({ openModal }) =>
										<div className="feature-item">
											<span className="base-bold" onClick={() => openModal("question",
											{
												products: [{
													url: window.location.href,
													img: this.state.choicesImageData?.[0],
													title: this.state.data.detail.Title,
													color: this.state.choicesColor.title,
													body: this.state.choicesBody.name,
													variantCode: this.state.choicesBody.code,
												}]
											})}>
												<Trans i18nKey="Detail.help" />
												<Icons.NewWindow height=".8em"/>
											</span>
										</div>
									}
									</AuthContext.Consumer>
								}
							</div>
							<div className="social">
								<ul className="list-inline">
									<li className="list-inline-item scl-wp" title="Whatsappla satın al">
										<a href={this.shareSocialMediaHref('whatsapp')} target="_blank" onClick={this.controlWorking}>
											<Icons.Whatsapp />
											<span>Mesajla sipariş</span>
										</a>
									</li>
									<li className="list-inline-item scl-phone" title="Telefonla sipariş ver">
										<a href="tel://03124723434" onClick={this.controlWorking}>
											<Icons.Phone />
											<span>Telefonla sipariş</span>
										</a>
									</li>
									<li className="list-inline-item scl-friend" title="Whatsapp'da paylaş">
										<a href={this.shareSocialMediaHref('friend')} target="_blank">
											<Icons.Send />
											<span>Arkadaşına Gönder</span>
										</a>
									</li>
									<li className="list-inline-item scl-shareopen" title="Paylaş" onClick={() => {
										this.setState({shareShow: true})
									}}>
										<Icons.Share />
										<span>Paylaş</span>
									</li>
								</ul>
								{
									this.state.shareShow &&
									<div className="share-window">
										<div className="row">
											<div className="col">
												<div className="input-group mb-3">
													<input type="text" name="share" title="Bağlantıyı Kopyala" readonly value={window.location.href} className="form-control" autoFocus onBlur={() => setTimeout(() => this.setState({shareShow: false}), 400)}/>
													<span className="input-group-text p-1 pointer" onClick={this.copyShareUrl}><Icons.NewWindow height=".5em"/></span>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col">
												<ul>
													<li>
														<a href={this.shareSocialMediaHref('facebook')} target="_blank">
															<Icons.Facebook />
														</a>
													</li>
													<li>
														<a href={this.shareSocialMediaHref('twitter')} target="_blank">
															<Icons.Twitter />
														</a>
													</li>
													<li>
														<a href={this.shareSocialMediaHref('linkedin')} target="_blank">
															<Icons.Linkedin />
														</a>
													</li>
													<li>
														<a href={this.shareSocialMediaHref('mail')} target="_top">
															<Icons.Message />
														</a>
													</li>
												</ul>
											</div>
										</div>
										<span className="close" onClick={() => this.setState({shareShow: false})}><Icons.Close /></span>
									</div>
								}
							</div>
						</div>
					</div>
					<div className="clearfix"></div>
					{
						window.innerWidth <= 540 && !this.state.quickActive && this.state?.data?.code && this.state.choicesColorData?.[0]?.code &&
						<CombineModals id={this.state?.data?.code + 'C' + this.state.choicesColorData.[0].code} />
					}
					{
						!this.state.quickActive &&
						<>
							<ProductGetSearch data={{title: "BENZER ÜRÜNLER", products: this.state.relationsDetail}}/>
							<ProductDiscovery data={{title: "SON GEZDİKLERİNİZ", products: this.state.lastDiscovery}}/>
						</>
					}
				</div>
				{footer}
			</>
		)
	}
}

export default withTranslation()(Detail);
