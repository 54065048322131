import * as React from "react";

function SvgCargoTag(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-3 -5 46 46"
      width="1em"
      height="1em"
      {...props}
    >
      <g fill="currentColor">
        <path d="M5.43 25.35c-.05 0-.1 0-.15-.01-.41-.04-.79-.24-1.05-.56l-3.9-4.71c-.55-.66-.45-1.64.21-2.18l8.47-7.01c.36-.3.83-.42 1.3-.33l2.88.58c.19.04.32.23.28.42a.36.36 0 01-.42.28l-2.88-.58c-.25-.05-.5.01-.7.18L1 18.44c-.36.3-.41.83-.11 1.18l3.9 4.71c.14.17.35.28.57.3.22.02.44-.05.61-.19l8.47-7.01c.2-.16.31-.4.3-.66l-.05-4.9c0-.2.16-.36.35-.36.19 0 .35.16.36.35l.05 4.9c0 .47-.2.91-.56 1.21L6.41 25c-.28.23-.62.35-.98.35z" />
        <path d="M6.35 20.61c-.1 0-.2-.04-.27-.13-.13-.15-.1-.38.05-.5l3.11-2.58c.15-.13.38-.1.5.05.13.15.1.38-.05.5l-3.11 2.58a.4.4 0 01-.23.08zM3.87 20.47c-.1 0-.2-.04-.27-.13-.13-.15-.1-.38.05-.5l4.51-3.74c.15-.13.38-.1.5.05.13.15.1.38-.05.5L4.1 20.39a.4.4 0 01-.23.08zM11.61 16.08c-.05 0-.1 0-.15-.01-.42-.04-.79-.24-1.06-.56a1.562 1.562 0 01.99-2.54c.2-.03.37.11.4.3.03.19-.11.37-.3.4a.85.85 0 00-.54 1.38c.14.18.35.28.58.3.47.04.88-.3.93-.77a.77.77 0 00-.13-.54.349.349 0 01.07-.48l4.06-3.33c.15-.12.38-.1.5.05.12.15.1.38-.05.5l-3.84 3.14c.09.22.12.46.1.72-.08.83-.76 1.44-1.56 1.44z" />
        <path d="M38.31 31.19H17.84c-.92 0-1.67-.75-1.67-1.67V10.46c0-.92.75-1.67 1.67-1.67h20.47c.92 0 1.67.75 1.67 1.67v19.05c.01.93-.74 1.68-1.67 1.68zM17.84 9.86c-.34 0-.61.27-.61.61v19.05c0 .33.27.61.61.61h20.47c.33 0 .61-.27.61-.61V10.46c0-.34-.27-.61-.61-.61H17.84z" />
        <path d="M20.97 23.37c-.29 0-.53-.24-.53-.53v-3.56c0-.29.24-.53.53-.53s.53.24.53.53v3.56c0 .29-.24.53-.53.53z" />
        <path d="M22.49 21.29c-.14 0-.27-.05-.38-.16l-1.15-1.15-1.15 1.15c-.21.21-.55.21-.75 0a.525.525 0 010-.75l1.53-1.53c.21-.21.55-.21.75 0l1.53 1.53c.21.21.21.55 0 .75-.1.11-.24.16-.38.16zM25.94 29.06h-6.4c-.29 0-.53-.24-.53-.53 0-.29.24-.53.53-.53h6.4c.29 0 .53.24.53.53a.52.52 0 01-.53.53zM28.6 26.57h-9.06c-.29 0-.53-.24-.53-.53s.24-.53.53-.53h9.06c.29 0 .53.24.53.53s-.24.53-.53.53zM31.63 16.97c-.1 0-.21-.03-.3-.09l-1.77-1.18-1.11 1.11c-.21.21-.55.21-.75 0l-1.11-1.11-1.77 1.18a.53.53 0 01-.83-.44V9.32c0-.29.24-.53.53-.53h7.11c.29 0 .53.24.53.53v7.11c0 .2-.11.38-.28.47a.43.43 0 01-.25.07zm-4.98-2.49c.14 0 .27.05.38.16l1.05 1.05 1.05-1.05a.53.53 0 01.67-.07l1.3.87V9.86h-6.04v5.58l1.3-.87c.09-.06.19-.09.29-.09z" />
      </g>
    </svg>
  );
}

export default SvgCargoTag;
