import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from "react-router-dom"
import axios from 'axios'
import * as Icons from './../icons/index'
import config from './../../package/config'
import { gaView } from './../../package/google-analytic'
import { visitTag } from './../../package/criteo'

import SeoComponent from './../../rComponents/global/seoComponent'

import Product from './../widgets/list/product'
import Input from './../widgets/forms/input'

function Null(props) {

	const history = useHistory()

	const [data, setData] = useState(null)
	const [searchText, setSearchText] = useState(null)

	const isFirstRun = useRef(true)

	useEffect(() => {
		if(isFirstRun.current){
			isFirstRun.current = false
			gaView()
			visitTag()

			const requestHeader = {headers: {titleinurl: "yeni-gelenler"}}
			const pageSize = window.innerWidth < 540 ? 6 : 5
			axios.get("/GetProducts?page=1&pagesize=" + pageSize, requestHeader)
			.then(res => {
				if(res?.data?.isSuccess){
					setData(res?.data?.data?.data)
				}
			})
		}

	}, [props])

	const toSearch = () => {
		if(searchText?.length > 2){
			history.push("/arama/" + searchText)
		}
	}

	const seoData = {
		title: "Sayfa bulunamadı" + config.prefix + config.name,
		description: "Böyle bir sayfa yok / 404 Not Found"
	}

	return (
		<>
			<SeoComponent {...seoData}/>
			<div id="content">
				<div className="container">
					<div className="_404 base-light">
						<span className="char_404">Hmmm.. Tanıdık Gelmedi</span>
						<h1>Üzgünüz! Aramanıza uygun ürün bulunamadı.</h1>

						<div className="row justify-content-center m-5">
							<div className="col-6">
								<div className="searching">
									<Input name="search" placeholder="Ne arıyordunuz ?" onKeyUp={e => {
											setSearchText(e.target.value)
											if (e.keyCode === 13) {
												toSearch()
											}
										}}/>
									<span onClick={toSearch}><Icons.Search /></span>
								</div>
							</div>
						</div>
						<h1 className="bg">Senin için önerilerimiz var</h1>
					</div>
					<div className="scl-product-list">
						{
							data?.map((elm, i) =>
								<Product data={elm} index={i} key={i}/>
							)
						}
					</div>
				</div>
			</div>
		</>
	)
}

export default Null
