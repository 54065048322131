import React, { Component } from "react";
import $ from "jquery";

import BasketContent from "./../widgets/basket/basket-content"

function BasketModal() {
	return (
		<div id="basketHover">
			<div className="content">
				<BasketContent modal={true} />
			</div>
		</div>
	);
}

export default BasketModal;
