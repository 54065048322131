import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import './package/system-init'

import App from "./App";
import './package/i18next'
import './package/axios-init'

ReactDOM.render(
	<React.StrictMode>
		<Suspense fallback={(<div>Yükleniyor..</div>)}>
			<App />
		</Suspense>
	</React.StrictMode>,
	document.getElementById("root")
);
