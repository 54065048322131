import React, { useEffect, useState, useRef, useContext } from "react";
import * as Icons from "./../../icons/index"

import { AuthContext } from './../../../context/AuthContext'

function PopupLightBottom(){

	const [show, setShow] = useState(false)

	const { openModal } = useContext(AuthContext)

	useEffect(() => {
		const data = localStorage.getItem("popupCookie")
		if(!data){
			setShow(true)
		}
	}, [])

	const closePopup = () => {
		setShow(false)
		localStorage.setItem("popupCookie","true")
	}

	return (
		<>
		{
			show &&
			<div className="popup">
				<div className="container">Size daha iyi bir alışveriş deneyimi sunabilmek için çerezler kullanıyoruz. Detaylı bilgi için <strong onClick={() => openModal('policy')}>Gizlilik bildirimi</strong> ve <strong onClick={() => openModal('membership')}>Kişisel verilerimin korunmasını</strong> hakkında açıklama metnini inceleyebilirsiniz.
				</div>
				<span onClick={closePopup}><Icons.Close /></span>
			</div>
		}
		</>
	);
}

export default PopupLightBottom;
