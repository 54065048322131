import * as React from "react";

function SvgSend(props) {
  return (
    <svg
      viewBox="-13 0 511 512"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M84.453 409.98l-34.168 34.172a7.497 7.497 0 000 10.606 7.47 7.47 0 005.3 2.195c1.923 0 3.84-.73 5.306-2.195l34.168-34.172a7.497 7.497 0 000-10.606 7.497 7.497 0 00-10.606 0zm-31.43-125.257a7.497 7.497 0 00-10.605 0L2.695 324.445c-2.925 2.926-2.925 7.676 0 10.606 1.465 1.46 3.387 2.195 5.305 2.195s3.84-.73 5.3-2.195l39.723-39.723a7.497 7.497 0 000-10.605zM476.2 6.793a9.956 9.956 0 00-.222-.195c-.055-.047-.106-.098-.16-.145-.036-.027-.07-.05-.106-.078-.07-.055-.145-.113-.219-.168-8.238-6.55-18.918-8.023-28.664-3.906l-59.144 24.988a7.498 7.498 0 00-3.989 9.828 7.497 7.497 0 009.828 3.988l59.141-24.984c.336-.144.668-.258.992-.371L98.691 276.633l-67.164-67.164c-2.468-2.469-3.5-5.852-2.832-9.278.668-3.43 2.899-6.175 6.114-7.53L361.168 54.772a7.497 7.497 0 003.988-9.828c-1.61-3.812-6.011-5.597-9.824-3.988L28.972 178.844a25.27 25.27 0 00-15 18.472 25.28 25.28 0 006.95 22.758l70.355 70.356 31.641 108.925c1.629 5.61 6.45 9.477 12.277 9.852a13.707 13.707 0 006.695-1.234l47.391-19.535 70.04 70.035a7.475 7.475 0 005.3 2.199c1.922 0 3.84-.734 5.305-2.2a7.497 7.497 0 000-10.605l-73.574-73.57a.362.362 0 00-.055-.055l-23.59-23.594L470.535 23.973c.914 2.504 1.121 5.465.04 8.71l-147.74 443.215c-1.358 4.086-4.546 6.997-8.737 7.985-4.192.992-8.344-.188-11.391-3.235l-8.328-8.328a7.497 7.497 0 00-10.606 0 7.497 7.497 0 000 10.606l8.329 8.332c5.175 5.176 12.027 7.965 19.125 7.965 2.097 0 4.214-.243 6.316-.739 9.227-2.18 16.527-8.847 19.523-17.843L484.805 37.426c3.703-11.11.316-23.07-8.606-30.633zM177.793 376.949l-22.742 9.375 9.765-22.355zm-20.992-31.113a7.527 7.527 0 00-1.332 2.05l-19.145 43.84-29.719-102.296L414.59 63.074zm38.316 96.184l-57.176 57.18a7.497 7.497 0 005.301 12.8 7.48 7.48 0 005.305-2.195l57.176-57.18a7.497 7.497 0 000-10.605 7.497 7.497 0 00-10.606 0zm0 0"
      />
    </svg>
  );
}

export default SvgSend;
