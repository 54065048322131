import * as React from "react";

function SvgWhatsapp(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <g fill="currentColor">
        <path d="M35.26 19.23c-.2-8.04-6.83-14.5-14.98-14.5-8.06 0-14.63 6.31-14.98 14.23-.01.22-.01.44-.01.65 0 2.81.79 5.44 2.15 7.69l-2.7 7.98 8.3-2.64a15.01 15.01 0 007.24 1.85c8.28 0 14.99-6.66 14.99-14.88 0-.13 0-.26-.01-.38zM20.28 32.11c-2.56 0-4.94-.76-6.93-2.07L8.5 31.59l1.57-4.65a12.37 12.37 0 01-2.34-8.54c.62-6.34 6.01-11.3 12.55-11.3 6.62 0 12.06 5.09 12.56 11.53.03.32.04.65.04.98 0 6.9-5.65 12.5-12.6 12.5z" />
        <path d="M27.15 22.61c-.37-.18-2.18-1.07-2.51-1.19-.34-.13-.58-.18-.83.18s-.95 1.19-1.17 1.42c-.22.25-.43.27-.8.09-.36-.18-1.55-.56-2.95-1.81-1.09-.96-1.83-2.15-2.04-2.52-.21-.37-.02-.56.16-.74.17-.17.36-.43.55-.64.05-.06.09-.12.13-.18.08-.13.15-.26.23-.44.13-.25.06-.46-.03-.64s-.83-1.98-1.14-2.71c-.3-.73-.61-.6-.83-.6-.21 0-.46-.03-.7-.03-.25 0-.65.09-.98.46-.34.37-1.28 1.25-1.28 3.05 0 .42.08.84.19 1.24.37 1.28 1.17 2.34 1.31 2.53.18.24 2.54 4.04 6.27 5.51 3.74 1.45 3.74.97 4.41.91.67-.06 2.18-.88 2.48-1.73.31-.85.31-1.58.22-1.73-.08-.16-.33-.25-.69-.43z" />
      </g>
    </svg>
  );
}

export default SvgWhatsapp;
