import React, { useEffect, useState, useContext } from 'react'
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import config from './../../../package/config'
import Toast from './../../../package/swal-init'
import { gaRemoveBasket } from './../../../package/google-analytic'
import * as Icons from './../../icons/index'

import {BasketContext} from './../../../context/BasketContext'
import {FiltersContext} from './../../../context/FiltersContext'
import {LanguageContext} from './../../../context/LanguageContext'
import {AlarmContext} from './../../../context/AlarmContext'

import Number from './../forms/number'
import Checkbox from './../forms/checkbox'
import Select from './../forms/select'

function BasketProduct(props) {

	const { t } = useTranslation()

	const { removeBasket, addBasket, addBasketNotification, updateBasket, addNotInclude, isNotInclude, addChoicesExtra } = useContext(BasketContext)
	const { getColorName, getCampaignName } = useContext(FiltersContext)
	const { toPriceConvert } = useContext(LanguageContext)
	const { addAlarm } = useContext(AlarmContext)

	const [data, setData] = useState(props.data)
	const [quantity, setQuantity] = useState(props.data.quantity)
	const [isModal, setIsModal] = useState(props.modal)
	const [discountsOptions, setDiscountsOptions] = useState([])
	const [isDisabled, setDisabled] = useState(false)

	useEffect(() => {
		setData(props.data)
		setQuantity(props.data.quantity)
		setIsModal(props.modal)
		setDiscountsOptions([{key: 0, value: t('Basket.select-discount')}, ...data.campaignDiscounts.map(elm => {
			return {key: elm.campaignID, value: getCampaignName(elm.campaignID)}
		})])
	}, [props])

	const basketPlus = (value) => {
		setDisabled(true)
		addBasket({variantCode: data.variantCode, productColorCode: data.productColorCode, quantity: 1})
		.then(e => {
			if(e?.data?.isSuccess){
				Toast.fire({
					icon: 'success',
					title: t('ContextMessage.basket.success')
				})
			}
		})
		.catch(e => e)
		.then(e => {
			setDisabled(false)
		})
	}

	const basketMinus = (value) => {
		setDisabled(true)
		removeBasket({variantCode: data.variantCode, productColorCode: data.productColorCode, quantity: 1})
		.then(e => {
			if(e?.data?.isSuccess){
				Toast.fire({
					icon: 'success',
					title: t('ContextMessage.basket.delete')
				})
			}
		}).catch(e => {
			Toast.fire({
				icon: 'error',
				title: t('ContextMessage.basket.non')
			})
		})
		.then(e => {
			setDisabled(false)
		})
	}

	const selectedCampaign = (elm) => {
		addChoicesExtra({"VariantCode": data.variantCode,"ProductColorCode": data.productColorCode, "Quantity": data.quantity, "DateTime": data.dateTime, "ExtraCampaign": elm.key, "LastChoice": true})
	}

	return (
		<div className="row dashedTB pb-3 pt-3 align-items-center">
			<div className="col-4 col-md-2">
				{
					!isModal && data.detail.maxStock > 0 &&
					<>
						<Checkbox onChange={() => addNotInclude(data.variantCode)} checked={isNotInclude(data.variantCode)}/>
					</>
				}
				<Link to={data.promotional ? '#promosyon' : "/" + data.detail.product.url}><img src={data.detail.product.imagePath !== "" ? data.detail.product.imagePath : config.noImage} width="80%" className="float-right" alt=""/></Link>
			</div>
			<div className={(!isModal ? "col-8 col-md-4" : "col-6 col-md")}>
				<Link to={data.promotional ? '#promosyon' : "/" + data.detail.product.url} className="pr-4 pr-lg-0">{/*data.detail.product.brandCode*/}{data.detail.product.title}</Link>
				<span className="base-light">{t('Basket.color')}: {getColorName(data.detail.product.colorCode)} / {t('Basket.body')}: {data.detail.name}</span>
				{/*<span>Beden ve Renk Değiştir</span>*/}
				{
					data.detail.extraCampaign > 0 &&
					<span className="campaign">{getCampaignName(data.detail.extraCampaign)}</span>
				}
				{
					!data.promotional && (isModal || window.innerWidth < 540) &&
					toPriceConvert(data.totalPriceWithoutDiscount)
				}
				{data.promotional && <span className="green">PROMOSYON</span>}
			</div>
			<div className="col-5 col-md-3 p-2 pl-3 pr-3">
				{
					data.detail.maxStock > 0 &&
					<Number
						value={quantity}
						valueMinus={basketMinus}
						valuePlus={basketPlus}
						plusID="add-to-basket"
						max={data.detail.maxStock}
						isDisabled={isDisabled}
					/>
				}
				{
					data.detail.maxStock <= 0 &&
					<>
						<span className="red text-left ml-2 d-block">TÜKENDİ</span>
						{
							!isModal &&
							<span className="btn btn-small mt-3" onClick={() => {
								addAlarm({variantCode: data.variantCode, type: "product"})
							}}>GELİNCE HABER VER</span>
						}
					</>
				}
				{
					data.detail.maxStock > 0 && data.detail.maxStock <= 3 &&
					<span className="red pt-2 d-block">(Son {data.detail.maxStock} Ürün)</span>
				}
				{
					data.detail.stockDiff > 0 &&
					<span className="red pt-2 d-block">{data.detail.maxStock} ürün kalmıştır</span>
				}
				{
					!isModal &&
					data.campaignDiscounts?.length > 1 &&
					<Select name="campaignData" id="campaignData" onlytext="true" selected={data.extraCampaign} options={discountsOptions} clicked={selectedCampaign}/>
				}
			</div>
			{
				!isModal && window.innerWidth > 540 &&
				<div className="col-6 col-md-2 price">
					{!data.promotional && data.totalDiscount > 0 && <span className="green">{t('Basket.gain')} {toPriceConvert(data.totalDiscount, false, 2, false)}</span>}
					{
						data.totalPriceWithoutDiscount > 0 ?
							toPriceConvert(data.totalPriceWithoutDiscount)
						:
							toPriceConvert(data.sellingPrice)
					}
				</div>
			}
			{
				!isModal &&
				<div className="col-1 col-md-1"><span className="pointer" onClick={() => {
						// Google Analytic
						const gaData = {
							id: data.variantCode,
							title: data.detail.product.title,
							brand: data.detail.product.brandCode,
							variant: getColorName(data.detail.product.colorCode),
							price: data.sellingPrice,
							quantity: data.quantity
						}

						gaRemoveBasket(gaData)
						// Google Analytic
						removeBasket({variantCode: data.variantCode, productColorCode: data.productColorCode, quantity: 0})
						.then(e => {
							if(e?.data?.isSuccess){
								Toast.fire({
									icon: 'success',
									title: t('ContextMessage.basket.delete')
								})
							}
						})
				}}><Icons.CloseRegular /></span></div>
			}
		</div>
	)
}

export default BasketProduct
