import * as React from "react";

function SvgArrowRight(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M14.43 9.24c.22-.22.57-.22.78 0L25.57 19.6a.552.552 0 010 .78L15.22 30.76c-.22.22-.57.22-.78 0a.555.555 0 010-.79L24 20.39c.22-.22.22-.57 0-.79l-9.56-9.58a.552.552 0 01-.01-.78z"
      />
    </svg>
  );
}

export default SvgArrowRight;
