import React from 'react'
import TypeLink from './../global/link'
import styles from './homeTrends.module.css'

function HomeTrends(props) {
	const elm = props.data?.data?.[0]

	return (
		<div className="banner">
			<div className="container">
				{props.data?.title && <h2><span>{props.data.title}</span></h2>}
				<div className={`row g-0 justify-content-between`}>
					<div className={styles.col}>
						{
								<div>
									{
											<TypeLink to={elm?.url}>
												<img src={elm?.img} alt={props?.data?.title}/>
											</TypeLink>
									}
								</div>
						}
					</div>
					<div className={styles.col}>
						{
							props.data?.data?.slice(1).map((elm2, i) =>
								<div key={i}>
									{
											<TypeLink to={elm2?.url}>
												<img src={elm2?.img} alt={props?.data?.title}/>
											</TypeLink>
									}
								</div>
							)
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default HomeTrends
