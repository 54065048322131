import React, { useState } from 'react'

function Textarea(props) {

	const [countChar, setCountChar] = useState(0)
	const [error, setError] = useState(props.max)

	let input_props = {...props, label: undefined, transferref: undefined}

	const onChange = e => {
		if(props.onChange){
			props.onChange(e)
		}
		if(props.max){
			const count = e.target?.value?.length
			if(count > props.max){
				e.target.value = e.target.value.slice(0, props.max)
				setError("error")
				setTimeout(() => setError(null), 1000);
			}else{
				setError(null)
				setCountChar(count)
			}
		}
	}

	return (
		<>
			<div className="mb-3">
				{
					props.label &&
					<label className="form-label" htmlFor={props.id}>
						{props.label}
					</label>
				}
				<textarea rows="5" cols="50" className={"form-control" +  (props.error ? ' red' : '') + (props.className ?? '')} {...input_props} ref={props.transferref} onChange={onChange}></textarea>
				{props.error &&  <span className="error danger mt-2">{props.error}</span>}
				{props.max && countChar > 5 &&  <span className={"error float-right text-right mt-2" + (error ? ' red' : '')}>{props.max - countChar}</span>}
			</div>
		</>
	)
}

export default Textarea
