import React, { useState, useContext, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination"
import axios from "axios";
import SwiperCore, { Navigation, Pagination as SwipperPagination, Scrollbar, Lazy } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { useTranslation } from 'react-i18next'
import styles from "./basketGroupList.module.css"

import config from "./../../package/config"
import Toast from "./../../package/swal-init"

import { BasketContext } from './../../context/BasketContext'
import { LanguageContext } from './../../context/LanguageContext'

import Modal from "./../global/modal/modal"
import Loading from "./../../components/widgets/global/loading"
import Image from "./../../components/widgets/global/image"
import Button from "./../../components/widgets/forms/button"

SwiperCore.use([Navigation, SwipperPagination, Lazy])

function BasketGroupList(props) {

	const { t } = useTranslation()
	const { addBasket } = useContext(BasketContext)

	const [smallListData, setSmallListData] = useState(null)

	const [listModalShow, setListModalShow] = useState(false)
	const [listData, setListData] = useState(null)
	const [listTotalProduct, setListTotalProduct] = useState(1)
	const [listPage, setListPage] = useState(1)

	const [modalShow, setModalShow] = useState(false)
	const [error, setError] = useState(null)
	const [detailData, setDetailData] = useState(null)
	const [choicesColor, setChoicesColor] = useState(null)
	const [choicesSize, setChoicesSize] = useState(null)

	const isFirstRun = useRef(true)

	useEffect(() => {
		if(isFirstRun.current){
			isFirstRun.current = false
			checkSmallList()
		}
	}, [props])

	const checkSmallList = () => {
		axios.get('/GetProducts?pagesize=12&promotional=true', {headers: {titleinurl: props?.leadGroup?.titleInUrl}})
		.then(res => {
			if(res?.data?.isSuccess){
				setSmallListData(res?.data?.data?.data)
				setListTotalProduct(res?.data?.data?.totalCount)
			}
		})
	}

	const checkListData = () => {
		axios.get(`/GetProducts?page=${listPage}&pagesize=25&promotional=true`, {headers: {titleinurl:  props?.leadGroup?.titleInUrl}})
		.then(res => {
			if(res?.data?.isSuccess){
				setListData(res.data?.data?.data)
				setListTotalProduct(res.data?.data?.totalCount)
			}
		})
	}

	const checkDetail = (e, elm) => {
		e.preventDefault()

		axios.get('/GetProductDetail?url=' + elm.url)
		.then(res => {
			if(res?.data?.isSuccess){
				const variantCount = res.data?.data?.colorsdetail[0]?.variantsdetail?.filter(e => e.stock)?.length

				setChoicesColor(res.data?.data?.colorsdetail?.[0])
				setChoicesSize(res.data?.data?.colorsdetail?.[0]?.variantsdetail?.filter(e => e.stock)?.[0])
				setDetailData(res.data?.data)

				if(variantCount > 1){
					setModalShow(true)
				}else{
					addBasketHandler({productColorCode: res.data?.data?.id, variantCode: res.data?.data?.colorsdetail?.[0].variantsdetail[0].code})
				}

			}
		})
	}

	const closeModalList = () => {
		setListModalShow(false)
		setListTotalProduct(0)
		setListData(null)
		setListPage(1)
	}

	const closeModalProduct = () => {
		setChoicesColor(null)
		setDetailData(null)
		setModalShow(false)
	}

	const addBasketHandler = (data)  => {
		if((!choicesSize?.code || !detailData?.id) && !data){
			setError("Lütfen renk ve beden seçin")
		}else{
			addBasket({
				variantCode: !data ? choicesSize.code : data.variantCode,
				productColorCode: !data ? detailData.id : data.productColorCode,
				quantity: 1,
				promotional: true
			})
			.then(e => {
				if(e?.data?.isSuccess){
					Toast.fire({
						icon: 'success',
						title: t('ContextMessage.basket.success')
					})
					setModalShow(false)
				}
			})
		}
	}

	const choicesColorHandler = (elm) => {
		setChoicesColor(elm)
		setChoicesSize(elm.variantsdetail.filter(e => e.stock > 0)[0])
	}

	return (
		<>
		{
			smallListData?.length > 0 &&
			<>
				<div id="promotional" className={styles.products}>
					<h2>
						Promosyon Seçimi <small className="red">({props?.leadGroup?.freeQuantity} adet promosyon ürünü ekleyebilirsiniz)</small>
						{
							listTotalProduct > 12 &&
							<small className="float-right pointer" onClick={() => {
								setListModalShow(true)
								checkListData('yeni-gelenler')
							}}>Tümünü gör</small>
						}
					</h2>
					<Swiper slidesPerView={2} spaceBetween={0} navigation={true} breakpoints={{540 : {slidesPerView: 6,spaceBetween: 0}}}>
						{
							smallListData?.map((elm, i) =>
								<SwiperSlide lazy="true" key={i}>
									<Product {...{...elm, click: (e => checkDetail(e, elm)), promotional: props.promotional}}/>
								</SwiperSlide>
							)
						}
					</Swiper>
				</div>
				<Modal show={listModalShow} size="large" rePosition={listPage + listData?.length} closeHandler={closeModalList}>
					{!listData && <Loading />}
					<div className={styles.list}>
					{
						listData?.map((elm, i) =>
							<div className={styles.listitem} key={i}>
								<Product {...{...elm, click: (e => checkDetail(e, elm)), promotional: props.promotional}}/>
							</div>
						)
					}
						{/*<Pagination
							activePage={listPage}
							itemsCountPerPage={25}
							totalItemsCount={listTotalProduct}
							pageRangeDisplayed={window.innerWidth > 768 ? 10 : 5}
							// onChange={pageGo}
							hideFirstLastPages
						/>*/}
					</div>
				</Modal>
				{
					detailData &&
					<Modal show={modalShow} closeHandler={closeModalProduct}>
						<div className="row d-flex">
							<div className="col-6">
								{
									choicesColor?.imagepaths?.length > 0 &&
									<Swiper slidesPerView={1} spaceBetween={0} pagination={true}>
										{
											choicesColor?.imagepaths?.map((elm, i) =>
											<SwiperSlide lazy="true" key={i}>
												<Image src={elm} alt=""/>
											</SwiperSlide>
											)
										}
									</Swiper>
								}
								{
									!choicesColor?.imagepaths?.length &&
									<Image src={config.noImage} alt=""/>
								}
							</div>
							<div className="col-6 text-left">
								{error && <span className="red mb-3">{error}</span>}
								{
									detailData.colorsdetail.length > 1 &&
									<>
										<h2>Renk{choicesColor && `: ${choicesColor.colorinfo[0].title}`}</h2>
										<div className={styles.colors}>
											{
												detailData.colorsdetail.map((elm, i) =>
													<div className={`${styles.color} ${elm.colorinfo[0].code === choicesColor?.colorinfo[0]?.code && styles.active}`} style={{backgroundColor: elm.colorinfo[0].hexcode}} onClick={() => choicesColorHandler(elm)} key={i}></div>
												)
											}
										</div>
									</>
								}
								<h2>Beden{choicesSize && `: ${choicesSize.name}`}</h2>
								<div className={styles.sizes}>
									{
										choicesColor?.variantsdetail?.map((elm, i) =>
											<div className={`${styles.size} ${elm.code === choicesSize?.code && styles.active} ${elm.stock <= 0 && styles.nonStock}`} onClick={() => {
												if(elm.stock > 0){
													setChoicesSize(elm)
												}
											}} key={i}>
												{elm.name}
											</div>
										)
									}
								</div>
								<Button id="add-to-basket" type="submit" className="btn" title="Sepete Ekle" onClick={addBasketHandler}/>
							</div>
						</div>
					</Modal>
				}
			</>
		}
		</>
	);
}

function Product(elm){
	const { toPriceConvert } = useContext(LanguageContext)
	return (
		<div className={styles.product}>
			<Image
				src={elm.imagePaths[0] ?? config.noImage}
				alt={elm.title}
			/>
			<span className={styles.title}>{elm.title}</span>
			<span className={styles.price}>{toPriceConvert([elm.price1, elm.price2])}</span>
			<span className={styles.add}>
				<span onClick={elm.click}>Sepete Ekle</span>
			</span>
		</div>
	)
}

export default BasketGroupList;
