import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom"
import $ from 'jquery'
import axios from 'axios'
import config from './../../package/config'
import { gaView } from './../../package/google-analytic'
import { visitTag } from './../../package/criteo'

import SeoComponent from './../../rComponents/global/seoComponent'

function Help(props) {

	const [data, setData] = useState([])
	const [sectionHelp, setSectionHelp] = useState(null)
	const [content, setContent] = useState('')
	const [seoContent, setSeoContent] = useState('')
	const isFirstRun = useRef(true)

	useEffect(() => {
		if(isFirstRun.current !== props.match.url){
			isFirstRun.current = props.match.url
			if(data.length <= 0){
				axios.get("/GetPages")
				.then(res => {
					setData(res.data.data)
					if(props.match.params.id){
						const index = res.data.data.map(elm => {
							return elm.TitleInUrl
						}).indexOf(props.match.params.id)
						setSectionHelp(res.data.data[index < 0 ? 0 : index])
					}else{
						setSectionHelp(res.data.data[0])
					}
				})
			}else{
				if(props.match.params.id){
					const index = data.map(elm => {
						return elm.TitleInUrl
					}).indexOf(props.match.params.id)
					if(index > 0){
						setSectionHelp(data[index])
					}else{
						setSectionHelp(data[0])
					}
				}
			}
		}
	}, [props])

	useEffect(() => {
		let id = sectionHelp?.ID
		gaView()
		visitTag()
		if(!id){
			id = data?.[0]?.ID
			setSectionHelp(data?.[0])
		}
		if(id && content.ID != id){
			axios.get("/GetPage?id=" + id)
			.then(res => {
				setContent(res.data?.data?.[0])
				setSeoContent(stripHtml(res.data?.data?.[0]?.Content)?.substring(0,130))
			})
			$("html, body, .App").stop().animate({ scrollTop: 70 }, 500);
		}
	}, [sectionHelp?.ID])

	const stripHtml = (html) => {
		let tmp = document.createElement("DIV");
		tmp.innerHTML = html;
		return tmp.textContent || tmp.innerText || "";
	}

	const seoData = {
		title: content?.Title + config.prefix + config.name,
		description: seoContent,
		ogytpe: "article"
	}

	return (
		<>
			<SeoComponent {...seoData}/>
			<div id="content" className="user-page">
				<div className="container">
					<div className="scl-sidebar">
						<ul className="user-panel">
							{
								data.map((elm, i) =>
									<li key={i} className={elm===sectionHelp ? 'active' : ''}><Link to={"/yardim/" + elm.TitleInUrl} onClick={() => setSectionHelp(elm)}>{elm.Title}</Link></li>
								)
							}
						</ul>
					</div>
					<div className="scl-content">
						<div className="scl-content-title">
							<h1 className="base-regular">{content && content.Title}</h1>
						</div>
						<div className="scl-section-content">
							<div className="accordion" id="help">
								<div className="help-content" dangerouslySetInnerHTML={{__html: content && content.Content}}>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="clearfix"></div>
			</div>
		</>
	);
}

export default Help;
