import React, { useState } from 'react'
import styles from './ibans.module.css'

import * as Icons from './../../icons/index'

import IbanList from './../../../rComponents/widgets/userPage/ibans/list'
import IbanAdd from './../../../rComponents/widgets/userPage/ibans/add'

function Ibans(props) {

	const [addShow, setAddShow] = useState(false)
	const [updateCounter, setUpdateCounter] = useState(0)

	const addModalOpen = () => {
		setAddShow(true)
	}

	const addModalClose = () => {
		setAddShow(false)
	}

	const updateHandler = () => {
		setUpdateCounter(updateCounter + 1)
	}

	return (
		<>
			<div className={styles.container}>
				<h1>
					IBANLARIM
					<span className="btn user-title-btn float-right base-regular" onClick={addModalOpen}><Icons.PlusBold/> YENİ IBAN</span>
				</h1>
				<IbanList updateHandler={updateCounter} modalOpen={addModalOpen} />
				<IbanAdd show={addShow} modalClose={addModalClose} updateHandler={updateHandler}/>
			</div>
		</>
	)
}

export default Ibans;
