import * as React from "react";

function SvgShieldTick(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <g fill="currentColor">
        <path d="M18.37 24.36c.13.13.31.2.49.2.19 0 .36-.07.49-.2l7.67-7.67c.27-.27.27-.72 0-.99a.706.706 0 00-.99 0l-7.18 7.18-3.21-3.21a.706.706 0 00-.99 0c-.27.27-.27.72 0 .99l3.72 3.7z" />
        <path d="M18.87 24.86a.99.99 0 01-.71-.29l-3.7-3.7a.99.99 0 01-.29-.71c0-.27.1-.52.29-.71.39-.39 1.03-.39 1.42 0l2.99 2.99 6.96-6.96c.38-.38 1.04-.38 1.42 0 .19.19.29.44.29.71 0 .27-.1.52-.29.71l-7.67 7.67a.99.99 0 01-.71.29zm-3.7-5.1c-.1 0-.2.04-.28.12-.16.15-.16.41 0 .56l3.7 3.7c.15.15.41.15.56 0l7.67-7.67c.08-.08.12-.17.12-.28 0-.11-.04-.21-.12-.28a.405.405 0 00-.56 0l-7.39 7.39-3.42-3.42a.395.395 0 00-.28-.12z" />
      </g>
      <path
        fill="currentColor"
        d="M34.72 5.43a.711.711 0 00-.77-.4C27.91 6.15 20.51.21 20.44.15a.707.707 0 00-.89 0c-.07.06-7.46 6-13.51 4.88-.31-.06-.63.1-.77.4-.09.2-8.96 20 14.35 34.46.23.14.51.14.74 0C43.68 25.43 34.81 5.63 34.72 5.43zM20 37.79C.67 25.57 5.69 9.94 6.85 7c5.5.68 11.5-3.49 13.15-4.73C21.65 3.51 27.64 7.68 33.14 7 34.24 9.9 39.1 25.71 20 37.79z"
      />
    </svg>
  );
}

export default SvgShieldTick;
