import * as React from "react";

function SvgClose(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M25.87 24.5L21.4 20l4.47-4.5a.996.996 0 000-1.41.984.984 0 00-1.4 0L20 18.59l-4.47-4.5a.984.984 0 00-1.4 0 .996.996 0 000 1.41L18.6 20l-4.47 4.5a.996.996 0 000 1.41c.39.39 1.01.39 1.4 0l4.47-4.5 4.47 4.5c.39.39 1.01.39 1.4 0 .39-.4.39-1.03 0-1.41z"
      />
    </svg>
  );
}

export default SvgClose;
