import * as React from "react";

function SvgEye(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M20 27.93c-4.37 0-7.93-3.56-7.93-7.93s3.56-7.93 7.93-7.93 7.93 3.56 7.93 7.93-3.56 7.93-7.93 7.93zm0-14.58c-3.67 0-6.65 2.98-6.65 6.65 0 3.67 2.98 6.65 6.65 6.65 3.67 0 6.65-2.98 6.65-6.65 0-3.67-2.98-6.65-6.65-6.65z"
      />
      <path
        fill="currentColor"
        d="M20 23.88c-2.14 0-3.88-1.74-3.88-3.88s1.74-3.88 3.88-3.88h.64v.64c0 1.43 1.17 2.6 2.6 2.6h.64V20c0 2.14-1.74 3.88-3.88 3.88zm-.58-6.42c-1.16.26-2.02 1.3-2.02 2.54 0 1.43 1.17 2.6 2.6 2.6 1.24 0 2.27-.87 2.54-2.02a3.9 3.9 0 01-3.12-3.12z"
      />
      <path
        fill="currentColor"
        d="M20 31.99c-4.36 0-9.06-2.01-13.59-5.82C3.02 23.32.93 20.51.85 20.39L.57 20l.28-.38c.09-.12 2.17-2.93 5.56-5.78 4.53-3.81 9.23-5.82 13.59-5.82s9.06 2.01 13.59 5.82c3.39 2.85 5.48 5.66 5.56 5.78l.28.38-.28.38c-.09.12-2.17 2.93-5.56 5.78-4.53 3.81-9.23 5.83-13.59 5.83zM2.17 20C3.73 21.95 11.21 30.7 20 30.7c8.8 0 16.27-8.76 17.83-10.7C36.27 18.05 28.79 9.3 20 9.3 11.2 9.3 3.73 18.05 2.17 20z"
      />
    </svg>
  );
}

export default SvgEye;
