import React from "react";

function Combine({image, products, look, onClickHandler}) {
	return <div className={`scl-product combine`} onClick={() => onClickHandler()}>
		<span className="images w-100"><img src={image} alt=""/></span>
		<div className="combine-list">
			{
				products.map((elm, i) =>
					<div key={i} className="combine-product">
						<div className="image"><img src={elm.imagePaths[0]} height="50px" alt=""/></div>
						<span className="title">{elm.title}</span>
						<span className="price">{elm.price}</span>
					</div>
				)
			}
		</div>
		<div className="look-modal">{look}</div>
	</div>
}

export default Combine;
