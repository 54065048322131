import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styles from './login.module.css'
import axios from 'axios'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2'

import yup from './../package/yup-init'
import Toast from './../package/swal-init'

import Modal from './../rComponents/global/modal/modal'
import Login from './../rComponents/global/login'
import ForgotPassword from './../rComponents/global/forgotPassword'

import { AuthContext } from './../context/AuthContext'
import { BasketContext } from './../context/BasketContext'
import { FavoriteContext } from './../context/FavoriteContext'

const loginSchema = yup.object().shape({
	login_name: yup.string()
	.required(),
	login_password: yup.string()
	.min(3)
	.max(32)
	.required(),
	remember: yup.boolean()
})

const forgotSchema = yup.object().shape({
	forgot_mail: yup.string()
	.required()
})

function LoginModal(props) {

	const { loginModalShow, logIn, setStateAuth, isAuth, data, leaveAuth } = useContext(AuthContext)
	const { updateBasket, setAuth } = useContext(BasketContext)
	const { controlAuth } = useContext(FavoriteContext)

	const [tab, setTab] = useState(1)
	const [successMsg, setSuccessMsg] = useState("")
	const [errorMsg, setErrorMsg] = useState("")
	const [registerStep, setRegisterStep] = useState(1)
	const [registerCode, setRegisterCode] = useState("")

	const { register: loginRegister, handleSubmit: loginHandleSubmit, errors: loginErrors } = useForm({
		resolver: yupResolver(loginSchema),
		mode: 'onBlur'
	})

	const { register: forgotRegister, handleSubmit: forgotHandleSubmit, errors: forgotErrors, setError: forgotSetError, reset: forgotReset } = useForm({
		resolver: yupResolver(forgotSchema),
		mode: 'onBlur'
	})

	const loginOnSubmit = (loginData) => {

		let data = {
			email: loginData.login_name.toLowerCase(),
			password: loginData.login_password,
			remember: loginData.remember
		}

		const login = logIn(data)
		login.then(req => {
			if(req.isSuccess){
				setErrorMsg("")
				if(leaveAuth){
					setStateAuth('leaveAuth', false)
					window.location.reload()
				}
				setTimeout(function(){
					setStateAuth('loginModalShow', false)
					const options = {
						headers: {
							'Authorization': 'Bearer ' + req.data.Token,
							'UserID': req.data.UserID
						}
					}
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + req.data.Token
					axios.defaults.headers.common['UserID'] = req.data.UserID
					updateBasket(options)
					controlAuth()
					Toast.fire({
						icon: 'success',
						title: 'Başarıyla giriş yapıldı'
					})
				}, 200)
			}else{
				setErrorMsg(req.error)
			}
		})
	}

	const forgotOnSubmit = (data) => {
		const isMail = data.forgot_mail.includes('@')
		let headers
		let phone
		if(!isMail){
			phone =  data.forgot_mail.replace(/[^0-9]+/g, '')
			headers = {
				headers: {
					phone: phone
				}
			}
		}else{
			headers = {
				headers: {
					email: data.forgot_mail
				}
			}
		}

		axios.post("/ForgotPassword", {}, headers)
		.then(res => {
			if(res?.data?.isSuccess){
				setSuccessMsg(`Şifre yenileme bağlantını ${isMail ? "E-Posta adresine" : `"${data.forgot_mail}" numaranıza`} gönderdik.`)
				setTimeout(() => {
					setStateAuth('loginModalShow', false)
				}, 3000)
				setTimeout(() => {
					setSuccessMsg("")
					setTab(1)
				}, 4000)
			}else{
				setErrorMsg(res.data.error)
			}
		})
	}

	return (
		<>
			{
				tab === 1 &&
				<Login
					goForgotPassword={() => setTab(3)}
					error={errorMsg}
					errors={loginErrors}
					register={loginRegister}
					onSubmit={loginHandleSubmit(loginOnSubmit)}
				/>
			}
			{
				tab === 3 &&
				<ForgotPassword
					goLogin={() => setTab(1)}
					error={errorMsg}
					errors={forgotErrors}
					setError={forgotSetError}
					reset={forgotReset}
					success={successMsg}
					register={forgotRegister}
					onSubmit={forgotHandleSubmit(forgotOnSubmit)}
				/>
			}
		</>
	)
}

export default LoginModal
