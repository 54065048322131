import React, { createContext, Component } from 'react'
import axios from 'axios'
import Toast from './../package/swal-init'
import i18next from 'i18next';

export const AlarmContext = createContext()

class AlarmContextProvider extends Component {
	constructor( props ) {
		super( props )

		this.state = {
			alarmData: null,
		}
	}

	updateAlarm = (options={}) => {
		axios.get("/GetNotifications")
		.then(res => {
			this.setState({alarmData: res.data.data || []})
		})
	}

	addAlarm = (data, updateEvent=true) => {
		axios.post("/AddNewNotification", data)
		.then(res => {
			if(updateEvent){
				this.updateAlarm()
			}
			if(res?.data?.isSuccess){
				Toast.fire({
					icon: 'success',
					title: i18next.t('ContextMessage.alarm.success')
				})
			}else{
				Toast.fire({
					icon: 'error',
					title: i18next.t('ContextMessage.alarm.error')
				})
			}

		})
	}

	removeAlarm = (data, updateEvent=true) => {
		axios.post("/DeleteNotification", data)
		.then(res => {
			if(updateEvent){
				this.updateAlarm()
			}
			Toast.fire({
				icon: 'success',
				title: i18next.t('ContextMessage.alarm.delete')
			})
		})
	}

	getData = async () => {
		await this.updateAlarm()
		return this.alarmData
	}

	render() {
		return (
			<AlarmContext.Provider value={{...this.state, updateAlarm: this.updateAlarm, addAlarm: this.addAlarm, removeAlarm: this.removeAlarm, getData: this.getData}}>
				{this.props.children}
			</AlarmContext.Provider>
		)
	}
}

export default AlarmContextProvider
