import React, { useState, useContext, useEffect, useRef } from "react"
import {Link} from "react-router-dom";
import axios from "axios"
import SwiperCore, { Navigation, Pagination as SwipperPagination, Scrollbar, Lazy } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import $ from 'jquery'
import queryString from 'query-string'
import { gaCheckout, gaView, dataLayerPush } from './../../package/google-analytic'
import config from './../../package/config'
import { visitTag, basketTag } from './../../package/criteo'
import { useTranslation } from 'react-i18next';

import { BasketContext } from "./../../context/BasketContext"
import { AuthContext } from "./../../context/AuthContext"
import { FiltersContext } from "./../../context/FiltersContext"
import { LanguageContext } from "./../../context/LanguageContext"

import * as Icons from './../icons/index'

import SeoComponent from './../../rComponents/global/seoComponent'
import BasketContent from "./../widgets/basket/basket-content"
import BasketOrder from "./../widgets/basket/basket-order"
import BasketOrderNon from "./../widgets/basket/basket-order-non"
import BasketPayment from "./../widgets/basket/basket-payment"
import BasketSuccess from "./../widgets/basket/basket-success"

function Basket(props) {

	const { t } = useTranslation()

	const { basketData, notInclude, choicesExtra, updateBasket, installment, binNumber, setInstallment, coupons, cargoID, setStateBasket } = useContext(BasketContext)
	const { isAuth, getUserMail, setStateAuth, loginModalShow } = useContext(AuthContext)
	const { getColorName } = useContext(FiltersContext)
	const { toPriceConvert } = useContext(LanguageContext)

	const sessionAddress = JSON.parse(sessionStorage.getItem('nonAddress'))
	const [step, setStep] = useState(1)
	const [choicesDeliveryAddress, setChoicesDeliveryAddress] = useState(sessionAddress?.delivery ?? {})
	const [choicesInvoiceAddress, setChoicesInvoiceAddress] = useState(sessionAddress?.invoice ?? {})
	const [dataAddress, setDataAddress] = useState(null)
	const [choicesPaymentType, setChoicesPaymentType] = useState(null)
	const [useSame, setUseSame] = useState(sessionAddress?.delivery?.useSame ?? true)
	const [nonMemberMail, setNonMemberMail] = useState("")

	const [insertAdress, setInsertAdress ] = useState(false)
	const [insertPayment, setInsertPayment ] = useState(false)
	const [insertOrder, setInsertOrder ] = useState(false)

	const [preliminaryInformation, setPreliminaryInformation] = useState(false)
	const [distanceSalesAgreement, setDistanceSalesAgreement] = useState(false)

	const [choicesInstallment, setStateChoicesInstallment] = useState(false)
	const [creditCard, setCreditCard] = useState(null)
	const [walletCode, setWalletCode] = useState(null)

	const [walletOn, setWalletOn] = useState(false)


	const [extraNote, setExtraNote] = useState("")
	const [isGift, setIsGift] = useState(false)
	const [isBusy, setIsBusy] = useState(false)

	const isFirstRun = useRef(true)
	const isPrevStep = useRef(1)
	const isPrevStep2 = useRef(0)

	useEffect(() => {
		axios.get("/getsettings")
		.then(res => {
			console.log(res.data)
			if(res.data?.data?.walletEnabled) {
				setWalletOn(true)
			}
		})

		if(isFirstRun.current){
			isFirstRun.current = false
			$("html, body, .App").stop().animate({ scrollTop: 0 }, 300)

			const queryStringParse = queryString.parse(props.location.search)
			if(queryStringParse?.u === "forgotbasket"){
				props.history.replace('/sepet')
				if(!loginModalShow && !isAuth){
					setStateAuth('loginModalShow', true)
					setStateAuth('leaveAuth', true)
				}
			}
		}

		let steps = props.location.pathname.replace(props.match.path.toString(), '').replace('/', '');
		steps = steps !== "" ? steps : 1
		steps = convertStepNumber(steps)

		if(!insertAdress && steps !== 4){
			steps = steps > 2 ? 2 : steps
		}else if(!insertPayment && steps !== 4){
			steps = steps > 3 ? 3 : steps
		}
		setStep(steps)

		$(".modal-open").removeClass("modal-open")
		$(".fixed-header").removeClass("fixed-header")
		$(".fixed").removeClass("fixed")

		const queryStringParse = queryString.parse(props.location.search)
		if(queryStringParse?.utm === "forgotbasket"){
			props.history.replace('/sepet')
			if(!loginModalShow && !isAuth){
				setStateAuth('loginModalShow', true)
			}
		}
	}, [props])

	useEffect(() => {
		if(step === 3){
			updateBasket(walletCode)
			setPreliminaryInformation(null)
			setDistanceSalesAgreement(null)
		}
	}, [installment, choicesPaymentType?.key])

	useEffect(() => {
		if(step === 3){
			updateBasket(walletCode)
		}
	}, [walletCode])


	useEffect(() => {
		if(!isAuth){
			sessionStorage.setItem('nonAddress', JSON.stringify({delivery: choicesDeliveryAddress, invoice: choicesInvoiceAddress}));
		}
	}, [choicesInvoiceAddress, choicesDeliveryAddress])

	useEffect(() => {
		if(choicesDeliveryAddress?.country?.code){
			setStateBasket('countryID', choicesDeliveryAddress.country.code, true)
		}
	}, [choicesDeliveryAddress?.country?.code])

	useEffect(() => {
		if(isPrevStep2.current !== step){
			if(basketData?.items?.length){
				isPrevStep2.current = step
				if(step <= 3){
					dataLayerPush({
						'event': 'bag-step-' + step,
						'value': basketData?.totalPrice
					})
				}
				if(step === 1){
					basketTag(basketData?.items.map(e => { return {id: e.productColorCode, price: e.sellingPrice, quantity: e.quantity}}))
				}
			}

			setIsBusy(false)
			setTimeout(() => {
				gaView()
				visitTag()
			}, 300)
		}

		if(basketData?.items?.length > 0 && step != isPrevStep.current){
			isPrevStep.current = step
			const gaData = {
				items: basketData.items.map(e => {
					return {
						id: e.variantCode,
						title: e.detail.product.title,
						brand: e.detail.product.brandCode,
						variant: getColorName(e.detail.product.colorCode),
						price:  e.sellingPrice,
						quantity: e.quantity
					}
				})
			}

			// if(step !== 3){
			// 	setChoicesPaymentType({key: 2, name: "Kredi Kartı"})
			// }

			if(step === 1){
				setStateBasket('cargoID', null, false)
				setStateBasket('isGift', false, true)
				setIsGift(false)
			}else if(step === 2){
				if(basketData?.shipping?.length > 0 && !cargoID){
					 setStateBasket('cargoID', basketData.shipping?.[0]?.id, false)
				}
				// gaCheckout(gaData, {step: 1})
			}else if(step === 3){
				// gaCheckout(gaData, {step: 2})
				updateBasket(walletCode)
				const variantCodes = basketData?.items?.filter(e => notInclude.indexOf(e.variantCode)).map(e => e.variantCode)
				if(variantCodes?.length && isAuth){
					axios.post("/StockLock", {}, {headers: {variantcodes: variantCodes?.join(',')}})
					.then(res => res)
				}
			}
		}

		if(basketData?.items?.length <= 0 && step > 1 && step < 4){
			props.history.push("/sepet")
		}

		if(step > 1 && step < 4 && basketData){
			if(isAuth){
				if(basketData?.totalPrice <= 0 && !basketData?.hasOwnProperty('wallet')){
					props.history.push("/sepet")
				}
			}else{
				if(basketData?.items?.length <= 0 || basketData?.items?.length <= 0 || !(nonMemberMail || sessionStorage.getItem('nonMemberMail'))){
					props.history.push("/sepet")
				}
			}
		}

	}, [step, basketData])

	const orderInsert = async (smsCode="", walletCode=null) => {
		if(isBusy){
			return false
		}
		setIsBusy(true)

		let data = createOrderData()

		if(smsCode !== ""){
			data = {...data, SmsCode: smsCode}
		}

		if(walletCode) {
			data = {...data, WalletCode: walletCode}
		}

		const response = axios.post("/InsertOrder", data)
		const promiseData = response.then((res) => res.data)

		promiseData.then(res => {

			setTimeout(() => {
				setIsBusy(false)
			}, 200)
		})

		return promiseData
	}

	const createOrderData = (basket=false) => {
		if(!choicesPaymentType?.key){
			return false
		}

		let data = {
			"PaymentTypeId": choicesPaymentType.key,
			"Installment": choicesPaymentType.key === 2 ? choicesInstallment.installment : 0,
			"DeliveryAddress" : {...choicesDeliveryAddress, phone: choicesDeliveryAddress.phone.replace(/[^0-9]+/g, '')},
			"VariantCodes": notInclude,
			"BasketItems": choicesExtra,
			"extraNote": extraNote,
			"isGift": isGift,
			"cargo": {id: cargoID},
		}

		if(choicesPaymentType.cash){
			data = {...data, "IsCash": choicesPaymentType.cash}
		}

		if(coupons?.length > 0){
			data = {...data, "couponCode": coupons[0]}
		}

		if(choicesPaymentType.key === 2){
			data = {...data, "CreditCard": {...creditCard, CardNumber: creditCard?.CardNumber?.replace(/_| /gi, '')}}
		}

		if(choicesPaymentType.key === 1){
			data = {...data, "BankId": choicesPaymentType.id}
		}

		if(!isAuth){
			const localBasketData = JSON.parse(localStorage.getItem("localBasket")) ?? []
			const newBasket = localBasketData.map((elm) => {
				let index = -1
				if(choicesExtra){
					index = choicesExtra.map(elm => {return elm.variantCode}).indexOf(elm.variantCode)
				}
				if(index <= -1){
					return elm
				}
			})

			data = {...data, "BasketItems" : newBasket, "NonMemberMail": nonMemberMail}
		} else {
			if(basket){
				data = {...data, "BasketItems" : basketData.items, "NonMemberMail": getUserMail()}
			}
		}

		if(useSame){
			data = {...data, "IsSameAddress" : true}
		} else {
			data = {...data, "InvoiceAddress" : {...choicesInvoiceAddress, phone: choicesInvoiceAddress.phone.replace(/[^0-9]+/g, '')}}
		}

		return data
	}

	const changeStep = (step) => {
		setStep(step)
		props.history.push(props.match.path + "/" + convertStepName(step))
	}

	const setChoicesInstallment = data => {
		setStateChoicesInstallment(data)
		setInstallment(data?.installment ?? 0)
	}

	const updateContract = () => {
		let data = createOrderData(true)

		axios.post("/GenerateContracts", data, {headers: {"variantcodes": notInclude.join(), installment : installment, binnumber: binNumber }})
		.then(res => {
			setPreliminaryInformation(res.data?.data?.PI)
			setDistanceSalesAgreement(res.data?.data?.DSA)
		})
	}

	const convertStepName = (step) => {
		if(step === 2){
			return "teslimat-bilgileri"
		}else if(step === 3){
			return "odeme-bilgileri"
		}else if(step === 4){
			return "siparis-ozeti"
		}else{
			return ""
		}
	}

	const convertStepNumber = (value) => {
		if(value === "teslimat-bilgileri"){
			return 2
		}else if(value === "odeme-bilgileri"){
			return 3
		}else if(value === "siparis-ozeti"){
			return 4
		}
		return 1
	}

	const seoData = {
		title: t('Basket.basket-title') + config.prefix + config.name,
		description: t('Basket.basket-title') + config.prefix + config.name,
		robots: "noindex, nofollow"
	}

	return (
		<>
		<SeoComponent {...seoData}/>
		{
			step > 1  &&
			<header className="navbar navbar-expand-lg navbar-light basket">
				<div className="head">
					<div className="container">
						<span className="scl-head-msg base-bold pointer" onClick={() => props.history.push("/")} disabled>{t('Basket.go-back')}</span>
						<Link to="/sepet" className="logo">
							<Icons.SecilStoreLogo width={"1.6em"} height={undefined}/>
						</Link>
						<div className="user-menu">
							<div className="secure-pay">
								<Icons.ShieldTick />
								<span className="base-bold" disabled>{t('Basket.secure-payment')}</span>
							</div>
						</div>
					</div>
				</div>
			</header>
		}
		<div id="basket" className="pb-5" style={step > 1 ? {backgroundColor: "#f2f3f4"} : undefined}>
			{
				step === 1 && basketData?.failCampaigns?.length > 0 &&
				<span className="basketMessage">
					<Swiper slidesPerView={1} spaceBetween={0} autoplay={{delay: 5000}} loop={basketData?.failCampaigns?.length > 1}>
					{
						basketData?.failCampaigns?.map((elm, i) =>
							<SwiperSlide key={i}>
								{
									!elm?.promotional &&
									<span className="successCampaign">
										{elm?.name} Kampanyamızdan yararlanmak için
										{
											elm?.criteriaGroup?.missingPrice === 0 ?
												<span className="red"> {elm?.criteriaGroup?.missingQuantity} adet </span>
											:
												<span className="red"> {toPriceConvert(elm?.criteriaGroup?.missingPrice)}'lik </span>
										}
										daha ürün eklemelisiniz
									</span>
								}
								{
									elm?.promotional &&
									<span className="dangerCampaign">
										<span className="pointer" onClick={() => {
											const eln = document.getElementById("promotional")?.getBoundingClientRect()
											if(eln?.top){
												window.scrollTo({
													top: eln.top - 100,
													left: 0,
													behavior: 'smooth'
												});
											}
										}}>
										{elm?.name} Kampanyamıza hak kazandınız. <span className="msg-btn">Hediyenizi seçmek için tıklayın!</span>
										</span>
									</span>
								}
							</SwiperSlide>
						)
					}
					</Swiper>
				</span>
			}
			{
				step === 1 ?
				(
					<div className="container">
						<h1>{t('Basket.basket-title')}</h1>
						<BasketContent
							modal={false}
							changeStep={changeStep}
							nonMemberMail={nonMemberMail}
							setNonMemberMail={setNonMemberMail}
						/>
						<div className="clear-fix"></div>
					</div>
				)
				:
				(
				step === 2 ?
					isAuth ?
						<BasketOrder
							step={step}
							changeStep={changeStep}
							choicesInvoiceAddress={choicesInvoiceAddress}
							choicesDeliveryAddress={choicesDeliveryAddress}
							setChoicesDeliveryAddress={setChoicesDeliveryAddress}
							setDataAddress={setDataAddress}
							dataAddress={dataAddress}
							setChoicesInvoiceAddress={setChoicesInvoiceAddress}
							setInsertAdress={setInsertAdress}
							useSame={useSame}
							setUseSame={setUseSame}
						/>
					:
						<BasketOrderNon
							step={step}
							changeStep={changeStep}
							choicesInvoiceAddress={choicesInvoiceAddress}
							choicesDeliveryAddress={choicesDeliveryAddress}
							setChoicesDeliveryAddress={setChoicesDeliveryAddress}
							setChoicesInvoiceAddress={setChoicesInvoiceAddress}
							useSame={useSame}
							setUseSame={setUseSame}
							setInsertAdress={setInsertAdress}
							nonMemberMail={nonMemberMail}
							setNonMemberMail={setNonMemberMail}
						/>
				:
				(step === 3 ?
					<BasketPayment
						step={step}
						changeStep={changeStep}
						choicesInvoiceAddress={choicesInvoiceAddress}
						choicesDeliveryAddress={choicesDeliveryAddress}
						choicesPaymentType={choicesPaymentType}
						setChoicesPaymentType={setChoicesPaymentType}
						orderInsert={orderInsert}
						useSame={useSame}
						preliminaryInformation={preliminaryInformation}
						distanceSalesAgreement={distanceSalesAgreement}
						choicesInstallment={choicesInstallment}
						creditCard={creditCard}
						setCreditCard={setCreditCard}
						setChoicesInstallment={setChoicesInstallment}
						updateContract={updateContract}
						isGift={isGift}
						setIsGift={setIsGift}
						extraNote={extraNote}
						setExtraNote={setExtraNote}
						isBusy={isBusy}
						walletOn={walletOn}
						walletCode={walletCode}
						setWalletCode={setWalletCode}
						wallet={basketData?.wallet}
						walletDiscount={basketData?.walletDiscount}
					/>
				:
					<BasketSuccess
						step={step}
						changeStep={changeStep}
						{...props}
					/>
				)
				)
			}
		</div>
		</>
	);
}

export default Basket;
