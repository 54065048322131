import React from 'react'
import TypeLink from './../global/link'

function HomeTrends(props) {
	return (
		<div className="banner">
			<div className="container">
				{props.data.title && <h2><span>{props.data.title}</span></h2>}
				<div className="row g-2">
					{
						props.data.data.map((elm, i) =>
							<div className="col-12" key={i} >
								<TypeLink to={elm.url}>
									<img src={elm.img} alt={props.data.title} />
								</TypeLink>
							</div>
						)
					}
				</div>
			</div>
		</div>
	)
}

export default HomeTrends
