import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from "react-router-dom"
import moment from 'moment'
import { gaAddBasket } from './../../../package/google-analytic'
import { sizeConverter, sortFirstNumber } from './../../../package/global-function'
import config from './../../../package/config'
import Swal from 'sweetalert2'

import { dataLayerPush } from './../../../package/google-analytic'

import * as Icons from './../../icons/index'

import Number from './../forms/number'
import Button from './../forms/button'

import { useTranslation } from 'react-i18next';
import { AuthContext } from './../../../context/AuthContext'
import { FiltersContext } from './../../../context/FiltersContext'
import { BasketContext } from './../../../context/BasketContext'
import { FavoriteContext } from './../../../context/FavoriteContext'
import { AlarmContext } from './../../../context/AlarmContext'
import {LanguageContext} from './../../../context/LanguageContext'
import {LoadingContext} from './../../../context/LoadingContext'

function DetailInfo(props) {

	const {t} = useTranslation();
	const history = useHistory()
	const { isAuth, addDiscoveryProduct, setStateAuth } = useContext(AuthContext)
	const { getWhiteColor, whiteData, getBrandName } = useContext(FiltersContext)
	const { addBasket, isBasket } = useContext(BasketContext)
	const { addFavorite, isFavorite, removeFavorite } = useContext(FavoriteContext)
	const { addAlarm } = useContext(AlarmContext)
	const { toPriceConvert, toPrice } = useContext(LanguageContext)
	const { loadingRender, closeLoading, openLoading } = useContext(LoadingContext)

	const [data, setData] = useState(props.data)
	const [choicesColor, setChoicesColor] = useState(props.choicesColor)
	const [choicesBodyData, setChoicesBodyData] = useState(props.choicesBodyData)
	const [choicesBody, setChoicesBody] = useState(props.choicesBody)
	const [activeFavorite, setActiveFavorite] = useState(false)
	const [activeBasket, setActiveBasket] = useState(false)
	const [quantity, setQuantity] = useState(1)
	const [sizeConvert, setSizeConvert] = useState(localStorage.getItem("sizeConvert") ?? "TR")

	useEffect(() => {
		if(!props.data){
			openLoading()
		}else{
			closeLoading()
		}

		setChoicesBodyData(props.choicesBodyData)
		setData(props.data)
		setChoicesColor(props.choicesColor)
		setChoicesBody(props.choicesBody)
		if(props.data){
			addDiscoveryProduct(props.data.id)
		}
	}, [props])

	useEffect(() => {
		setActiveFavorite(false)
		setActiveBasket(false)

		if(choicesBody?.prices?.[0]){
			dataLayerPush({
				'event': 'detail-page',
				'value': toPrice([choicesBody.prices[0].Price1, choicesBody.prices[0].Price2, choicesBody.prices[0].Price3])
			})
		}
	}, [choicesColor, choicesBody])

	const changeQuantity = (value) => {
		setQuantity(value)
	}

	const addBasketHandler = () => {
		// Google Analytic
		let gaData = {
			id: data.code,
			title: data.detail.Title,
			brand: data.brandcode,
			variant: choicesColor.title,
			price: toPrice([choicesBody.prices[0].Price1, choicesBody.prices[0].Price2, choicesBody.prices[0].Price3]),
			quantity: quantity
		}

		if(props.match?.params?.category){
			gaData = {...gaData, category: props.match.params.category}
		}

		gaAddBasket(gaData)
		// Google Analytic

		addBasket({variantCode: choicesBody.code, productColorCode: data.code + "C" + choicesColor.code, quantity: quantity})
		.then(res => {
			if(res?.data?.isSuccess){
				Swal.fire({
					title: t('ContextMessage.basket.success'),
					showDenyButton: true,
					html:
					'<div class="add-basket-product">' +
					'<span class="img">' +
					'<img src="' + props.choicesImageData?.[0] + '" alt="" /></span>' +
					'<span class="title">' + data.detail.Title + '</span>' +
					'<span><b>' + t('Detail.info.color') + ':</b>' + choicesColor.title + '</span>' +
					'<span><b>' + t('Detail.info.size') + ':</b>' + choicesBody.name + '</span>' +
					'<span></span></div>',
					denyButtonText: t('ContextMessage.basket.go'),
					confirmButtonText: t('ContextMessage.basket.continue'),
				}).then((result) => {
					if (result.isDenied) {
						history.push("/sepet")
					}else if (result.isConfirmed) {
						if(props.quickActive && props.modalClose){
							props.modalClose()
						}
					}
				})
			}
		})
		setActiveBasket(true)
	}

	return (
		!data ?
			loadingRender()
		:
			<div className="row">
				<h1 className="title"><strong>{data.detail.Title} - {choicesColor.title}</strong></h1>

				<div className="info">
					<strong>{t('Detail.info.code')}:</strong>
					{data.code}
				</div>
				<div className="d-none d-md-block">
				<div className={'w-100 d-flex price' }>
					{
						choicesBody.prices?.length && choicesBody && toPriceConvert([choicesBody.prices[0].Price1, choicesBody.prices[0].Price2, choicesBody.prices[0].Price3], false, 2, true)
					}
				</div>
				</div>
				<div className="scl-select-option">
					<span><b>{t('Detail.info.color')}:</b> {choicesColor.title}</span>
					<ul className="list-inline section-colors m-0">
						{
							data?.colorsdetail?.map((elm, i) =>
							{
								if(elm.colorinfo){
									return <li
										key={i}
										className={"list-inline-item" + (choicesColor.code === elm.colorinfo[0].code ? ' active' : '') + (getWhiteColor(elm.colorinfo[0].code) > -1 ?  ' whiteColor' : '')}
										onClick={() => choicesColor.code !== elm.colorinfo[0].code ? props.selectColor(elm) : undefined}
										style={{backgroundColor: elm.colorinfo[0].hexcode}}>
										{choicesColor.code === elm.colorinfo[0].code ? <Icons.TickBold /> : undefined}
										<span></span>
									</li>
								}
							})
						}
					</ul>
				</div>
				<div className="scl-select-option">
					<div className="size-convert">
						{/*
							['TR','US'].map((elm, i) =>
								<span key={i} className={sizeConvert === elm ? 'active' : ''} onClick={() => {
									setSizeConvert(elm)
									localStorage.setItem("sizeConvert", elm)
								}}>{elm}</span>
							)
						*/}
					</div>
					{
						choicesBody &&
						<span className="w-100"><b>{t('Detail.info.size')}:</b> {sizeConverter(sizeConvert, choicesBody.name)} {choicesBody.stock <= 3 && choicesBody.stock > 0 && <span className="red ml-1">(Son {choicesBody.stock} ürün)</span>} {!props.quickActive && <span className="size-chart" data-bs-toggle="modal" data-bs-target="#sizeChartModal">{t('Detail.info.body-table')}</span>}</span>
					}
					<div className="sizes">
						{
							choicesBodyData && choicesBodyData.sort((a, b) => sortFirstNumber(a.name, b.name)).map((elm, i) =>
								<span
									key={i} className={(elm.stock > 0 ? (elm.stock < 2 ? ' danger' : '' ) : ' not' ) + (choicesBody.name === elm.name ? ' active' : '')} onClick={() => props.setChoicesBody(elm)}>
									{sizeConverter(sizeConvert, elm.name)}
								</span>
							)
						}
					</div>
				</div>
				<AuthContext.Consumer>
					{
						value =>
						<>
						<div className="d-none d-md-block">
						<div className="go-basket d-flex flex-row align-items-center">
								<div>
								{
									choicesBody && choicesBody.stock ?
										<Number max={choicesBody.stock} value={quantity} changeHandler={changeQuantity}/>
									:
										<span className="non-stock">TÜKENDİ</span>
								}
								</div>
								<div className="pl-5 pr-5 col-8">
									{
										choicesBody && choicesBody.stock ?
											<Button id="add-to-basket" className={(isBasket({VariantCode: choicesBody.code, ProductColorCode: data.code + "C" + choicesColor.code}) || activeBasket) ? 'active' : ''} title={(isBasket({VariantCode: choicesBody.code, ProductColorCode: data.code + "C" + choicesColor.code}) || activeBasket) ? t('Detail.info.added-basket') : t('Detail.info.add-basket')} onClick={addBasketHandler} />
										:
											<>
												{
													isAuth ?
														<button className="w-100" onClick={() => {
															addAlarm({variantCode: choicesBody.code, type: "product"})
														}}>{t('Detail.info.add-alarm')}</button>
													:
														<button className="w-100" onClick={() => setStateAuth('loginModalShow', true)}>{t('Detail.info.add-alarm')}</button>
												}
											</>
									}
								</div>
						</div>
						</div>

						<div className="malt d-sm-block d-md-none">
								<>
									{
										choicesBody && choicesBody.stock ?
											<Button id="add-to-basket" className={'add-to-basket ' + ((isBasket({VariantCode: choicesBody.code, ProductColorCode: data.code + "C" + choicesColor.code}) || activeBasket) ? 'active' : '')} onClick={addBasketHandler} >
												<>
													<div style={{float: 'left'}} className={'price align-items-center' }>
														{
															choicesBody.prices?.length && choicesBody && toPriceConvert([choicesBody.prices[0].Price1, choicesBody.prices[0].Price2, choicesBody.prices[0].Price3], false, 2, true)
														}
													</div>
													<div style={{float: 'right', 'white-space': 'normal'}}>
														{(isBasket({VariantCode: choicesBody.code, ProductColorCode: data.code + "C" + choicesColor.code}) || activeBasket) ? t('Detail.info.added-basket') : t('Detail.info.add-basket') }
													</div>
												</>
											</Button>
										:
											<>
												{
													isAuth ?
														<button className="add-to-basket" onClick={() => {
															addAlarm({variantCode: choicesBody.code, type: "product"})
														}}>{t('Detail.info.add-alarm')}</button>
													:
														<button className="add-to-basket" onClick={() => setStateAuth('loginModalShow', true)}>{t('Detail.info.add-alarm')}</button>
												}
											</>
									}
								</>
						</div>
						</>
					}
				</AuthContext.Consumer>
			</div>
	)
}

export default DetailInfo
