import React, { useState } from 'react'
import styles from './phoneSmsCode.module.css'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import yup from "./../../../package/yup-init";

import Input from './../../../components/widgets/forms/input'
import Button from './../../../components/widgets/forms/button'

import TimeCounter from './../../global/timeCounter'

const schema = yup.object().shape({
	code: yup.string()
	.length(4)
	.required(),
})

function PhoneSmsCode(props) {

	const [timeCount, setTimeCount] = useState()

	const { register, handleSubmit: onSubmit, errors, setError } = useForm({
		resolver: yupResolver(schema),
		reValidateMode: 'onChange'
	})

	const timeChangeHandler = time => {
		setTimeCount(time)
	}

	const inputChangeHandler = () => {
		props.onChange && props.onChange()
	}

	return (
		<div className={styles.smsCode}>
			<span className="red">{props.error}</span>
			<form onSubmit={onSubmit(e => props.onSubmit(e))}>
				<Input name="code" title="Doğrulama Kodu" placeholder="DOĞRULAMA KODU GİRİNİZ" error={errors?.code?.message} onChange={inputChangeHandler} transferref={register} />
				<div className="row">
					<div className={`col-1 ${styles.counter}`}>
					{String(timeCount).toMMSS()}
					<TimeCounter start={props.time ?? 200} visible={false} onChange={timeChangeHandler} finishedHandler={() => props.finishedHandler()}/>
					</div>
					<div className="col">
						<Button type="submit" title={timeCount === 0 ? "TEKRAR KOD İSTE" : "TAMAMLA"} className="btn float-right" onClick={() => timeCount === 0 && props.onSubmit()}/>
						{
							props.onCancel &&
							<Button type="button" title="VAZGEÇ" className="btn btn-reverse float-right mr-4" onClick={props.onCancel} />
						}
					</div>
				</div>
			</form>
		</div>
	)
}

export default PhoneSmsCode
