import React from 'react';
import {Route, Router} from "react-router-dom"
import { createBrowserHistory } from "history";
import $ from 'jquery'
import 'bootstrap'
import './assets/css/App.css'
import './assets/css/Ilmio.css'
import './assets/css/Responsive.css'

import AuthContextProvider from './context/AuthContext'
import GeoContextProvider from './context/GeoContext'
import LanguageContextProvider from './context/LanguageContext'
import BasketContextProvider from './context/BasketContext'
import FavoriteContextProvider from './context/FavoriteContext'
import FiltersContextProvider from './context/FiltersContext'
import LoadingContextProvider from './context/LoadingContext'
import AlarmContextProvider from './context/AlarmContext'

import Routing from './components/pages/routing'

function App() {

	if (parseInt(localStorage.getItem("clearStorage"), 0) < 4 || localStorage.getItem("clearStorage") === null) {
	  localStorage.clear();
		sessionStorage.clear();
	  localStorage.setItem("clearStorage", "4");
	}

	const history = createBrowserHistory()

	let prevLocation = ""
	history.listen((location, action)=> {
		if(prevLocation.match(new RegExp("/", "g"))?.length !== location.pathname.match(new RegExp("/", "g"))?.length || location.pathname.includes('sepet') || location.pathname.match(new RegExp("/", "g"))?.length > 2){
			if(action === "PUSH" && (location.state?.indexOf('noScroll') ?? -1) === -1){
				$("html").animate({scrollTop: 0}, 0)
				const modalBackground = document.querySelector(".modal-backdrop")
				if(modalBackground){
					modalBackground.remove()
				}
			}
		}
		prevLocation = location.pathname
	})

	return (
		<div className="App">
			<LoadingContextProvider>
				<AlarmContextProvider>
					<FiltersContextProvider>
						<GeoContextProvider>
							<BasketContextProvider>
								<FavoriteContextProvider>
									<LanguageContextProvider>
										<AuthContextProvider>
											<Router history={history}>
												<Route path="*" exact component={Routing}/>
											</Router>
										</AuthContextProvider>
									</LanguageContextProvider>
								</FavoriteContextProvider>
							</BasketContextProvider>
						</GeoContextProvider>
					</FiltersContextProvider>
				</AlarmContextProvider>
			</LoadingContextProvider>
		</div>
	)
}

export default App;
