import React, { useState, useEffect } from 'react'
import styles from './product.module.css'
import { useTranslation } from 'react-i18next'
import Number from './../../components/widgets/forms/number'
import Select from './../../components/widgets/forms/select'

function Product(props) {
	const {img, title, color, name, code} = props.data

	const { t } = useTranslation()

	const [quantity, setQuantity] = useState(props.data.quantity)
	const [reason, setReason] = useState()

	useEffect(() => {
		if(isActive()){
			props.onClick({variantCode: code, quantity: quantity, reasonCode: reason?.key}, true)
		}
	}, [reason])

	useEffect(() => {
		if(!reason){
			const thisItem = props.chooseData?.filter(e => e.variantCode === code)
			const reasonSelect = props.reasonCodes?.filter(e => e.key === thisItem?.[0]?.reasonCode)?.[0]
			if(reasonSelect){
				setReason(reasonSelect)
			}else{
				setReason(props.reasonCodes?.[0])
			}
		}
	}, [props.reasonCodes, props.chooseData])

	const onClick = e => {
		if(!(
			e.target.classList?.contains('input-number') ||
			e.target.parentElement.classList?.contains('input-number') ||
			e.target.parentElement.parentElement.classList?.contains('input-number') ||
			e.target.classList?.contains(styles.select) ||
			e.target.parentElement.classList?.contains(styles.select) ||
			e.target.parentElement.parentElement.classList?.contains(styles.select) ||
			e.target.parentElement.parentElement.parentElement.classList?.contains(styles.select) ||
			e.target.parentElement.parentElement.parentElement.parentElement.classList?.contains(styles.select) ||
			e.target.parentElement.parentElement.parentElement.parentElement.parentElement.classList?.contains(styles.select)
		)){
			props.onClick({variantCode: code, quantity: quantity, reasonCode: reason.key})
		}
	}

	const isActive = () => {
		const index = props.chooseData?.map(e => e.variantCode)?.indexOf(code)
		if(index > -1){
			return true
		}
		return false
	}

	const changeQuantity = value => {
		setQuantity(value)
		props.onClick({variantCode: code, quantity: value, reasonCode: reason.key}, true)
	}

	return (
		<div className={`noselect ${styles.product} ${(isActive() && styles.active)}`} onClick={onClick}>
			<div className={styles.img}>
				<img src={img} />
			</div>
			<div className={styles.info}>
				<span className="base-regular">{title}</span>
				<span>{t('UserPage.order-info.color')} : {color}</span>
				<span>{t('UserPage.order-info.body')} : {name}</span>
				<span>
					<span>{t('UserPage.order-info.quantity')} :</span> <span>
					{
						props.data.quantity > 1 ?
							<Number max={props.data.quantity} value={quantity} changeHandler={changeQuantity}/>
						:
							quantity
					}
					</span>
				</span>
			</div>
			{
				isActive() &&
				<div className={styles.select}>
					<Select label="İade sebebiniz nedir ?" options={props.reasonCodes} clicked={e => setReason(e)} selected={reason?.key} />
				</div>
			}
		</div>
	)
}

export default Product
